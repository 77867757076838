class LeftSideBar {
  static instance

  _isOpen = true

  changeLeftSideBarState() {
    this._isOpen = !this._isOpen
    return this._isOpen
  }

  getLeftSideBarState() {
    return this._isOpen
  }
}

export { LeftSideBar }
