export const handleFlowOrdersChange = (currentOrders, updateResults) => {
    if(!updateResults)
        return currentOrders

    let updatedOrders = []

    if(currentOrders.some(order => order.id === updateResults.id)){
        updatedOrders = currentOrders.map(order => {
            if(order.id === updateResults.id){
                order.targetId = updateResults.targetId || order.targetId
                order.sourceId = updateResults.sourceId || order.sourceId
                order.flowId = updateResults.flowId || order.flowId
                order.printerNames = updateResults.printerNames || order.printerNames
                order.printerTypes = updateResults.printerTypes || order.printerTypes
                order.standardNames = updateResults.standardNames || order.standardNames
                order.imageNames = updateResults.imageNames || order.imageNames
                order.validationResults = updateResults.validationResults || order.validationResults
                order.totalFileSize = updateResults.totalFileSize || order.totalFileSize
                order.status = updateResults.status || order.status
                order.created = updateResults.created || order.created
            }
            return order
        })
    }
    else{
        updatedOrders = currentOrders.slice()
        updatedOrders.push(updateResults)
    }

    return updatedOrders
}