import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M23.31,23.31l-6.55-6.55c1.93-1.73,3.18-4.33,3.18-7.12c0-5.29-4.33-9.63-9.63-9.63S0.69,4.35,0.69,9.64
	s4.33,9.63,9.63,9.63c2.12,0,4.14-0.67,5.78-1.93l6.64,6.64L23.31,23.31z M1.65,9.64c0-4.81,3.85-8.66,8.66-8.66
	s8.66,3.85,8.66,8.66s-3.85,8.66-8.66,8.66S1.65,14.45,1.65,9.64z"
    />
  </React.Fragment>,
  'SearchIcon'
)
