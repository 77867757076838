import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M9.11,16.47a.85.85,0,0,1,.26-.63.91.91,0,0,1,1.26,0,.9.9,0,0,1,0,1.27.91.91,0,0,1-1.26,0A.83.83,0,0,1,9.11,16.47Zm.23-9H10.7v6H9.34Z" />
    <path d="M10,2.13l8.41,17.38H1.59L10,2.13M10,0,0,20.48H20L10,0Z" />
  </React.Fragment>,
  'DangerIcon'
)
