import React from 'react'
import PropTypes from 'prop-types'

const TippyContentList = ({ contentList }) => {
  return (
    <span>
      {contentList.slice(1).map(item => {
        return (
          <span key={ Math.random() }>
            {item }<br />
          </span>
        )
      }) }
    </span>
  )
}

TippyContentList.propTypes = {
  contentList: PropTypes.array.isRequired
}

export default TippyContentList