import gql from 'graphql-tag'
import { client } from '../../config/Clients'
import * as types from '../types/permissionActionTypes'
import { getCustomerInfo } from '../../../../graphql/queries'

export const loadCustomerInfo = () => {
  return dispatch => {
    dispatch({
      type: types.LOAD_PERMISSIONS
    })
    return client.query({
      query: gql(getCustomerInfo),
      fetchPolicy: 'no-cache'
    })
      .then(res => dispatch({ type: types.LOAD_PERMISSIONS_SUCCESS, res }))
      .catch(err => dispatch({ type: types.LOAD_PERMISSIONS_FAILURE, err }))
  }
}

export const updatePermission = (cpId, role) => {
  return dispatch => {
    dispatch({
      type: types.UPDATE_PERMISSION
    })

    if (!cpId || !role)
      dispatch({ type: types.UPDATE_PERMISSION_FAILURE })

    dispatch({ type: types.UPDATE_PERMISSION_SUCCESS, cpId, role })
  }
}