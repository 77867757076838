import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CpPrinters from './../../CpPrinters'
import { unsubscribeFrom } from './../../../shared/duck/helpers/subscriptions'
import {
  getCustomerPrinters, 
  subscribeToCpAddPrinter, 
  subscribeToPrinterDelete,
  subscribeToPrinterOverviewUpdates,
  subscribeToPrinterDetailUpdates
} from './../printersActions'

class CpPrintersWithPrinters extends React.Component {
  fetchPrinters = this.fetchPrinters.bind(this)

  async componentDidMount() {
    await this.fetchPrinters()
  }  
  
  componentDidUpdate(prevProps){
    if(prevProps.printers.length !== this.props.printers.length){
      this.subscribeToPrinterOverviewUpdates()
      this.subscribeToPrinterDetailUpdates()
    }
  }

  componentWillUnmount() {
    unsubscribeFrom(this.props.updatePrinterOverviewSubscriptions)
    unsubscribeFrom(this.props.updatePrinterDetailSubscriptions)
    unsubscribeFrom(this.props.addPrinterSubscriptions)
    unsubscribeFrom(this.props.deletePrinterSubscriptions)
  }

  async fetchPrinters() {
    await this.props.getCustomerPrinters()

    if(!this.props.printers)
      return
    
    this.subscribeToPrinterOverviewUpdates()
    this.subscribeToPrinterDetailUpdates()
    this.subscribeToPrinterAdd()        
    this.subscribeToPrinterDelete()        
  }

  subscribeToPrinterAdd() {
    unsubscribeFrom(this.props.addPrinterSubscriptions)
    this.props.subscribeToCpAddPrinter(this.props.licensedCps)
  }

  subscribeToPrinterDelete() {
    unsubscribeFrom(this.props.deletePrinterSubscriptions)
    this.props.subscribeToPrinterDelete(this.props.licensedCps)    
  }

  subscribeToPrinterOverviewUpdates() {
    unsubscribeFrom(this.props.updatePrinterOverviewSubscriptions)
    this.props.subscribeToPrinterOverviewUpdates(this.props.licensedCps)
  }

  subscribeToPrinterDetailUpdates(){
    unsubscribeFrom(this.props.updatePrinterDetailSubscriptions)
    this.props.subscribeToPrinterDetailUpdates(this.props.printers)
  }

  render() {
    return <CpPrinters/>
  }
}

function mapStateToProps(state) {
  return {
    printers: state.printers.customerPrinters,
    addPrinterSubscriptions: state.printers.addPrinterSubscriptions,
    updatePrinterOverviewSubscriptions: state.printers.updatePrinterOverviewSubscriptions,
    updatePrinterDetailSubscriptions: state.printers.updatePrinterDetailSubscriptions,
    deletePrinterSubscriptions: state.printers.deletePrinterSubscriptions,
    printerDetailsUpdated: state.printers.printerDetailsUpdated,
    licensedCps: state.permissionsInfo.licensedCps
  }
}

CpPrintersWithPrinters.propTypes = {
  getCustomerPrinters: PropTypes.func.isRequired,
  subscribeToCpAddPrinter: PropTypes.func.isRequired,
  subscribeToPrinterDelete: PropTypes.func.isRequired,
  subscribeToPrinterOverviewUpdates: PropTypes.func.isRequired,
  subscribeToPrinterDetailUpdates: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {
  getCustomerPrinters, 
  subscribeToCpAddPrinter,
  subscribeToPrinterDelete,
  subscribeToPrinterOverviewUpdates,
  subscribeToPrinterDetailUpdates
})(CpPrintersWithPrinters)