import React from 'react'
import { createMuiTheme } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

const palette = {
  common: {
    black: '#000',
    white: '#fff',
    lightBorder: '#CCCCCC' // Mobile site Tab border
  },
  background: {
    paper: '#FAFAFA', // GMG: Light Grey Background (e.g. Joblist)
    default: '#EDEDED', // GMG: Mid Grey Background
    lightPaper: '#F5F5F5', // Mobile site Tab background
    darkPaper: '#E6E6E6' // Mobile site Tab background
  },
  primary: {
    light: '#EE2D68', // Not Used. Can be Overwritten
    main: '#EE2D68', // GMG-Red Light
    dark: '#D4285C', // GMG-Red State:Active
    contrastText: '#fff' // Not Used. Can be Overwritten
  },
  secondary: {
    // Not Used. Can be Overwritten => Partially applied in notification, cancel button in reset case.
    light: '#EEEEEE',
    main: '#999999',
    dark: '#666666',
    contrastText: '#fff'
  },
  error: {
    light: '#e57373',
    main: '#999',
    dark: '#333',
    contrastText: '#fff',
    red: '#ff0000'
  },
  text: {
    primary: '#333', // GMG Primary Text Color
    secondary: '#999', // GMG Secondary Text Color
    disabled: '#95989A', // GMG Disabled Text Color
    hint: '#333' // GMG Hint Text Color
  }
}

const defaultTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
})

const theme = createMuiTheme({
  palette,
  shadows: Array(25).fill('none'), // Disable shadows globally
  typography: {
    fontFamily: [
      'Lato',
      '"Helvetica Neue"',
      'Segoe UI',
      'Arial',
      'sans-serif'
    ].join(','),
    useNextVariants: true,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    body2: {
      fontSize: '0.8rem'
    },
    h4: {
      fontWeight: 500
    },
    h5: {
      fontWeight: 300
    },
    subtitle2: {
      fontWeight: 700
    },
    caption: {
      lineHeight: 1.3
    }
  },
  // Global overrides of Components
  overrides: {
    MuiButton: {
      // e.g. used on Login Page
      outlinedPrimary: {
        [ defaultTheme.breakpoints.down('lg') ]: {
          transition: 'unset'
        },
        borderRadius: '0.25em',
        borderWidth: '0.05em',
        '&:hover': {
          borderWidth: '0.05em',
          backgroundColor: palette.primary.main,
          color: palette.common.white,
          '@media (hover: none)': {
            backgroundColor: palette.primary.dark
          }
        },
        '&:active': {
          borderWidth: '0.05em',
          backgroundColor: palette.primary.dark,
          borderColor: palette.primary.dark,
          color: palette.common.white
        }
      },
      textSecondary: {
        color: '#999999',
        '&:hover': {
          borderWidth: '0.05em',
          backgroundColor: palette.background.default,
          color: palette.common.black
        }
      }
    },
    MuiDrawer: {
      root: {
        height: '100%'
      }
    },
    MuiOutlinedInput: {
      // e.g. used on Login Page
      root: {
        '&$focused $notchedOutline': {
          borderColor: palette.text.primary,
          borderWidth: '0.05em'
        }
      },
      input: {
        padding: '7px 10px 7px 10px'
      }
    },
    MuiInputBase: {
      input: {
        padding: '6px 10px 7px'
      }
    },
    // Required for DOM structure of Select Dropdowns.
    // Width goes always over the edge otherwise.
    MuiInput: {
      root: {
        width: '100%'
      }
    },
    MuiListItem: {
      dense: {
        // e.g. used on SignUp Page (Password Criterias)
        paddingTop: 0,
        paddingBottom: 0
      },
      button: {
        // e.g. used on JobList (Left Filter Panel)
        paddingTop: 18.5,
        paddingBottom: 18.5,
        '&$selected, &$selected:hover': {
          backgroundColor: palette.background.paper,
          borderRight: '2px solid ' + palette.background.default
        }
      }
    },
    MuiTabs: {
      root: {
        minHeight: 40
      },
      flexContainer: {
        marginTop: 0.2,
        overflow: 'hidden'
      },
      fixed: {
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        marginRight: 8
      }
    },
    MuiCheckbox: {
      // e.g. used on Login Page (Remember Me)
      colorPrimary: {
        '&$checked': {
          color: palette.text.primary
        }
      }
    },
    MuiTable: {
      root: {
        margin: '0px auto'
        // width: '96%'
      }
    },
    MuiFormControlLabel: {
      // e.g. used on Login Page (Remember Me)
      label: {
        fontSize: 12
      }
    },
    MuiTableCell: {
      root: {
        paddingRight: 20
      }
    },
    MuiPopover: {
      paper: {
        // e.g. used on Menu
        maxWidth: '100%'
      }
    },

    // TODO: Do not do this globally, this influences also ui widgets etc.
    MuiList: {
      padding: {
        paddingTop: 0
      }
    },
    MuiMenuItem: {
      root: {
        color: palette.common.white,
        marginLeft: '15%'
      }
    },
    MuiCardContent: {
      root: {
        padding: 0
      }
    },
    MuiModal: {
      root: {
        zIndex: 180000
      }
    },
    MuiSelect: {
      icon: {
        transform: 'rotate(270deg)',
        // width: 10,
        height: 10,
        marginTop: 8,
        marginRight: 33
      },
      selectMenu: {
        paddingLeft: 5
      }
    }
  }
})

export default function withTheme (WrappedComponent) {
  return class extends React.Component {
    render () {
      return (
        <MuiThemeProvider theme={ theme }>
          <CssBaseline />
          <WrappedComponent { ...this.props } />
        </MuiThemeProvider>
      )
    }
  }
}

export { theme }
