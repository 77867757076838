import React from 'react';
import { TextField } from "@material-ui/core"
import { Field } from 'react-final-form';
import { Validator } from '../../Types/types';

const inputPropsNoText = {
    style: {
      paddingLeft: 12,
      paddingRight: 12,
      height: "12px",
    },
    input: {
      textAlign: "left",
      height: "12px",
    },
  };

  const inputProps = {
    maxLength: 7,
    style: {
      paddingLeft: 3,
      paddingRight: 3,
      height: "12px",
      textAlign: "center",
    },
    input: {
      textAlign: "center",
      height: "12px",
    },
  };

interface Props {
    placeholder: string,
    name: string,
    validator: Validator;
};

export const ChannelValueTextField: React.FC<Props> = (props: Props) => {
    return (
      <Field name={props.name} placeholder={props.placeholder} validator={props.validator} validate={props.validator}>
          {props => (
            <div>
              <TextField variant="outlined" size="small" placeholder={props.placeholder}
                value={props.input.value}
                name={props.input.name}
                //type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if(e.target.value === "" || props.validator(e.target.value) == undefined) {
                    props.input.onChange(e)}
                  }
                }
                inputProps={props.input.value !== "" ? inputProps : inputPropsNoText}
              />
            </div>
          )}
      </Field>


    )
};

