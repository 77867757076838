import PermissionTypes from '../types/permissionTypes'
import RoleTypes from '../types/roleTypes'

export default class PermissionsValidator {
  constructor(sitePermissionsCurrentUser) {
    this.sitePermissions = sitePermissionsCurrentUser
  }

  defineUserPermissions = (sitePermission, permissionType) => {
    switch (permissionType) {
      case PermissionTypes.ViewColorProofGo:
        return sitePermission.role === RoleTypes.Viewer ||
          sitePermission.role === RoleTypes.Operator ||
          sitePermission.role === RoleTypes.Admin
      case PermissionTypes.ExecuteActions:
        return sitePermission.role === RoleTypes.Operator ||
          sitePermission.role === RoleTypes.Admin
      case PermissionTypes.ManageColorProofGo:
        return sitePermission.role === RoleTypes.Admin
      case PermissionTypes.ViewFlow:
        return sitePermission.flowLicensed
      case PermissionTypes.ManageFlow:
        return sitePermission.role === RoleTypes.Admin && sitePermission.flowLicensed
      case PermissionTypes.ShowFlowTab:
      case PermissionTypes.ShowCpHub:
      case PermissionTypes.ShowFlowItemForHub:
      case PermissionTypes.ShowFlowOrderTab:
      case PermissionTypes.ShowFlowOrderItemForHub:
      case PermissionTypes.ShowFlowStatistics:
        return sitePermission.role === RoleTypes.Viewer ||
          sitePermission.role === RoleTypes.Operator ||
          sitePermission.role === RoleTypes.Admin
      case PermissionTypes.ChangeCpHub:
      case PermissionTypes.AddFlowForHub:
      case PermissionTypes.RemoveFlowItemForHub:
      case PermissionTypes.RemoveFlowItemPairedClient:
        return sitePermission.role === RoleTypes.Admin
      default:
        console.log('UNKNOWN PERMISSION PASSED')
        return false
    }
  }

  hasUserPermissionForCp = (cpId, permissionType) => {
    const permission = this.sitePermissions.find(sp => sp.cpId === cpId)
    if (!permission)
      return false

    return this.defineUserPermissions(permission, permissionType)
  }

  hasUserFlowManagementPermissionForAtLeastOneCp = () => {
    return this.sitePermissions.some(p => p.flowLicensed && p.role === RoleTypes.Admin)
  }

  isUserLicensedAdmin = () => {
    return this.sitePermissions.some(p => p.licensed && p.role === RoleTypes.Admin)
  }

  hasUserLicenseAndExecutePermissions = () => {
    return this.sitePermissions.some(p => p.licensed && this.hasUserPermissionForCp(p.cpId, PermissionTypes.ExecuteActions))
  }

  hasUserFlowLicenseAndExecutePermissions = () => {
    return this.sitePermissions.some(p => p.licensed && this.hasUserPermissionForCp(p.cpId, PermissionTypes.ExecuteActions))
  }

  isCpLicensed = cpId => {
    return this.sitePermissions.some(p => p.cpId === cpId && p.licensed)
  }
}