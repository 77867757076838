
import * as types from '../types/permissionActionTypes'
import { findLicensedCps, updateCustomerInfo } from '../handlers/permissionsHandler'

let initialState = {
    customerInfo: [],
    licensedCps: []
}

export default function permissionsInfo(state = initialState, action) {    
    switch (action.type) {
        case types.LOAD_PERMISSIONS_SUCCESS:   
            const sitePermissionsCurrentUser = action.res.data.getCustomerInfo.items || []         
            return{
                customerInfo: sitePermissionsCurrentUser,
                licensedCps: findLicensedCps(sitePermissionsCurrentUser)
            }
        case types.UPDATE_PERMISSION_SUCCESS:
            const updatedCustomerInfo = updateCustomerInfo(action.cpId, action.role, state.customerInfo)
            return{
                customerInfo: updatedCustomerInfo,
                ...state
            }
        default:
            return state
    }
}