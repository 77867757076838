import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <circle style={{ fill: '#ee2d68' }} cx="254.1" cy="617" r="36.3" />
    <path
      style={{ fill: '#ee2d68' }}
      d="M627,10H227.8a72.85,72.85,0,0,0-72.6,72.6V917.4A72.85,72.85,0,0,0,227.8,990H772.2a72.85,72.85,0,0,0,72.6-72.6V264.1Zm0,54.4L797.6,264H663.3c-18.1,0-36.3-18.1-36.3-36.3Zm181.5,853c0,18.1-14.5,36.3-36.3,36.3H227.8c-18.1,0-36.3-14.5-36.3-36.3V82.6c0-18.1,14.5-36.3,36.3-36.3h363V227.8a72.85,72.85,0,0,0,72.6,72.6H808.6v617Z"
      transform="translate(-155.2 -10)"
    />
    <path
      style={{ fill: '#ee2d68' }}
      d="M264.1,881.1H736v-363H264.1Zm36.3-326.7H699.7V772.2L576.2,663.3,445.6,779.5l-54.4-43.6-90.7,72.6V554.4Z"
      transform="translate(-155.2 -10)"
    />
  </React.Fragment>,
  'FileIcon'
)
