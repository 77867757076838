import React from 'react'

const HelpCenterActive = props => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 26 26"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{'.prefix__st0{fill:#333}'}</style>
    <path
      className="prefix__st0"
      d="M13 1C6.4 1 1 6.4 1 13s5.4 12 12 12 12-5.4 12-12S19.6 1 13 1zm0 23C6.9 24 2 19.1 2 13S6.9 2 13 2s11 4.9 11 11-4.9 11-11 11z"
    />
    <path
      className="prefix__st0"
      d="M13.2 7c-2.5 0-3.9 1.2-3.9 3.6h1.1c0-1.8.9-2.7 2.8-2.7 1.3 0 2.5.9 2.5 2.3 0 .9-.5 1.6-1.1 2.2-1.3 1.2-1.7 2.1-1.7 3.7H14c.1-1.5 0-1.8 1.3-3.1.9-.8 1.5-1.6 1.5-2.9-.1-1.9-1.7-3.1-3.6-3.1zM13.2 17.7c-.5 0-1 .4-1 1s.4 1 1 1c.5 0 1-.4 1-1 0-.5-.4-1-1-1z"
    />
  </svg>
)

export default HelpCenterActive
