import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g>
      <path
        d="M10.8,16.08c-0.12,0.12-0.36,0.24-0.6,0.24s-0.36-0.12-0.6-0.24L6,12.48l1.2-0.96l3,2.88l7.92-7.92
			l1.08,1.2L10.8,16.08z"
      />
    </g>
    <path
      d="M20.4,1.2c1.32,0,2.4,1.08,2.4,2.4v16.8c0,1.32-1.08,2.4-2.4,2.4H3.6c-1.32,0-2.4-1.08-2.4-2.4V3.6
		c0-1.32,1.08-2.4,2.4-2.4H20.4 M20.4,0H3.6C1.56,0,0,1.56,0,3.6v16.8C0,22.44,1.56,24,3.6,24h16.8c2.04,0,3.6-1.56,3.6-3.6V3.6
		C24,1.56,22.44,0,20.4,0L20.4,0z"
    />
  </React.Fragment>,
  'CheckboxCheckedIcon'
)
