class NavigationService {
  // See: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/history.md
  _history = null
  _trackingService = null

  // Defines if CP GO is used in an emebedded mode
  _variant = null

  static instance

  constructor(history, trackingService) {
    // noinspection ES6ModulesDependencies
    if (NavigationService.instance) {
      // noinspection ES6ModulesDependencies
      return NavigationService.instance
    }

    this._history = history
    this._trackingService = trackingService

    this._history.listen(e => {
      this._trackingService.trackPage(e.pathname + (e.hash ? '#' + e.hash : ''))
    })

    const urlParams = new URLSearchParams(window.location.search)
    const variant = urlParams.get('variant')

    if (variant) {
      this._variant = variant
    }

    NavigationService.instance = this
  }

  isInitialized() {
    return this._history != null && this._trackingService != null
  }

  goTo(path, state = null) {
    if (this._variant) {
      let queryParamKeySymbol = '?'

      if (path.indexOf('?') > -1) {
        queryParamKeySymbol = '&'
      }

      path += queryParamKeySymbol + 'variant=' + this._variant
    }

    this._history.push(path, state)
  }

  getLocation(){
    const pathParts = this._history.location.pathname.split('/')
    return pathParts[1]
  }
}

export { NavigationService }
