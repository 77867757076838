export const unsubscribeFrom = (subscriptionsList) => {
    if (!subscriptionsList)
        return

    for (let key in subscriptionsList) {
        if (subscriptionsList.hasOwnProperty(key)) {
            subscriptionsList[key].unsubscribe()
        }
    }
    let props = Object.keys(subscriptionsList)
    for (let i = 0; i < props.length; i++) {
        delete subscriptionsList[props[i]]
    }
}