import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './App'
import AmplifyConfig from './app/shared/config/Amplify'
import configureStore from './app/store/configureStore'
import { client } from './app/shared/config/Clients'

window.ColorProof = {
  jobActionsEnabled: false,
  printerActionsEnabled: false
}

Amplify.configure(AmplifyConfig)
const store = configureStore()

client.resetStore().then(() => {
  ReactDOM.render(
    <ApolloProvider client={ client }>
      <Provider store={ store }>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>,
    document.getElementById('root')
  )
})