import Dmytro_Dev_Config from '../../../graphql/Dmytro_Dev_Config.json'
import GMG_Dev_Config from '../../../graphql/GMG_Dev_Config.json'
import GMG_QA_Config from '../../../graphql/GMG_QA_Config.json'
import GMG_Test_Config from '../../../graphql/GMG_Test_Config.json'
import GMG_Preprod_Config from '../../../graphql/GMG_Preprod_Config.json'
import GMG_Prod_Config from '../../../graphql/GMG_Prod_Config.json'
import Joachim_Dev_Config from '../../../graphql/Joachim_Dev_Config.json'

const local = {
  url: 'https://ht6e6nmpcvghdlkp5t4kehgqka.appsync-api.eu-central-1.amazonaws.com/graphql',
  region: 'eu-central-1'
}

let config = local

if (process.env.REACT_APP_STAGE === 'Prod') {
  config = GMG_Prod_Config[ 'appSync' ]
} else if (process.env.REACT_APP_STAGE === 'Preprod') {
  config = GMG_Preprod_Config[ 'appSync' ]
} else if (process.env.REACT_APP_STAGE === 'Test') {
  config = GMG_Test_Config[ 'appSync' ]
} else if (process.env.REACT_APP_STAGE === 'QA') {
  config = GMG_QA_Config[ 'appSync' ]
} else if (process.env.REACT_APP_STAGE === 'Dev') {
  config = GMG_Dev_Config[ 'appSync' ]
} else if (process.env.REACT_APP_STAGE === 'Dmytro_Dev') {
  config = Dmytro_Dev_Config[ 'appSync' ]
} else if (process.env.REACT_APP_STAGE === 'Joachim_Dev') {
  config = Joachim_Dev_Config[ 'appSync' ]
}
export default config
