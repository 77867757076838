import React from 'react'
import PropTypes from 'prop-types'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { DateRange } from 'react-date-range'
import { de, enUS } from 'date-fns/locale'

const DateRangeFilter = ({onChangeDateRange, locale, rootClass}) => {    
    const [open, setOpen] = React.useState(true)
    const [dateRange, setDateRange] = React.useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            color: '#E5004A'
        }
    ])    

    const handleClickAway = () => {
        setOpen(false)
        onChangeDateRange(dateRange[0])        
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                {open &&
                    <DateRange
                        locale={locale === "en-US" ? enUS : de}
                        weekStartsOn = {1} 
                        direction="horizontal"
                        ranges={dateRange}        
                        showDateDisplay={false}
                        className = {rootClass}
                        onChange={e => setDateRange([e.selection])}
                        showMonthAndYearPickers={false}
                    />
                }
            </div>
        </ClickAwayListener>
    )
}

DateRangeFilter.propTypes = {
    rootClass: PropTypes.string.isRequired,
    onChangeDateRange: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired
}

export default DateRangeFilter