import * as asn1js from 'asn1js'
import Certificate from 'pkijs/src/Certificate'
import OriginatorInfo from 'pkijs/src/OriginatorInfo'
import CertificateSet from 'pkijs/src/CertificateSet'
import EnvelopedData from 'pkijs/src/EnvelopedData'
import Attribute from 'pkijs/src/Attribute'
import ContentInfo from 'pkijs/src/ContentInfo'
import { getCrypto } from 'pkijs/src/common'

export const createPublicKey = async () => {
  const crypto = getCrypto()
  if ( typeof crypto === "undefined" )
    throw new Error( "No WebCrypto extension found" )

  const publicKey = await crypto.generateKey({ name: "AES-CBC", length: 128 }, true, [ "encrypt", "decrypt" ] )
  const algorithm = await getDataEncryptionAlgorithm( publicKey )

  return { publicKey, algorithm }
}

export const encryptData = async ( keyData, file ) => {
  if ( !keyData.publicKey )
    throw new Error( "publicKey not found." )

  const crypto = getCrypto()
  if ( typeof crypto === "undefined" )
    throw new Error( "No WebCrypto extension found" )

  const data = await convertFile( file )

  return await crypto.encrypt( keyData.algorithm, keyData.publicKey, data )
}

export const createEnvelope = async ( keyData, certId, certString ) => {
  if ( !keyData.publicKey )
    throw new Error( "PublicKey not found." )

  if ( !certString )
    throw new Error( "Certificate not found." )

  if ( !certId )
    throw new Error( "Certificate Id not found." )

  const cert = await parseCertificate( certString )
  const attribute = createEnvelopeAttribute( certId )
  const info = createOriginatorInfo( cert )
  const convertedKey = await convertKey( keyData.publicKey )

  const envelope = new EnvelopedData( {
    originatorInfo: info,
    unprotectedAttrs: [ attribute ]
  } )
  envelope.addRecipientByCertificate( cert, { oaepHashAlgorithm: "SHA-1" } )

  await envelope.encrypt( keyData.algorithm, convertedKey )

  const envelopedContent = createEnvelopedContent( envelope )

  return arrayBufferToBase64( envelopedContent.toSchema().toBER( false ) )
}

const createEnvelopedContent = envelope => {
  const envelopedContent = new ContentInfo()
  envelopedContent.contentType = "1.2.840.113549.1.7.3"
  envelopedContent.content = envelope.toSchema()

  return envelopedContent
}

const convertKey = async ( keyToConvert ) => {
  const crypto = getCrypto()
  if ( typeof crypto === "undefined" )
    throw new Error( "No WebCrypto extension found" )

  return await crypto.exportKey( "raw", keyToConvert )
}

const createEnvelopeAttribute = strValue => {
  const attribute = new Attribute( {
    type: "1.2.840.113549.1.9.9",
    values: [ new asn1js.PrintableString( { value: strValue } ) ]
  } )

  return attribute
}

const createOriginatorInfo = cert => {
  const info = new OriginatorInfo( {
    certs: new CertificateSet( {
      certificates: [ cert ]
    } )
  } )

  return info
}

const getDataEncryptionAlgorithm = async publicKey => {
  //let iv = getRandomValues( new Uint8Array( 16 ) )
  let iv = await convertKey( publicKey )
  return {
    name: "AES-CBC",
    iv: Buffer.from( iv ),
    length: 128
  }
}

const convertFile = async file => {
  return new Promise( ( resolve, reject ) => {
    const reader = new FileReader()

    reader.onload = ( event ) => {
      resolve( event.target.result )
    }

    reader.onerror = ( err ) => {
      reject( err )
    }

    reader.readAsArrayBuffer( file )
  } )
}

const arrayBufferToBase64 = aBuf => {
  var encryptedBytes = Buffer.from( aBuf )

  return encryptedBytes.toString( 'base64' )
}

const parseCertificate = async certString => {
  const payload = ( await certString ).replace( /(-----(BEGIN|END) CERTIFICATE-----|\n)/g, "" )
  const asn1 = asn1js.fromBER( new Uint8Array( Buffer.from( payload, 'base64' ) ).buffer )

  return new Certificate( { schema: asn1.result } )
}