import * as types from './types'
import { handleFlowOrdersChange } from './handlers/flowOrdersHandler'

let initialState = {
    orders: [],
    subscription: null,
    ordersUpdated: false,
    graphQLErrors: null
}

export default function flowOrders(state = initialState, action) {
    switch (action.type) {
        case types.LIST_FLOW_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.res.data.customerListOrder.items,
                ordersUpdated: false
            }
        case types.LIST_FLOW_ORDERS_FAILURE:
            return {
                ...state,
                ordersUpdated: false,
                graphQLErrors: action.err
            }
        case types.SUBSCRIBE_TO_FLOW_ORDERS_UPDATES_SUCCESS:
            const updatedOrders = handleFlowOrdersChange(state.orders, action.data)
            return {
                ...state,
                orders: updatedOrders,
                subscription: action.subscription,
                ordersUpdated: true
            }
        default:
            return state
    }
}