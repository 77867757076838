//google analytics constants -->
export const GA_LOGIN = '/login'
export const GA_TERMS_OF_SERVICE = 'https://gmgcolor.com/terms-conditions'

export const GA_HOME = '/'
export const GA_JOBS = '/jobs'
export const GA_PRINTERS = '/printers'
export const GA_FLOW_ORDERS = '/flowOrders'
export const GA_SETTINGS = '/settings'
export const GA_SETTINGS_SYSTEMS = '/settings/systems'
export const GA_SETTINGS_USERS = '/settings/users'
export const GA_SETTINGS_FLOWS = '/settings/flows'
export const GA_JOB_ID = '/jobs/:id'
//<--