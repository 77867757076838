export const SET_LIVE_SEARCH_TERM = 'SET_LIVE_SEARCH_TERM'
export const SET_LIVE_SEARCH_TERM_FAILURE = 'SET_LIVE_SEARCH_TERM_FAILURE'

export const SET_FILTER_CHIP = 'SET_FILTER_CHIP'
export const SET_FILTER_CHIP_FAILURE = 'SET_FILTER_CHIP_FAILURE'

export const REMOVE_FILTER_CHIP = 'REMOVE_FILTER_CHIP'
export const REMOVE_FILTER_CHIP_FAILURE = 'REMOVE_FILTER_CHIP_FAILURE'

export const CHANGE_FILTER_CHIP_STATE = 'CHANGE_FILTER_CHIP_STATE'
export const CHANGE_FILTER_CHIP_STATE_FAILURE = 'CHANGE_FILTER_CHIP_STATE_FAILURE'

export const SET_DATERANGE_FLOWS_STATISTICS_VALUES = 'SET_DATERANGE_FLOWS_STATISTICS_VALUES'
export const SET_DATERANGE_FLOWS_STATISTICS_VALUES_FAILURE = 'SET_DATERANGE_FLOWS_STATISTICS_VALUES_FAILURE'

export const SET_DATERANGE_ORDERS_LIST_VALUES = 'SET_DATERANGE_ORDERS_LIST_VALUES'
export const SET_DATERANGE_ORDERS_LIST_VALUES_FAILURE = 'SET_DATERANGE_ORDERS_LIST_VALUES_FAILURE'