import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles/index'
import Paper from '@material-ui/core/Paper/index'
import Table from '@material-ui/core/Table/index'
import TableBody from '@material-ui/core/TableBody/index'
import TableCell from '@material-ui/core/TableCell/index'
import TableHead from '@material-ui/core/TableHead/index'
import TableRow from '@material-ui/core/TableRow/index'
import ShowAllIcon from '../../assets/icons/ShowAllIcon'
import PrintIcon from '../../assets/icons/PrintIcon'
import InProgressIcon from '../../assets/icons/InProgressIcon'
import DangerIcon from '../../assets/icons/DangerIcon'
import LeftSidePane from '../shared/Components/SideBar/LeftSidePane'
import styles from '../shared/Styles/styles'
import ServiceContext from '../shared/Services/ServiceContext'
import { getFlowOrders, subscribeToFlowOrdersUpdates } from './duck/flowOrdersActions'
import { listRows } from './Components/ListRows'
import { FlowOrdersCategories } from './duck/helper/Categories'
import { getStartDate, getEndDate, defaultDateRangeValues } from '../shared/duck/helpers/dateTime'
import { setDateRangeFlowOrdersValues } from '../shared/duck/actions/filtersActions'

class CpFlowOrders extends React.Component {
  static contextType = ServiceContext

  state = {
    selectedFilter: 'inProgress',
    filteredFlowOrders: null
  }
  categories = new FlowOrdersCategories()

  async componentDidMount () {
    await this.preloadFlowOrders()
  }

  componentDidUpdate (prevProps) {
    if (this.props.flowOrdersUpdated && prevProps.flowOrdersUpdated !== this.props.flowOrdersUpdated)
      this.handleSearchTerms()
    if (prevProps.flowOrders.length !== this.props.flowOrders.length) {
      this.subscribeToFlowOrders()
      this.handleSearchTerms()
    }
    if (prevProps.filterChips !== this.props.filterChips)//filterchips modified
      this.handleSearchTerms()
    if (prevProps.liveSearchTerm && this.props.liveSearchTerm !== prevProps.liveSearchTerm)
      this.handleLiveSearch()
  }

  componentWillUnmount () {
    this.unsubscribeFromFlowOrders()
  }

  async preloadFlowOrders () {
    let sd = new Date(this.props.dateRangeValues.startDate)
    let ed = new Date(this.props.dateRangeValues.endDate)
    sd = getStartDate(sd.getFullYear(), sd.getMonth(), sd.getDate())
    ed = getEndDate(ed.getFullYear(), ed.getMonth(), ed.getDate() + 1)

    await this.props.getFlowOrders(sd, ed)
    this.subscribeToFlowOrders()
  }

  subscribeToFlowOrders () {
    this.unsubscribeFromFlowOrders()
    this.props.subscribeToFlowOrdersUpdates()
  }

  unsubscribeFromFlowOrders () {
    // if(this.props.flowOrdersSubscription)
    //   this.props.flowOrdersSubscription.unsubscribe()
  }

  handleSearchTerms () {
    const newFilteredFlowOrders = this.applySearchTerms()
    this.setState({ filteredFlowOrders: newFilteredFlowOrders })
  }

  handleLiveSearch () {
    if (!this.props)
      return

    const searchTerm = this.props.liveSearchTerm
    const flowOrders = this.applySearchTerms()
    const filteredFlowOrders = flowOrders.filter(item => this.isJobMeetSearchTerm(item, searchTerm))

    this.setState({ filteredFlowOrders: filteredFlowOrders })
  }

  applySearchTerms () {
    const page = this.context.navigationService.getLocation()
    const searchTerms = this.props.filterChips[ page ] ? this.props.filterChips[ page ].filter(t => t.isActive) : []

    return this.props.flowOrders.filter(item => searchTerms.every(searchTerm => this.isJobMeetSearchTerm(item, searchTerm.value)))
  }

  isJobMeetSearchTerm (flowOrder, searchTerm) {
    if (!searchTerm)
      return true

    return this.findFlowName(flowOrder, searchTerm) ||
      this.findSourceOrTargetName(flowOrder, searchTerm) ||
      (flowOrder.status && flowOrder.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (flowOrder.imageNames && flowOrder.imageNames.join().toLowerCase().includes(searchTerm.toLowerCase())) ||
      (flowOrder.printerNames && flowOrder.printerNames.join().toLowerCase().includes(searchTerm.toLowerCase())) ||
      (flowOrder.standardNames && flowOrder.standardNames.join().toLowerCase().includes(searchTerm.toLowerCase()))
  }

  findFlowName = (flowOrder, searchTerm) => {
    const flow = this.props.flows.find(flow => flow.id === flowOrder.flowId)
    if (!flow)
      return

    return flow.name.toLowerCase().includes(searchTerm.toLowerCase())
  }

  findSourceOrTargetName = (flowOrder, searchTerm) => {
    const site = this.props.sites.find(site => site.cpId === flowOrder.targetId || site.cpId === flowOrder.sourceId)

    if (!site)
      return

    return site.site && site.site.toLowerCase().includes(searchTerm.toLowerCase()) ||
      site.alias && site.alias.toLowerCase().includes(searchTerm.toLowerCase())
  }

  getNumberOfOrders = filter => {
    const { flowOrders, flows } = this.props

    if (!flowOrders)
      return 0

    const validOrders = this.getValidFlowOrders(flowOrders, flows)
    if (!filter)
      return validOrders.length

    const filterStates = this.categories.getCategoryStates(filter)
    return validOrders.filter(order => filterStates.includes(order.status)).length
  }

  getValidFlowOrders = (allFlowOrders, flows) => {
    return (allFlowOrders || []).filter(order => flows.find(flow => flow.id === order.flowId))
  }

  filterFlowOrdersCounter = () => ({
    inProgress: this.getNumberOfOrders('inProgress'),
    inError: this.getNumberOfOrders('inError'),
    complete: this.getNumberOfOrders('complete'),
    showAll: this.getNumberOfOrders()
  })

  handleLeftSidePaneItemClick = filterName => {
    this.setState({ selectedFilter: filterName })
    this.handleSearchTerms()
  }

  renderLeftSideBar () {
    const categories = [
      {
        name: 'inProgress',
        label: 'In Progress',
        id: 'cpFlowOrders.inProgress',
        icon: <InProgressIcon />
      },
      {
        name: 'inError',
        label: 'In Error',
        id: 'cpFlowOrders.inError',
        icon: <DangerIcon />
      },
      {
        name: 'complete',
        label: 'Complete',
        id: 'cpFlowOrders.complete',
        icon: <PrintIcon />
      },
      {
        name: 'showAll',
        label: 'Show All',
        id: 'cpFlowOrders.showAll',
        icon: <ShowAllIcon />
      }
    ]

    return (
      <LeftSidePane
        onCategoryClick={ this.handleLeftSidePaneItemClick }
        onDateRangeChange={ this.changeOrdersDateRange }
        classes={ this.props.classes }
        data={ {
          counter: this.filterFlowOrdersCounter(),
          selectedFilter: this.state.selectedFilter,
          categories: categories,
          dateRangeFilter: true
        } }
      />
    )
  }

  changeOrdersDateRange = async e => {
    await this.props.setDateRangeFlowOrdersValues(e)
    await this.props.getFlowOrders(e.startDate, e.endDate)

    this.handleSearchTerms()
  }

  renderFlowOrder = (flowOrder, rows) => {
    const { classes } = this.props
    const flow = this.props.flows.find(flow => flow.id === flowOrder.flowId)
    if(!flow || !flow.name)
      return

    return (
      <TableRow
        key={ flowOrder.id }
        className={ classes.hoverRow }
      >
        {rows.map(row => (
          <TableCell
            key={ `${flowOrder.id}-${row.key}` }
            className={ classNames(classes.tableCell, {
              [ classes.tableCellHideMobile ]: row.hideMobile,
              [ classes.tableCellHideTablet ]: row.hideTablet,
              [ classes.tableCellSpacer ]: row.key === 'spacerLeft' || row.key === 'spacerRight',
              [ classes.tableHeadCellState ]: row.key === 'status',
              [ classes.tableValueAdjustStatus ]: row.key === 'status'
            }) }
          >
            {row.customTemplate && row.customTemplate(flowOrder, flow.name) }
            {!row.customTemplate && flowOrder[ row.key ] }
          </TableCell>
        )) }
      </TableRow>
    )
  }

  renderFlowOrders () {
    if (!this.props.flowOrders)
      return

    const { classes } = this.props
    const filterStates = this.categories.getCategoryStates(this.state.selectedFilter)
    const flowOrders = (this.state.filteredFlowOrders || this.props.flowOrders) || []
    const filteredFlowOrders = filterStates ? flowOrders.filter(order => filterStates.includes(order.status)) : flowOrders
    const filteredAndSortedOrders = filteredFlowOrders.sort((a, b) => (a.created < b.created ? 1 : -1))
    const rows = listRows(classes, this.props.sites)

    return (
      <Table classes={ { root: classes.tableHeadRoot } }>
        <TableHead >
          <TableRow className={ classNames(classes.tableHeadRow) }>
            { rows.map(row => (
              <TableCell
                className={ classNames(classes[ row.headClassName ], {
                  [ classes.tableCellHideMobile ]: row.hideMobile,
                  [ classes.tableCellHideTablet ]: row.hideTablet,
                  [ classes.tableCellSpacer ]: row.key === 'spacerLeft' || row.key === 'spacerRight',
                  [ classes.tableHeadCellState ]: row.key === 'status',
                  [ classes.tableHeadAdjustStatus ]: row.key === 'status'
                }) }
                key={ row.key }
                align={ row.numeric ? 'right' : 'left' }
              >
                {row.label }
              </TableCell>
            )) }
          </TableRow>
        </TableHead>
        <TableBody>
          { filteredAndSortedOrders.length ? (
            filteredAndSortedOrders.map(order => this.renderFlowOrder(order, rows))
          ) : (
              <TableRow>
                <TableCell
                  colSpan={ 1 }
                  className={ classes.tableCellSpacerMobile }
                />
                <TableCell
                  colSpan={ rows.length - 2 }
                  className={ classes.tableCell }
                >
                  <span className={ classes.noJobs }>
                    <FormattedMessage
                      id="cpFlowOrders.noFlowOrders"
                      defaultMessage="No Flow Orders"
                    />
                  </span>
                </TableCell>
                <TableCell
                  colSpan={ 1 }
                  className={ classes.tableCellSpacerMobile }
                />
              </TableRow>
            ) }
        </TableBody>
      </Table>
    )
  }

  render () {
    return (
      <div className={ this.props.classes.root }>
        {this.renderLeftSideBar() }
        <Paper square className={ this.props.classes.mainPaper }>
          { this.renderFlowOrders() }
        </Paper>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    flowOrders: state.flowOrders.orders,
    flowOrdersSubscription: state.flowOrders.subscription,
    flowOrdersUpdated: state.flowOrders.ordersUpdated,
    flows: state.pairingInfo.flows,
    users: state.pairingInfo.users,
    sites: state.pairingInfo.sites,
    liveSearchTerm: state.filters.liveSearchTerm,
    currentSearchTerm: state.filters.currentSearchTerm,
    filterChips: state.filters.chips,
    dateRangeValues: state.filters.dateRangeOrdersListValues
  }
}

CpFlowOrders.defaultProps = {
  dateRangeValues: defaultDateRangeValues
}

CpFlowOrders.propTypes = {
  getFlowOrders: PropTypes.func.isRequired,
  subscribeToFlowOrdersUpdates: PropTypes.func.isRequired,
  setDateRangeFlowOrdersValues: PropTypes.func.isRequired,
  flowOrders: PropTypes.array,
  sites: PropTypes.array,
  users: PropTypes.array,
  flows: PropTypes.array,
  liveSearchTerm: PropTypes.string,
  currentSearchTerm: PropTypes.object,
  filterChips: PropTypes.object,
  dateRangeValues: PropTypes.object.isRequired
}

export default withStyles(styles)(
  injectIntl(
    withRouter(
      connect(mapStateToProps,
        {
          getFlowOrders,
          subscribeToFlowOrdersUpdates,
          setDateRangeFlowOrdersValues
        }
      )(CpFlowOrders)
    )
  )
)