export type Validator = (input: string) => string | undefined;

export interface Cmyk {
  c: string,
  m: string,
  y: string,
  k: string
}

export interface Lab {
  l: string,
  a: string,
  b: string,
}

export interface Channel {
  id: string;
  name: string;
  selectedColor: string;
  cmyk?: Cmyk;
  lab?: Lab;
  replaceColorName?: string;
  enabled?: boolean;
  availableColors: string[];
  printOrder: string
}

export interface ChannelValueRowItem {
    placeholder: string,
    name: string,
    validator: Validator;
}

export interface ChannelType {
  name: string,
  select: string,
  cmyk: Cmyk,
  lab: Lab,
  replaceColor?: string
}

export interface ValueMapType {
  [input: string]: ChannelType;
}

export const required = (value: any) => (value ? undefined : 'Required')

export const mustBeNumber = (value: any) => (isNaN(value) ? 'Must be a number' : undefined)

export const maxValue = (max: number) => (value: any) =>
    isNaN(value) || value <= max ? undefined : `Should be less than ${max}`

export const minValue = (min: number) => (value: any) =>
    isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`

export const composeValidators = (...validators: any[]) => (value: any) =>
    validators.reduce((error, validator) => error || validator(value), undefined)

export const isThreeDigitNumber = (value: string): string | undefined => {
  const re = /^[0-9\b]{1,3}$/;

  if (value === '' || re.test(value)) {
    return undefined;
  }

  return "is not valid";
}

export const ciEquals = (a: string, b: string) => {
  return typeof a === 'string' && typeof b === 'string'
      ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
      : a === b;
}