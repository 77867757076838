import * as types from './types'

let initialState = {
  flowOrderStatistics: {}
}

export default function settings(state = initialState, action) {
    switch (action.type) {
      case types.GET_FLOW_ORDER_STATISTICS_SUCCESS:
        return Object.assign({}, state, {
            flowOrderStatistics: action.res.data.getOrderStatistics
        })
        default:
      return state
  }
}