class SessionService {
  _cognitoUser = null

  static instance

  constructor() {
    // noinspection ES6ModulesDependencies
    if (SessionService.instance) {
      // noinspection ES6ModulesDependencies
      return SessionService.instance
    }

    SessionService.instance = this
  }

  setUser = cognitoUser => {
    this._cognitoUser = cognitoUser
  }

  getUser = () => {
    return this._cognitoUser
  }

  //TODO: move to store
  getChips = () => {
      const serializedChips = localStorage.getItem('chips')
      if (serializedChips === null)
          return

      let chips = JSON.parse(serializedChips)

      return chips.value
  }

  //TODO: move to store
  setChips = (chips) => {
      const serializedChips = JSON.stringify({'value': chips})
      localStorage.setItem('chips', serializedChips)
  }
}

export { SessionService }
