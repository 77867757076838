import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <rect className="cls-1" y="9.65" width="22" height="3" />
    <rect className="cls-1" x="9.65" width="3" height="22" />
  </React.Fragment>,
  'PlusIcon'
)
