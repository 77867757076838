import React from 'react'
import classNames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Fade from '@material-ui/core/Fade'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DangerIcon from '../../../../assets/icons/DangerIcon'
import InfoActiveIcon from '../../../../assets/icons/InfoActiveIcon'
import CheckmarkCircleIcon from '../../../../assets/icons/CheckmarkCircleIcon'
import CloseIcon from '../../../../assets/icons/CloseIcon'
import { NotificationTypes } from '../../../shared/duck/types/notificationTypes'

const styles = theme => ({
  notificationContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 100001,
    left: 0,
    bottom: 0
  },

  notification: {
    // Twice the spacing in the grid!
    padding: theme.spacing(2),
    borderRadius: 0,
    color: theme.palette.text.primary,

    minWidth: '100%',
    minHeight: '120px',
    '& span': {
      display: 'flex'
    },
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 10002,

    // Centers the content vertically
    display: 'flex',
    align: 'left',
    alignItems: 'center'
  },

  notificationTwoCol: {
    backgroundColor: '#EDEDED'
  },

  notificationThreeCol: {
    backgroundColor: '#CCCCCC'
  },

  notificationIcon: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },

  alignLeft: {
    textAlign: 'left'
  },

  alignRight: {
    textAlign: 'right'
  },

  alignJustify: {
    textAlign: 'justify'
  },

  close: {
    cursor: 'pointer',
    padding: 16,
    position: 'absolute',
    top: 0,
    right: 0
  },

  closeIcon: {
    fontSize: 20
  },

  blanket: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.75)',
    zIndex: 10002
  }
})

class Notification extends React.Component {
  // TODO: Currently this doesn't work due to React.createElement (could not find out how to use this properly)
  // static contextType = ServiceContext

  _slideTimeoutSeconds = 400
  _fadeTimeoutSeconds = 400
  _animRunning = false

  constructor(props) {
    super(props)
    this.defaultButtonLabel = this.props.intl.formatMessage({
      id: 'notification.close'
    })
    this.state = {
      bottom: 0,
      fadeIn: true,
      fadeTimeout: 0
    }
    this.updateNotificationDimensions = this.updateNotificationDimensions.bind(
      this
    )
    this.close = this.close.bind(this)
    this.defaultButtonAction = this.defaultButtonAction.bind(this)

    this.timer = null
  }

  componentDidMount () {
    this.updateNotificationDimensions()
    window.addEventListener('resize', this.updateNotificationDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateNotificationDimensions)
  }

  close () {
    if (!this._animRunning) {
      // TODO: Figure out how to pass this via context API when using React.createElement (see Authenticator, services passed as props)
      this.props.services.trackingService.trackEvent(
        'Notification Bar',
        'Notification Close',
        '1',
        1
      )
      this._animRunning = true
      this.setState({ fadeIn: false, fadeTimeout: this._fadeTimeoutSeconds })
      setTimeout(() => {
        this._animRunning = false
        this.props.setNotification(null)
        document.removeEventListener('click', this.closeOnClickOutside)
        clearTimeout(this.timer)
        this.setState({ fadeIn: true, fadeTimeout: 0 })

        // We cannot change the body style with material UI, hence we apply
        document.body.style.overflowY = 'auto'
      }, this._fadeTimeoutSeconds)
    }
  }

  defaultButtonAction () {
    this.close()
  }

  updateNotificationDimensions () {
    if (window.innerHeight < 570) {
      this.setState({ bottom: 40 })
    }
  }

  // Clicking outside the notification triggers a close always
  closeOnClickOutside = event => {
    // If the click origin is outside the notification container
    let notificationsContainer = document.querySelector(
      '#notification-container'
    )
    if (
      notificationsContainer &&
      !notificationsContainer.contains(event.target)
    ) {
      this.close()
    }
  }

  render () {
    const { notification, classes } = this.props

    if (!notification) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      return null
    }

    // We cannot change the body style with material UI, hence we apply it directly and only temporarily
    document.body.style.overflowY = 'hidden'

    // When clicking outside the notification, hide it
    document.removeEventListener('click', this.closeOnClickOutside)
    document.addEventListener('click', this.closeOnClickOutside)

    if (notification.timeout) {
      this.timer = setTimeout(() => {
        clearTimeout(this.timer)
        this.props.setNotification(null)
      }, notification.timeout)
    }

    return (
      <Fade in={ this.state.fadeIn } timeout={ this.state.fadeTimeout }>
        <div
          id="notification-container"
          className={ classes.notificationContainer }
          style={ { bottom: notification.offset } }
        >
          { notification.showBlanket && <div className={ classes.blanket } /> }

          <Slide direction="up" in={ true } timeout={ this._slideTimeoutSeconds }>
            <div
              className={ classNames(classes.notification, {
                [ classes.notificationTwoCol ]: notification.layout === '2col',
                [ classes.notificationThreeCol ]: notification.layout === '3col'
              }) }
              style={ { bottom: this.state.bottom } }
            >
              <Grid
                container
                spacing={ 0 }
                align="center"
                alignItems="center"
                justify="center"
                direction="row"
              >
                <Grid item xs={ 1 } className={ classes.notificationIcon }>
                  { (() => {
                    switch (notification.type) {
                      case NotificationTypes.Success:
                        return <CheckmarkCircleIcon />
                      case NotificationTypes.Warning:
                        return <DangerIcon />
                      case NotificationTypes.Error:
                        return <DangerIcon />
                      case NotificationTypes.Fatal:
                        return <DangerIcon />
                      case NotificationTypes.Info:
                        return <InfoActiveIcon />
                      default:
                        return <DangerIcon />
                    }
                  })() }
                </Grid>

                <Grid
                  item
                  xs={ notification.layout === '2col' ? 11 : 8 }
                  className={
                    notification.layout === '2col' ? classes.alignJustify : null
                  }
                >
                  <Typography>{ notification.text }</Typography>
                </Grid>

                <Grid
                  item
                  xs={ notification.layout === '2col' ? 12 : 3 }
                  className={
                    notification.layout === '2col' ? classes.alignRight : null
                  }
                >
                  { notification.layout === '2col' && notification.buttonAction && (
                    <Button
                      onClick={ () => {
                        if (notification.buttonCancel) {
                          notification.buttonCancel()
                          this.close()
                        } else {
                          this.defaultButtonAction()
                        }
                      } }
                      //onClick={this.close}
                      type="button"
                      color="secondary"
                      variant="text"
                      size={ notification.layout === '2col' ? 'medium' : 'small' }
                      disableRipple
                    >
                      <FormattedMessage id="notification.cancel" />
                    </Button>
                  ) }

                  { notification.buttonAction && (
                    <Button
                      onClick={ () => {
                        if (notification.buttonAction) {
                          notification.buttonAction()
                          this.close()
                        } else {
                          this.defaultButtonAction()
                        }
                      } }
                      type="button"
                      color="primary"
                      variant="outlined"
                      size="medium"
                      disableRipple
                    >
                      {notification.buttonLabel || this.defaultButtonLabel }
                    </Button>
                  ) }

                  { notification.layout === '3col' && (
                    <span
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={ classes.close }
                      onClick={ this.close }
                    >
                      <CloseIcon className={ classes.closeIcon } />
                    </span>
                  ) }
                </Grid>
              </Grid>
            </div>
          </Slide>
        </div>
      </Fade>
    )
  }
}

export default injectIntl(withStyles(styles)(Notification))
