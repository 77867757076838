export const LIST_CUSTOMER_HFWFS = 'LIST_CUSTOMER_HFWFS'
export const LIST_CUSTOMER_HFWFS_SUCCESS = 'LIST_CUSTOMER_HFWFS_SUCCESS'
export const LIST_CUSTOMER_HFWFS_FAILURE = 'LIST_CUSTOMER_HFWFS_FAILURE'

export const LIST_CUSTOMER_CP_JOB_DETAILS = 'LIST_CUSTOMER_CP_JOB_DETAILS'
export const LIST_CUSTOMER_CP_JOB_DETAILS_SUCCESS = 'LIST_CUSTOMER_CP_JOB_DETAILS_SUCCESS'
export const LIST_CUSTOMER_CP_JOB_DETAILS_FAILURE = 'LIST_CUSTOMER_CP_JOB_DETAILS_FAILURE'

export const GET_CUSTOMER_CP_JOB_IDS = "GET_CUSTOMER_CP_JOB_IDS"
export const GET_CUSTOMER_CP_JOB_IDS_SUCCESS = "GET_CUSTOMER_CP_JOB_IDS_SUCCESS"
export const GET_CUSTOMER_CP_JOB_IDS_FAILURE = "GET_CUSTOMER_CP_JOB_IDS_FAILURE"

export const GET_CUSTOMER_CP_JOBS = 'GET_CUSTOMER_CP_JOBS'
export const GET_CUSTOMER_CP_JOBS_SUCCESS = 'GET_CUSTOMER_CP_JOBS_SUCCESS'
export const GET_CUSTOMER_CP_JOBS_FAILURE = 'GET_CUSTOMER_CP_JOBS_FAILURE'

export const ADD_ACTION = 'ADD_ACTION'
export const ADD_ACTION_SUCCESS = 'ADD_ACTION_SUCCESS'
export const ADD_ACTION_FAILURE = 'ADD_ACTION_FAILURE'

export const CUSTOMER_CREATE_ORDER = 'CUSTOMER_CREATE_ORDER'
export const CUSTOMER_CREATE_ORDER_SUCCESS = 'CUSTOMER_CREATE_ORDER_SUCCESS'
export const CUSTOMER_CREATE_ORDER_FAILURE = 'CUSTOMER_CREATE_ORDER_FAILURE'

export const DOWNLOAD_JOBDETAILS_IMAGE = 'DOWNLOAD_JOBDETAILS_IMAGE'
export const DOWNLOAD_JOBDETAILS_IMAGE_SUCCESS = 'DOWNLOAD_JOBDETAILS_IMAGE_SUCCESS'
export const DOWNLOAD_JOBDETAILS_IMAGE_FAILURE = 'DOWNLOAD_JOBDETAILS_IMAGE_FAILURE'

export const DOWNLOAD_JOBDETAILS_FILE = 'DOWNLOAD_JOBDETAILS_FILE'
export const DOWNLOAD_JOBDETAILS_FILE_SUCCESS = 'DOWNLOAD_JOBDETAILS_FILE_SUCCESS'
export const DOWNLOAD_JOBDETAILS_FILE_FAILURE = 'DOWNLOAD_JOBDETAILS_FILE_FAILURE'

export const GET_IMAGE_DETAILS = 'GET_IMAGE_DETAILS_JOBS'
export const GET_IMAGE_DETAILS_SUCCESS = 'GET_IMAGE_DETAILS_SUCCESS'
export const GET_IMAGE_DETAILS_FAILURE = 'GET_IMAGE_DETAILS_FAILURE'

export const SUBSCRIBE_TO_CP_JOB_ADD = 'SUBSCRIBE_TO_CP_JOB_ADD'
export const SUBSCRIBE_TO_CP_JOB_ADD_SUCCESS = 'SUBSCRIBE_TO_CP_JOB_ADD_SUCCESS'
export const SUBSCRIBE_TO_CP_JOB_ADD_FAILURE = 'SUBSCRIBE_TO_CP_JOB_ADD_FAILURE'

export const SUBSCRIBE_TO_CP_JOB_DELETE = 'SUBSCRIBE_TO_CP_JOB_DELETE'
export const SUBSCRIBE_TO_CP_JOB_DELETE_SUCCESS = 'SUBSCRIBE_TO_CP_JOB_DELETE_SUCCESS'
export const SUBSCRIBE_TO_CP_JOB_DELETE_FAILURE = 'SUBSCRIBE_TO_CP_JOB_DELETE_FAILURE'

export const SUBSCRIBE_TO_CP_JOB_UPDATE = 'SUBSCRIBE_TO_CP_JOB_UPDATE'
export const SUBSCRIBE_TO_CP_JOB_UPDATE_SUCCESS = 'SUBSCRIBE_TO_CP_JOB_UPDATE_SUCCESS'
export const SUBSCRIBE_TO_CP_JOB_UPDATE_FAILURE = 'SUBSCRIBE_TO_CP_JOB_UPDATE_FAILURE'

export const CLEAR_JOBS_DATA = 'CLEAR_JOBS_DATA'

export const GET_CP_CERTIFICATE = 'GET_CP_CERTIFICATE'
export const GET_CP_CERTIFICATE_SUCCESS = 'GET_CP_CERTIFICATE_SUCCESS'
export const GET_CP_CERTIFICATE_FAILURE = 'GET_CP_CERTIFICATE_FAILURE'

export const GET_COLOR_CHANNEL_MAPPING_DETAILS = "GET_COLOR_CHANNEL_MAPPING_DETAILS"
export const GET_COLOR_CHANNEL_MAPPING_DETAILS_SUCCESS = "GET_COLOR_CHANNEL_MAPPING_DETAILS_SUCCESS"
export const GET_COLOR_CHANNEL_MAPPING_DETAILS_FAILURE = "GET_COLOR_CHANNEL_MAPPING_DETAILS_FAILURE"

export const SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING = 'SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING'
export const SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING_SUCCESS = 'SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING_SUCCESS'
export const SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING_FAILURE = 'SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING_FAILURE'

export const SAVE_CHANNEL_MAPPING = 'SAVE_CHANNEL_MAPPING'
export const SAVE_CHANNEL_MAPPING_SUCCESS = 'SAVE_CHANNEL_MAPPING_SUCCESS'
export const SAVE_CHANNEL_MAPPING_FAILURE = 'SAVE_CHANNEL_MAPPING_FAILURE'
