import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles/index'
import Paper from '@material-ui/core/Paper/index'
import Typography from '@material-ui/core/Typography/index'
import Tabs from '@material-ui/core/Tabs/index'
import Tab from '@material-ui/core/Tab/index'
import Table from '@material-ui/core/Table/index'
import TableBody from '@material-ui/core/TableBody/index'
import TableCell from '@material-ui/core/TableCell/index'
import TableHead from '@material-ui/core/TableHead/index'
import TableRow from '@material-ui/core/TableRow/index'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import InProgressIcon from '../../assets/icons/InProgressIcon'
import DangerIcon from '../../assets/icons/DangerIcon'
import PrintIcon from '../../assets/icons/PrintIcon'
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon'
import PauseIcon from '../../assets/icons/PauseIcon'
import LeftIcon from '../../assets/icons/LeftIcon'
import PlayIcon from '../../assets/icons/PlayIcon'
import EditIcon from '../../assets/icons/EditIcon'
import CheckmarkIcon from '../../assets/icons/CheckmarkIcon'
import ColorManagementIcon from '../../assets/icons/ColorManagementIcon'
import styles from '../shared/Styles/styles'
import LeftSidePane from '../shared/Components/SideBar/LeftSidePane'
import Notification from '../shared/Components/Messaging/Notification'
import { NotificationTypes } from '../shared/duck/types/notificationTypes'
import LoadingButton from '../shared/Components/Buttons/LoadingButton'
import Popover from '../shared/Components/Messaging/Popover'
import Utils from '../shared/duck/helpers/Utils'
import ServiceContext from '../shared/Services/ServiceContext'
import PermissionTypes from '../shared/duck/types/permissionTypes'
import PermissionsValidator from '../shared/duck/helpers/permissionsValidator'
import { listRows } from './Components/ListRows'
import { mutateAddAction } from './duck/printersActions'
import { PrintersCategories } from './duck/helpers/PrintersCategories'
class CpPrinters extends React.Component {
  static contextType = ServiceContext

  constructor() {
    super()
    this.printersCategories = new PrintersCategories()
  }

  playActions = [ 'Held' ]

  state = {
    selectedFilter: 'allPrinters',
    tabValue: 0,
    isOpenRightPane: false,
    modalOpen: false,
    caliSetModalOpen: false,
    mediaChangeModalOpen: false,
    // Order according to ColorProof color definition (Enum)
    // -----------------------------------------------------
    // Gloss,
    // Cyan,
    // LightCyan,
    // Magenta,
    // LightMagenta,
    // Yellow,
    // Gray,
    // LightGray,
    // DarkGray,
    // Black,
    // PhotoBlack,
    // MatteBlack,
    // Red,
    // Green,
    // Blue,
    // Orange,
    // Violet,
    // LightBlack,
    // LightLightBlack,
    // White,
    // White1,
    // MetallicSilver,
    // Cleaning,
    // Cleaning1,
    // Cleaning2,
    // Maintenance,
    // Wiper,
    // Unknown
    // TODO: Why is this part of the state?
    colorCombination: {
      'Gloss Enhancer': { backgroundColor: '#F5F5F5', fontColor: '#000000' },
      Cyan: { backgroundColor: '#00EEEE', fontColor: '#000000' },
      'Light Cyan': { backgroundColor: '#D4F3F3', fontColor: '#000000' },
      Magenta: { backgroundColor: '#EE00EE', fontColor: '#000000' },
      'Light Magenta': { backgroundColor: '#F5D6F5', fontColor: '#000000' },
      Yellow: { backgroundColor: '#F3F300', fontColor: '#000000' },
      Gray: '#808080', // From web page, not color picked from color proof
      'Light Gray': { backgroundColor: '#D3D3D3', fontColor: '#000000' }, // From web page, not color picked from color proof
      'Dark Gray': { backgroundColor: '#A9A9A9', fontColor: '#000000' }, // From web page, not color picked from color proof
      Black: { backgroundColor: '#000000', fontColor: '#ffffff' },
      'Matte Black': { backgroundColor: '#000000', fontColor: '#ffffff' },
      'Photo Black': { backgroundColor: '#7C7C7C', fontColor: '#ffffff' },
      Red: { backgroundColor: '#FF0000', fontColor: '#000000' },
      Green: { backgroundColor: '#179840', fontColor: '#000000' },
      Blue: { backgroundColor: '#0000FF', fontColor: '#000000' },
      Orange: { backgroundColor: '#F1A461', fontColor: '#000000' },
      Violet: { backgroundColor: '#B900A6', fontColor: '#000000' },
      'Light Black': { backgroundColor: '#7A7A7A', fontColor: '#000000' },
      'Light Light Black': { backgroundColor: '#C4C4C4', fontColor: '#000000' },
      White: { backgroundColor: '#F5F5F5', fontColor: '#000000' },
      'White 1': { backgroundColor: '#F5F5F5', fontColor: '#000000' },
      'Metallic Silver': { backgroundColor: '#C0C0C0', fontColor: '#000000' },
      Cleaning: { backgroundColor: '#F5F5F5', fontColor: '#000000' },
      'Cleaning 1': { backgroundColor: '#F5F5F5', fontColor: '#000000' },
      'Cleaning 2': { backgroundColor: '#F5F5F5', fontColor: '#000000' },
      'Maintenance Tank': { backgroundColor: '#F5F5F5', fontColor: '#000000' },
      'Maintenance Tank R ': {
        backgroundColor: '#F5F5F5',
        fontColor: '#000000'
      },
      Wiper: { backgroundColor: '#F5F5F5', fontColor: '#000000' },
      Unknown: { backgroundColor: '#CCCCCC', fontColor: '#000000' }
    },

    currentPrinter: {},
    caliModal: false,
    changeSetup: false,
    currentMedia: '',
    currentCaliSet: '',
    currentSize: '',
    filteredPrinters: null,
    notification: null
  }

  componentDidUpdate (prevProps) {
    if (prevProps.printers.length !== this.props.printers.length)//printer added/deleted
      this.handleSearchTerms()
    if (prevProps.printerDetailsUpdated && prevProps.printerDetailsUpdated !== this.props.printerDetailsUpdated)//printer modified
      this.handleSearchTerms()
    if (prevProps.filterChips !== this.props.filterChips)//filterchips modified
      this.handleSearchTerms()
    if (prevProps.liveSearchTerm && this.props.liveSearchTerm !== prevProps.liveSearchTerm)
      this.handleLiveSearch()
  }

  componentDidMount () {
    this.setState({})
  }

  handleSearchTerms () {
    const newFilteredPrinters = this.applySearchTerms()
    this.setState({ filteredPrinters: newFilteredPrinters })
  }

  handleLiveSearch () {
    if (!this.props)
      return

    const searchTerm = this.props.liveSearchTerm
    const printers = this.applySearchTerms()
    const filteredPrinters = printers.filter(item => this.isPrinterMeetSearchTerm(item, searchTerm))

    this.setState({ filteredPrinters: filteredPrinters })
  }

  applySearchTerms () {
    const page = this.context.navigationService.getLocation()
    const searchTerms = this.props.filterChips[ page ] ? this.props.filterChips[ page ].filter(t => t.isActive) : []

    return this.props.printers.filter(item => searchTerms.every(searchTerm => this.isPrinterMeetSearchTerm(item, searchTerm.value)))
  }

  isPrinterMeetSearchTerm (printer, searchTerm) {
    if (!searchTerm)
      return true

    return this.findPrinterAlias(printer, searchTerm) ||
      printer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      printer.site.toLowerCase().includes(searchTerm.toLowerCase()) ||
      printer.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      printer.media.toLowerCase().includes(searchTerm.toLowerCase())
  }

  findPrinterAlias (printer, searchTerm) {
    const site = this.props.sites.find(site => site.site === printer.site && site.cpId === printer.cpId)

    if (!site)
      return

    return site.alias && site.alias.toLowerCase().includes(searchTerm.toLowerCase())
  }

  setStateSelectValues = printer => {
    if (printer.mediaId) {
      this.setState({
        currentMedia: printer.mediaId
      })
      if (printer.mediaSizeId) {
        this.setState({
          currentSize: printer.mediaSizeId
        })
      } else {
        this.setState({
          currentMedia: printer.medias[ 0 ].id,
          currentSize: printer.medias[ 0 ].Sizes[ 0 ].id
        })
      }
    } else if (printer.medias) {
      this.setState({
        currentMedia: printer.medias[ 0 ].id,
        currentSize: printer.medias[ 0 ].Sizes[ 0 ].id
      })
    }
    if (printer.caliSets) {
      for (let i = 0; i < printer.caliSets.length; i++) {
        if (printer.caliSets[ i ].CaliSetDetail) {
          this.setState({
            currentCaliSet: printer.caliSets[ i ].CaliSetDetail
          })
          break
        }
      }
    }
  }

  // When a printer from the main list gets updated, sync this to the currentPrinter in state, for the right pane
  // The properties of both can overlap, we only sync the overlapping set of fields to the currentPrinter, otherwise
  // values retrieved might get deleted.
  static getDerivedStateFromProps (props, state) {
    if (props.printers) {
      props.printers.forEach(printer => {
        if (printer.printerId === state.currentPrinter.printerId) {
          if (!Utils.objEquals(printer, state.currentPrinter)) {
            // debugger
            if (process.env.REACT_APP_STAGE !== 'Prod') {
              console.log('GET DERIVED STATE FOR PRINTER')
              console.log(printer)
            }
            state.currentPrinter.status = printer.status
            state.currentPrinter.medias = printer.medias
            state.currentPrinter.mediaSizeId = printer.mediaSizeId
            state.currentPrinter.caliSets = printer.caliSets
            state.currentPrinter.status = printer.status
            state.currentPrinter.media = printer.media
            if (printer.caliSets) {
              for (let i = 0; i < printer.caliSets.length; i++) {
                if (printer.caliSets[ i ].CaliSetDetail) {
                  state.currentCaliSet = printer.caliSets[ i ].CaliSetDetail
                  break
                }
              }
            }
          }
        }
      })
    }

    return state
  }

  isSiteForPrinterOnline (printer) {
    let isOnline = false
    this.props.sites.forEach((site, index) => {
      if (printer.cpId === site.cpId) {
        isOnline = Utils.isSiteOnline(site)
      }
    })
    return isOnline
  }

  handleListItemClick = filterName => {
    this.setState({ isOpen: false, isOpenRightPane: false, selectedFilter: filterName })
    this.handleSearchTerms()
  }
  getNumberOfPrinters = selectedFilter => {
    const { printers } = this.props
    const filterStates = this.printersCategories.getCategoryStates(selectedFilter)
    let filteredAndSortedPrinters = printers || []
    if (selectedFilter === 'notCalibrated') {
      filteredAndSortedPrinters = filteredAndSortedPrinters.filter(
        printer => !printer.isCalibrated || printer.isCalibrated === null)
      return filteredAndSortedPrinters.length
    }
    return (printers || []).filter(printer =>
      filterStates.includes(printer.status)
    ).length
  }

  getCpSortedInkCollection (inkCollection) {
    let retCollection = []
    // Note: Not performant, on the other hand only 20 items.
    for (let inkName in this.state.colorCombination) {
      if (this.state.colorCombination.hasOwnProperty(inkName)) {
        let itemFromInkCollection = inkCollection.find(
          item => inkName === item.InkName
        )
        if (itemFromInkCollection) {
          retCollection.push(itemFromInkCollection)
        }
      }
    }

    if (retCollection.length !== inkCollection.length) {
      console.warn(
        'InkName Mapping mismatch. There might be missing inks in the display.'
      )
    }

    return retCollection
  }

  filterPrinterCounter = () => ({
    allPrinters: this.getNumberOfPrinters('allPrinters'),
    notCalibrated: this.getNumberOfPrinters('notCalibrated'),
    inError: this.getNumberOfPrinters('inError')
  })

  openRightPanePrinter (printer) {
    this.context.trackingService.trackEvent(
      'Printer Right Pane',
      'Printer Right Pane Open',
      '1',
      1
    )
    this.setState({ isOpenRightPane: true, currentPrinter: printer })
    this.setStateSelectValues(printer)
  }

  renderSidebar () {
    const { selectedFilter } = this.state
    const categories = [
      {
        name: 'allPrinters',
        label: 'All Printers',
        id: 'cpPrinters.allPrinters',
        icon: <PrintIcon />
      },
      {
        name: 'notCalibrated',
        label: 'Not Calibrated',
        id: 'cpPrinters.notCalibrated',
        icon: <InProgressIcon />
      },
      {
        name: 'inError',
        label: 'In Error',
        id: 'categoryList.inError',
        icon: <DangerIcon />
      }
    ]

    return (
      <React.Fragment>
        <LeftSidePane
          onCategoryClick={ this.handleListItemClick }
          classes={ this.props.classes }
          data={ {
            counter: this.filterPrinterCounter(),
            selectedFilter: selectedFilter,
            categories: categories,
            label: true,
            labelTitle: 'PROOF PRINTERS',
            labelID: 'cpPrinters.proofPrinters'
          } }
        />
      </React.Fragment>
    )
  }

  handleMobileTab = (event, value) => {
    let currentScreen
    value === 0
      ? (currentScreen = 'allPrinters')
      : value === 1
        ? (currentScreen = 'notCalibrated')
        : (currentScreen = 'inError')
    this.setState({
      tabValue: value,
      selectedFilter: currentScreen,
      isOpenRightPane: false
    })
  }

  renderMobileHeader () {
    const { classes } = this.props
    let currentScreen = this.state.selectedFilter
    const printerNumbers = this.filterPrinterCounter()[ currentScreen ]

    return (
      <Typography
        variant="h6"
        align="left"
        color="textPrimary"
        className={ classNames(
          classes.navigationTitle,
          classes.navigationTitleMobile
        ) }
      >
        <FormattedMessage
          id="cpPrinters.proofPrinters"
          defaultMessage="PROOF PRINTERS"
        />
        <FormattedMessage
          id={ `cpPrinters.${currentScreen}` }
          defaultMessage={ currentScreen.toUpperCase() }
        />
        ({printerNumbers })
      </Typography>
    )
  }

  renderTabs () {
    const { classes } = this.props
    return (
      <Paper
        square
        className={ classNames(classes.tabPaper, {
          // [classes.noDisplay]: this.state.isOpenRightPane
        }) }
      >
        <Tabs
          value={ this.state.tabValue }
          onChange={ this.handleMobileTab }
          classes={ {
            indicator: classes.indicator
          } }
          textColor="primary"
          centered
          className={ classes.tabs }
        >
          <Tab
            id="allPrintersTab"
            className={ classes.tabOptions }
            icon={ <PrintIcon /> }
            label={
              <FormattedMessage
                id="cpPrinters.allPrinters"
                defaultMessage="All Printers"
              />
            }
          />
          <Tab
            id="notCalibratedTab"
            className={ classes.tabOptions }
            icon={ <InProgressIcon /> }
            label={
              <FormattedMessage
                id="cpPrinters.notCalibrated"
                defaultMessage="Not Calibrated"
              />
            }
          />
          <Tab
            id="inErrorTab"
            className={ classes.tabOptions }
            icon={ <DangerIcon /> }
            label={
              <FormattedMessage
                id="cpPrinters.inError"
                defaultMessage="In Error"
              />
            }
          />
        </Tabs>
      </Paper>
    )
  }

  getTranslatedStatus = status => {
    return this.printersCategories.translatedStatus[ status ]
  }

  renderPrinter = (printer, rows) => {
    const { classes } = this.props
    return (
      <TableRow
        key={ Math.random() }
        className={ classNames(classes.hoverRow, {
          [ classes.activeJob ]:
            printer.printerId === this.state.currentPrinter.printerId &&
            this.state.isOpenRightPane
        }) }
      >
        {rows.map(row => (
          <TableCell
            key={ `${row.key}` }
            className={ classNames(classes.tableCell, {
              [ classes.tableCellHideMobile ]: row.hideMobile,
              [ classes.tableCellHideTablet ]: row.hideTablet,
              [ classes.tableCellSpacerMobile ]: row.key === 'spacerLeft' || row.key === 'spacerRight',
              [ classes.tableCellHideRightPaneLandscape ]: row.hideRightPaneTabLandscape && this.state.isOpenRightPane
            }) }
            onClick={ () => this.openRightPanePrinter(printer) }
          >
            {row.customTemplate && row.customTemplate(printer) }
            {!row.customTemplate && row.key === 'status' && this.getTranslatedStatus(printer[ row.key ]) }
            {!row.customTemplate && row.key !== 'status' && printer[ row.key ] }
          </TableCell>
        )) }
      </TableRow>
    )
  }

  sendRemoteAction = async addActionInput => {
    if (!this.context.featureFlagService.arePrinterActionsSupported(this.state.currentPrinter, this.props.sites)) {
      this.context.notificationService.showNotification(
        this,
        NotificationTypes.Warning,
        this.context.translationService.tr('cpJobs.printerActionsUnsupported')
      )
      return
    }

    await this.props.mutateAddAction(addActionInput)
  }

  openAutoCalibration = () => {
    if (this.state.currentPrinter.caliSets) {
      for (let i = 0; i < this.state.currentPrinter.caliSets.length; i++) {
        if (this.state.currentPrinter.caliSets[ i ].CaliSetDetail &&
          !this.state.currentPrinter.caliSets[ i ].CaliSetDetail.includes('0000')) {
          this.setState({
            caliModal: true,
            changeSetup: false
          })
          break
        }
      }
    }
  }
  openChangeSetupModal = () => {
    if (this.state.currentPrinter.medias) {
      this.setState({ caliModal: false, changeSetup: true })
    }
  }

  handleMediaSelectBox = event => {
    this.setState({ currentMedia: event.target.value })
  }

  handleCaliSetModal = event => {
    this.setState({ currentCaliSet: event.target.value })
  }

  handleMediaSize = event => {
    this.setState({ currentSize: event.target.value })
  }

  submitPlayPause = async actionType => {
    const cpId = this.state.currentPrinter.cpId
    const printerId = this.state.currentPrinter.printerId
    let addActionInput = {
      cpId: cpId,
      action: actionType,
      actionParams: [
        {
          name: 'id',
          value: printerId
        }
      ]
    }
    await this.sendRemoteAction(addActionInput)

    // Notification for user about job
    this.context.notificationService.showNotification(
      this,
      NotificationTypes.Success,
      this.context.translationService.tr('cpPrinterActions.' + actionType)
    )
  }

  submitMediaChanges = async () => {
    this.context.trackingService.trackEvent(
      'Printer Right Pane Action',
      'Submit Media Change Changes',
      '1',
      1
    )
    const cpId = this.state.currentPrinter.cpId
    const printerId = this.state.currentPrinter.printerId
    const actionType = 'ChangePrinterPaper'
    const mediaSizeId = this.state.currentSize
    const mediaId = this.state.currentMedia

    let addActionInput = {
      cpId: cpId,
      action: actionType,
      actionParams: [
        {
          name: 'id',
          value: printerId
        },
        {
          name: 'mediaSizeId',
          value: mediaSizeId
        },
        {
          name: 'mediaId',
          value: mediaId
        }
      ]
    }
    this.setState({
      changeSetup: false,
      mediaChangeModalOpen: false
    })

    await this.sendRemoteAction(addActionInput)


    this.props.printers.forEach(printer => {
      if (printer.printerId === this.state.currentPrinter.printerId) {
        if (printer.caliSets.length !== 0 && printer.caliSets[ 0 ].CaliSetDetail) {
          this.setState({
            currentCaliSet: printer.caliSets[ 0 ].CaliSetDetail
          })
        }
      }
    })
    this.openRightPanePrinter(this.props.printers.find(p => p.printerId === printerId))
  }

  clearMediaChanges = () => {
    this.context.trackingService.trackEvent(
      'Printer Right Pane Action',
      'Clear Media Change Changes',
      '1',
      1
    )
    this.setState({
      changeSetup: false,
      mediaChangeModalOpen: false
    })

    this.props.printers.forEach(printer => {
      if (printer.printerId === this.state.currentPrinter.printerId) {
        this.setState({
          currentMedia: printer.mediaId,
          currentSize: printer.mediaSizeId
        })
      }
    })
  }

  submitCaliChanges = async () => {
    this.context.trackingService.trackEvent(
      'Printer Right Pane Action',
      'Submit Caliset Changes',
      '1',
      1
    )
    const cpId = this.state.currentPrinter.cpId
    const printerId = this.state.currentPrinter.printerId
    const actionType = 'CalibratePrinter'
    const caliSetDetail = this.state.currentCaliSet
    let addActionInput = {
      cpId: cpId,
      action: actionType,
      actionParams: [
        {
          name: 'id',
          value: printerId
        },
        {
          name: 'caliSetDetail',
          value: caliSetDetail
        }
      ]
    }
    this.setState({ caliModal: false, caliSetModalOpen: false })
    await this.sendRemoteAction(addActionInput)
  }

  clearCaliChanges = () => {
    this.context.trackingService.trackEvent(
      'Printer Right Pane Action',
      'Clear Caliset Changes',
      '1',
      1
    )
    this.setState({ caliModal: false, caliSetModalOpen: false })
  }

  rightPaneData = (classes, currentPrinter) => {
    const validator = new PermissionsValidator(this.props.permissions)
    return (
      <React.Fragment>
        {currentPrinter && validator.hasUserPermissionForCp(currentPrinter.cpId, PermissionTypes.ExecuteActions) ?
          (
            <span className={ classes.jobActions }>
              {this.playActions.includes(currentPrinter.status) ? (
                this.isSiteForPrinterOnline(currentPrinter) ? (
                  <React.Fragment>
                    <FormattedMessage
                      id="printerRightPane.start"
                      defaultMessage="Start"
                    >
                      { title => (
                        <span id="printerRightPane-play-button"
                          className={ classNames(
                            classes.printerActionSpace,
                            classes.normalClass
                          ) }
                          onClick={ () => {
                            Utils.clearWindowSelection()
                            this.submitPlayPause('StartPrinter')
                            this.context.trackingService.trackEvent(
                              'Printer Right Pane Action',
                              'Start Printer',
                              '1',
                              1
                            )
                          } }
                          title={ title }
                        >
                          <PlayIcon id="printerRightPane-play-icon-active" className={ classes.noPointerEvent } />
                        </span>
                      ) }
                    </FormattedMessage>

                    <FormattedMessage
                      id="printerRightPane.pause"
                      defaultMessage="Pause"
                    >
                      { title => (
                        <span id="printerRightPane-pause-button"
                          className={ classNames(
                            classes.printerActionSpace,
                            classes.disabled
                          ) }
                          title={ title }
                        >
                          <PauseIcon id="printerRightPane-pause-icon-inactive" />
                        </span>
                      ) }
                    </FormattedMessage>
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <Popover
                        content={
                          currentPrinter.site +
                          ' ' +
                          this.props.intl.formatMessage({
                            id: 'cpPrinters.siteIsOffline'
                          })
                        }
                        position="left"
                      >
                        <span id="printerRightPane-play-button"
                          className={ classNames(
                            classes.printerActionSpace,
                            classes.disabled
                          ) }
                        >
                          <PlayIcon id="printerRightPane-play-icon-inactive" />
                        </span>
                      </Popover>
                      <Popover
                        content={
                          currentPrinter.site +
                          ' ' +
                          this.props.intl.formatMessage({
                            id: 'cpPrinters.siteIsOffline'
                          })
                        }
                        position="left"
                      >
                        <span
                          className={ classNames(
                            classes.printerActionSpace,
                            classes.disabled
                          ) }
                        >
                          <PauseIcon id="printerRightPane-pause-icon-active" />
                        </span>
                      </Popover>
                    </React.Fragment>
                  )
              ) : this.isSiteForPrinterOnline(currentPrinter) ? (
                <React.Fragment>
                  <FormattedMessage
                    id="printerRightPane.start"
                    defaultMessage="Start"
                  >
                    { title => (
                      <span id="printerRightPane-play-button"
                        className={ classNames(
                          classes.printerActionSpace,
                          classes[currentPrinter.status === 'Ready' ? 'disabled' : 'normalClass']
                        ) }
                        title={ title }
                      >
                        <PlayIcon id="printerRightPane-play-icon-active" />
                      </span>
                    ) }
                  </FormattedMessage>

                  <FormattedMessage
                    id="printerRightPane.pause"
                    defaultMessage="Pause"
                  >
                    { title => (
                      <span id="printerRightPane-pause-button"
                        className={ classNames(
                          classes.printerActionSpace,
                          classes.normalClass
                        ) }
                        onClick={ () => {
                          Utils.clearWindowSelection()
                          this.submitPlayPause('PausePrinter')
                          this.context.trackingService.trackEvent(
                            'Printer Right Pane Action',
                            'Pause Printer',
                            '1',
                            1
                          )
                        } }
                        title={ title }
                      >
                        <PauseIcon id="printerRightPane-pause-icon-inactive" className={ classes.noPointerEvent } />
                      </span>
                    ) }
                  </FormattedMessage>
                </React.Fragment>
              ) : (
                    <React.Fragment>
                      <Popover
                        content={
                          currentPrinter.site +
                          ' ' +
                          this.props.intl.formatMessage({
                            id: 'cpPrinters.siteIsOffline'
                          })
                        }
                        position="left"
                      >
                        <span id="printerRightPane-play-button"
                          className={ classNames(
                            classes.printerActionSpace,
                            classes.disabled
                          ) }
                        >
                          <PlayIcon id="printerRightPane-play-icon-inactive" />
                        </span>
                      </Popover>
                      <Popover
                        content={
                          currentPrinter.site +
                          ' ' +
                          this.props.intl.formatMessage({
                            id: 'cpPrinters.siteIsOffline'
                          })
                        }
                        position="left"
                      >
                        <span id="printerRightPane-pause-button"
                          className={ classNames(
                            classes.printerActionSpace,
                            classes.disabled
                          ) }
                        >
                          <PauseIcon id="printerRightPane-pause-icon-active" />
                        </span>
                      </Popover>
                    </React.Fragment>
                  ) }

              {this.isSiteForPrinterOnline(currentPrinter) ? (
                <React.Fragment>
                  <FormattedMessage
                    id="printerRightPane.calibrateTooltip"
                    defaultMessage="Calibrate"
                  >
                    { title => (
                      <span id="printerRightPane-inprogress-button"
                        className={ classNames(
                          classes.printerActionSpace,
                          classes.normalClass,
                          {
                            [ classes.caliSetDisabled ]:
                              this.state.currentPrinter &&
                              this.state.currentPrinter.caliSets &&
                              this.state.currentPrinter.caliSets.length === 0
                          },
                          {
                            [ classes.caliSetVisible ]:
                              currentPrinter.status === 'Calibrating'
                          }
                        ) }
                        // mouseup - IE Edge Fix - Otherwise double click is required
                        onMouseUp={ () => {
                          // Fix auto selection in IE Edge - unknown bug so far
                          Utils.clearWindowSelection()
                          this.openAutoCalibration()
                        } }
                        title={ title }
                      >
                        <InProgressIcon id="printerRightPane-inprogress-icon-active" />
                      </span>
                    ) }
                  </FormattedMessage>
                  <FormattedMessage
                    id="printerRightPane.calibratingTooltip"
                    defaultMessage="Calibrating"
                  >
                    { title => (
                      <span id="printerRightPane-circularprogress-button"
                        className={ classNames(
                          classes.printerActionSpace,
                          classes.activeClass,
                          {
                            [ classes.caliSetVisible ]:
                              currentPrinter.status !== 'Calibrating'
                          }
                        ) }
                        title={ title }
                      >
                        <CircularProgress
                          id="printerRightPane-circularprogress-icon-active"
                          color="secondary"
                          style={ { width: 24, height: 24 } }
                        />
                      </span>
                    ) }
                  </FormattedMessage>
                </React.Fragment>
              ) : (
                  <Popover
                    content={
                      currentPrinter.site +
                      ' ' +
                      this.props.intl.formatMessage({
                        id: 'cpPrinters.siteIsOffline'
                      })
                    }
                    position="left"
                  >
                    <span id="printerRightPane-inprogress-button"
                      className={ classNames(
                        classes.printerActionSpace,
                        classes.disabled
                      ) }
                    >
                      <InProgressIcon id="printerRightPane-inprogress-icon-inactive" />
                    </span>
                  </Popover>
                ) }

              <Modal
                open={ this.state.caliModal }
                onClose={ () => {
                  this.setState({ caliModal: false })
                } }
                className={ classes.zIndex }
              >
                <div className={ classes.modalStylePrinterPaneCaliSet }>
                  <div>
                    <Typography className={ classNames(classes.printerModalTitle) }>
                      <FormattedMessage
                        id="printerRightPane.calibrate"
                        defaultMessage="CALIBRATE"
                      />
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      className={ classNames(classes.printerModalSelectBoxTitle) }
                    >
                      <FormattedMessage
                        id="printerRightPane.calibrationSetModal"
                        defaultMessage="Calibration Set"
                      />
                    </Typography>

                    { this.renderCaliSetForm() }
                  </div>

                  <div className={ classes.printerModalButtons }>
                    { this.renderOkaySubmitCaliSetButton(classes) }
                  </div>
                </div>
              </Modal>

              {this.isSiteForPrinterOnline(currentPrinter) ? (
                <FormattedMessage
                  id="printerRightPane.changeMediaTooltip"
                  defaultMessage="Change Media"
                >
                  {title => (
                    <span id="printerRightPane-colormanagement-button"
                      className={ classNames(
                        classes.printerActionSpace,
                        classes.normalClass
                      ) }
                      // mouseup - IE Edge Fix - Otherwise double click is required
                      onMouseUp={ () => {
                        // Fix auto selection in IE Edge - unknown bug so far
                        Utils.clearWindowSelection()
                        this.openChangeSetupModal()
                      } }
                      title={ title }
                    >
                      <ColorManagementIcon id="printerRightPane-colormanagement-icon-active" />
                    </span>
                  ) }
                </FormattedMessage>
              ) : (
                  <Popover
                    content={
                      currentPrinter.site +
                      ' ' +
                      this.props.intl.formatMessage({
                        id: 'cpPrinters.siteIsOffline'
                      })
                    }
                    position="left"
                  >
                    <span id="printerRightPane-colormanagement-button"
                      className={ classNames(
                        classes.printerActionSpace,
                        classes.disabled
                      ) }
                    >
                      <ColorManagementIcon id="printerRightPane-colormanagement-icon-inactive" />
                    </span>
                  </Popover>
                ) }

              {this.state.currentPrinter.medias ? (
                <Modal
                  open={ this.state.changeSetup }
                  onClose={ () => {
                    this.setState({ changeSetup: false })
                  } }
                  className={ classes.zIndex }
                >
                  <div className={ classes.modalStylePrinterPane }>
                    <div>
                      <Typography
                        className={ classNames(classes.printerModalTitle) }
                      >
                        <FormattedMessage
                          id="printerRightPane.changeMedia"
                          defaultMessage="CHANGE MEDIA"
                        />
                      </Typography>
                    </div>

                    <div>
                      <Typography
                        className={ classNames(classes.printerModalSelectBoxTitle) }
                      >
                        <FormattedMessage
                          id="printerRightPane.media"
                          defaultMessage="Media"
                        />
                      </Typography>

                      { this.renderMediaMobilePane(classes) }
                    </div>

                    <div>
                      <Typography
                        className={ classNames(classes.printerModalSelectBoxTitle) }
                      >
                        <FormattedMessage
                          id="printerRightPane.mediaSize"
                          defaultMessage="Media Size"
                        />
                      </Typography>
                      { this.renderMediaSize(classes) }
                    </div>
                    <div className={ classes.printerModalButtons }>
                      { this.renderOkaySubmitMediaChangeButton(classes) }
                    </div>
                  </div>
                </Modal>
              ) : (
                  ''
                ) }
            </span>
          ) : (
            <span className={ classes.marginTop } />
          ) }

        {(() => {
          if (currentPrinter.inks && currentPrinter.inks.length > 0) {
            return (
              <div id="printerRightPane-printerInkStatusPanel" className={ classes.printerColorPosition }>
                {this.getCpSortedInkCollection(currentPrinter.inks).map(
                  (value, index) => {
                    return (
                      <React.Fragment key={ index }>
                        <Popover content={ value.InkName } position="left">
                          <div className={ classes.printerColorDiv }>
                            <div
                              className={ classes.printerColors }
                              style={ {
                                backgroundColor: this.state.colorCombination[
                                  value.InkName
                                ].backgroundColor,
                                height: value.InkPercentage
                              } }
                            />
                            <Typography
                              className={ classes.colorNamePrinterPane }
                              style={ {
                                color: this.state.colorCombination[
                                  value.InkName
                                ].fontColor
                              } }
                            >
                              <span className={ classes.rotatePrinterPaneText }>
                                { value.InkName }
                              </span>
                            </Typography>
                          </div>
                        </Popover>
                      </React.Fragment>
                    )
                  }
                ) }
              </div>
            )
          } else {
            return (
              <Popover
                content={ this.props.intl.formatMessage({
                  id: 'printerRightPane.inkUnsupported'
                }) }
                position="left"
                distance="-17"
              >
                <span>
                  <Typography
                    className={ classNames(
                      classes.drawerValues,
                      classes.ellipsisPrinterPane,
                      classes.noInkMessageMobile
                    ) }
                  >
                    <FormattedMessage
                      id="printerRightPane.inkUnsupported"
                      defaultMessage="Ink details are not supported by this ColorProof version."
                    />
                  </Typography>
                </span>
              </Popover>
            )
          }
        })() }

        <Typography
          className={ classNames(classes.drawerTitles, {
            [ classes.marginTopPrinterPaneStatus ]:
              currentPrinter &&
              currentPrinter.inks &&
              currentPrinter.inks.length === 0
          }) }
        >
          <FormattedMessage
            id="printerRightPane.status"
            defaultMessage="STATUS"
          />
        </Typography>
        <Typography
          id="printerRightPane-printerStatusPanel"
          className={ classNames(classes.drawerValues, classes.ellipsis) }
        >
          { this.getTranslatedStatus(currentPrinter.status) }
        </Typography>

        {currentPrinter.errorMessage ? (
          <Typography
            className={ classNames(classes.drawerValues, classes.halfWidth) }
          >
            <Popover
              content={ currentPrinter.errorMessage }
              position="left"
              distance="-17"
            >
              <span>{ currentPrinter.errorMessage }</span>
            </Popover>
          </Typography>
        ) : null }

        <div
          className={ classNames(
            {
              [ classes.printerMobileCaliSet ]:
                currentPrinter &&
                currentPrinter.inks &&
                currentPrinter.inks.length === 0
            },
            {
              [ classes.printerMobileCaliSetWithInks ]:
                currentPrinter.inks && currentPrinter.inks.length > 0
            }
          ) }
        >
          { currentPrinter.caliSets && currentPrinter.caliSets.length > 0 ? (
            <Typography className={ classNames(classes.drawerTitles) }>
              <FormattedMessage
                id="printerRightPane.calibrationSet"
                defaultMessage="CALIBRATION SET"
              />
            </Typography>
          ) : null }

          { currentPrinter.caliSets ? (
            currentPrinter.caliSets
              .sort(Utils.sortObjectByKey('Name', false))
              .map((value, index) => {
                return (
                  <Popover
                    key={ index }
                    content={ value.Name }
                    distance="-17"
                    position="left"
                  >
                    <span>
                      <Typography
                        id="printerRightPane-printerCalibrationSetPanel"
                        className={ classNames(
                          classes.drawerValues,
                          classes.ellipsis,
                          {
                            [ value.Calibrated ]: classes.textUnderline
                          }
                        ) }
                      >
                        { value.Name }
                      </Typography>
                    </span>
                  </Popover>
                )
              })
          ) : (
              <Typography
                className={ classNames(classes.drawerValues, classes.ellipsis) }
              >
                <FormattedMessage
                  id="printerRightPane.caliSetsUnsupported"
                  defaultMessage="Calibration sets are not supported by this ColorProof version."
                />
              </Typography>
            ) }

          <span
            className={ classNames(classes.editButtonPrinterPane) }
            onClick={ () => {
              this.setState({ modalOpen: true })
              this.context.trackingService.trackEvent(
                'Printer Pane Actions Mobile',
                'Open Printer Actions Mobile',
                '1',
                1
              )
            } }
          >
            <EditIcon className={ classes.pencilIconPrinterPane } />
          </span>
        </div>
        <Typography className={ classNames(classes.drawerTitles) }>
          <FormattedMessage
            id="printerRightPane.printerType"
            defaultMessage="PRINTER TYPE"
          />
        </Typography>
        <Typography
          id="printerRightPane-printerTypePanel"
          className={ classNames(classes.drawerValues, classes.ellipsis) }
        >
          { currentPrinter.printerType }
        </Typography>
        <Typography className={ classNames(classes.drawerTitles) }>
          <FormattedMessage
            id="printerRightPane.inkSet"
            defaultMessage="INK SET"
          />
        </Typography>
        <Typography
          id="printerRightPane-printerInkSetPanel"
          className={ classNames(classes.drawerValues, classes.ellipsis) }
        >
          { currentPrinter.inkSet ? (
            currentPrinter.inkSet
          ) : (
              <FormattedMessage
                id="printerRightPane.noInkSet"
                defaultMessage="No Ink Set."
              />
            ) }
        </Typography>

        {this.renderPrinterActions(classes) }

        {this.renderCaliSetPane(classes) }

        {this.renderMediaChangePane(classes) }
      </React.Fragment>
    )
  }

  renderMediaSize = classes => {
    return this.state.currentPrinter.medias ? (
      <Select
        disableUnderline={ true }
        value={ this.state.currentSize }
        onChange={ event => {
          this.handleMediaSize(event)
        } }
        displayEmpty
        name="mediaSet"
        IconComponent={ LeftIcon }
        classes={ {
          root: classes.cpSelectBox + ' ' + classes.printerModalFormControl,
          selectMenu: classes.cpSelectBoxContent
        } }
        MenuProps={ {
          classes: {
            paper: classes.cpSelectMenuPaper + ' ' + classes.dropdownStyle
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          MenuListProps: {
            className: classNames(classes.cpSelectList)
          }
        } }
      >
        {this.state.currentPrinter.medias.map((value, index) => {
          if (value.id === this.state.currentMedia) {
            return value.Sizes.sort(Utils.sortObjectByRoll('Name', false)).map(
              (item, ind) => {
                return (
                  <MenuItem
                    className={ classes.cpSelectMenuItem }
                    key={ Math.random() }
                    value={ item.id }
                    classes={ { selected: classes.cpSelectSelected } }
                    selected={ this.state.currentSize === item.id }
                  // checked={item.id && this.state.currentSize === item.id}
                  >
                    {this.state.currentSize === item.id ? (
                      <span className={ classNames(classes.cpSelectCheckMark) }>
                        <CheckmarkIcon className={ classes.selectedCheckMark } />
                      </span>
                    ) : (
                        <span className={ classes.noCheckMark }> </span>
                      ) }
                    {item.Name }
                  </MenuItem>
                )
              }
            )
          } else {
            return ''
          }
        }) }
      </Select>
    ) : null
  }

  renderMediaMobilePane = classes => {
    return this.state.currentPrinter.medias ? (
      <Select
        disableUnderline={ true }
        value={ this.state.currentMedia }
        onChange={ e => {
          this.handleMediaSelectBox(e)
        } }
        displayEmpty
        name="mediaSet"
        IconComponent={ LeftIcon }
        classes={ {
          root: classes.cpSelectBox + ' ' + classes.printerModalFormControl,
          selectMenu: classes.cpSelectBoxContent
        } }
        MenuProps={ {
          classes: {
            paper: classes.cpSelectMenuPaper + ' ' + classes.dropdownStyle
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          MenuListProps: {
            className: classNames(classes.cpSelectList)
          }
        } }
      >
        {this.state.currentPrinter.medias
          .sort(Utils.sortObjectByGmg('Name', false))
          .map((value, index) => {
            return (
              <MenuItem
                className={ classes.cpSelectMenuItem }
                key={ Math.random() }
                value={ value.id }
                classes={ { selected: classes.cpSelectSelected } }
                selected={ this.state.currentMedia === value.id }
              >
                {this.state.currentMedia === value.id ? (
                  <span className={ classNames(classes.cpSelectCheckMark) }>
                    <CheckmarkIcon className={ classes.selectedCheckMark } />
                  </span>
                ) : (
                    <span className={ classes.noCheckMark }> </span>
                  ) }
                {value.Name }
              </MenuItem>
            )
          }) }
      </Select>
    ) : null
  }

  renderPrinterActions = classes => {
    const { modalOpen } = this.state
    return (
      <Modal
        open={ modalOpen }
        onClose={ () => {
          this.setState({ modalOpen: false })
        } }
        className={ classes.zIndex }
      >
        <div
          className={ classNames(
            classes.modalStyle,
            classes.modalStylePrinterActions
          ) }
        >
          { this.playActions.includes(this.state.currentPrinter.status) ? (
            <div
              className={ classes.iconModalPane }
              onClick={ () => {
                this.setState({ modalOpen: false })
                this.submitPlayPause('StartPrinter')
                this.context.trackingService.trackEvent(
                  'Printer Right Pane Action',
                  'Start Printer',
                  '1',
                  1
                )
              } }
            >
              <PlayIcon className={ classes.tabletIconPosition } />
              <Typography className={ classes.paneDrawerValues }>
                <FormattedMessage
                  id="printerRightPane.start"
                  defaultMessage="Start"
                />
              </Typography>
            </div>
          ) : (
              <div
                className={ classes.iconModalPane }
                onClick={ () => {
                  this.setState({ modalOpen: false })
                  this.submitPlayPause('PausePrinter')
                  this.context.trackingService.trackEvent(
                    'Printer Right Pane Action',
                    'Pause Printer',
                    '1',
                    1
                  )
                } }
              >
                <PauseIcon className={ classes.tabletIconPosition } />
                <Typography className={ classes.paneDrawerValues }>
                  <FormattedMessage
                    id="printerRightPane.pause"
                    defaultMessage="Pause"
                  />
                </Typography>
              </div>
            ) }

          <div
            className={ classNames(classes.iconModalPane, {
              [ classes.caliSetDisabled ]:
                this.state.currentPrinter &&
                this.state.currentPrinter.caliSets &&
                this.state.currentPrinter.caliSets.length === 0
            }) }
          >
            <span
              className={ classNames(
                {
                  [ classes.caliSetVisible ]:
                    this.state.currentPrinter.status === 'Calibrating'
                },
                {
                  [ classes.flexDisplay ]:
                    this.state.currentPrinter.status !== 'Calibrating'
                }
              ) }
              onClick={ () => {
                this.setState({ modalOpen: false, caliSetModalOpen: true })
                this.context.trackingService.trackEvent(
                  'Printer Right Pane Action',
                  'Open Caliset Select Box',
                  '1',
                  1
                )
              } }
            >
              <InProgressIcon className={ classes.tabletIconPosition } />
              <Typography className={ classes.paneDrawerValues }>
                <FormattedMessage
                  id="printerRightPane.calibrateTooltip"
                  defaultMessage="Calibrate"
                />
              </Typography>
            </span>

            <span
              className={ classNames(
                {
                  [ classes.caliSetVisible ]:
                    this.state.currentPrinter.status !== 'Calibrating'
                },
                {
                  [ classes.flexDisplay ]:
                    this.state.currentPrinter.status === 'Calibrating'
                }
              ) }
              onClick={ () => {
                this.setState({ modalOpen: false })
                this.context.trackingService.trackEvent(
                  'Printer Right Pane Action',
                  'Close Printer Action Mobile',
                  '1',
                  1
                )
              } }
            >
              <InProgressIcon className={ classes.rotatingIcon } />
              <Typography className={ classes.paneDrawerValues }>
                <FormattedMessage
                  id="printerRightPane.calibrateTooltip"
                  defaultMessage="Calibrate"
                />
              </Typography>
            </span>
          </div>

          <div
            className={ classNames(classes.iconModalPane, {
              [ classes.caliSetDisabled ]: !this.state.currentPrinter.medias
            }) }
            onClick={ () => {
              this.setState({ modalOpen: false, mediaChangeModalOpen: true })
              this.context.trackingService.trackEvent(
                'Printer Right Pane Action',
                'Open Media Change Select Box',
                '1',
                1
              )
            } }
          >
            <ColorManagementIcon className={ classes.tabletIconPosition } />
            <Typography className={ classNames(classes.paneDrawerValues) }>
              <FormattedMessage
                id="printerRightPane.changeMediaTooltip"
                defaultMessage="Change Media"
              />
            </Typography>
          </div>
        </div>
      </Modal>
    )
  }

  renderCaliSetPane = classes => {
    const { caliSetModalOpen } = this.state
    return (
      <Modal
        open={ caliSetModalOpen }
        onClose={ () => {
          this.setState({ caliSetModalOpen: false })
        } }
        className={ classes.zIndex }
      >
        <div
          className={ classNames(
            classes.showRightPaneMobile
            // classes.hideMobileTablet
          ) }
        >
          <div className={ classes.mobilePaneHeader }>
            <div
              className={ classNames(classes.row) }
              onClick={ () => {
                this.setState({ caliSetModalOpen: false })
              } }
            >
              <div className={ classes.backButtonPane }>
                <ArrowLeftIcon />
                <Typography className={ classes.rightPaneTitle }>
                  <FormattedMessage
                    id="printerRightPane.calibrate"
                    defaultMessage="CALIBRATE"
                  />
                </Typography>
              </div>
            </div>
          </div>

          <div className={ classes.marginTopCaliSetMobileModal }>
            <Typography
              className={ classNames(classes.printerModalSelectBoxTitle) }
            >
              <FormattedMessage
                id="printerRightPane.calibrationSetModal"
                defaultMessage="Calibration Set"
              />
            </Typography>
            { this.renderCaliSetForm() }
          </div>
          { this.renderOkaySubmitCaliSetButton(classes) }
        </div>
      </Modal>
    )
  }

  renderMediaChangePane = classes => {
    const { mediaChangeModalOpen } = this.state

    return (
      <Modal
        open={ mediaChangeModalOpen }
        onClose={ () => {
          this.setState({ mediaChangeModalOpen: false })
        } }
        className={ classes.zIndex }
      >
        <div
          className={ classNames(
            classes.showRightPaneMobile
            // classes.hideMobileTablet
          ) }
        >
          <div className={ classes.mobilePaneHeader }>
            <div
              className={ classNames(classes.row) }
              onClick={ () => {
                this.setState({ mediaChangeModalOpen: false })
              } }
            >
              <div className={ classes.backButtonPane }>
                <ArrowLeftIcon />
                <Typography className={ classes.rightPaneTitle }>
                  <FormattedMessage
                    id="printerRightPane.changeMediaTooltip"
                    defaultMessage="Change Media"
                  />
                </Typography>
              </div>
            </div>
          </div>

          <div className={ classes.marginTopCaliSetMobileModal }>
            <Typography
              className={ classNames(classes.printerModalSelectBoxTitle) }
            >
              <FormattedMessage
                id="printerRightPane.media"
                defaultMessage="Media"
              />
            </Typography>
            { this.renderMediaMobilePane(classes) }
            <Typography
              className={ classNames(classes.printerModalSelectBoxTitle) }
            >
              <FormattedMessage
                id="printerRightPane.mediaSize"
                defaultMessage="Media Size"
              />
            </Typography>
            { this.renderMediaSize(classes) }
          </div>

          { this.renderOkaySubmitMediaChangeButton(classes) }
        </div>
      </Modal>
    )
  }

  renderOkaySubmitMediaChangeButton = classes => {
    return (
      <span className={ classes.printerActions }>
        <Button
          color="secondary"
          className={ classNames(classes.printerModalCancelButton) }
          fontFamily="Segoe UI"
          onClick={ () => {
            this.clearMediaChanges()
          } }
        >
          <FormattedMessage
            id="printerRightPane.cancel"
            defaultMessage="CANCEL"
          />
        </Button>
        <LoadingButton
          className={ classes.okayButton }
          fullWidth
          type="submit"
          color="primary"
          variant="outlined"
          disableRipple
          onClick={ () => {
            this.submitMediaChanges()
          } }
        >
          <FormattedMessage id="printerRightPane.okay" defaultMessage="OKAY" />
        </LoadingButton>
      </span>
    )
  }

  renderOkaySubmitCaliSetButton = classes => {
    return (
      <span className={ classes.printerActions }>
        <Button
          color="secondary"
          className={ classNames(classes.printerModalCancelButton) }
          fontFamily="Segoe UI"
          onClick={ () => {
            this.clearCaliChanges()
          } }
        >
          <FormattedMessage
            id="printerRightPane.cancel"
            defaultMessage="CANCEL"
          />
        </Button>
        <LoadingButton
          className={ classes.okayButton }
          fullWidth
          type="submit"
          color="primary"
          variant="outlined"
          disableRipple
          onClick={ () => {
            this.submitCaliChanges()
          } }
        >
          <FormattedMessage id="printerRightPane.okay" defaultMessage="OKAY" />
        </LoadingButton>
      </span>
    )
  }

  renderCaliSetForm = () => {
    const { classes } = this.props
    // const {caliSetModalOpen, currentPrinter} = this.state

    return (
      <Select
        disableUnderline={ true }
        value={ this.state.currentCaliSet }
        onChange={ e => {
          this.handleCaliSetModal(e)
        } }
        displayEmpty
        name="mediaSet"
        IconComponent={ LeftIcon }
        classes={ {
          root: classes.cpSelectBox + ' ' + classes.printerModalFormControl,
          selectMenu: classes.cpSelectBoxContent
        } }
        MenuProps={ {
          classes: {
            paper: classes.cpSelectMenuPaper + ' ' + classes.dropdownStyle
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          MenuListProps: {
            className: classNames(classes.cpSelectList)
          }
        } }
      >
        {this.state.currentPrinter.caliSets &&
          this.state.currentPrinter.caliSets
            .sort(Utils.sortObjectByKey('Name', false))
            .map((value, index) => {
              if (value.CaliSetDetail) {
                return (
                  <MenuItem
                    className={ classes.cpSelectMenuItem }
                    key={ Math.random() }
                    value={ value.CaliSetDetail }
                    classes={ { selected: classes.cpSelectSelected } }
                    selected={ this.state.currentCaliSet === value.CaliSetDetail }
                  >
                    {this.state.currentCaliSet === value.CaliSetDetail ? (
                      <span className={ classNames(classes.cpSelectCheckMark) }>
                        <CheckmarkIcon className={ classes.selectedCheckMark } />
                      </span>
                    ) : (
                        <span className={ classes.noCheckMark }> </span>
                      ) }
                    {value.Name }
                  </MenuItem>
                )
              } else {
                return ''
              }
            }) }
      </Select>
    )
  }

  renderRightPane () {
    const { classes } = this.props
    const { currentPrinter } = this.state

    return (
      <React.Fragment>
        <Drawer
          variant="permanent"
          align="center"
          classes={ {
            paper: classNames(
              classes.drawerPaperTablet,
              classes.rightPaneDrawer
            )
          } }
          PaperProps={ { style: this.state.newWidth } }
        >
          { this.rightPaneData(classes, currentPrinter) }
        </Drawer>
        <div className={ classNames(classes.showRightPaneMobile) }>
          <div className={ classes.mobilePaneHeader }>
            <div className={ classes.mobilePaneHeader }>
              <div
                className={ classNames(classes.row) }
                onClick={ () => {
                  this.setState({ isOpenRightPane: false })
                  this.context.trackingService.trackEvent(
                    'Printer Right Pane',
                    'Printer Right Pane Close',
                    '1',
                    1
                  )
                } }
              >
                <div className={ classes.backButtonPane }>
                  <ArrowLeftIcon />
                  <Typography className={ classes.rightPaneTitle }>
                    { currentPrinter.name }
                  </Typography>
                </div>
              </div>
            </div>
            <div className={ classes.marginTopPrinterMobile }>
              { this.rightPaneData(classes, currentPrinter) }
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  setNotification = notification => {
    this.setState({ notification })
  }

  render () {
    const { classes, printers, sites } = this.props
    const { selectedFilter } = this.state

    if (!this.state.filteredPrinters && printers.length !== 0)
      this.handleSearchTerms()

    const rows = listRows(classes, sites)
    const filteredPrinters = this.state.filteredPrinters || []
    const filterStates = this.printersCategories.getCategoryStates(selectedFilter)
    let filteredAndSortedPrinters = (filteredPrinters || []).filter(printer =>
      filterStates.includes(printer.status)
    )
    filteredAndSortedPrinters = filteredAndSortedPrinters.sort((x, y) => {
      if (x.site === y.site) {
        return x.name < y.name ? -1 : x.name > y.name ? 1 : 0
      } else {
        return x.site < y.site ? -1 : 1
      }
    })
    if (selectedFilter === 'notCalibrated') {
      filteredAndSortedPrinters = filteredAndSortedPrinters.filter(
        printer => !printer.isCalibrated || printer.isCalibrated === null
      )
      filteredAndSortedPrinters = filteredAndSortedPrinters.sort((x, y) => {
        if (x.site === y.site) {
          return x.name < y.name ? -1 : x.name > y.name ? 1 : 0
        } else {
          return x.site < y.site ? -1 : 1
        }
      })
    }
    return (
      <div className={ classes.root }>
        {this.renderSidebar() }
        <Paper square className={ classes.mainPaper }>
          { this.renderMobileHeader() }
          <Table classes={ { root: classes.tableHeadRoot } }
            className={ classNames(classes.table, {
              [ classes.tableTabLandscape ]: this.state.isOpenRightPane
            }) }
          >
            <TableHead>
              <TableRow className={ classNames(classes.tableHeadRow) }>
                { rows.map(row => (
                  <TableCell
                    className={ classNames(classes[ row.headClassName ], {
                      [ classes.tableCellHideMobile ]: row.hideMobile,
                      [ classes.tableCellHideTablet ]: row.hideTablet,
                      [ classes.tableCellHideRightPaneLandscape ]: row.hideRightPaneTabLandscape && this.state.isOpenRightPane
                    }) }
                    key={ row.key }
                    align={ row.numeric ? 'right' : 'left' }
                  >
                    {row.label }
                  </TableCell>
                )) }
              </TableRow>
            </TableHead>
            <TableBody>
              { filteredAndSortedPrinters.length ? (
                filteredAndSortedPrinters.map(printer => this.renderPrinter(printer, rows))
              ) : (
                  <TableRow>
                    <TableCell
                      colSpan={ 1 }
                      className={ classes.tableCellSpacerMobile }
                    />
                    <TableCell
                      colSpan={ rows.length }
                      className={ classes.tableCell }
                    >
                      <span className={ classes.noPrinters }>
                        <FormattedMessage
                          id="cpPrinters.noPrinters"
                          defaultMessage="No Printers"
                        />
                      </span>
                    </TableCell>
                    <TableCell
                      colSpan={ 1 }
                      className={ classes.tableCellSpacerMobile }
                    />
                  </TableRow>
                ) }
            </TableBody>
          </Table>
        </Paper>
        {this.state.isOpenRightPane ? this.renderRightPane() : null }
        {this.renderTabs() }
        <Notification
          setNotification={ this.setNotification }
          notification={ this.state.notification }
          services={ this.context }
        />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    printers: state.printers.customerPrinters,
    printerDetailsUpdated: state.printers.printerDetailsUpdated,
    sites: state.pairingInfo.sites,
    permissions: state.permissionsInfo.customerInfo,
    liveSearchTerm: state.filters.liveSearchTerm,
    currentSearchTerm: state.filters.currentSearchTerm,
    filterChips: state.filters.chips
  }
}

CpPrinters.propTypes = {
  mutateAddAction: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  printers: PropTypes.array,
  selectedFilter: PropTypes.string,
  liveSearchTerm: PropTypes.string,
  currentSearchTerm: PropTypes.object,
  filterChips: PropTypes.object
}

export default injectIntl(
  withStyles(styles)(
    withRouter(
      connect(
        mapStateToProps,
        {
          mutateAddAction
        }
      )(CpPrinters)
    )
  )
)