import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import ServiceContext from '../shared/Services/ServiceContext'
import Utils from '../shared/duck/helpers/Utils'
import { saveAuthToken } from './../store/localStorage'
import { GMGAuth } from '../shared/config/GMGAuth'
import { Auth } from 'aws-amplify';
import UpdatePage from './UpdatePage';

import * as constants from '../shared/duck/helpers/constants'
import {loginSuccess, runPairing} from './duck/actions/auth'
import getJwtFromCurrentSession from '../shared/config/SafeAuth'

if (window.location.search.indexOf('colorproofid') > -1) {
  localStorage.setItem('signedurlparameters', window.location.search)

  if (process.env.REACT_APP_STAGE !== 'Prod') {
    console.log(
      'Replace current URL with BaseURL and save: ',
      window.location.search
    )
  }

  window.history.replaceState({}, document.title, '/')
}

class Authenticator extends React.Component {
  static contextType = ServiceContext
  
  async componentDidMount () {
    // this is for backwards-compatibility;
    // versions of the CC app that don't use SSO will come here
    // so we show them an upgrade message instead of "failed to connect"
    if (Utils.isElectron()) {
      window.Bridge.sendMessage(
        JSON.stringify({
          isLoading: 'false' 
      }));

      return;
    }

    const user = await GMGAuth.getInstance().getUserIfSignedIn();
    if (!user) {
      await Auth.federatedSignIn();
    } else {
      await this.handleAuthenticationStateChange(user);
    }
  }

  async checkAndDoParing () {
    const signedurlparameters = localStorage.getItem('signedurlparameters');
    if (!signedurlparameters) {
      return;
    }

    if (process.env.REACT_APP_STAGE !== 'Prod') {
      console.info('Create Pairing to Colorproof-Instance: ', signedurlparameters);
    }

    localStorage.removeItem('signedurlparameters');
    await this.props.runPairing(signedurlparameters);
  }

  handleAuthenticationStateChange = async (user) => {
    await this.props.loginSuccess(user);
    this.context.navigationService.goTo(constants.GA_JOBS, {isUserAuthenticated: true});

    saveAuthToken(await getJwtFromCurrentSession());

    await this.checkAndDoParing();
  }

  render () {
    let updatePage = null;
    if (Utils.isElectron()) {
      // show the update notification page for old CC's
      updatePage = <UpdatePage />
    }
    return <ServiceContext.Provider value={ this.context }>
      {updatePage}
    </ServiceContext.Provider>
  }
}

Authenticator.propTypes = {
  runPairing: PropTypes.func.isRequired
}

export default injectIntl(
  withRouter(
    connect(undefined,
      {
        runPairing,
        loginSuccess
      }
    )(Authenticator)
  )
)