import * as types from '../types'

export default function authItemsState(state = {}, action) {
  switch (action.type) {
    case types.CHANGE_TOS_COMPONENT_STATE:
      return Object.assign({}, state, {
        login_state: action.isActive,
        register_state: !action.isActive,
        forgotpass_state: !action.isActive,
        resetpass_state: !action.isActive,
        tos_state: !action.isActive,
        confirm_signup: !action.isActive
      })
    default:
      return state
  }
}
