import { AUTH_TYPE } from 'aws-appsync-auth-link'
import { AWSAppSyncClient, createAppSyncLink } from 'aws-appsync'
import { setContext } from "apollo-link-context"
import { ApolloLink } from "apollo-link"
import { createHttpLink } from "apollo-link-http"
import { defaultDataIdFromObject } from 'apollo-cache-inmemory'
import AppSyncConfig from './AppSync'
import CpGoDocDbApiConfig from './CpGoDocDbAppsync'
import { loadAuthToken as loadAuthTokenFromLocalStorage } from './../../store/localStorage'
import { GMGAuth } from './GMGAuth'

const credentialsFunction = async () => {
  return await GMGAuth.getInstance().currentCredentials();
}

const AppSyncConfigLink = {
  region: AppSyncConfig.region,
  url: AppSyncConfig.url,
  auth: {
      type: AUTH_TYPE.AWS_IAM,
      credentials: credentialsFunction
  },
  fetchPolicy: 'network-only'
};

const authLink = setContext((_, {headers, ...context}) => {
  const token = loadAuthTokenFromLocalStorage();
  return {
    headers: {
      ...headers,
      ...(token ? {jwt: token} : {}),
    },
    ...context,
  };
});

const httpLink = createHttpLink({uri: AppSyncConfig.url});
const link = ApolloLink.from([authLink, httpLink]);

export const client = new AWSAppSyncClient({
  AppSyncConfigLink,
  cacheOptions: {
    dataIdFromObject: obj => {
      switch (obj.__typename) {
        case 'Job':
          return `Job:${obj.id}`
        default:
          return defaultDataIdFromObject(obj) // fall back to default handling
      }
    }
  }},
  {
    link: createAppSyncLink({
    ...AppSyncConfigLink,
    resultsFetcherLink: link,
    })
  }
)

export const cpGoDocDbApi = new AWSAppSyncClient({
    ...CpGoDocDbApiConfig,
    disableOffline: true,
    fetchPolicy: 'network-only',
    auth: {
      type: AUTH_TYPE.AWS_IAM,
      credentials: credentialsFunction
    },
    cacheOptions: {
      addTypename: false  // strip out typename __typename from the response
    }
  }
)

