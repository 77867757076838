module.exports = {
  // eslint-disable
  // this is an auto generated file. This will be overwritten

  onCpAddJob : `subscription OnCpAddJob($cpId: ID!) {
    onCpAddJob(cpId: $cpId) {
      cpId
      id
      name
      state
      userAction
      userActionMessage
      site
      created
      workflow
      printer
      printerType
      images
      copies
      widthInMM
      heightInMM
      mediaName
    }
  }
  `,
  onCpDeleteJob : `subscription OnCpDeleteJob($cpId: ID!) {
    onCpDeleteJob(cpId: $cpId) {
      cpId
      id
      name
      state
      userAction
      userActionMessage
      site
      created
      workflow
      printer
      printerType
      images
      copies
      widthInMM
      heightInMM
      mediaName
    }
  }
  `,
  onCpUpdateJob : `subscription OnCpUpdateJob($cpId: ID!) {
    onCpUpdateJob(cpId: $cpId) {
      cpId
      id
      name
      state
      userAction
      userActionMessage
      site
      created
      workflow
      printer
      printerType
      images
      copies
      widthInMM
      heightInMM
      mediaName
    }
  }
  `,
  onCpAddPrinter : `subscription OnCpAddPrinter($cpId: ID!) {
    onCpAddPrinter(cpId: $cpId) {
      cpId
      printerId
      name
      status
      site
      media
      mediaId
      isCalibrated
      lastCalibrationDate
      mediaSize
      mediaSizeId
      errorMessage
      printerType
      caliSets {
        Name
        Calibrated
        CaliSetDetail
      }
      inks {
        InkName
        InkPercentage
      }
      inkSet
      medias {
        Name
        id
        Sizes {
          Name
          id
        }
      }
    }
  }
  `,
  onCpDeletePrinter : `subscription OnCpDeletePrinter($cpId: ID!) {
    onCpDeletePrinter(cpId: $cpId) {
      cpId
      printerId
      name
      status
      site
      media
      mediaId
      isCalibrated
      lastCalibrationDate
      mediaSize
      mediaSizeId
      errorMessage
      printerType
      caliSets {
        Name
        Calibrated
        CaliSetDetail
      }
      inks {
        InkName
        InkPercentage
      }
      inkSet
      medias {
        Name
        id
        Sizes {
          Name
          id
        }
      }
    }
  }
  `,
  onCpUpdatePrinter : `subscription OnCpUpdatePrinter($cpId: ID!) {
    onCpUpdatePrinter(cpId: $cpId) {
      cpId
      printerId
      name
      status
      site
      media
      mediaId
      isCalibrated
      lastCalibrationDate
    }
  }
  `,
  onUpdateDongleInfo : `subscription OnUpdateDongleInfo($dongleId: ID!) {
    onUpdateDongleInfo(dongleId: $dongleId) {
      dongleId
      licensed
      cpId
      licenseName
    }
  }
  `,
  onDeleteDongleInfo : `subscription OnDeleteDongleInfo($dongleId: ID!) {
    onDeleteDongleInfo(dongleId: $dongleId) {
      dongleId
      licensed
      cpId
      licenseName
    }
  }
  `,
  onUpdateLinkedCpInfo : `subscription OnUpdateLinkedCpInfo($dongleId: ID!) {
    onUpdateLinkedCpInfo(dongleId: $dongleId) {
      dongleId
      cpId
      site
      lastSeen
      licensed
      licenseName
      isOnline
    }
  }
  `,
  onCpUpdatePrinterDetails : `subscription OnCpUpdatePrinterDetails($cpId: ID!, $printerId: String!) {
    onCpUpdatePrinterDetails(cpId: $cpId, printerId: $printerId) {
      cpId
      printerId
      errorMessage
      printerType
      caliSets {
        Name
        Calibrated
        CaliSetDetail
      }
      inks {
        InkName
        InkPercentage
      }
      mediaSize
      mediaSizeId
      inkSet
      medias {
        Name
        id
        Sizes {
          Name
          id
        }
      }
    }
  }
  `,
  onCustomerUpdateCpSite : `subscription OnCustomerUpdateCpSite($dongleId: String!) {
    onCustomerUpdateCpSite(dongleId: $dongleId) {
      cpId
      dongleId
      version
      site
      licensed
      lastSeen
      licenseName
      alias
      isHub
      role
      flowLicensed
      customers {
        customerId
        email
        lastlogin
        role
        isPending
        color255
      }
      isOnline
    }
  }
  `,
  onAddFlow : `subscription OnAddFlow {
    onAddFlow {
      hubId
      id
      customerId
      name
      created
      clients
      clientId
      targetHfwfId
      targetWfName
    }
  }
  `,
  onDeleteFlow : `subscription OnDeleteFlow {
    onDeleteFlow {
      hubId
      id
      customerId
      name
      created
      clients
      clientId
      targetHfwfId
      targetWfName
    }
  }
  `,
  onUpdateFlow : `subscription OnUpdateFlow {
    onUpdateFlow {
      hubId
      id
      customerId
      name
      created
      clients
      clientId
      targetHfwfId
      targetWfName
    }
  }
  `,
  onUpdateCustomerPair : `subscription OnUpdateCustomerPair($id: ID!) {
    onUpdateCustomerPair(id: $id) {
      CustomerCpPair
      cpId
      customerId
      role
    }
  }
  `,
  onUpdateOrder : `subscription OnUpdateOrder {
    onUpdateOrder {
      id
      targetId
      sourceId
      flowId
      printerNames
      printerTypes
      standardNames
      imageNames
      validationResults
      totalFileSize
      status
      created
      targetDongleIdDoc
      sourceDongleIdDoc
      flowNameDoc
      type
      customJobId
      substrateId
      thirdPartyID
      thirdPartyPayload
    }
  }
  `,
  onAddAction : `subscription OnAddAction($cpId: ID!) {
    onAddAction(cpId: $cpId) {
      id
      cpId
      created
      action
      actionParams {
        name
        value
      }
    }
  }
  `,
  onFinishCalculateProfileV2 : `subscription OnFinishCalculateProfileV2($clientId: String!) {
    onFinishCalculateProfileV2(clientId: $clientId) {
      clientId
      jobId
      succeeded
      error
      thirdpartyId
      thirdpartyPayload
    }
  }
  `,
}
