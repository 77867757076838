import { Auth } from 'aws-amplify'

const getJwtFromCurrentSession = async () => {
    try {
        return (await Auth.currentSession()).idToken.jwtToken;
    } catch {
        console.error('could not get current session');
        return undefined;
    }
}

export default getJwtFromCurrentSession;