import Utils from '../duck/helpers/Utils'
class TrackingService {
  _isColorCards = false

  static instance

  constructor() {
    // noinspection ES6ModulesDependencies
    if (TrackingService.instance) {
      // noinspection ES6ModulesDependencies
      return TrackingService.instance
    }

    TrackingService.instance = this

    this._initVariant()
  }

  _initVariant() {
    if (Utils.isColorCard()) {
      this._isColorCards = true
    }
  }

  isInitialized() {
    // return true to avoid throwing exceptions
    return true
  }

  trackPage() {
  }

  trackEvent() {
  }
}

export { TrackingService }
