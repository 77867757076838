export const categories = {
  inError: ['Error', 'MultiError', 'ForDelete'],
  notCalibrated: [
    'Ready',
    'Held',
    'Error',
    'Busy',
    'Multi',
    'MultiError',
    'ForDelete'
  ],
  allPrinters: [
    'Ready',
    'Held',
    'Error',
    'Busy',
    'Multi',
    'MultiError',
    'ForDelete',
    'Calibrating'
  ]
}