import { createStyles } from '@material-ui/core';

const drawerWidth = 282
const navTitleHeight = 70
const toolbarHeight = 111
const toolbarHeightXS = 60

const styles = theme => createStyles ({
  '@keyframes rotating': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' }
  },

  // Global
  disabled: {
    color: theme.palette.common.lightBorder,
    '&:hover, &:hover *': {
      boxShadow: 'none !important',
      backgroundColor: 'rgba(0, 0, 0, 0) !important'
    }
  },

  // Jobs / Printers
  root: {
    display: 'flex',
    height: '100%',
    flexWrap: 'wrap',
    alignItems: 'stretch'
  },
  minWidthWorkflow: {
    minWidth: 180
  },
  tabPaper: {
    [ theme.breakpoints.down('md') ]: {
      alignSelf: 'flex-end',
      flex: 1,
      width: '100%',

      position: 'fixed',
      bottom: 0,
      transform: 'translate3d(0,0,0)'
    }
  },

  // Mobile Screen UI
  mobileScreen: {
    display: 'none',
    [ theme.breakpoints.down('md') ]: {
      display: 'block'
    }
  },

  tabOptions: {
    display: 'none',
    [ theme.breakpoints.down('md') ]: {
      justifyContent: 'center',
      display: 'flex',
      flexGrow: 2,
      maxWidth: 1279,
      backgroundColor: theme.palette.background.lightPaper,
      textTransform: 'capitalize'
    }
  },
  tabs: {
    borderTop: '1px solid ' + theme.palette.common.lightBorder
  },

  indicator: {
    backgroundColor: theme.palette.background.lightPaper
  },

  // Jobs Right Sidebar
  drawerPaper: {
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    borderRight: '0px solid',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [ theme.breakpoints.down('md') ]: {
      display: 'none'
    }
  },
  drawerPaperTablet: {
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    whiteSpace: 'pre-wrap',
    width: drawerWidth,
    borderRight: '0px solid',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [ theme.breakpoints.down('sm') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('md') ]: {
      minWidth: 437,
      position: 'absolute',
      right: 0,
      left: '54%',
      marginTop: '10%',
      maxHeight: '74%',
      zIndex: 0
    }
  },
  menuButtonContainer: {
    textAlign: 'right'
  },
  menuButton: {
    width: '12px',
    height: '12px',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary
    }
  },
  hoverButtonLeft: {
    padding: 10,
    marginLeft: -15,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      cursor: 'pointer'
    },
    [ theme.breakpoints.only('sm') ]: {
      marginLeft: 0
    },
    [ theme.breakpoints.only('md') ]: {
      marginLeft: 0
    }
  },
  hoverButtonRight: {
    padding: 10,
    marginLeft: -3,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      cursor: 'pointer'
    },
    [ theme.breakpoints.only('sm') ]: {
      marginLeft: 25
    },
    [ theme.breakpoints.only('md') ]: {
      marginLeft: 0
    }
  },
  leftIcon: {
    width: '12px',
    height: '12px',
    marginTop: 17,
    marginRight: 5,
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      cursor: 'pointer'
    },
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    }
  },

  leftIconPrinter: {
    width: '12px',
    height: '12px',
    marginTop: 17,
    marginRight: 5
  },
  rightIconFlows: {
    width: '12px',
    height: '12px',
    marginTop: 17,
    marginLeft: -345
  },
  rightIcon: {
    width: '12px',
    height: '12px',
    transform: 'rotate(180deg)'
  },
  navigationTitle: {
    height: navTitleHeight - 33,
    fontSize: '17px',
    fontWeight: 700,
    [ theme.breakpoints.down('md') ]: {
      paddingTop: '3%',
      textTransform: 'uppercase',
      height: navTitleHeight
    },
    '&>span': {
      paddingRight: '5px'
    }
  },
  navigationTitleMobile: {
    display: 'none',
    [ theme.breakpoints.down('md') ]: {
      display: 'block'
    }
  },
  drawerPaperClose: {
    width: 95,
    display: 'block',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  hide: {
    visibility: 'hidden'
  },

  // Joblist
  mainPaper: {
    flex: 1,
    overflowX: 'auto',
    overflowY: 'auto',
    // Do not show scrollbars on desktop
    scrollbarWidth: 'none' /* Firefox */,
    '-ms-overflow-style': 'none' /* IE 10+ */,
    '&::-webkit-scrollbar': {
      /* WebKit */
      width: 0
    },
    height: '100%',
    padding: 28,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderTop: '10px solid ' + theme.palette.background.default,
    outlineTop: '2px solid black',
    [ theme.breakpoints.down('md') ]: {
      overflowX: 'hidden',
      padding: '5%',
      paddingTop: '2%',
      borderTop: 0,
      width: '100%',
      flex: 'none',
      marginBottom: 100
    },
    '& >*': {
      flex: 1,
      overflowY: 'auto',
      scrollbarWidth: 'none' /* Firefox */,
      '-ms-overflow-style': 'none' /* IE 10+ */,
      '&::-webkit-scrollbar': {
        /* WebKit */
        width: 0
      }
    }
  },
  table: {
    minWidth: 700,
    overflowY: 'auto',
    [ theme.breakpoints.down('md') ]: {
      maxWidth: 1279,
      minWidth: 0,
      marginBottom: 80
    }
  },
  tableTabLandscape: {
    minWidth: 300,
    overflowY: 'auto',
    scrollbarWidth: 'none' /* Firefox */,
    '-ms-overflow-style': 'none' /* IE 10+ */,
    '&::-webkit-scrollbar': {
      /* WebKit */
      width: 0
    },
    [ theme.breakpoints.down('md') ]: {
      maxWidth: 465,
      minWidth: 0,
      marginBottom: 80,
      marginLeft: 0
    }
  },
  tableHeadRow: {
    height: 30
  },
  tableHeadRoot: {
    borderCollapse: 'inherit'
  },
  tableHeadRowSettings: {
    height: 40
  },
  tableHeadCell: {
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    paddingLeft: 0,
    position: 'sticky',
    height: 60,
    top: -0,
    paddingBottom: 20,
    paddingTop: 20,
    zIndex: 100,
    background: theme.palette.background.paper,
    [ theme.breakpoints.down('md') ]: {
      top: -20.5
    },
    [ theme.breakpoints.down('xs') ]: {
      top: -13.5
    }
  },
  tableHeadCellMinWidth: {
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    paddingLeft: 0
  },
  tableBubbleHeadCellMinWidth: {
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    paddingLeft: 0,
    textAlign: 'center'
  },
  tableHeadCellState: {
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    paddingLeft: 0,
    textAlign: 'center',
    minWidth: 10,
    maxWidth: 30
  },
  tableCell: {
    fontSize: '12px',
    paddingLeft: 0,
    height: 60,
    minWidth: 115,
    maxWidth: 200
  },
  tableCellHover: {
    '&:hover': {
      backgroundColor: theme.palette.background.lightPaper,
      cursor: 'pointer'
    }
  },
  tableCellContextMenuContainer: {
    border: '1px solid' + theme.palette.secondary.main,
    color: theme.palette.text.primary,
    fontSize: '11px',
    textAlign: 'center',
    fontWeight: 400,
    minWidth: 140,
    marginLeft: 0
  },
  tableCellSpacer: {
    borderBottom: 0,
    padding: 10,
    margin: 0,
    minWidth: 0,
    maxWidth: 20,
    '&:last-child': {
      padding: 0,
      minWidth: 0,
      maxWidth: 20
    }
  },
  tableHeadAdjustStatus: {
    paddingRight: 20,
    position: 'sticky',
    top: 0,
    height: 60,
    zIndex: 100,
    background: theme.palette.background.paper,
    [ theme.breakpoints.down('md') ]: {
      top: -20.5
    },
    [ theme.breakpoints.down('xs') ]: {
      top: -13.5
    }
  },
  tableValueAdjustStatus: {
    paddingRight: 20,
    minWidth: 0,
    maxWidth: 30
  },
  tableCellSpacerMobile: {
    borderBottom: 0,
    padding: 0,
    margin: 0,
    minWidth: 10,
    maxWidth: 20,
    '&:last-child': {
      padding: 0,
      minWidth: 20,
      maxWidth: 30,
    },
    [ theme.breakpoints.down('md') ]: {
      display: 'none'
    }
  },
  hoverRow: {
    '&:hover': {
      backgroundColor: theme.palette.background.lightPaper,
      cursor: 'pointer'
    },
    '&:hover td': {
      borderTop:
        '2px solid' + theme.palette.background.lightPaper + '!important',
      borderBottom:
        '1px solid' + theme.palette.background.lightPaper + '!important'
    }
  },
  // TODO: Refactor name.
  activeJob: {
    backgroundColor: theme.palette.background.lightPaper,
    cursor: 'pointer',

    '& td': {
      borderTop:
        '2px solid' + theme.palette.background.lightPaper + '!important',
      borderBottom:
        '1px solid' + theme.palette.background.lightPaper + '!important'
    }
  },
  tableCellHideMobile: {
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    }
  },
  tableCellHideTablet: {
    [ theme.breakpoints.between('sm', 'md') ]: {
      display: 'none'
    }
  },
  tableCellHideRightPane: {
    display: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  tableCellHideRightPaneLandscape: {
    [ theme.breakpoints.only('md') ]: {
      display: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  tableCellState: {
    paddingLeft: 0
  },
  noLeftPadding: {
    paddingLeft: 0
  },
  noJobs: {
    textAlign: 'center',
    display: 'block'
  },

  rightPaneDrawer: {
    [ theme.breakpoints.up('sm') ]: {
      width: 214,
      height: '100%',
      overflowY: 'auto',
      // Do not show scrollbars on desktop
      scrollbarWidth: 'none' /* Firefox */,
      '-ms-overflow-style': 'none' /* IE 10+ */,
      '&::-webkit-scrollbar': {
        /* WebKit */
        width: 0
      },

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      overflow: 'hidden'
    }
  },
  rightPaneNavigationTitle: {
    height: navTitleHeight - 33,
    fontSize: '17px',
    fontWeight: 700,
    paddingTop: '10px',
    alignItems: 'left',
    textAlign: 'left',
    paddingLeft: 27,
    [ theme.breakpoints.down('md') ]: {
      paddingTop: '3%',
      textTransform: 'uppercase',
      height: navTitleHeight
    }
  },
  card: {
    maxWidth: '100%',
    alignItems: 'left',
    [ theme.breakpoints.only('xs') ]: {
      maxWidth: '100%'
    },
    [ theme.breakpoints.only('sm') ]: {
      marginBottom: 30,
      maxWidth: '100%'
      // margin: '0 auto'
    }
  },
  cardWidth: {
    [ theme.breakpoints.only('sm') ]: {
      marginBottom: 30,
      maxWidth: '92%',
      margin: '0 auto'
    }
  },
  media: {
    cursor: 'default',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    [ theme.breakpoints.only('xs') ]: {
      height: 250
    },
    [ theme.breakpoints.only('sm') ]: {
      minHeight: 300
    }
  },
  showMobile: {
    display: 'none',
    [ theme.breakpoints.only('xs') ]: {
      display: 'block'
    }
  },
  showTablet: {
    display: 'none',
    [ theme.breakpoints.only('sm') ]: {
      display: 'block'
    },
    [ theme.breakpoints.only('md') ]: {
      display: 'block'
    }
  },

  showWeb: {
    display: 'block',
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    }
  },
  showWebTableCell: {
    display: 'table-cell',
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    }
  },
  colorSmallBoxesLeftSide: {
    marginRight: 10,
    position: 'relative',
    width: 0,
    height: 0,
    borderLeft: '14px solid transparent',
    opacity: 0.6,
  },
  colorSmallBoxesRightSide: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderRight: '14px solid transparent',
    marginLeft: -24,
    marginTop: -12,
  },
  drawerTitles: {
    width: '100%',
    fontSize: 10,
    paddingLeft: 27,
    alignItems: 'left',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    color: theme.palette.text.disabled,
    marginTop: 15,
    marginBottom: 0,
    [ theme.breakpoints.only('xs') ]: {
      marginLeft: 0,
      paddingLeft: 27,
      width: 150
    },
    [ theme.breakpoints.only('sm') ]: {
      marginLeft: '10%',
      paddingLeft: 27,
      width: '15%',
      marginTop: 15
    }
  },
  separator: {
    borderTop: '1px solid #CCCCCC',
    marginTop: 15,
    marginLeft: 27,
    marginRight: 27,
    marginBottom: 0
  },
  menuSeparator: {
    borderTop: '1px solid ' + theme.palette.text.primary,
    marginLeft: 10,
    marginRight: 27,
    marginBottom: 15,
    pointerEvents: 'none'
  },
  noMarginTop: {
    marginTop: 0
  },
  verificationStripMargin: {
    marginTop: -5
  },
  rightPaneTabLandscape: {
    [ theme.breakpoints.only('md') ]: {
      marginLeft: -52,
      marginTop: 18
    }
  },
  drawerValues: {
    // width: '76%',
    fontSize: 12,
    alignItems: 'left',
    textAlign: 'left',
    minHeight: 20,
    color: theme.palette.common.black,
    marginLeft: 27,
    marginRight: 27,
    [ theme.breakpoints.only('xs') ]: {
      marginTop: -2,
      width: 131
    },
    [ theme.breakpoints.only('sm') ]: {
      marginLeft: '10%',
      paddingLeft: 27,
      marginTop: -2
    },
    [ theme.breakpoints.only('md') ]: {
      // marginLeft: -25,
      marginTop: -2
    }
  },
  marginTop: {
    marginTop: 10
  },
  flexDirection: {
    flexDirection: 'column'
  },
  colorBox: {
    width: 14,
    height: 14,
    backgroundColor: 'red'
  },
  colorPosition: {
    textAlign: 'left',
    marginTop: 20,
    paddingLeft: 27,
    flexDirection: 'column',
    [ theme.breakpoints.only('sm') ]: {
      marginLeft: '10%'
    }
  },
  imageCount: {
    display: 'block',
    marginTop: 20,
    fontSize: 14,
    fontFamily: 'Lato',
    color: theme.palette.secondary.main,
    [ theme.breakpoints.between('xs', 'sm') ]: {
      display: 'none'
    }
  },
  imageCountTab: {
    display: 'none',
    [ theme.breakpoints.only('sm') ]: {
      fontSize: 14,
      fontFamily: 'Lato',
      color: theme.palette.secondary.main,
      margin: '0 auto',
      padding: 10,
      display: 'table'
    }
  },
  imageCountMobile: {
    display: 'none',
    [ theme.breakpoints.only('xs') ]: {
      fontSize: 14,
      fontFamily: 'Lato',
      color: theme.palette.secondary.main,
      display: 'inline-flex',
      marginRight: 30
    }
  },
  rightPaneDivider: {
    borderTop: '1px solid ' + theme.palette.text.disabled,
    width: '80%',
    marginTop: 15,
    marginLeft: 27,
    marginRight: 27,
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('md') ]: {
      display: 'none'
    }
  },
  marginBottom: {
    marginBottom: 5,
    [ theme.breakpoints.only('xs') ]: {
      marginBottom: 25
    }
  },
  smallerFont: {
    fontSize: 10
  },
  jobActions: {
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 20,
    outline: 'none',
    textDecoration: 'none',
    cursor: 'pointer',
    [ theme.breakpoints.between('xs', 'sm') ]: {
      display: 'none'
    }
  },
  jobActionSpace: {
    marginRight: 0,
    marginLeft: 5,
    border: '1px solid ' + theme.palette.common.lightBorder,
    borderRadius: 3,
    padding: 7,
    height: 40,
    width: 40,
    cursor: 'pointer',
    display: 'inline-block'
  },

  activeClass: {
    border: '1px solid ' + theme.palette.error.dark + ' !important',
    color: theme.palette.error.dark + ' !important',
    pointerEvents: 'none'
  },
  normalClass: {
    border: '1px solid ' + theme.palette.secondary.dark + ' !important',
    color: theme.palette.secondary.dark + ' !important',
    '&:hover': {
      border: '1px solid ' + theme.palette.error.dark + ' !important',
      color: theme.palette.error.dark + ' !important'
    }
  },
  noPointerEvent: {
    pointerEvents: 'none'
  },
  printerActionSpace: {
    marginLeft: 5,
    border: '1px solid ' + theme.palette.common.lightBorder,
    borderRadius: 3,
    padding: 7,
    height: 40,
    width: 40,
    cursor: 'pointer',
    display: 'inline-block'
  },

  row: {
    '&after': {
      content: '',
      display: 'table',
      clear: 'both'
    }
  },
  leftColumn: {
    float: 'left',
    width: '10%',
    padding: '10px',
    paddingBottom: 0,
    paddingTop: 30,
    [ theme.breakpoints.only('xs') ]: {
      display: 'none',
      width: 0
    },
    [ theme.breakpoints.only('sm') ]: {
      paddingTop: '15%'
    }
  },
  cardDiv: {
    float: 'left',
    width: '80%',
    [ theme.breakpoints.only('xs') ]: {
      float: 'none',
      width: '100%'
    }
  },
  rightColumn: {
    float: 'left',
    width: '10%',
    padding: '10px',
    paddingLeft: 0,
    paddingTop: 30,
    [ theme.breakpoints.only('xs') ]: {
      display: 'none',
      width: 0
    },
    [ theme.breakpoints.only('sm') ]: {
      paddingTop: '15%'
    }
  },
  passed: {
    width: 15,
    height: 15,
    paddingTop: 6,
    color: 'green'
  },
  failed: {
    width: 15,
    height: 15,
    paddingTop: 6,
    color: 'red'
  },
  adjustAlign: {
    marginLeft: 20,
    width: '93%',
    paddingRight: 20,
    [ theme.breakpoints.only('xs') ]: {
      marginTop: 0,
      marginLeft: 0,
      paddingRight: 0,
      width: '100%'
    },
    [ theme.breakpoints.only('sm') ]: {
      // marginLeft: '5%',
      width: '80%'
    }
  },
  adjustCard: {
    [ theme.breakpoints.only('sm') ]: {
      marginLeft: '14%'
    }
  },
  showRightPaneMobile: {
    display: 'none',
    [ theme.breakpoints.only('xs') ]: {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      overflowY: 'auto',
      background: theme.palette.background.paper,
      height: '100%',
      width: '100%',
      zIndex: 10000
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      background: theme.palette.background.paper,
      height: '100%',
      width: '100%',
      zIndex: 10002
    }
  },
  mobilePaneHeader: {
    position: 'fixed',
    transform: 'translate3d(0,0,0)',
    top: 0,
    left: 0,
    height: 60,
    zIndex: 10003,
    width: '100%',
    borderBottom: '1px solid ' + theme.palette.common.lightBorder,
    background: theme.palette.background.lightPaper
  },
  backButtonPane: {
    display: 'flex',
    padding: '15px'
  },
  rightPaneTitle: {
    display: 'inline-block',
    fontSize: 17,
    fontWeight: 'bold',
    marginLeft: 10
  },
  rightPaneMobileDataContainer: {
    position: 'relative',
    top: 60,
    background: theme.palette.common.white,
    display: 'flex'
  },
  rightPaneMobileDataCol: {
    width: '100%'
  },
  noDisplay: {
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    }
  },
  tabletView: {
    [ theme.breakpoints.only('sm') ]: {
      marginTop: 15
    }
  },
  editButton: {
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    [ theme.breakpoints.only('xs') ]: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '100%',
      padding: '30px 30px 25px 30px',
      float: 'right',
      marginTop: -75,
      marginRight: 30,
      marginBottom: 30
    },
    [ theme.breakpoints.only('sm') ]: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '100%',
      padding: '30px 30px 25px 30px',
      float: 'right',
      marginTop: -75,
      marginBottom: 20,
      marginRight: 95
    }
  },
  pencilIcon: {
    display: 'none',
    width: 32,
    height: 37,
    [ theme.breakpoints.only('xs') ]: {
      display: 'block',
      color: theme.palette.common.white
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'block',
      color: theme.palette.common.white
    }
  },
  verificationStyle: {
    [ theme.breakpoints.only('xs') ]: {
      float: 'right',
      marginTop: -300,
      marginBottom: 20
    },
    [ theme.breakpoints.only('sm') ]: {
      float: 'right',
      marginTop: -350,
      marginBottom: 20,
      marginRight: '25%'
    },
    [ theme.breakpoints.only('md') ]: {
      position: 'absolute',
      marginBottom: 20,
      right: 0,
      marginTop: '43%'
    }
  },
  verificationRules: {
    width: 120,
    display: 'inline-flex',
    overflow: 'hidden',
    [ theme.breakpoints.only('xs') ]: {
      width: 90,
      whiteSpace: 'nowrap'
    },
    [ theme.breakpoints.only('sm') ]: {
      width: 90,
      whiteSpace: 'nowrap'
    },
    [ theme.breakpoints.only('md') ]: {
      width: 90,
      whiteSpace: 'nowrap'
    }
  },
  verificationHide: {
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('md') ]: {
      display: 'none'
    }
  },
  modalStyle: {
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    [ theme.breakpoints.only('xs') ]: {
      padding: 27,
      height: 175,
      position: 'absolute',
      bottom: 150,
      backgroundColor: theme.palette.common.white,
      // right: 30,
      left: '20%',
      outline: 'none',
      borderRadius: 5
    },
    [ theme.breakpoints.only('sm') ]: {
      padding: 27,
      position: 'absolute',
      bottom: '30%',
      left: '35%',
      backgroundColor: theme.palette.common.white,
      outline: 'none',
      right: 200,
      width: 200,
      height: 215,
      borderRadius: 5
    }
  },
  tabletIconPosition: {
    [ theme.breakpoints.only('sm') ]: {
      marginRight: 40,
      marginTop: -2
    }
  },
  iconModalPane: {
    display: 'none',
    [ theme.breakpoints.only('xs') ]: {
      display: 'block'
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'inline-flex',
      width: 150,
      paddingBottom: 50
    }
  },
  zIndex: {
    zIndex: 100000
  },
  paneDrawerValues: {
    [ theme.breakpoints.only('xs') ]: {
      marginLeft: 50,
      marginTop: -28,
      fontSize: 17,
      paddingBottom: 20
    }
  },
  noBottomPadding: {
    [ theme.breakpoints.only('xs') ]: {
      paddingBottom: 0
    }
  },
  noTopMargin: {
    [ theme.breakpoints.only('xs') ]: {
      marginTop: -90
    }
  },
  mobileImageSlide: {
    display: 'none',
    [ theme.breakpoints.only('xs') ]: {
      display: 'flex',
      marginBottom: 15,
      paddingLeft: 35,
      marginTop: 20,
      paddingRight: 35
    }
  },
  imageMarginMobile: {
    margin: '0 auto',
    display: 'inline-flex'
  },
  leftImageSlideMobile: {
    display: 'none',
    [ theme.breakpoints.only('xs') ]: {
      display: 'block',
      marginRight: 30
    }
  },
  rightImageSlideMobile: {
    display: 'none',
    [ theme.breakpoints.only('xs') ]: {
      display: 'block',
      float: 'right',
      transform: 'rotate(180deg)',
      zIndex: 900000
    }
  },
  dragger: {
    width: '5px',
    cursor: 'ew-resize',
    padding: '4px 0 0',
    borderTop: '1px solid #ddd',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: '100',
    backgroundColor: '#f4f7f9'
  },

  noPrinters: {
    textAlign: 'center',
    display: 'block'
  },

  // User List
  settingTableCellWidth: {
    width: '25%'
  },
  settingPageBorder: {
    borderTop: '10px solid ' + theme.palette.background.default
  },
  paddingLeft: {
    paddingLeft: 20
  },
  textUnderline: {
    textDecoration: 'underline'
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [ theme.breakpoints.only('xs') ]: {
      maxWidth: 125
    }
  },
  deleteIcon: {
    display: 'inline-block',
    height: '24px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      boxShadow: '0 0 0 0.2rem rgba(0, 0, 0, 0.08)'
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.32)',
      boxShadow: '0 0 0 0.2rem rgba(0, 0, 0, 0.32)'
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.32)',
      boxShadow: '0 0 0 0.2rem rgba(0, 0, 0, 0.32)'
    }
  },
  deleteProgressColor: {
    color: 'rgba(0, 0, 0, 0.5)'
  },

  // Menu Bar
  rootMenu: {
    display: 'flex'
  },
  tabsDesktop: {
    display: 'none',
    [ theme.breakpoints.up('lg') ]: {
      display: 'flex',
      marginTop: 18
      // backgroundColor: theme.palette.background.default,
    }
  },
  indicatorMenu: {
    backgroundColor: theme.palette.background.default,
    marginBottom: 10
  },
  tabSizeTabFirst: {
    marginRight: 2,
    minWidth: drawerWidth / 2 - 1,
    width: drawerWidth / 2 - 1,
    marginTop: 0,
    minHeight: 40,
    maxHeight: 40,
    textTransform: 'capitalize'
  },
  tabSizeTabSecond: {
    minWidth: drawerWidth / 2 - 1,
    width: drawerWidth / 2 - 1,
    marginTop: 0,
    minHeight: 40,
    maxHeight: 40,
    textTransform: 'capitalize'
  },
  tabSizeTabThird: {
    marginLeft: 2,
    minWidth: drawerWidth / 2 - 1,
    width: drawerWidth / 2 - 1,
    marginTop: 0,
    minHeight: 40,
    maxHeight: 40,
    textTransform: 'capitalize'
  },
  tabBackgroundDark: {
    backgroundColor: theme.palette.background.darkPaper,
    borderBottom: '1px solid ' + theme.palette.primary.main,
    opacity: 1,
    zIndex: 20
  },
  tabBackgroundLight: {
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid ' + theme.palette.background.default,
    opacity: 1
  },
  hideDesktop: {
    [ theme.breakpoints.up('lg') ]: {
      display: 'none !important'
    }
  },
  showDesktop: {
    [ theme.breakpoints.down('md') ]: {
      display: 'none !important'
    }
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,

    // Fix for auto hiding the top bar on mobile safari when loading the page, all values are necessary!
    top: 0,
    position: 'fixed',
    transform: 'translate3d(0,0,0)',

    // TODO: Ask Himanshu why this was added, the transition is not visible at all.
    transition: theme.transitions.create([ 'width', 'margin' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  headerLogo: {
    marginLeft: 20,
    height: 28,
    marginTop: 20,
    [ theme.breakpoints.down('md') ]: {
      marginRight: 60,
      marginTop: 15
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  headerLogoHide: {
    [ theme.breakpoints.down('md') ]: {
      display: 'none'
    }
  },
  chipsRoot: {
    marginTop: 40,
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    margin: 10,
    fontSize: 17,
    fontFamily: 'Lato',
    fontWeightRegular: 200,
    border: '1px solid ' + theme.palette.error.dark + ' !important'
  },
  chipLabel: {
    color: theme.palette.error.dark
  },
  searchBar: {
    fontSize: 17,
    width: 190,
    fontFamily: 'Lato',
    fontWeightRegular: 200,
    outline: 0,
    borderBottom: '1px solid ' + theme.palette.error.dark + ' !important',
    [ theme.breakpoints.up('lg') ]: {
      marginTop: -39,
      marginRight: 105,
      marginLeft: 'auto',
      float: 'right'
    },
    '&:hover, &:hover *': {
      cursor: 'pointer'
    }
  },
  searchIcon: {
    display: 'none',
    [ theme.breakpoints.up('lg') ]: {
      display: 'block',
      marginTop: -49,
      marginRight: 95,
      marginLeft: 'auto',
      float: 'right'
    }
  },
  searchIconActive: {
    display: 'none',
    [ theme.breakpoints.up('lg') ]: {
      display: 'block',
      marginTop: -49,
      marginRight: 295,
      marginLeft: 'auto',
      float: 'right'
    }
  },
  settingIcon: {
    display: 'none',
    [ theme.breakpoints.up('lg') ]: {
      display: 'block',
      marginTop: -48,
      marginRight: 25,
      marginLeft: 'auto',
      float: 'right'
    }
  },

  helpCenterIcon: {
    display: 'none',
    [ theme.breakpoints.up('lg') ]: {
      display: 'block',
      marginTop: -48,
      marginRight: 60,
      marginLeft: 'auto',
      float: 'right'
    }
  },
  title: {
    flexGrow: 1,
    [ theme.breakpoints.down('md') ]: {
      marginRight: 50,
      justifyContent: 'center',
      display: 'flex'
    },
    [ theme.breakpoints.up('lg') ]: {
      flexGrow: 0
    }
  },
  signOut: {
    [ theme.breakpoints.down('md') ]: {
      flex: 0.1
    }
  },

  returnButton: {
    display: 'none',
    cursor: 'pointer',
    [ theme.breakpoints.up('lg') ]: {
      marginLeft: 15,
      marginTop: 'auto',
      background: 'transparent',
      display: 'flex'
    }
  },
  backIcon: {
    width: 30,
    height: 16,
    marginRight: 15,
    marginTop: 10
  },
  settingTitle: {
    fontSize: 20
  },
  menuShow: {
    [ theme.breakpoints.down('md') ]: {
      display: 'block'
    },
    [ theme.breakpoints.between('sm', 'md') ]: {
      width: 300,
      borderRadius: 0,
      overflow: 'hidden'
    },
    [ theme.breakpoints.up('lg') ]: {
      width: 180,
      borderRadius: 0,
      left: 'auto !important',
      marginRight: 35,
      marginTop: 110,
      float: 'right',
      opacity: 1
    }
  },

  printerMenu: {
    [ theme.breakpoints.down('xs') ]: {
      marginLeft: '17%'
    },
    [ theme.breakpoints.between('sm', 'md') ]: {
      marginLeft: '8%'
    },
    [ theme.breakpoints.up('lg') ]: {
      display: 'none !important'
    }
  },

  desktopMenuLogout: {
    [ theme.breakpoints.up('lg') ]: {
      padding: '6px 20px 6px 20px',
      border: '1px solid' + theme.palette.primary.main,
      color: theme.palette.primary.main,
      borderRadius: 3
    }
  },
  menuIcon: {
    display: 'none',
    [ theme.breakpoints.down('md') ]: {
      display: 'flex',
      marginTop: 8,
      marginLeft: '3.5%'
    }
  },
  menuDesign: {
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.2)',
    margin: 1,
    outline: 'none',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    [ theme.breakpoints.up('lg') ]: {
      border: '1px solid ' + theme.palette.common.black,
      borderRadius: 3,
      boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2)',
      marginTop: '3px !important',
      backgroundColor: theme.palette.background.paper
    }
  },
  settingsLogoutItemPadding: {
    [ theme.breakpoints.up('lg') ]: {
      paddingTop: '18px !important',
      paddingBottom: '18px !important'
    }
  },
  settingsLogoutItemEmptyMenuPadding: {
    [ theme.breakpoints.up('lg') ]: {
      paddingTop: '18px !important',
      paddingBottom: '10px !important'
    }
  },
  upperPadding: {
    [ theme.breakpoints.down('xs') ]: {
      paddingTop: '6%',
      marginLeft: '17%'
    },
    [ theme.breakpoints.between('sm', 'md') ]: {
      paddingTop: '8%',
      marginLeft: '8%'
    },
    [ theme.breakpoints.up('lg') ]: {
      display: 'none !important'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  menuItems: {
    [ theme.breakpoints.down('xs') ]: {
      marginLeft: '17%'
    },
    [ theme.breakpoints.between('sm', 'md') ]: {
      marginLeft: '8%'
    },
    [ theme.breakpoints.up('lg') ]: {
      color: theme.palette.common.black,
      marginLeft: '1%',
      paddingBottom: '10px !important',
      paddingTop: '10px !important'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  menuItemNoMargin: {
    marginLeft: 0
  },
  marginBottomMenu: {
    [ theme.breakpoints.down('md') ]: {
      marginBottom: 50
    }
  },
  blurBackground: {
    [ theme.breakpoints.down('md') ]: {
      opacity: 0.2
    }
  },

  toolbar: {
    zIndex: 2,
    borderBottom: '1px solid ' + theme.palette.primary.main,
    paddingRight: 0, // keep right padding when drawer closed
    paddingLeft: 0, // keep left padding when drawer closed
    height: toolbarHeight,
    flexDirection: 'column',
    alignItems: 'flex-start',
    [ theme.breakpoints.down('md') ]: {
      height: toolbarHeightXS,
      borderBottom: '1px solid ' + theme.palette.common.lightBorder,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }
  },
  appBarSpacer: {
    flex: 'initial !important',
    height: toolbarHeight,
    [ theme.breakpoints.down('md') ]: {
      height: toolbarHeightXS
    }
  },
  content: {
    height: '100vh',
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    overflow: 'auto'
  },

  // Category List
  counterNumber: {
    paddingRight: 4
  },

  // TODO: HACK - Hard override, maybe there is a better way in Material UI?
  listItemSelected: {
    '&:focus': {
      backgroundColor: '#FAFAFA !important'
    }
  },
  // TODO: HACK - Hard override, maybe there is a better way in Material UI?
  listItemDeselected: {
    '&:hover': {
      borderRight: '2px solid #EDEDED !important'
    }
  },

  selected: {
    '& *': {
      color: theme.palette.text.primary
    }
  },
  deselected: {
    '& *': {
      color: theme.palette.text.secondary
    },
    '&:hover *': {
      color: theme.palette.text.primary
    }
  },

  //Job Images
  tooltip: {
    whiteSpace: 'pre-line'
  },
  subline: {
    color: theme.palette.text.secondary,
    fontSize: '10px'
  },

  // Job State
  jobStateIconSize: {
    width: '18px',
    height: '18px'
  },
  inError: {
    color: '#FF0000'
  },
  onHold: {
    color: '#F1C82E'
  },
  inQueue: {
    color: '#50B24F'
  },
  complete: {
    color: '#50B24F'
  },

  //FlowOrder State
  Uploading: {
    color: '#50B24F'
  },
  UploadError: {
    color: '#FF0000'//red
  },
  Downloading: {
    color: '#50B24F'
  },
  TargetDownloadError: {
    color: '#FF0000'
  },
  TargetProcessing: {
    color: '#50B24F'
  },
  TargetJobProcessing: {
    color: '#50B24F'
  },
  TargetValidationFailed: {
    color: '#FF0000'
  },
  TargetJobFinished: {
    color: '#50B24F'
  },
  TargetJobWaiting: {
    color: '#F1C82E'//yellow
  },
  TargetJobError: {
    color: '#FF0000'
  },
  // Printer State
  notCalibrated: {
    color: '#F1C82E'
  },
  allPrinters: {
    color: '#50B24F'
  },

  // Sites List

  cursorPointer: {
    cursor: 'pointer'
  },
  aliasInputBox: {
    border: 0,
    outline: 0,
    background: theme.palette.background.paper
  },

  aliasInputBoxJobPrinter: {
    border: 0,
    outline: 0
  },

  green: {
    color: 'green'
  },
  red: {
    color: 'red'
  },
  // TODO: Refactor CSS rules
  iconSizeCheckmark: {
    width: '18px',
    marginLeft: 10
  },
  iconSizeCross: {
    width: '18px',
    marginLeft: 10
  },
  noData: {
    textAlign: 'center'
  },

  // InfoToast
  infoToast: {
    maxHeight: 104,
    minWidth: '100%',
    borderRadius: 0,
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '& span': {
      display: 'flex'
    },
    '& svg': {
      marginRight: theme.spacing(1)
    }
  },

  // Loading Buttons
  progressButtonWrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: '50%',
    marginLeft: -12
  },

  // Password With Validations
  formControl: {
    display: 'block'
  },
  textFieldLabel: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(0.1)
  },
  formError: {
    float: 'right',
    marginTop: 3,
    position: 'relative'
  },
  satisfiedInputCriteria: {
    color: theme.palette.text.disabled
  },

  //VBox
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    scrollbarWidth: 'none' /* Firefox */,
    '-ms-overflow-style': 'none' /* IE 10+ */,
    '&::-webkit-scrollbar': {
      /* WebKit */
      width: 0
    },
    '& >*': {
      flex: 1,
      overflowY: 'hidden'
    }
  },

  //Tooltip Styles (Tippy)
  tooltips: {
    fontFamily: 'Lato',
    fontSize: 10
  },
  tooltipsWithWidth: {
    fontFamily: 'Lato',
    fontSize: 10,
    width: 156
  },
  jobActionDisabled: {
    pointerEvents: 'none',
    borderRadius: 3,
    border: '1px solid ' + theme.palette.error.main + '!important',
    color: theme.palette.error.main
  },
  caliSetVisible: {
    display: 'none'
  },
  flexDisplay: {
    [ theme.breakpoints.only('sm') ]: {
      display: 'flex'
    }
  },
  caliSetDisabled: {
    pointerEvents: 'none'
  },

  // Printer Mobile Pane

  marginTopPrinterMobile: {
    [ theme.breakpoints.only('xs') ]: {
      marginTop: 60
    },
    [ theme.breakpoints.only('sm') ]: {
      marginLeft: -50
    }
  },
  noInkMessageMobile: {
    [ theme.breakpoints.only('xs') ]: {
      marginTop: 80
    }
  },
  printerMobileCaliSet: {
    [ theme.breakpoints.down('sm') ]: {
      right: '20%',
      position: 'absolute',
      top: 75
    },
    [ theme.breakpoints.only('xs') ]: {
      right: '8%',
      position: 'absolute',
      top: 67
    },
    [ theme.breakpoints.only('sm') ]: {
      right: '-5%',
      position: 'absolute',
      top: 75
    },
    [ theme.breakpoints.down(350) ]: {
      right: 0,
      position: 'absolute',
      top: 67
    }
  },
  printerMobileCaliSetWithInks: {
    [ theme.breakpoints.only('xs') ]: {
      right: 45,
      position: 'absolute',
      top: 230
    },
    [ theme.breakpoints.only('sm') ]: {
      right: '15%',
      position: 'absolute',
      top: 300
    },
    [ theme.breakpoints.down(350) ]: {
      right: 0,
      position: 'absolute',
      top: 230
    }
  },
  ellipsisPrinterPane: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [ theme.breakpoints.only('xs') ]: {
      maxWidth: '100%'
    },
    [ theme.breakpoints.only('sm') ]: {
      marginTop: 84
    }
  },
  halfWidth: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [ theme.breakpoints.only('sm') ]: {
      maxWidth: '50%'
    }
  },

  marginTopCaliSetMobileModal: {
    [ theme.breakpoints.only('xs') ]: {
      marginTop: 71
    },
    [ theme.breakpoints.only('sm') ]: {
      marginTop: 71
    }
  },
  modalStylePrinterActions: {
    [ theme.breakpoints.only('xs') ]: {
      right: 70,
      bottom: 200
    }
  },
  editButtonPrinterPane: {
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    [ theme.breakpoints.only('xs') ]: {
      borderRadius: '100%',
      padding: '30px 30px 25px 30px',
      float: 'left',
      marginTop: 20,
      marginLeft: 30,
      backgroundColor: theme.palette.primary.main
    },
    [ theme.breakpoints.only('sm') ]: {
      borderRadius: '100%',
      backgroundColor: theme.palette.primary.main,
      padding: '30px 30px 25px 30px',
      float: 'left',
      marginTop: 30,
      marginLeft: '13.5%'
    }
  },
  pencilIconPrinterPane: {
    display: 'none',
    width: 32,
    height: 37,
    [ theme.breakpoints.only('xs') ]: {
      display: 'block',
      color: theme.palette.common.white
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'block',
      color: theme.palette.common.white
    }
  },
  printerModalTitle: {
    fontSize: 17,
    marginLeft: 30,
    paddingTop: 20,
    textTransform: 'uppercase'
  },
  printerModalSelectBoxTitle: {
    fontSize: 14,
    marginLeft: 30,
    paddingTop: 20
  },
  printerModalFormControl: {
    width: 362,
    height: 20,
    marginLeft: 30,
    marginRight: 30,
    border: '1px solid' + theme.palette.secondary.main,
    marginTop: 5,
    [ theme.breakpoints.only('xs') ]: {
      width: 'calc(100% - 60px)'
    },
    [ theme.breakpoints.only('sm') ]: {
      width: 'calc(100% - 60px)'
    }
  },
  printerModalSelectBox: {},
  printerModalButtons: {
    marginTop: 40,
    height: 100,
    background: theme.palette.background.default,
    flexDirection: 'column'
  },
  modalStylePrinterPane: {
    [ theme.breakpoints.up('md') ]: {
      display: 'block',
      width: 460,
      height: 300,
      background: theme.palette.background.paper,
      outline: 'none',
      margin: '0 auto',
      marginTop: 214
    },
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'none'
    }
  },
  modalStylePrinterPaneCaliSet: {
    [ theme.breakpoints.up('md') ]: {
      display: 'block',
      width: 460,
      height: 250,
      background: theme.palette.common.white,
      outline: 'none',
      margin: '0 auto',
      marginTop: 214
    },
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'none'
    }
  },
  printerModalCancelButton: {
    paddingLeft: 30,
    paddingTop: 8,
    display: 'inline-block',
    fontSize: 17,
    textAlign: 'center',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  okayButton: {
    display: 'inline-block',
    fontSize: 17,
    marginLeft: 13
  },
  printerActions: {
    flexDirection: 'column',
    display: '-webkit-inline-box',
    marginRight: 42,
    marginTop: 30,
    float: 'right',
    [ theme.breakpoints.only('sm') ]: {
      marginRight: '5.5%'
    }
  },
  marginTopPrinterPaneStatus: {
    marginTop: 40,
    [ theme.breakpoints.only('xs') ]: {
      marginTop: 10
    }
  },

  printerColors: {
    width: 6.7,
    backgroundColor: 'red',
    padding: 2,
    position: 'absolute',
    bottom: 0,
    [ theme.breakpoints.only('xs') ]: {
      width: 12,
      marginLeft: 0.5
    },
    [ theme.breakpoints.only('sm') ]: {
      width: 18,
      marginLeft: 0.5
    }
  },

  printerColorDiv: {
    border: '1px solid ' + theme.palette.text.disabled,
    width: 9,
    marginBottom: 5,
    height: 102,
    display: 'inline-block',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    marginRight: 5,
    [ theme.breakpoints.only('xs') ]: {
      height: 144,
      width: 14
    },
    [ theme.breakpoints.only('sm') ]: {
      height: 200,
      width: 20
    }
  },

  printerColorPosition: {
    margin: 0,
    marginLeft: 8,
    [ theme.breakpoints.only('xs') ]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 27,
      marginTop: 74
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: '13.5%',
      marginRight: '8%',
      marginTop: 74,
      marginBottom: 30
    }
  },
  colorNamePrinterPane: {
    display: 'none',
    [ theme.breakpoints.only('xs') ]: {
      // alignItems: 'flex-end',
      justifyContent: 'flex-end',
      display: 'block',
      fontSize: 10,
      marginLeft: -1,
      color: theme.palette.common.black,
      marginTop: 40
    },
    [ theme.breakpoints.only('sm') ]: {
      // alignItems: 'flex-end',
      justifyContent: 'flex-end',
      display: 'block',
      fontSize: 10,
      color: theme.palette.common.black,
      marginTop: 40
    }
  },
  rotatePrinterPaneText: {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    transform: 'rotate(180deg)'
  },

  tooltipsMargin: {
    //background: theme.palette.common.black,
    fontFamily: 'Lato',
    fontSize: 10,
    marginBottom: 5
  },
  hideFeature: {
    display: 'none'
  },
  hideMobileTablet: {
    [ theme.breakpoints.up('sm') ]: {
      display: 'none'
    }
  },

  dropdownStyle: {
    minWidth: 400,
    width: 400,
    maxWidth: 400,
    position: 'relative',
    border: '1px solid ' + theme.palette.secondary.main,
    marginTop: 40,
    [ theme.breakpoints.only('xs') ]: {
      minWidth: '64%',
      width: '64%',
      maxWidth: '64%'
    }
  },
  countersContainer: {
    paddingBottom: 50,
    paddingRight: 10
  },
  cpNumberInput: {
    width: '40%',
    fontSize: 17,
    borderRadius: 5,
    marginLeft: 30,
    paddingTop: 3,
    paddingBottom: 3,
    border: '1px ' + theme.palette.secondary.main,
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input": {
      '-moz-appearance': 'textfield',
      margin: 0
    }
  },
  counterUpButton: {
    position: 'absolute',
    marginTop: 5,
    marginRight: 5,
    opacity: 0.3,
    '&:hover, &:hover *': {
      opacity: 1,
      cursor: 'pointer'
    },
  },
  counterDownButton: {
    position: 'absolute',
    marginTop: 20,
    marginRight: 5,
    opacity: 0.3,
    '&:hover, &:hover *': {
      opacity: 1,
      cursor: 'pointer'
    },
  },
  // Dropdown Styles - Re-usable
  cpSelectBox: {
    fontSize: 17,
    borderRadius: 3,
    border: '1px solid ' + theme.palette.secondary.main
  },
  cpSelectCheckMark: {
    display: 'inline',
    marginLeft: -15,
    marginRight: 5
  },
  selectedCheckMark: {
    height: 15
  },
  noCheckMark: {
    marginLeft: 15
  },
  cpSelectBoxContent: {
    '& > span:nth-of-type(1)': {
      display: 'none'
    },
    paddingTop: 5,
    paddingBottom: 6,
    paddingLeft: 5,
    background: theme.palette.background.paper,
    borderRadius: 5
  },

  cpSelectMenuPaper: {
    border: '1px solid ' + theme.palette.secondary.main,
    marginTop: 10
  },

  cpSelectList: {
    paddingTop: 0,
    paddingBottom: 0
  },

  cpSelectMenuItem: {
    marginLeft: 0, // Reset left offset no hover
    color: theme.palette.text.disabled,
    background: theme.palette.background.paper,
    padding: '5px 15px',
    fontSize: 17
  },

  cpSelectSelected: {
    backgroundColor: theme.palette.background.paper + ' !important',
    border: 'none !important', // Fix for global style applied (unknown from where)
    color: theme.palette.common.black
  },

  // User admin role select box
  userAdminRoleSelectBox: {
    width: 125
  },

  // Flows
  flowFormControl: {
    border: '1px solid' + theme.palette.secondary.main
  },

  // New Work Flow Modal
  newWorkFlowModal: {
    display: 'block',
    width: 460,
    height: 416,
    background: theme.palette.common.white,
    outline: 'none',
    margin: '0 auto',
    marginTop: 214
  },
  newWorkFlowModalTitle: {
    fontSize: 17,
    marginLeft: 30,
    paddingTop: 20,
    textTransform: 'uppercase'
  },
  newWorkFlowSelectBoxTitle: {
    fontSize: 14,
    marginLeft: 30,
    paddingTop: 20
  },
  workFlowFileInputLabel: {
    width: 350,
    marginLeft: 30,
    marginTop: 10,
    height: 32,
    background: '#EDEDED',
    appearance: 'none'
  },
  uploadWorkFlowContainer: {
    display: 'inline-flex'
  },
  inputFileHidden: {
    opacity: 0,
    width: 40
  },
  uploadNewWorkFlow: {
    height: 33,
    width: 33,
    marginTop: 10,
    marginLeft: 20,
    border: '1px solid #999999',
    borderRadius: 3,
    textAlign: 'center'
  },
  // File Upload Modal
  fileUploadModal: {
    position: 'absolute',
    background: theme.palette.common.black,
    opacity: 0.85,
    height: '100%',
    width: '100%',
    zIndex: 10000,
    overflow: 'auto'
  },
  fileUploadCross: {
    color: theme.palette.background.paper,
    width: 30,
    height: 30,
    opacity: 1,
    fontSize: 54,
    padding: 30,
    cursor: 'pointer'
  },
  fileUploadLabel: {
    fontSize: 54,
    color: theme.palette.background.paper,
    opacity: 1,
    fontFamily: 'Lato'
  },
  browseUploadLabel: {
    fontSize: 21,
    color: theme.palette.background.paper,
    opacity: 1,
    textDecoration: 'underline',
    fontFamily: 'Lato',
    cursor: 'pointer'
  },
  crossModal: {
    textAlign: 'end',
    height: 100
  },
  labelModal: {
    textAlign: 'center',
    height: 100
  },

  modalContentGrid: {
    textAlign: 'center',
    display: 'inline-grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '20px',
    gridRowGap: '20px'
  },
  modalContentGridColumn: {
    textAlign: 'center',
    display: 'inline-block',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '20px',
    gridRowGap: '20px'
  },
  fileUploadContainer: {
    width: 370,
    height: 'auto',
    // border: '1px solid ' + theme.palette.secondary.main,
    borderRadius: 3
  },
  fileUploadInnerContainer: {
    pointerEvents: 'none',
    display: 'block',
    order: 1
  },
  siteNameLabel: {
    textAlign: 'left',
    fontSize: 19,
    color: theme.palette.secondary.main,
    opacity: 1,
    margin: 15,
    fontFamily: 'Lato'
  },
  workflowNameLabel: {
    color: theme.palette.background.paper,
    textAlign: 'center',
    fontSize: 30,
    marginTop: 20,
    marginLeft: 15,
    marginRight: 15,
    fontFamily: 'Lato',
    fontWeight: 100
  },
  workflowNumberLabel: {
    color: theme.palette.background.paper,
    textAlign: 'center',
    fontSize: 19,
    fontFamily: 'Lato',
    fontWeight: 100
  },
  dropZoneCustomize: {
    width: 350,
    height: 'auto',
    margin: 8,
    padding: '5px',
    border: '1px solid ' + theme.palette.secondary.main,
    paddingBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    outline: '2px dotted ' + theme.palette.secondary.main,
    outlineOffset: -13
  },
  inputLabelDropZone: {
    display: 'block',
    position: 'relative',
    cursor: 'pointer'
  },
  inputLabelWithFiles: {
    display: 'none'
  },
  previewFiles: {
    border: 0,
    display: 'grid'
  },
  previewFileName: {
    fontSize: 10,
    fontFamily: 'Lato',
    fontWeight: 100
  },
  crossButton: {
    width: 10,
    height: 10,
    color: '#fafafa',
    marginLeft: 5,
    cursor: 'pointer',
    zIndex: 10000
  },
  progressBar: {
    border: '1px solid #fafafa!important',
    height: 10,
    width: 240,
    borderRadius: 4,
    margin: '0 auto'
  },
  progressBarBackground: {
    color: '#000!important',
    backgroundColor: '#fafafa!important',
    height: 8
  },
  dropZoneActiveSize: {
    width: 350,
    height: 'auto',
    outlineOffset: -18
  },
  fileNameLabel: {
    color: '#fafafa',
    fontSize: 10,
    fontFamily: 'lato',
    textAlign: 'initial',
    margin: '0 auto',
    marginTop: 10
  },
  fileIcon: {
    color: 'red',
    marginTop: 10
  },
  autoHeight: {
    height: 'auto'
  },

  // Input file Button
  svgWrapper: {
    margin: '0 auto',
    marginTop: 35,
    transform: 'translateY(-50%)',
    '&:hover, &:hover *': {
      '-webkit-animation': '0.5s $draw linear forwards',
      animation: '0.5s $draw linear forwards',
      cursor: 'pointer'
    },
    '&:active, &:active *': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  },
  defaultDateMenuContainerRight: {
    border: '1px solid' + theme.palette.text.primary,
    background: theme.palette.common.white,
    width: '160px',
    marginTop: '45px'
  },
  defaultDateMenuItemRight: {
    color: theme.palette.text.primary,
    fontSize: 11,
    textAlign: 'center',
    fontWeight: 800,
    height: 2,
    marginLeft: 0
    //padding: 0
  },
  defaultDateMenuContainerLeft: {
    border: '1px solid' + theme.palette.text.primary,
    background: theme.palette.common.white,
    width: '260px',
    marginTop: '45px',
    marginLeft: -5
  },
  defaultDateMenuItemLeft: {
    color: theme.palette.text.primary,
    fontSize: '17px',
    textAlign: 'center',
    fontWeight: 500,
    height: 5,
    marginLeft: 0
  },
  dateRangeInputWrapperLeft: {
    margin: '0 auto',
    marginTop: 20,
    marginBottom: 10,
    width: 260,
    background: theme.palette.common.white
  },
  dateRangeInputWrapperRight: {
    margin: '0 auto',
    marginTop: 5,
    width: 160,
    height: 35,
    background: theme.palette.common.white
  },
  dateRangeInput: {
    width: '100%',
    height: '100%',
    //border: '1px solid #000000'
  },
  dateRangeInputRight: {
    width: '100%',
    height: '100%',
    fontSize: 11,
    fontWeight: 800,
    //border: '1px solid #000000'
  },
  dateRangeInputArrow: {
    position: 'absolute',
    marginTop: 15,
    marginRight: 5,
    opacity: 0.3,
    '&:hover, &:hover *': {
      opacity: 1,
      cursor: 'pointer'
    },
  },
  dateRangeContainerLeft: {
    marginLeft: 14,
    marginTop: 0,
    position: 'fixed',
    zIndex: 1
  },
  dateRangeContainerRight: {
    marginLeft: -251,
    marginTop: 10,
    position: 'fixed',
    zIndex: 1
  },
  shape: {
    fill: 'transparent',
    strokeDasharray: '10 440',
    strokeDashoffset: -415,
    strokeWidth: 0,
    stroke: '#E5004A',
    '&:hover, &:hover *': {
      '-webkit-animation': '0.5s $draw linear forwards',
      animation: '0.5s $draw linear forwards'
    }
  },

  inputLabelText: {
    marginTop: -40,
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#E5004A'
  },

  '@keyframes draw': {
    '0%': {
      strokeDasharray: '10 440',
      strokeDashoffset: -410,
      strokeWidth: 6
    },
    '100%': {
      strokeDasharray: 760,
      strokeDashoffset: 0,
      strokeWidth: 2
    }
  },
  plusIcon: {
    width: 23,
    height: 17,
    margin: '9px 2px -4px 6px'
  },

  //Channel Mapping
  channelMappingUserButtons: {
    marginTop: 50,
    height: 100,
    background: theme.palette.background.default,
  },

  //Invite User
  inviteUser: {
    border: '1px solid #EE2D68',
    borderRadius: 3,
    opacity: 1,
    padding: '9.5px 26px',
    color: '#EE2D68',
    cursor: 'pointer'
  },
  inviteUserBox: {
    [ theme.breakpoints.up('md') ]: {
      display: 'block',
      width: 460,
      height: 250,
      background: theme.palette.common.white,
      outline: 'none',
      margin: '0 auto',
      marginTop: 214
    },
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'none'
    }
  },

  inviteUserTitle: {
    fontSize: 17,
    marginLeft: 30,
    paddingTop: 20,
    textTransform: 'uppercase'
  },
  inviteUserSelectBoxTitle: {
    fontSize: 14,
    marginLeft: 30,
    paddingTop: 20
  },
  inviteUserSelectBoxTitleError: {
    fontSize: 12,
    marginLeft: 30,
    paddingTop: 15,
    color: theme.palette.secondary.main
  },
  userInviteUserMobileModal: {
    [ theme.breakpoints.only('xs') ]: {
      marginTop: 71
    },
    [ theme.breakpoints.only('sm') ]: {
      marginTop: 71
    }
  },
  inviteInputBox: {
    width: 400,
    height: 20,
    margin: '4px 30px 12px 30px'
  },
  inviteModalStyle: {
    [ theme.breakpoints.up('md') ]: {
      display: 'block',
      width: 460,
      height: 320,
      background: theme.palette.common.white,
      outline: 'none',
      margin: '0 auto',
      marginTop: 214
    },
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'none'
    }
  },

  inviteUserFormControl: {
    width: 400,
    marginLeft: 30,
    marginRight: 30,
    border: '1px solid' + theme.palette.secondary.main,
    marginTop: 5,
    [ theme.breakpoints.only('xs') ]: {
      width: 'calc(100% - 60px)'
    },
    [ theme.breakpoints.only('sm') ]: {
      width: 'calc(100% - 60px)'
    }
  },

  inviteUserFormControlSelect: {
    width: 400,
    height: 20,
    marginLeft: 30,
    marginRight: 30,
    border: '1px solid' + theme.palette.secondary.main,
    marginTop: 5,
    [ theme.breakpoints.only('xs') ]: {
      width: 'calc(100% - 60px)'
    },
    [ theme.breakpoints.only('sm') ]: {
      width: 'calc(100% - 60px)'
    }
  },
  inviteUserButtons: {
    marginTop: 30,
    height: 100,
    background: theme.palette.background.default,
    flexDirection: 'column'
  },
  inviteModalCancelButton: {
    paddingLeft: 30,
    paddingTop: 8,
    display: 'inline-block',
    fontSize: 17,
    textAlign: 'center',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  inviteButton: {
    display: 'inline-block',
    fontSize: 17,
    marginLeft: 13
  },
  inviteActionSpace: {
    flexDirection: 'column',
    display: '-webkit-inline-box',
    marginRight: 42,
    marginTop: 30,
    float: 'right',
    [ theme.breakpoints.only('sm') ]: {
      marginRight: '5.5%'
    }
  },
  flowModalStyle: {
    [ theme.breakpoints.up('md') ]: {
      display: 'block',
      width: 460,
      height: 390,
      background: theme.palette.common.white,
      outline: 'none',
      margin: '0 auto',
      marginTop: 214
    },
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'none'
    }
  },
  flowModalStyleError: {
    [ theme.breakpoints.up('md') ]: {
      display: 'block',
      width: 460,
      height: 455,
      background: theme.palette.common.white,
      outline: 'none',
      margin: '0 auto',
      marginTop: 214
    },
    [ theme.breakpoints.only('xs') ]: {
      display: 'none'
    },
    [ theme.breakpoints.only('sm') ]: {
      display: 'none'
    }
  },
  roleIcon: {
    left: 140
  },

  helpCenterIconInvite: {
    display: 'none',
    [ theme.breakpoints.up('lg') ]: {
      display: 'inline-block',
      padding: 0,
      marginLeft: 4,
      width: '4%',
      height: '4%',
      marginTop: -5
    }
  },
  disableDullColor: {
    pointerEvents: 'none',
    color: theme.palette.secondary.main
  },

  // Modal dialog
  modalDialogTitle: {
    padding: 0,
  },
  modalDialogUserTitle: {
    fontSize: 17,
    marginLeft: 33,
    paddingTop: 20,
    paddingBottom: 20,
    textTransform: 'uppercase'
  },
  modalDialogActions: {
    flexDirection: 'column',
    display: '-webkit-inline-box',
    marginRight: 38,
    alignItems: 'end',
    float: 'right',
    [ theme.breakpoints.only('sm') ]: {
      marginRight: '5.5%'
    }
  },

  // Grid Channel Mapping
  gridColorChannelMapping: {
    flexGrow: 1,
  },
  gridBoxLabel: {
    backgroundColor: '#ededed',
    fontSize: 17,
    padding: 4,
    paddingLeft: 11,
    textAlign: "left",
    color: "#333333",
    boxShadow: "none",
  },
  gridTextFieldReplaceColor: {
    color: "#bbbbbb",
    fontStyle: "italic",
    height: "12px",
    margin: "0px",
    padding: "0px",
    lineHeight: "0.5em",
    transform: "translate(8px, 12px) scale(1)"
  },
  gridChannelMappingIcon: {
    height: 14,
    marginRight: 5
  },
  gridFormError: {
    float: 'right',
    marginTop: 3,
    position: 'relative',
    textAlign: 'right'
  },
  modalColorChannelMappingContent: {
    overflowY: 'hidden',
    padding: '8px 33px'
  },
  dialogColorChannelMapping: { minWidth: "700px" },
})

export default styles
