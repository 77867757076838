import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography/index'

const InputComponent = ({value, classes, sites}) => {
    return(
        <div key={Math.random()}
            className={classes.fileUploadInnerContainer}>
            <div className={classes.siteNameLabel}>
                {sites.map(site => {
                    if (site.cpId === value.id) {
                        return site.alias ? (
                            <React.Fragment key={Math.random()}>
                                <React.Fragment>{site.alias}</React.Fragment>
                            </React.Fragment>
                        ) : (
                            <React.Fragment key={Math.random()}>
                                {site.site}
                            </React.Fragment>
                        )
                    } else {
                        return null
                    }
                })}
            </div>       
            <Typography className={classNames(classes.workflowNameLabel)} noWrap={true}>
                {value.StandardName}          
            </Typography>
            <div className={classes.workflowNumberLabel}>
                {value.Name}
            </div>
        </div>
    )
}

InputComponent.propTypes = {
    value: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    sites: PropTypes.array.isRequired
}

export default InputComponent