import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      fill="none"
      d="M12,1.8C6.4,1.8,1.8,6.4,1.8,12S6.4,22.2,12,22.2S22.2,17.7,22.2,12S17.6,1.8,12,1.8z M10.1,17.1
	c-0.2,0.2-0.4,0.3-0.5,0.3S9.1,17.3,9,17.1l-3.6-3.6l1.2-1.2l3,3l8-8l1.1,1.2L10.1,17.1z"
    />
    <path
      d="M12,0.2C5.5,0.2,0.2,5.5,0.2,12S5.5,23.9,12,23.9S23.8,18.6,23.8,12S18.5,0.2,12,0.2z M12,22.2
	C6.4,22.2,1.8,17.7,1.8,12S6.4,1.8,12,1.8S22.2,6.4,22.2,12S17.6,22.2,12,22.2z"
    />
    <path d="M9.6,15.3l-3-3l-1.2,1.2L9,17.1c0.1,0.2,0.4,0.3,0.5,0.3s0.4-0.1,0.5-0.3l8.5-8.6l-1.1-1.2L9.6,15.3z" />
  </React.Fragment>,
  'CheckmarkCircleIcon'
)
