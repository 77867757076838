import React from 'react'
import { IntlProvider } from 'react-intl'
//import en from 'react-intl/locale-data/en'
//import de from 'react-intl/locale-data/de'
import Container from '../Services/Container'

//addLocaleData([...en, ...de])

const trService = Container.TranslationService()

export const locale = trService.getLocale()
export default function withIntl(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <IntlProvider
          locale={trService.getLocale()}
          messages={trService.getLocalizationData()}
        >
          <WrappedComponent
            locale={trService.getLocale()}
            messages={trService.getLocalizationData()}
            {...this.props}
          />
        </IntlProvider>
      )
    }
  }
}
