import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tippy from '@tippy.js/react'
import classNames from 'classnames'
import CircleIcon from '../../../../assets/icons/CircleIcon'
import styles from '../../Styles/styles'

const StatusBubble = ({ status, categories, classes }) => {
  const category = categories.getCategoryOfState(status)
  return (
    <Tippy
      content={ category }
      arrow={ true }
      arrowType="sharp"
      animation="fade"
      placement={ 'top' }
      distance="15"
      className={ classes.tooltips }
      popperOptions={ {
        placement: 'top',
        modifiers: {
          preventOverflow: {
            enabled: false
          },
          hide: {
            enabled: false
          }
        }
      } }
    >
      <span>
        <CircleIcon className={ classNames(classes.jobStateIconSize, classes[ category ]) } />
      </span>
    </Tippy>
  )
}

StatusBubble.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired
}

export default withStyles(styles)(StatusBubble)