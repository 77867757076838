
import React, { useEffect } from 'react';
import * as constants from './Types/constants'
import PropTypes from 'prop-types';
import styles from '../../shared/Styles/styles'
import { withStyles } from '@material-ui/core/styles/index'
import { FormattedMessage } from 'react-intl'
import LoadingButton from '../../shared/Components/Buttons/LoadingButton'
import { Channel } from './Types/types';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import AddChannelRow from './Components/AddChannelRow';
import { JobsCategories } from '../duck/helpers/JobsCategories';
import { EditChannelIcon } from './Components/EditChannelIcon';
import { Form } from 'react-final-form';
import { connect, ConnectedProps, useSelector } from 'react-redux'
import {
  getColorChannelMapping,
  saveColorChannelMapping,
} from './../duck/jobsActions'
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { NotificationTypes } from '../../shared/duck/types/notificationTypes';

interface Props extends PropsFromRedux {
  classes: any;
  imageDetail: any;
  jobsCategories: JobsCategories;
  currentJobState: string;
  currentCpId: string;
  isEnabled: boolean;
  popOverText: string;
  sendNotification: (notificationType: string, messageId: string) => void;
}

const DialogSelect: React.FC<Props> = ({ classes, imageDetail, jobsCategories, currentJobState, currentCpId, isEnabled, popOverText, getColorChannelMapping, saveColorChannelMapping, initialValuesFromState, channelValuesFromState, isFetching, sendNotification }) => {
  const [open, setOpen] = React.useState(false);
  const initialValues = useSelector<PropsFromRedux, any>(() => initialValuesFromState);
  const channels: Channel[] | undefined = useSelector<PropsFromRedux, any>(() => channelValuesFromState);
  const isLoading = useSelector<PropsFromRedux, any>(() => isFetching);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    if(reason != 'backdropClick') {
      setOpen(false)
    }
  };

  useEffect( () => {   
    const loadInitiData = async () => {
      await getColorChannelMapping(imageDetail.jobId, imageDetail.id)
    }

    if(open) {
      loadInitiData()
        // make sure to catch any error
        .catch(console.error);
    }

    // eslint-disable-next-line
  }, [imageDetail.id, open])

  const onSubmit = async (values: any) => {    
    console.log(JSON.stringify(values, undefined, 2));

    saveColorChannelMapping(imageDetail.jobId, imageDetail.id, currentCpId, values, channelValuesFromState);
    setOpen(false)

    sendNotification(NotificationTypes.Info, "cpJobs.colorchannelmapping");
  };

  const validate = (values: any) => {
    const errors = {};
    return errors;
  };

  const renderColorChannelMapping = (values: any) => { 
    return (
    <span>
      <Grid container spacing={2}>
        {channels?.map((channel, index) => {
          if (!channel.availableColors.includes(constants.SEPARATOR)) {
            channel.availableColors.splice(channel.availableColors.indexOf(constants.DO_NOT_PRINT) + 1, 0, constants.SEPARATOR)
          }
          return (
            <Grid container item spacing={1} key={index}>
              <AddChannelRow classes={classes} channel={channel} values={values} />
            </Grid>
          );
        })}
      </Grid>       
    </span>
  ) };

  return (
    <span>
      <EditChannelIcon classes={classes} jobsCategories={jobsCategories} currentJobState={currentJobState} isEnabled={isEnabled} popOverText={popOverText} handleClickOpen={handleClickOpen} />      
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose} fullWidth={true} classes={{ paper: classes.dialogColorChannelMapping}}>
        <DialogTitle className={ classes.modalDialogTitle }>
          <Typography className={ classes.modalDialogUserTitle }>
              <FormattedMessage
                id="cpSitesUser.channelMapping"
                defaultMessage="CHANNEL MAPPING"
              />
          </Typography>          
        </DialogTitle>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, submitting, pristine, values, valid }) => (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent className={ classes.modalColorChannelMappingContent }>
                  <Box className={ classes.gridColorChannelMapping }>      
                  <ReactPlaceholder showLoadingAnimation={true} type='text' rows={14} color='#E7E7E7' ready={!isLoading && channels !== null}>
                        { renderColorChannelMapping(values) }
                  </ReactPlaceholder>
                  </Box>              
                </DialogContent>
                <DialogActions className={ classes.channelMappingUserButtons }>
                  <span className={ classes.modalDialogActions }>
                    <Button
                        id="channelMapping.cancelChanges"
                        color="secondary"             
                        className={ classes.inviteModalCancelButton }      
                        disabled={submitting}      
                        onClick={ handleClose }
                      >
                        <FormattedMessage
                          id="cpSitesUser.cancel"
                          defaultMessage="CANCEL"
                        />
                    </Button>
                    <LoadingButton
                        id="channelMapping.applyChanges"
                        className={ classes.inviteButton }
                        fullWidth
                        type="submit"
                        color="primary"
                        variant="outlined"
                        disableRipple
                        disabled={submitting || !valid || isLoading}
                      >
                        <FormattedMessage
                          id="cpSitesUser.apply"
                          defaultMessage="APPLY"
                        />
                    </LoadingButton>
                  </span>  
                </DialogActions>
              </form>
            )}
          />
      </Dialog>  
    </span>
  ) 
}

function mapStateToProps (state: any) {
  return {
    initialValuesFromState: state.jobs.initialValuesFromState,
    channelValuesFromState: state.jobs.channelValuesFromState,
    isFetching: state.jobs.isFetching,
    errorColorChannelMapping: state.jobs.errorColorChannelMapping,
  }
}

DialogSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  getColorChannelMapping: PropTypes.func.isRequired,
  saveColorChannelMapping: PropTypes.func.isRequired
};

const connector = connect(mapStateToProps,
  {
    getColorChannelMapping,
    saveColorChannelMapping,
  }
)

type PropsFromRedux = ConnectedProps<typeof connector>

export default withStyles(styles)(
  connector(DialogSelect))
