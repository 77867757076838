import messagesDE from '../Localization/de.json'
import messagesEN from '../Localization/en.json'

export class TranslationService {
  static instance

  _locale = '' // en_US
  _langCode = '' // en
  _localizationData = null

  constructor() {
    // noinspection ES6ModulesDependencies
    if (TranslationService.instance) {
      // noinspection ES6ModulesDependencies
      return TranslationService.instance
    }

    this._determineLocale()
    this._determineLangCode()
    this._loadLocalizationData()
    TranslationService.instance = this
  }

  _determineLocale() {
    this._locale =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage
  }

  _determineLangCode() {
    this._langCode = this._locale.toLowerCase().split(/[_-]+/)[0]
  }

  _loadLocalizationData() {
    const localizations = {
      de: messagesDE,
      en: messagesEN
    }

    this._localizationData = localizations[this._langCode] || localizations.en
  }

  isInitialized() {
    return (
      this._locale !== '' &&
      this._langCode !== '' &&
      this._localizationData !== null
    )
  }

  getLocale() {
    return this._locale
  }

  getLangCode() {
    return this._langCode
  }

  getLocalizationData() {
    return this._localizationData
  }

  tr(key) {
    return this._localizationData[key] || key
  }
}
