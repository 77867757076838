import GMGColorCardLogo from '../../../RootComponents/Logo/GMGColorCardLogo'
import GMGLogo from '../../../RootComponents/Logo/GMGLogo'
import BackgroundImage from '../../../../assets/backgroundMedia.jpg'
import React from 'react'
import { Auth } from 'aws-amplify'

export default class Utils {
  static arrayAddById (arr, newElement) {
    const found = arr.some(el => el.id === newElement.id)
    if (!found) arr.push(newElement)
    return arr
  }

  static isSiteOnline (site) {
    if (site.hasOwnProperty('isOnline') && site.isOnline != null)
      return site.isOnline

    if (!site.lastSeen) {
      return false
    }

    const dateNow = new Date()
    const lastSeenInt = parseInt(site.lastSeen)
    if (isNaN(lastSeenInt)) {
      // eslint-disable-next-line
      throw 'Could not parse timestamp from server.'
    }

    const dateLastSeen = new Date(lastSeenInt)
    if (dateNow.getTime() - dateLastSeen.getTime() < 300000) {
      return true
    }
    return false
  }

  /**
   * @description
   * Returns a function which will sort an
   * array of strings.
   *
   * @param  {String}  key
   * @param  {Boolean} reverse
   * @return {Function}
   */
  static alphabeticalSort (a, b) {
    if (a < b) {
      return -1
    } else if (a > b) {
      return 1
    }
    return 0
  }

  /**
   * @description
   * Returns a function which will sort an
   * array of objects by the given key.
   *
   * @param  {String}  key
   * @param  {Boolean} reverse
   * @return {Function}
   */
  static sortObjectByKey (key, reverse) {
    // Move smaller items towards the front
    // or back of the array depending on if
    // we want to sort the array in reverse
    // order or not.
    const moveSmaller = reverse ? 1 : -1

    // Move larger items towards the front
    // or back of the array depending on if
    // we want to sort the array in reverse
    // order or not.
    const moveLarger = reverse ? -1 : 1

    /**
     * @param  {*} a
     * @param  {*} b
     * @return {Number}
     */
    return (a, b) => {
      if (a[ key ] < b[ key ]) {
        return moveSmaller
      }
      if (a[ key ] > b[ key ]) {
        return moveLarger
      }
      return 0
    }
  }

  static sortObjectByGmg = (key, reverse) => {
    const moveSmaller = reverse ? 1 : -1
    const moveLarger = reverse ? -1 : 1
    return (a, b) => {
      if (a[ key ].includes('GMG') && b[ key ].includes('GMG')) {
        if (a[ key ] < b[ key ]) {
          return moveSmaller
        }
        if (a[ key ] > b[ key ]) {
          return moveLarger
        }
      } else if (a[ key ].includes('GMG')) {
        return moveSmaller
      } else if (b[ key ].includes('GMG')) {
        return moveLarger
      } else {
        if (a[ key ] < b[ key ]) {
          return moveSmaller
        }
        if (a[ key ] > b[ key ]) {
          return moveLarger
        }
      }
      return 0
    }
  }

  static sortObjectByRoll = (key, reverse) => {
    const moveSmaller = reverse ? 1 : -1
    const moveLarger = reverse ? -1 : 1
    return (a, b) => {
      if (a[ key ].includes('Roll') && b[ key ].includes('Roll')) {
        if (a[ key ] < b[ key ]) {
          return moveSmaller
        }
        if (a[ key ] > b[ key ]) {
          return moveLarger
        }
      } else if (a[ key ].includes('Roll')) {
        return moveSmaller
      } else if (b[ key ].includes('Roll')) {
        return moveLarger
      } else {
        if (a[ key ] < b[ key ]) {
          return moveSmaller
        }
        if (a[ key ] > b[ key ]) {
          return moveLarger
        }
      }
      return 0
    }
  }

  static deepClone (obj, hash = new WeakMap()) {
    if (Object(obj) !== obj) return obj // primitives
    if (obj instanceof Set) return new Set(obj) // See note about this!
    if (hash.has(obj)) return hash.get(obj) // cyclic reference
    const result =
      obj instanceof Date
        ? new Date(obj)
        : obj instanceof RegExp
          ? new RegExp(obj.source, obj.flags)
          : obj.constructor
            ? new obj.constructor()
            : Object.create(null)
    hash.set(obj, result)
    if (obj instanceof Map)
      Array.from(obj, ([ key, val ]) =>
        result.set(key, Utils.deepClone(val, hash))
      )
    return Object.assign(
      result,
      ...Object.keys(obj).map(key => ({
        [ key ]: Utils.deepClone(obj[ key ], hash)
      }))
    )
  }

  static clearWindowSelection () {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty()
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges()
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty()
    }
  }

  static isColorCardLogo (classes) {
    if (Utils.isColorCard()) {
      return <GMGColorCardLogo className={ classes.logo } />
    } else {
      return <GMGLogo className={ classes.logo } />
    }
  }

  static isColorCard () {
    // Change logo according to URL Variant
    const urlParams = new URLSearchParams(window.location.search)
    const variant = urlParams.get('variant')
    if (variant === 'colorcards') {
      return true
    }
    return false
  }

  static backGroundImage () {
    if (Utils.isColorCard()) {
      // Currently no background
      return ''
    } else {
      return BackgroundImage
    }
  }

  static isElectron () {
    let userAgent = navigator.userAgent.toLowerCase()
    return userAgent.indexOf(' electron/') > -1
  }

  static objEquals (obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  static deutschLink () {
    return 'https://www.gmgcolor.com/de/support/hilfe/colorproof/colorproofgo/colorproofgo.htm'
  }

  static englishLink () {
    return 'https://www.gmgcolor.com/support/help/colorproof/colorproofgo/colorproofgo.htm'
  }

  static async userEmail (user) {
    if (user) {
      const userObj = user.attributes ?? user;
      if ( userObj.email && userObj.email !== "") {
        return userObj.email;
      }
    }
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const session = await Auth.userSession(cognitoUser);
      return session.getIdToken().decodePayload().email;
    } catch (e) {
      throw new Error(`could not get email from user: ${JSON.stringify(user)}`);
    }
  }
}
