export const saveChannelMapping = `mutation saveChannelMapping($input: ChannelMappingInput) {
  saveChannelMapping(input: $input) {
    cpId
    jobId
    action
    actionParams {
      name
      value
    }
  }
}
`;