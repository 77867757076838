export const handleAction = (action, currentPrinters) => {
  const mediaId = action.actionParams.find(p => p.name === "mediaId")
  const printerId = action.actionParams.find(p => p.name === "id")
  const mediaSizeId = action.actionParams.find(p => p.name === "mediaSizeId")

  if (!mediaId || !printerId || !mediaSizeId)
    return currentPrinters

  return currentPrinters.map(printer => {
    if (printer.printerId === printerId.value) {
      printer.mediaId = mediaId.value
      printer.media = printer.medias.find(p => p.id === mediaId.value).Name
      printer.mediaSizeId = mediaSizeId.value
    }
    return printer
  })
}