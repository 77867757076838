import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12.06,6.03c-3.3,0-5.96,2.68-5.96,5.97s2.68,5.96,5.97,5.96c3.3,0,5.96-2.68,5.96-5.97
			c0-1.58-0.63-3.1-1.76-4.22C15.16,6.66,13.64,6.03,12.06,6.03z M12.06,16.97c-2.75,0-4.97-2.23-4.97-4.97s2.23-4.97,4.97-4.97
			c2.75,0,4.97,2.23,4.97,4.97S14.81,16.97,12.06,16.97L12.06,16.97z"
    />
    <path
      d="M8.63,1.24c0.26,0.41,0.6,0.77,0.99,1.06C10.32,2.8,11.15,3.06,12,3.05c0.83,0,1.65-0.26,2.33-0.74
			c0.39-0.29,0.73-0.65,0.99-1.06c0.66,0.21,1.3,0.48,1.91,0.81c-0.26,1.33,0.16,2.69,1.11,3.65c0.76,0.77,1.8,1.2,2.87,1.19
			c0.25-0.02,0.49-0.06,0.73-0.13c0.31,0.6,0.57,1.22,0.78,1.86c-1.86,1.25-2.35,3.77-1.1,5.63c0.29,0.43,0.67,0.81,1.1,1.1
			c-0.2,0.65-0.46,1.27-0.78,1.87c-0.26-0.05-0.52-0.07-0.78-0.07c-2.24,0-4.06,1.82-4.05,4.06c0,0.26,0.03,0.52,0.07,0.77
			c-0.6,0.31-1.22,0.57-1.86,0.78c-1.25-1.86-3.77-2.35-5.63-1.1c-0.43,0.29-0.81,0.67-1.1,1.1c-0.63-0.22-1.25-0.5-1.83-0.83
			c0.09-0.49,0.09-0.99,0-1.48c-0.42-2.19-2.52-3.63-4.71-3.23c-0.31-0.6-0.57-1.22-0.78-1.86c0.41-0.27,0.77-0.6,1.05-0.99
			c0.48-0.7,0.73-1.53,0.72-2.38c0-0.83-0.26-1.65-0.74-2.33C2.02,9.28,1.66,8.94,1.25,8.68c0.21-0.66,0.48-1.3,0.81-1.91
			c0.26,0.05,0.52,0.07,0.78,0.07c1.96-0.01,3.64-1.42,3.98-3.35c0.09-0.49,0.09-1,0-1.49c0.6-0.31,1.22-0.57,1.86-0.78 M14.86,0.07
			C14.42,1.26,13.28,2.05,12,2.06c-1.28,0-2.42-0.79-2.86-1.99c-1.25,0.3-2.45,0.8-3.55,1.47c0.69,1.54,0,3.36-1.54,4.05
			c-0.8,0.36-1.71,0.36-2.5,0c-0.68,1.1-1.17,2.3-1.47,3.55C1.26,9.58,2.05,10.72,2.06,12c0,1.28-0.79,2.42-1.99,2.86
			c0.3,1.25,0.8,2.45,1.47,3.55c1.54-0.69,3.36,0,4.05,1.54c0.36,0.8,0.36,1.71,0,2.5c1.1,0.68,2.3,1.17,3.55,1.47
			c0.61-1.58,2.38-2.37,3.96-1.76c0.81,0.31,1.45,0.95,1.76,1.76c1.25-0.3,2.45-0.8,3.55-1.47c-0.69-1.54,0-3.36,1.54-4.05
			c0.8-0.36,1.71-0.36,2.5,0c0.68-1.1,1.18-2.3,1.48-3.55c-1.58-0.6-2.38-2.37-1.78-3.95c0.31-0.82,0.96-1.47,1.78-1.78
			c-0.3-1.25-0.8-2.45-1.47-3.55c-1.54,0.69-3.36,0-4.05-1.54c-0.36-0.8-0.36-1.71,0-2.5C17.32,0.86,16.12,0.37,14.86,0.07z"
    />
  </React.Fragment>,
  'SettingsIcon'
)
