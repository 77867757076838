import AWS from 'aws-sdk'
import config from './Amplify'

export const getSignedUrlForImageSrc = async (params, extension) => {
    let result = '';
    var s3 = new AWS.S3({signatureVersion: 'v4', region: config.Storage.AWSS3.region});
    try {
      result = await s3.getSignedUrlPromise('getObject', {
        Bucket: config.Storage.AWSS3.bucket,
        Key: `public/job-detail/${params.cpId}/${params.jobId}/${params.id}${extension}`,
      });
    } catch (err) {
      result = err;
    }
    return result;
}