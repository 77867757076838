import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12,6.03L4.51,0.06V12v11.94L12,17.97L19.49,12L12,6.03z M11.72,16.99l-6.26,4.99V12V2.02l6.26,4.99
        L17.98,12L11.72,16.99z"
    />
  </React.Fragment>,
  'PlayIcon'
)
