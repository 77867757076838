export const categories = {
    inError: [
        'onUploadError',
        'onTargetProcessingError',
        'onTargetDownloadError',
        'onTargetValidationFailed',
        'onDownloadError',
        'onVerificationFailed',
        'onOrderVerificationFailed',
        'onTargetValidationError'
    ],
    inProgress: [
        'onOrderCreation',
        'onUploadIncomplete',
        'onTargetProcessing',
        'onMeasurementFinish',
        'onHistoryPerFrame',
        'onTargetOnHold',
        'onUploaded',
        'onTargetDownloaded',
        'onDownloaded',
        'onUploadComplete',
        'onOrderVerification',
        'onVerification'
    ],
    complete: [
        'onFinish',
        'onRemoval'
    ]
}

export const itemsListCategories = {
    Uploading: [
        'onOrderCreation',
        'onUploadIncomplete'
    ],
    UploadError: [
        'onUploadError'
    ],
    Downloading: [
        'onUploaded',
        'onUploadComplete',
        'onOrderVerification',
        'onVerification'
    ],   
    TargetDownloadError: [
        'onTargetDownloadError',
        'onDownloadError',
        'onOrderVerificationFailed',
        'onVerificationFailed'
    ],
    TargetProcessing: [
        'onTargetDownloaded',
        'onDownloaded'
    ],
    TargetJobProcessing: [
        'onTargetProcessing',
        'onMeasurementFinish'
    ],
    TargetValidationFailed: [
        'onTargetValidationFailed',
        'onTargetValidationError'
    ],
    TargetJobFinished:[
        'onHistoryPerFrame',
        'onFinish',
        'onRemoval'
    ],
    TargetJobWaiting: [
        'onTargetOnHold'
    ],
    TargetJobError: [
        'onTargetProcessingError'
    ]
}