import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippy.js/react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../Styles/styles'
import CheckmarkIcon from '../../../../assets/icons/CheckmarkIcon'
import CloseIcon from '../../../../assets/icons/CloseIcon'
import TippyContentList from './TippyContentList'

const ItemsCellList = ({ classes, items, alias, withSatusImage }) => {
  if (items.length > 1) {
    return (
      <React.Fragment>
        {withSatusImage &&
          <React.Fragment>
            { items[ 0 ] === 'Passed' ? (
              <CheckmarkIcon className={ classNames(classes.iconSizeCheckmark, classes.green) } />
            ) : (
                <CloseIcon className={ classNames(classes.iconSizeCross, classes.red) } />
              ) }
          </React.Fragment>
        }
        {!withSatusImage &&
          <span>{ items[ 0 ] }</span>
        }
        <br />
        <Tippy
          content={ <TippyContentList contentList={ items } /> }
          arrow={ true }
          arrowType="sharp"
          animation="fade"
          placement={ 'top' }
          distance="10"
          className={ classes.tooltips }
          popperOptions={ {
            placement: 'top',
            modifiers: {
              preventOverflow: {
                enabled: false
              },
              hide: {
                enabled: false
              }
            }
          } }
        >
          <span className={ classes.subline }>
            { `+ ${items.length - 1} ${alias}` }
          </span>
        </Tippy>
      </React.Fragment>
    )
  }
  return <React.Fragment>{ items[ 0 ] }</React.Fragment>
}

ItemsCellList.defaultProps = {
  withSatusImage: false
}

ItemsCellList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  alias: PropTypes.string.isRequired,
  withSatusImage: PropTypes.bool
}

export default withStyles(styles)(ItemsCellList)