import React from 'react'
import className from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../app/shared/Styles/styles'

const VBox = ({ children, classes, className: classNameProp, ...props }) => (
  <div {...props} className={className(classes.fullHeight, classNameProp)}>
    {children}
  </div>
)

export default withStyles(styles)(VBox)
