import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Utils from '../shared/duck/helpers/Utils'

const styles = theme => ({
    logo: {
      display: 'block',
      margin: 'auto',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      width: '100%',
      paddingBottom: 40,
      [ theme.breakpoints.up('sm') ]: {
        paddingBottom: 80
      }
    },
    container: {
      height: '100vh',
      overflow: 'auto',
      scrollbarWidth: 'none' /* Firefox */,
      '-ms-overflow-style': 'none' /* IE 10+ */,
      '&::-webkit-scrollbar': {
        /* WebKit */
        width: 0
      }
    },
    backgroundImage: {
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative'
    },
    tabs: {
      borderBottom: '1px solid ' + theme.palette.divider,
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5)
    },
    auth: {
      margin: 'auto',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0 0px 80px 0 rgba(0, 0, 0, 0.20)',
      position: 'relative',
      minHeight: '100vh',
      paddingTop: '55px',
      paddingBottom: '75px',
      [ theme.breakpoints.up('sm') ]: {
        paddingTop: '105px',
        maxWidth: '480px'
      }
    }
  })

function UpdatePage({ classes }) {
    return (
        <Grid
          container
          justify="center"
          className={ classes.backgroundImage }
          style={ { backgroundImage: `url(${Utils.backGroundImage()})` } }>
          <Grid item xs={ 12 } sm={ 6 } md={ 5 } lg={ 4 } xl={ 3 }>
            <div className={ classes.auth }>
              { Utils.isColorCardLogo(classes) }
              <div className={classes.tabs}>
                <Typography>
                  There is a new version available.<br /><br />Please wait a few seconds for the automatic update notification and restart.<br />
                </Typography>
              <br />
              </div>
            </div>
          </Grid>
        </Grid>
  )
}

export default withStyles(styles)(UpdatePage)
