import React from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DefaultDateTypes from '../../duck/helpers/DefaultDateTypes'

const DefaultDatesDropdown = ({ menuAnchorEl, currentValue, onMenuItemClick, onCloseMenu, classes, useRight }) => {
    const handleMenuItemCLick = e => {
        onMenuItemClick(e)
    }

    const handleCloseMenu = () => {
        onCloseMenu()
    }

    const menuClass = useRight ? classes.defaultDateMenuContainerRight : classes.defaultDateMenuContainerLeft
    const menuItemClass = useRight ? classes.defaultDateMenuItemRight : classes.defaultDateMenuItemLeft

    return (
        <Menu keepMounted
            id="default-dates-menu"
            anchorEl={menuAnchorEl}
            open={(Boolean(menuAnchorEl))}
            PaperProps={{ className: menuClass }}
            onClose={handleCloseMenu}
        >
            {currentValue !== DefaultDateTypes.Today &&
                <MenuItem 
                    className={menuItemClass}
                    onClick={e => handleMenuItemCLick(e)}
                >
                    {DefaultDateTypes.Today}
                </MenuItem>
            }
            {currentValue !== DefaultDateTypes.Yesterday &&
                <MenuItem 
                    className={menuItemClass}
                    onClick={e => handleMenuItemCLick(e)}
                >
                    {DefaultDateTypes.Yesterday}
                </MenuItem>
            }
            {currentValue !== DefaultDateTypes.LastWeek &&
                <MenuItem 
                    className={menuItemClass}
                    onClick={e => handleMenuItemCLick(e)}
                >
                    {DefaultDateTypes.LastWeek}
                </MenuItem>
            }
            {currentValue !== DefaultDateTypes.LastMonth &&
                <MenuItem 
                    className={menuItemClass}
                    onClick={e => handleMenuItemCLick(e)}
                >
                    {DefaultDateTypes.LastMonth}
                </MenuItem>
            }
            <MenuItem
                key={DefaultDateTypes.Custom}
                className={menuItemClass}
                onClick={e => handleMenuItemCLick(e)}
            >
                {DefaultDateTypes.Custom}
            </MenuItem>
        </Menu>
    )
}

DefaultDatesDropdown.defaultProps = {
    currentValue: ''
}

DefaultDatesDropdown.propTypes = {
    menuAnchorEl: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    useRight: PropTypes.bool,
    onMenuItemClick: PropTypes.func.isRequired,
    onCloseMenu: PropTypes.func.isRequired
}

export default DefaultDatesDropdown