export const DEFINE_IN_CMYK = "Define in CMYK";
export const DEFINE_IN_LAB = "Define in Lab";
export const DO_NOT_PRINT = "Do not print";
export const SEPARATOR = "SEPARATOR";
export const REPLACE_WITH = "Replace with";
export const SELECT = "Select";
export const CUSTOM = "Custom";
export const TYPE_NAME = "type name";

export const COLOR_TYPE_FREE = "free";
export const COLOR_TYPE_FREE_LAB = "freeLab";
export const MULTI_ICC = "Multi ICC: ";
export const MXN = "MXN: ";