import * as types from '../types'

let initialState = {
  currentUser: undefined,
  loggedIn: false,
  paired: false,
  errors: null
}

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        currentUser: action.data,
        loggedIn: true
      })
    case types.RUN_PAIRING_SUCCESS:
      return Object.assign({}, state, {
        paired: action.res && action.res.status === 200
      })
    case types.RUN_PAIRING_FAILURE:
      return Object.assign({}, state, {
        errors: action.err
      })  
    case types.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        loggedIn: false,
        paired: false,
        currentUser: undefined
      })
    default:
      return state
  }
}