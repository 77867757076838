import { withStyles } from '@material-ui/core'
import styles from '../../Styles/styles'
import TimeAgo from 'react-timeago'
import React from 'react'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import EnglishStrings from 'react-timeago/lib/language-strings/en'
import germanStrings from 'react-timeago/lib/language-strings/de'
import { locale } from '../../Localization/index'

function translateDate () {
  if (locale.includes('de')) {
    return buildFormatter(germanStrings)
  } else {
    return buildFormatter(EnglishStrings)
  }
}

let formatter = translateDate()

class CpTimeAgo extends React.Component {
  render () {
    const { date } = this.props
    return (
      <span>
        <TimeAgo date={ date } formatter={ formatter } />
      </span>
    )
  }
}

export default withStyles(styles)(CpTimeAgo)
