import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles/index'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar/index'
import Toolbar from '@material-ui/core/Toolbar/index'
import Typography from '@material-ui/core/Typography/index'
import IconButton from '@material-ui/core/IconButton/index'
import MenuItem from '@material-ui/core/MenuItem/index'
import MenuList from '@material-ui/core/MenuList/index'
import Menu from '@material-ui/core/Menu/index'
import Tabs from '@material-ui/core/Tabs/index'
import Tab from '@material-ui/core/Tab/index'
import Input from '@material-ui/core/Input'
import SettingsIcon from '../../../assets/icons/SettingsIcon'
import MenuIcon from '../../../assets/icons/MenuIcon'
import LeftIcon from '../../../assets/icons/LeftIcon'
import SearchIcon from '../../../assets/icons/SearchIcon'
import HelpCenterActive from '../../../assets/icons/HelpCenterActive'
import GMGLogo from '../Logo/GMGLogo'
import styles from '../../shared/Styles/styles'
import LoadingButton from '../../shared/Components/Buttons/LoadingButton'
import ServiceContext from '../../shared/Services/ServiceContext'
import DropletMenu from './DropletMenu'
import { locale } from '../../shared/Localization/index'
import utils from '../../shared/duck/helpers/Utils'
import * as constants from '../../shared/duck/helpers/constants'
import PermissionsValidator from '../../shared/duck/helpers/permissionsValidator'
import { setLiveSearchTerm, setFilterChip } from '../../shared/duck/actions/filtersActions'


const JOBS = 0
const PRINTERS = 1
const FLOW_ORDERS = 2
const SITES = 3
const USERS = 4
const FLOWS = 5

const menuItems = [
  {
    id: "menuButtonJobs",
    key: constants.GA_JOBS,
    classNames: 'upperPadding',
    screenValue: 0,
    label: <FormattedMessage id="menu.jobs" defaultMessage="JOBS" />
  },
  {
    id: "menuButtonPrinters",
    key: constants.GA_PRINTERS,
    classNames: 'printerMenu',
    screenValue: 1,
    label: <FormattedMessage id="menu.printers" defaultMessage="PRINTERS" />
  },
  {
    id: "menuButtonFlowOrders",
    key: constants.GA_FLOW_ORDERS,
    classNames: 'printerMenu',
    screenValue: 2,
    label: <FormattedMessage id="menu.flowOrders" defaultMessage="FLOW ORDERS" />
  },
  {
    id: "menuButtonSystems",
    key: constants.GA_SETTINGS_SYSTEMS,
    classNames: 'menuItems',
    screenValue: 3,
    label: <FormattedMessage id="menu.sites" defaultMessage="SITES" />
  },
  {
    id: "menuButtonUsers",
    key: constants.GA_SETTINGS_USERS,
    classNames: 'menuItems',
    screenValue: 4,
    label: <FormattedMessage id="menu.users" defaultMessage="USERS" />
  }
]

class MenuBar extends React.Component {
  static contextType = ServiceContext

  _skipFrame = false
  state = {
    menuOpen: false,
    selectedTab: 0,
    selectedView: 0,
    searchBar: false,
    searchContent: ''
  }

  getMenuItemId = (itemNumber) => {
    switch (itemNumber) {
      case 0: return "menuButtonJobs"
      case 1: return "menuButtonPrinters"
      case 2: return "menuButtonSystems"
      case 3: return "menuButtonUsers"
      case 4: return "menuButtonFlows"
      case 5: return "menuButtonFlowOrders"
      default: return ""
    }
  }

  signOut = () => {
    // Wired from AppRoot
    this.context.trackingService.trackEvent(
      'Droplet Menu',
      'Logout Click',
      '1',
      1
    )
    this.props.signOut()
  }

  menuOpen = e => {
    this.setState({ menuOpen: true })
    this.context.trackingService.trackEvent(
      'Droplet Menu',
      'Close Menu Button Click',
      '1',
      1
    )
    e.nativeEvent.stopImmediatePropagation()
  }
  menuClose = () => {
    this.setState({ menuOpen: false })
  }

  handleMenuItems = screenValue => {
    this.context.trackingService.trackEvent(
      'Droplet Menu',
      'Change Current View',
      '1',
      1
    )
    if (screenValue === JOBS) {
      this.setState({ menuOpen: false, selectedTab: JOBS, selectedView: JOBS })
      this.context.navigationService.goTo(constants.GA_JOBS)
    } else if (screenValue === PRINTERS) {
      this.setState({ menuOpen: false, selectedTab: PRINTERS, selectedView: PRINTERS })
      this.context.navigationService.goTo(constants.GA_PRINTERS)
    } else if (screenValue === FLOW_ORDERS) {
      // TODO: Remove top line?
      this.setState({ menuOpen: false, selectedTab: FLOW_ORDERS, selectedView: FLOW_ORDERS })
      this.context.navigationService.goTo(constants.GA_FLOW_ORDERS)
    } else if (screenValue === SITES) {
      // TODO: Remove top line?
      this.setState({ menuOpen: false, selectedView: SITES })
      this._skipFrame = true
      this.context.navigationService.goTo(constants.GA_SETTINGS_SYSTEMS)
    } else if (screenValue === FLOWS) {
      // TODO: Remove top line?
      this.setState({ menuOpen: false, selectedView: FLOWS })
      this._skipFrame = true
      this.context.navigationService.goTo(constants.GA_SETTINGS_FLOWS)
    } else {
      // TODO: Remove top line?
      this.setState({ menuOpen: false, selectedView: USERS })
      this._skipFrame = true
      this.context.navigationService.goTo(constants.GA_SETTINGS_USERS)
    }
  }

  handleIndexView = (event, ViewName) => {
    this.setState({ selectedTab: ViewName, selectedView: ViewName })
    if (ViewName === JOBS) {
      this.context.navigationService.goTo(constants.GA_JOBS)
    } else if (ViewName === PRINTERS) {
      this.context.navigationService.goTo(constants.GA_PRINTERS)
    } else {
      this.context.navigationService.goTo(constants.GA_FLOW_ORDERS)
    }
  }

  mobileTabletMenu () {
    const { menuOpen } = this.state
    const { pathname } = this.props.location
    const { classes } = this.props
    return (
      <DropletMenu
        id="simple-menu"
        open={ menuOpen }
        onClose={ this.menuClose }
        className={ classes.hideDesktop }
      >
        <MenuList>
          { menuItems.map(item => {
            if (pathname === item.key) return ''

            return (
              <MenuItem
                id={ item.id }
                key={ item.key }
                onClick={ () => this.handleMenuItems(item.screenValue) }
                disableGutters={ false }
                className={ classNames(
                  classes[ item.classNames ],
                  classes.menuItemNoMargin
                ) }
              >
                {item.label }
              </MenuItem>
            )
          }) }
          <MenuItem
            id="menuButtonLogout"
            key="signout"
            onClick={ this.signOut }
            disableGutters={ false }
            className={ classes.menuItemNoMargin }
          >
            <span className={ classes.desktopMenuLogout }>
              <FormattedMessage id="menu.logout" defaultMessage="LOG OUT" />
            </span>
          </MenuItem>
        </MenuList>
      </DropletMenu>
    )
  }

  desktopMenu () {
    const { pathname } = this.props.location
    const { classes } = this.props
    return (
      <Menu
        id="simple-menu"
        open={ this.state.menuOpen }
        onClose={ this.menuClose }
        className={ classNames(classes.showDesktop, classes.menuShow) }
        anchorReference="none"
        PaperProps={ {
          style: {
            width: '100%',
            backgroundColor: 'transparent',
            overflow: 'hidden'
          }
        } }
      >
        <MenuList className={ classes.menuDesign }>
          { menuItems.map((item, index) => {
            if ((pathname !== constants.GA_SETTINGS_USERS &&
              pathname !== constants.GA_SETTINGS_SYSTEMS &&
              pathname !== constants.GA_SETTINGS_FLOWS) ||
              this._skipFrame) {
              // TODO: HACK - Waiting 0.5 seconds until the menu is hidden,
              //              then allow rendering again. This avoids changing content while animating the menu close.
              setTimeout(() => {
                this._skipFrame = false
              }, 500)

              return (
                <MenuItem
                  id={ item.id }
                  key={ item.key }
                  onClick={ () => this.handleMenuItems(item.screenValue) }
                  disableGutters={ false }
                  disableRipple
                  className={ classes[ item.classNames ] }
                >
                  {item.label }
                </MenuItem>
              )
            } else {
              return ''
            }
          }) }
          <MenuItem
            onClick={ this.signOut }
            disableGutters={ false }
            disableRipple
            className={ classNames(
              classes.marginBottomMenu,
              classes.menuItems,
              classes.settingsLogoutItemPadding,
              {
                [ classes.settingsLogoutItemEmptyMenuPadding ]:
                  pathname === constants.GA_SETTINGS_USERS ||
                  pathname === constants.GA_SETTINGS_SYSTEMS ||
                  pathname === constants.GA_SETTINGS_FLOWS
              }
            ) }
          >
            <LoadingButton
              className={ classes.submitButton }
              fullWidth
              type="submit"
              color="primary"
              variant="outlined"
              disableRipple
            >
              <FormattedMessage id="menu.logout" defaultMessage="LOG OUT" />
            </LoadingButton>
          </MenuItem>
        </MenuList>
      </Menu>
    )
  }

  renderIndexTabs = () => {
    const { classes } = this.props
    const { selectedView } = this.state
    const { pathname } = this.props.location

    const jobsActive = pathname === constants.GA_JOBS || pathname === '/'
    const printersActive = pathname === constants.GA_PRINTERS
    const flowOrdersActive = pathname === constants.GA_FLOW_ORDERS
    const validator = new PermissionsValidator(this.props.permissions)

    return (
      <Tabs
        value={ selectedView }
        onChange={ this.handleIndexView }
        classes={ {
          indicator: classes.indicatorMenu
        } }
        className={ classes.tabsDesktop }
      >
        <Tab
          label={
            <FormattedMessage id="tab.jobs" defaultMessage="jobs" />
          }
          id="jobsTab"
          className={ classNames(classes.tabSizeTabFirst, {
            [ classes.tabBackgroundLight ]: jobsActive,
            [ classes.tabBackgroundDark ]: !jobsActive
          }) }
          disableRipple
        />
        <Tab
          label={
            <FormattedMessage
              id="tab.printersSmall"
              defaultMessage="Printers"
            />
          }
          id="printersTab"
          className={ classNames(classes.tabSizeTabSecond, {
            [ classes.tabBackgroundLight ]: printersActive,
            [ classes.tabBackgroundDark ]: !printersActive
          }) }
          disableRipple
        />
        {validator.hasUserFlowManagementPermissionForAtLeastOneCp() &&
          <Tab
            label={ <FormattedMessage id="tab.flowOrders" defaultMessage="Flow Orders" /> }
            className={ classNames(classes.tabSizeTabThird, {
              [ classes.tabBackgroundLight ]: flowOrdersActive,
              [ classes.tabBackgroundDark ]: !flowOrdersActive
            }) }
            disableRipple
          />
        }
      </Tabs>
    )
  }

  renderBackButton = () => {
    const { classes } = this.props
    return (
      <div
        className={ classes.returnButton }
        onClick={ () => {
          this.context.trackingService.trackEvent(
            'Back to Index Page',
            'Going from Settings page to Index',
            '1',
            1
          )
          this.handleMenuItems(0)
        } }
      >
        <LeftIcon className={ classes.backIcon } />
        <Typography
          variant="h6"
          gutterBottom
          color="textPrimary"
          className={ classes.settingTitle }
        >
          <FormattedMessage id="app.settings" defaultMessage="Settings" />
        </Typography>
      </div>
    )
  }

  switchSearchBar = () => {
    const searchTerm = this.props.liveSearchTerm
    this.setState({ searchBar: !this.state.searchBar, searchContent: searchTerm || '' })
  }

  handleSearchContent = e => {
    if (!this.state.searchBar)
      return

    this.setSearchContent(e.target.value)
  }

  setSearchContent = value => {
    this.props.setLiveSearchTerm(value)
    this.setState({ searchContent: value })
    this.createLiveSearchEvent()
  }

  createLiveSearchEvent () {
    var event = new Event('livesearchactive')
    document.dispatchEvent(event)
  }

  openHelpCenter = () => {
    // open English or German help center
    const link = locale.includes('de') ? utils.deutschLink() : utils.englishLink()
    window.open(link, '_blank')
  }

  renderHeader () {
    const { classes } = this.props
    const { pathname } = this.props.location
    return (
      <AppBar
        position="absolute"
        className={ classNames(classes.appBar) }
        color="default"
      >
        <Toolbar className={ classes.toolbar }>
          <IconButton
            className={ classes.menuIcon }
            color="inherit"
            onClick={ this.menuOpen }
            title="Menu"
          >
            <MenuIcon />
          </IconButton>
          { this.mobileTabletMenu() }
          { this.desktopMenu() }
          <Typography
            noWrap
            className={ classes.title }
            onClick={ () => {
              this.context.trackingService.trackEvent(
                'Back to Index Page',
                'Going to Index by Clicking Logo',
                '1',
                1
              )
              this.handleMenuItems(JOBS)
            } }
          >
            <GMGLogo
              className={ classNames(classes.headerLogo, {
                [ classes.headerLogoHide ]: this.state.searchBar
              }) }
            />
          </Typography>

          { pathname === constants.GA_HOME ||
            pathname === constants.GA_JOBS ||
            pathname === constants.GA_PRINTERS ||
            pathname === constants.GA_FLOW_ORDERS
            ? this.renderIndexTabs() : this.renderBackButton() }

          <IconButton
            id="searchButton"
            className={ this.state.searchBar ? classes.searchIconActive : classes.searchIcon }
            color="inherit"
            onClick={ this.switchSearchBar }
            title="Search">
            <SearchIcon />
          </IconButton>
          <IconButton
            id="helpCenter"
            className={ classes.helpCenterIcon }
            color="inherit"
            onClick={ this.openHelpCenter }
            title="Help Center">
            <HelpCenterActive />
          </IconButton>

          <IconButton
            id="menuButton"
            className={ classes.settingIcon }
            color="inherit"
            onClick={ this.menuOpen }
            title="Menu">
            <SettingsIcon />
          </IconButton>
          { this.state.searchBar &&
            <Input value={ this.state.searchContent }
              id="search.imputField"
              name="searchContent"
              autoFocus
              className={ classes.searchBar }
              disableUnderline={ true }
              onChange={ this.handleSearchContent }
              onBlur={ () => this.setState({ searchBar: !this.state.searchBar }) }
            />
          }
        </Toolbar>
      </AppBar>
    )
  }

  handleKeys = e => {
    if (e.keyCode === 13) {//enter
      const term = { value: this.state.searchContent, isActive: true }

      this.props.setFilterChip(this.context.navigationService.getLocation(), term)
      this.setState({ searchContent: "" })
    }
    if (e.keyCode === 27) {//esc
      this.setState({ searchContent: "", searchBar: false })
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.handleKeys.bind(this))
  }

  componentDidUpdate () {
    const validator = new PermissionsValidator(this.props.permissions)
    if (validator.hasUserFlowManagementPermissionForAtLeastOneCp()) {
      //TODO: Try to find fix for this, sometimes rendered twice in browser, as seen in demo
      if (!menuItems[ 5 ]) {
        menuItems.push({
          id: "menuButtonFlows",
          key: constants.GA_SETTINGS_FLOWS,
          classNames: 'menuItems',
          screenValue: 5,
          label: <FormattedMessage id="menu.flows" defaultMessage="FLOWS" />
        })
      }
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleKeys.bind(this))
  }

  render () {
    const { classes } = this.props

    return (
      <div className={ classNames(classes.rootMenu) }>
        {this.renderHeader() }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    permissions: state.permissionsInfo.customerInfo,
    liveSearchTerm: state.filters.liveSearchTerm,
    currentSearchTerm: state.filters.currentSearchTerm
  }
}

MenuBar.propTypes = {
  classes: PropTypes.object.isRequired,
  setLiveSearchTerm: PropTypes.func.isRequired,
  setFilterChip: PropTypes.func.isRequired,
  permissions: PropTypes.array
}

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      {
        setLiveSearchTerm,
        setFilterChip
      }
    )(MenuBar)
  )
)