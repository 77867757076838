import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles/index'
import Paper from '@material-ui/core/Paper/index'
import Typography from '@material-ui/core/Typography/index'
import Table from '@material-ui/core/Table/index'
import TableBody from '@material-ui/core/TableBody/index'
import TableCell from '@material-ui/core/TableCell/index'
import TableHead from '@material-ui/core/TableHead/index'
import TableRow from '@material-ui/core/TableRow/index'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal'
import Drawer from '@material-ui/core/Drawer/index'
import SitesList from './Components/Lists/SiteList/SitesList'
import UsersList from './Components/Lists/UserList/UsersList'
import FlowList from './Components/Lists/FlowList/FlowList'
import FlowManager from './Components/FlowManager'
import styles from '../shared/Styles/styles'
import LeftSidePane from '../shared/Components/SideBar/LeftSidePane'
import Notification from '../shared/Components/Messaging/Notification'
import ServiceContext from '../shared/Services/ServiceContext'
import LeftIcon from '../../assets/icons/LeftIcon'
import InfoActiveIcon from '../../assets/icons/InfoActiveIcon'
import Utils from '../shared/duck/helpers/Utils'
import LoadingButton from '../shared/Components/Buttons/LoadingButton'
import DateRangeFilter from '../shared/Components/Date/DateRangeDisplay'
import { inviteUser, getCustomerPairInfoLists, handleUserDelete } from '../shared/duck/actions/pairingInfoActions'
import { setDateRangeFlowsStatisticsValues } from '../shared/duck/actions/filtersActions'
import { getFlowOrdersStatistics } from './duck/settingsActions'
import RoleTypes from '../shared/duck/types/roleTypes'
import { NotificationTypes } from '../shared/duck/types/notificationTypes'
import * as constants from '../shared/duck/helpers/constants'
import { getStartDate, getEndDate, defaultDateRangeValues } from '../shared/duck/helpers/dateTime'
import PermissionsValidator from '../shared/duck/helpers/permissionsValidator'
import { FlowRows } from './Components/Lists/FlowList/FlowRows'
import { SiteRows } from './Components/Lists/SiteList/SiteRows'
import { UserRows } from './Components/Lists/UserList/UserRows'

class CpSettings extends React.Component {
  static contextType = ServiceContext

  state = {
    sidebarOpen: true,
    settingsRoute: this.props.location.pathname.split('/').pop(),
    tabValue: this.props.location.pathname.split('/').pop(),
    inviteUserModal: false,
    inputUserEmailId: '',
    emailValidation: true,
    emailValidationError: '',
    inputUserRole: [],
    inputUserSystemName: [],
    userList: [],
    toShowInviteButton: false,
    roleList: [
      {
        Name: 'Admin',
        Value: RoleTypes.Admin
      },
      {
        Name: 'Operator',
        Value: RoleTypes.Operator
      },
      {
        Name: 'Viewer',
        Value: RoleTypes.Viewer
      }
    ],
    filteredSites: null,
    filteredUsers: null,
    filteredFlows: null,
    showAddFlowModal: false,
    siteWorkflows: [],
    notification: null,
    isOpenRightPane: false,
    selectedFlow: null,
    deleteUserInProgress: false
  }

  showFlowModal = () => {
    this.setState({
      showAddFlowModal: true
    })
  }

  closeFlowModal = () => {
    this.setState({
      showAddFlowModal: false
    })
  }

  showInviteBox = () => {
    let systemList = []
    this.props.sites.map((siteValue, siteIndex) => {
      this.props.users.map((userValue, userIndex) => {
        if (siteValue.site === userValue.site && siteValue.role === 'Admin') {
          if (userValue.role === 'Admin') {
            let siteObject = {}
            siteObject[ 'Value' ] = siteValue.cpId
            if (siteValue.alias) {
              siteObject[ 'Name' ] = siteValue.alias
            } else {
              siteObject[ 'Name' ] = siteValue.site
            }
            //check if the object already added
            if (!systemList.find(item => JSON.stringify(item) === JSON.stringify(siteObject))) {
              systemList.push(siteObject)
            }
          }
        }
        return ''
      })
      return systemList
    })
    let preSelectedUser,
      preSelectedRole = []

    if (systemList.length > 0) {
      preSelectedUser = systemList[ 0 ]
      preSelectedRole = this.state.roleList[ 0 ]
    }
    this.setState({
      userList: systemList,
      inviteUserModal: true,
      inputUserSystemName: preSelectedUser,
      inputUserRole: preSelectedRole
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.sites && (this.props.sites !== prevProps.sites || prevProps.sites.length !== this.props.sites.length))//add/remove/modify
      this.handleSearchTerms()
    if (this.props.users && (this.props.users !== prevProps.users || prevProps.users.length !== this.props.users.length))
      this.handleSearchTerms()
    if (this.props.flows && (this.props.flows !== prevProps.flows || prevProps.flows.length !== this.props.flows.length))
      this.handleSearchTerms()
    if (prevProps.filterChips !== this.props.filterChips)//filterchips modified
      this.handleSearchTerms()
    if (prevProps.liveSearchTerm && this.props.liveSearchTerm !== prevProps.liveSearchTerm)
      this.handleLiveSearch()
  }

  handleSearchTerms () {
    const searchTerms = this.getSearchTerms()
    const newFilteredUsers = this.applySearchTermsForUsers(searchTerms)
    const newFilteredSites = this.applySearchTermsForSites(searchTerms)
    const newFilteredFlows = this.applySearchTermsForFlows(searchTerms)

    this.setState({ filteredUsers: newFilteredUsers, filteredSites: newFilteredSites, filteredFlows: newFilteredFlows })
  }

  handleLiveSearch () {
    if (!this.props)
      return

    const searchTerms = this.getSearchTerms()

    const sites = this.applySearchTermsForSites(searchTerms)
    const filteredSites = sites.filter(item => this.isSiteMeetSearchTerm(item, this.props.liveSearchTerm))

    const users = this.applySearchTermsForUsers(searchTerms)
    const filteredUsers = users.filter(item => this.isUserMeetSearchTerm(item, this.props.liveSearchTerm))

    const flows = this.applySearchTermsForFlows(searchTerms)
    const filteredFlows = flows.filter(item => this.isFlowMeetSearchTerm(item, this.props.liveSearchTerm))

    this.setState({ filteredUsers: filteredUsers, filteredSites: filteredSites, filteredFlows: filteredFlows })
  }

  getSearchTerms () {
    const page = this.context.navigationService.getLocation()
    return this.props.filterChips[ page ] ? this.props.filterChips[ page ].filter(t => t.isActive) : []
  }

  applySearchTermsForSites (searchTerms) {
    return this.props.sites.filter(item => searchTerms.every(searchTerm => this.isSiteMeetSearchTerm(item, searchTerm.value)))
  }

  applySearchTermsForUsers (searchTerms) {
    return this.props.users.filter(item => searchTerms.every(searchTerm => this.isUserMeetSearchTerm(item, searchTerm.value)))
  }

  applySearchTermsForFlows (searchTerms) {
    return this.props.flows.filter(item => searchTerms.every(searchTerm => this.isFlowMeetSearchTerm(item, searchTerm.value)))
  }

  isSiteMeetSearchTerm (site, searchTerm) {
    if (!searchTerm)
      return true

    return (site.alias && site.alias.toLowerCase().includes(searchTerm.toLowerCase())) ||
      site.site.toLowerCase().includes(searchTerm.toLowerCase())
  }

  isUserMeetSearchTerm (user, searchTerm) {
    if (!searchTerm)
      return true

    return this.findUserAlias(user, searchTerm) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.site.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.lastlogin && user.lastlogin.toLowerCase().includes(searchTerm.toLowerCase())) ||
      user.dongleId.toLowerCase().includes(searchTerm.toLowerCase())
  }

  isFlowMeetSearchTerm (flow, searchTerm) {
    if (!searchTerm)
      return true

    return flow.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (flow.hubName && flow.hubName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      flow.hub.toLowerCase().includes(searchTerm.toLowerCase())
  }

  findUserAlias (user, searchTerm) {
    const site = this.props.sites.find(site => site.site === user.site && site.cpId === user.cpId)

    if (!site)
      return

    return site.alias && site.alias.toLowerCase().includes(searchTerm.toLowerCase())
  }

  UNSAFE_componentWillUpdate (prevProps, prevState, snapshot) {
    if (prevProps.location.pathname.split('/').pop() !== this.props.location.pathname.split('/').pop() ||
      this.state.settingsRoute !== this.props.location.pathname.split('/').pop()) {
      this.setState({
        settingsRoute: this.props.location.pathname.split('/').pop(),
        tabValue: this.props.location.pathname.split('/').pop()
      })
    }
  }

  handleListItemClick = filterName => {
    if (filterName === 'users')
      this.context.navigationService.goTo(constants.GA_SETTINGS_USERS)
    else if (filterName === 'flows')
      this.context.navigationService.goTo(constants.GA_SETTINGS_FLOWS)
    else
      this.context.navigationService.goTo(constants.GA_SETTINGS_SYSTEMS)

    if (filterName !== 'flows')
      this.setState({ isOpenRightPane: false })

    this.handleSearchTerms()
  }

  renderSidebar () {
    const { settingsRoute } = this.state
    const { classes } = this.props
    const counter = {
      users: this.props.users.length,
      systems: this.props.sites.length,
      flows: this.props.flows.length
    }
    const categories = [
      {
        name: 'systems',
        label: 'Systems',
        id: 'cpSitesUser.systems',
        icon: <span className={ classes.paddingLeft } />
      },
      {
        name: 'users',
        label: 'Users',
        id: 'cpSitesUser.users',
        icon: <span className={ classes.paddingLeft } />
      }
    ]
    const validator = new PermissionsValidator(this.props.permissions)

    if (validator.hasUserFlowManagementPermissionForAtLeastOneCp()) {
      categories.push({
        name: 'flows',
        label: 'Flows',
        id: 'cpSitesUser.flows',
        icon: <span className={ classes.paddingLeft } />
      })
    }

    return (
      <React.Fragment>
        <div className={ classes.settingPageBorder }>
          <LeftSidePane
            onCategoryClick={ this.handleListItemClick }
            classes={ this.props.classes }
            data={ {
              counter: counter,
              selectedFilter: settingsRoute,
              categories: categories,
              label: false
            } }
          />
        </div>
      </React.Fragment>
    )
  }

  renderSettingsTitle () {
    const { classes, location } = this.props
    const route = location.pathname
    const title = route.split('/').pop()

    let count
    if (route === constants.GA_SETTINGS_USERS)
      count = this.props.users.length
    else if (route === constants.GA_SETTINGS_SYSTEMS)
      count = this.props.sites.length
    else if (route === constants.GA_SETTINGS_FLOWS)
      count = this.props.flows.length

    return (
      <Typography
        variant="h6"
        align="left"
        color="textPrimary"
        className={ classNames(
          classes.navigationTitle,
          classes.navigationTitleMobile
        ) }
      >
        <FormattedMessage
          id={ `cpSitesUser.${title}` }
          defaultMessage={ title.toUpperCase() }
        />
        ({ count })
      </Typography>
    )
  }

  showInviteButton () {
    return this.props.users.map((v) => { return v.role }).indexOf('Admin') !== -1
  }

  renderTableHeader () {
    const { classes } = this.props
    const { settingsRoute } = this.state
    let currentHeaderCols
    settingsRoute === 'systems'
      ? (currentHeaderCols = SiteRows())
      : settingsRoute === 'users'
        ? (currentHeaderCols = UserRows(classes, this.showInviteBox))
        : settingsRoute === 'flows'
          ? (currentHeaderCols = FlowRows(classes, this.showFlowModal))
          : (currentHeaderCols = null)

    const validator = new PermissionsValidator(this.props.permissions)

    return currentHeaderCols.map(col => (
      <TableCell
        className={ classNames(classes[ col.headClassName ], {
          [ classes.tableCellHideMobile ]: col.hideMobile,
          [ classes.tableCellHideTablet ]: col.hideTablet,
          [ classes.hideFeature ]: !validator.hasUserFlowManagementPermissionForAtLeastOneCp() && col.key === 'colorHub',
          [ classes.tableCellSpacer ]: col.key === 'spacerLeft' || col.key === 'spacerRight'
        }) }
        key={ col.key }
        align={ col.numeric ? 'right' : 'left' }>
        { col.key === 'delete' || col.key === 'add-workflow' &&
          <span
            key={ col.key }
            className={ classNames({ [ classes.hideFeature ]: !validator.hasUserFlowLicenseAndExecutePermissions() }) }>
            { col.label }
          </span>
        }
        { col.key === 'add-user' &&
          <span
            key={ col.key }
            className={ classNames({ [ classes.hideFeature ]: !this.canInviteUsers() }) }>
            { col.label }
          </span>
        }
        { ![ 'add-user', 'delete', 'add-workflow' ].includes(col.key) && col.label }
      </TableCell>
    ))
  }

  orderStatisticsDateChange = async e => {
    await this.props.setDateRangeFlowsStatisticsValues(e)
    await this.props.getFlowOrdersStatistics(this.state.selectedFlow.id, e.startDate, e.endDate)
  }

  loadOrderStatistics = async e => {
    const open = !this.state.isOpenRightPane

    //close right pane on second click
    if (!open && this.state.selectedFlow && this.state.selectedFlow.id === e.id) {
      this.setState({ isOpenRightPane: open })
      return
    }

    this.setState({ selectedFlow: e, isOpenRightPane: true })

    await this.loadFlowOrdersStatistics(e.id)
  }

  loadFlowOrdersStatistics = async id => {
    let sd = new Date(this.props.dateRangeValues.startDate)
    let ed = new Date(this.props.dateRangeValues.endDate)
    sd = getStartDate(sd.getFullYear(), sd.getMonth(), sd.getDate())
    ed = getEndDate(ed.getFullYear(), ed.getMonth(), ed.getDate() + 1)

    await this.props.getFlowOrdersStatistics(id, sd, ed)
  }

  showRightPane () {
    const { classes } = this.props

    if (!this.props.orderStatistics)
      return <React.Fragment />

    return (
      <Drawer
        variant="permanent"
        align="center"
        classes={ {
          paper: classNames(
            classes.drawerPaperTablet,
            classes.rightPaneDrawer
          )
        } }
      >
        <Typography
          variant="h6"
          align="center"
          color="textPrimary"
          className={ classes.rightPaneNavigationTitle }
        >
          { this.state.selectedFlow.name }
        </Typography>
        <Typography className={ classes.drawerTitles }>
          <FormattedMessage id="flowStatisticsPane.accountingTimeFrame" defaultMessage="ACCOUNTING TIME FRAME" />
        </Typography>
        <DateRangeFilter
          classes={ this.props.classes }
          useRight={ true }
          dateRange={ this.props.dateRangeValues }
          onDateRangeChange={ this.orderStatisticsDateChange }
        />
        <Typography className={ classes.drawerTitles }>
          <FormattedMessage id="flowStatisticsPane.numberOfCopies" defaultMessage="NUMBER OF COPIES" />
        </Typography>
        <Typography className={ classes.drawerValues }>
          { this.props.orderStatistics.number }
        </Typography>
        <Typography className={ classes.drawerTitles }>
          <FormattedMessage id="flowStatisticsPane.dataVolume" defaultMessage="DATA VOLUME" />
        </Typography>
        <Typography className={ classes.drawerValues }>
          { this.convertDataVolume(this.props.orderStatistics.data) }
        </Typography>
        <Typography className={ classes.drawerTitles }>
          <FormattedMessage id="flowStatisticsPane.mediaUsage" defaultMessage="MEDIA USAGE" />
        </Typography>
        <Typography className={ classes.drawerValues }>
          { this.convertMediaUsage(this.props.orderStatistics.mediaUsage) }
        </Typography>
      </Drawer>
    )
  }

  convertDataVolume = bytes => {
    if (!bytes || bytes === 0)
      return '0MB'

    //bytes to MB
    let d = bytes / (1024 * 1024)
    //round and drop any extra zeroes at the end
    d = +d.toFixed(2)

    return `${d}MB`
  }

  convertMediaUsage = value => {
    if (!value || value === 0)
      return '0m²'

    //MM2 to M2
    let d = value / 1000000
    //round and drop any extra zeroes at the end
    d = +d.toFixed(2)

    return `${d}m²`
  }

  renderTableBody () {
    const { pathname } = this.props.location
    const filteredSites = this.state.filteredSites || this.props.sites
    const filteredUsers = this.state.filteredUsers || this.props.users
    const filteredFlows = this.state.filteredFlows || this.props.flows
    if (pathname === constants.GA_SETTINGS_SYSTEMS) {
      return (
        <SitesList
          sites={ filteredSites }
          client={ this.props.client }
          classes={ this.props.classes }
          messages={ this.props.messages }
        />
      )
    }
    else if (pathname === constants.GA_SETTINGS_USERS) {
      return (
        <UsersList
          client={ this.props.client }
          users={ filteredUsers }
          sites={ filteredSites }
          classes={ this.props.classes }
          intl={ this.props.intl }
          deleteUser={ this.deleteUser }
          deleteUserInProgress={ this.state.deleteUserInProgress }
        />
      )
    }
    else if (pathname === constants.GA_SETTINGS_FLOWS) {
      return (
        <FlowList
          client={ this.props.client }
          sites={ this.props.sites }
          flows={ filteredFlows }
          onShowRightPane={ this.loadOrderStatistics }
          classes={ this.props.classes }
          toggleRightPane={ this.state.isOpenRightPane }
        />
      )
    }
  }

  validateEmail (email) {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  closeInviteBox () {
    this.setState({
      inviteUserModal: false,
      inputUserSystemName: [],
      inputUserRole: [],
      inputUserEmailId: '',
      emailValidation: true,
      emailValidationError: ''
    })
  }

  setInviteData (event) {
    this.setState({
      [ event.target.name ]: event.target.value,
      emailValidation: true,
      emailValidationError: ''
    })
  }

  async sendInvite () {
    if (this.props.users.some(item =>
      item.email === this.state.inputUserEmailId.toLowerCase() &&
      item.cpId === this.state.inputUserSystemName.Value)) {
      this.context.notificationService.showNotification(
        this,
        NotificationTypes.Warning,
        this.context.translationService.tr("cpSitesUser.userHasAccessError"),
        '3col',
        true
      )
      return
    }

    if (!this.validateEmail(this.state.inputUserEmailId)) {
      this.setState({
        emailValidation: false,
        emailValidationError: 'Email is not valid.'
      })
      return
    }

    this.closeInviteBox()

    await this.props.inviteUser(this.state.inputUserSystemName.Value, this.state.inputUserRole.Value, this.state.inputUserEmailId.toLowerCase())

    if (!this.props.graphQLErrors) {
      this.context.notificationService.showNotification(
        this,
        NotificationTypes.Info,
        this.context.translationService.tr("cpSitesUser.userInvited"),
        '3col',
        true
      )

      //since email pairing response provides no info, we have to reload the data again
      await this.props.getCustomerPairInfoLists()

      return
    }

    this.context.notificationService.showNotification(
      this,
      NotificationTypes.Error,
      this.props.graphQLErrors,
      '3col',
      true
    )
  }

  showRolesInfo () {
    const url = this.context.translationService.tr("cpSitesUser.rolesInfoUrl")
    const win = window.open(url, '_blank');
    win.focus();
  }

  canAddFlow () {
    const validator = new PermissionsValidator(this.props.permissions)
    return this.props.location.pathname === constants.GA_SETTINGS_FLOWS && validator.hasUserFlowManagementPermissionForAtLeastOneCp()
  }

  canInviteUsers () {
    const validator = new PermissionsValidator(this.props.permissions)
    return this.props.location.pathname === constants.GA_SETTINGS_USERS && validator.isUserLicensedAdmin()
  }

  setNotification = notification => {
    this.setState({ notification })
  }

  deleteUser = e => {
    this.setState({ deleteUserInProgress: true })
    if (e.removeLastUser) {
      const messageText = `Sure you want to delete user "${e.email}" for "${e.site}"? Once deleted you will not be able to access this ColorProof system anymore.`
      this.context.notificationService.showNotification(
        this,
        NotificationTypes.Warning,
        messageText,
        '2col',
        false,
        'DELETE',
        () => this.props.handleUserDelete(e.cpId, e.customerId, e.isPending),
        () => this.setState({ deleteUserInProgress: false }),
        1
      )
    }
    else {
      this.props.handleUserDelete(e.cpId, e.customerId, e.isPending)
      this.setState({ deleteUserInProgress: false })
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div className={ classes.root }>
        { this.renderSidebar() }
        <Paper square className={ classes.mainPaper }>
          { this.renderSettingsTitle() }
          { this.canAddFlow() &&
            <FlowManager
              sites={ this.props.sites }
              visible={ this.state.showAddFlowModal }
              close={ this.closeFlowModal.bind(this) }
              { ...this.props }
            />
          }
          <Table classes={ { root: classes.tableHeadRoot } }
            className={ classNames(classes.table, {
              [ classes.tableTabLandscape ]: this.state.isOpenRightPane
            }) }
          >
            <TableHead>
              <TableRow className={ classNames(classes.tableHeadRow) }>
                { this.renderTableHeader() }
              </TableRow>
            </TableHead>
            <TableBody>{ this.renderTableBody() }</TableBody>
          </Table>
        </Paper>
        <Modal
          open={ this.state.inviteUserModal }
          onClose={ () => { this.setState({ inviteUserModal: false }) } }
          className={ classes.zIndex }
        >
          <div className={ classes.inviteModalStyle }>
            <Typography className={ classNames(classes.inviteUserTitle) }>
              <FormattedMessage
                id="cpSitesUser.inviteUser"
                defaultMessage="INVITE USER"
              />
            </Typography>

            <div className={ classes.userInviteUserMobileModal }>
              <Typography className={ classNames(classes.inviteUserSelectBoxTitle) }>
                <FormattedMessage
                  id="cpSitesUser.inviteUserEmail"
                  defaultMessage="E-Mail"
                />
              </Typography>
              <TextField
                fullWidth
                key={ 'inviteUserEmail' }
                type="text"
                defaultValue={ this.state.inputUserEmailId }
                name="inputUserEmailId"
                placeholder={ ' Enter E-mail' }
                variant="outlined"
                onChange={ e => this.setInviteData(e) }
                error={ !this.state.emailValidation }
                helperText={ this.state.emailValidationError }
                FormHelperTextProps={ { className: classes.formError } }
                InputLabelProps={ { shrink: true } }
                className={ classNames(
                  classes.inviteInputBox,
                  classes.formControl
                ) } />
              <Typography className={ classNames(classes.inviteUserSelectBoxTitle) }>
                <FormattedMessage
                  id="cpSitesUser.systemAccess"
                  defaultMessage="System-Access"
                />
              </Typography>
              <Select
                onChange={ e => this.setInviteData(e) }
                name="inputUserSystemName"
                key={ 'inviteUserEmailSystem' + Math.random() }
                disableUnderline={ true }
                value={ this.state.inputUserSystemName }
                displayEmpty
                IconComponent={ LeftIcon }
                classes={ {
                  root: classes.cpSelectBox + ' ' + classes.inviteUserFormControlSelect,
                  selectMenu: classes.cpSelectBoxContent
                } }
                MenuProps={ {
                  classes: {
                    paper: classes.cpSelectMenuPaper + ' ' + classes.dropdownStyle
                  },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  MenuListProps: {
                    className: classNames(classes.cpSelectList)
                  }
                } }
              >
                { this.state.userList
                  .sort(Utils.sortObjectByKey('Name', false))
                  .map((value, index) => {
                    return (
                      <MenuItem
                        className={ classes.cpSelectMenuItem }
                        key={ Math.random() }
                        value={ value }
                        classes={ { selected: classes.cpSelectSelected } }
                      >
                        { value.Name }
                      </MenuItem>
                    )
                  }) }
              </Select>
              <Typography
                className={ classNames(classes.inviteUserSelectBoxTitle) }
              >
                <FormattedMessage
                  id="cpSitesUser.roleInvite"
                  defaultMessage="Role"
                />
                <IconButton
                  className={ classes.helpCenterIconInvite }
                  color="inherit"
                  title="Help"
                  onClick={ () => this.showRolesInfo() }
                >
                  <InfoActiveIcon style={ { width: '100%' } } />
                </IconButton>
              </Typography>
              <Select
                onChange={ event => {
                  this.setInviteData(event)
                } }
                name="inputUserRole"
                key={ 'inviteUserEmailRole' + Math.random() }
                disableUnderline={ true }
                value={ this.state.inputUserRole }
                displayEmpty
                IconComponent={ LeftIcon }
                classes={ {
                  root: classes.cpSelectBox + ' ' + classes.inviteUserFormControlSelect,
                  selectMenu: classes.cpSelectBoxContent
                } }
                MenuProps={ {
                  classes: {
                    paper: classes.cpSelectMenuPaper + ' ' + classes.dropdownStyle
                  },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  MenuListProps: {
                    className: classNames(classes.cpSelectList)
                  }
                } }
              >
                { this.state.roleList
                  .sort(Utils.sortObjectByKey('Name', false))
                  .map((value, index) => {
                    return (
                      <MenuItem
                        className={ classes.cpSelectMenuItem }
                        key={ Math.random() }
                        value={ value }
                        classes={ { selected: classes.cpSelectSelected } }
                      >
                        { value.Name }
                      </MenuItem>
                    )
                  }) }
              </Select>
            </div>
            <div className={ classes.inviteUserButtons }>
              <span className={ classes.printerActions }>
                <Button
                  id="usersList.cancelInviteUser"
                  color="secondary"
                  className={ classNames(classes.inviteModalCancelButton) }
                  fontFamily="Segoe UI"
                  onClick={ () => this.closeInviteBox() }
                >
                  <FormattedMessage
                    id="cpSitesUser.cancel"
                    defaultMessage="CANCEL"
                  />
                </Button>
                <LoadingButton
                  id="usersList.inviteUser"
                  className={ classes.inviteButton }
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="outlined"
                  disableRipple
                  onClick={ () => this.sendInvite() }
                >
                  <FormattedMessage
                    id="cpSitesUser.invite"
                    defaultMessage="INVITE"
                  />
                </LoadingButton>
              </span>
            </div>
          </div>
        </Modal>
        { this.state.isOpenRightPane && this.props.orderStatistics && this.showRightPane() }
        <Notification
          setNotification={ this.setNotification }
          notification={ this.state.notification }
          services={ this.context }
        />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    flows: state.pairingInfo.flows,
    users: state.pairingInfo.users,
    sites: state.pairingInfo.sites,
    orderStatistics: state.settings.flowOrderStatistics,
    graphQLErrors: state.pairingInfo.graphQLErrors,
    liveSearchTerm: state.filters.liveSearchTerm,
    currentSearchTerm: state.filters.currentSearchTerm,
    filterChips: state.filters.chips,
    dateRangeValues: state.filters.dateRangeStatisticsValues,
    permissions: state.permissionsInfo.customerInfo
  }
}

CpSettings.defaultProps = {
  dateRangeValues: defaultDateRangeValues
}

CpSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  inviteUser: PropTypes.func.isRequired,
  getCustomerPairInfoLists: PropTypes.func.isRequired,
  getFlowOrdersStatistics: PropTypes.func.isRequired,
  handleUserDelete: PropTypes.func.isRequired,
  setDateRangeFlowsStatisticsValues: PropTypes.func.isRequired,
  // siteUsers means this can contain either sites or users...
  sites: PropTypes.array,
  users: PropTypes.array,
  flows: PropTypes.array,
  selectedFilter: PropTypes.string,
  liveSearchTerm: PropTypes.string,
  currentSearchTerm: PropTypes.object,
  filterChips: PropTypes.object,
  permissions: PropTypes.array,
  dateRangeValues: PropTypes.object.isRequired
}

export default withStyles(styles)
  (injectIntl
    (
      connect(mapStateToProps,
        {
          inviteUser,
          getCustomerPairInfoLists,
          getFlowOrdersStatistics,
          handleUserDelete,
          setDateRangeFlowsStatisticsValues
        })(CpSettings)
    )
  )