import * as types from '../types'
import { API } from 'aws-amplify'

export const loginSuccess = user => {
  return async dispatch => {
    dispatch({
      type: types.LOGIN_SUCCESS,
      data: {
        username: user.username,
        attributes: user.attributes
      }
    })
  }
}

export const runPairing = signedurlparameters => {
  const apiName = 'SignedAPI'
  const path = '/consumesignedurl' + signedurlparameters
  const options = {
    headers: {},
    response: true
  }

  return dispatch => {
    dispatch({
      type: types.RUN_PAIRING
    })
    return API.get(apiName, path, options)
      .then(res => dispatch({ type: types.RUN_PAIRING_SUCCESS, res }))
      .catch(err => dispatch({ type: types.RUN_PAIRING_FAILURE, err: err.response }))
  }
}

export const logoutSuccess = () => {
  return dispatch => {
    dispatch({
      type: types.LOGOUT_SUCCESS
    })
  }
}