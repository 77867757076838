import gql from 'graphql-tag'
import { client } from '../../shared/config/Clients'
import * as types from './types'
import { customerListOrder } from '../../../graphql/queries'
import { onUpdateOrder } from '../../../graphql/subscriptions'

export const getFlowOrders = (dateFrom, dateTo) => {
  return dispatch => {
    dispatch({
      type: types.LIST_FLOW_ORDERS
    })
    return client.query({
      query: gql(customerListOrder),
      variables: {
        input: {
          fromDate: dateFrom,
          toDate: dateTo
        }
      },
      fetchPolicy: 'no-cache'
    }).then(res => dispatch({ type: types.LIST_FLOW_ORDERS_SUCCESS, res }))
      .catch(err => dispatch({ type: types.LIST_FLOW_ORDERS_FAILURE, err }))
  }
}

export const subscribeToFlowOrdersUpdates = () => {
  return dispatch => {
    dispatch({
      type: types.SUBSCRIBE_TO_FLOW_ORDERS_UPDATES
    })

    return client.subscribe({
      query: gql(onUpdateOrder)
    }).subscribe(
      result => {
        dispatch({
          type: types.SUBSCRIBE_TO_FLOW_ORDERS_UPDATES_SUCCESS,
          subscription: result,
          data: result.data.onUpdateOrder
        })
      },
      error => dispatch({ type: types.SUBSCRIBE_TO_FLOW_ORDERS_UPDATES_FAILURE, err: error })
    )
  }
}