import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
export default createSvgIcon(
  <React.Fragment>
    <g>
      <path
        d="M12,6.5c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.5-0.1-0.6-0.2c-0.2-0.2-0.3-0.4-0.3-0.6
            c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.5,0.1,0.6,0.3C11.9,6,12,6.2,12,6.5z M11.7,16h-1.4V9.5h1.4V16z"
      />
    </g>
    <path
      d="M11,2c5,0,9,4,9,9s-4,9-9,9s-9-4-9-9S6,2,11,2 M11,1C5.5,1,1,5.5,1,11s4.5,10,10,10s10-4.5,10-10S16.5,1,11,1
        L11,1z"
    />
  </React.Fragment>,
  'InfoActiveIcon'
)
