import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteIcon from '../../../../../assets/icons/DeleteIcon'
import ServiceContext from '../../../../shared/Services/ServiceContext'
import PermissionTypes from '../../../../shared/duck/types/permissionTypes'
import PermissionsValidator from '../../../../shared/duck/helpers/permissionsValidator'
import { deleteCpCustomerFlow } from '../../../../shared/duck/actions/pairingInfoActions'
import UserIcon from '../../UserIcon'

class FlowsList extends React.Component {
  static contextType = ServiceContext

  state = {
    deleteInProgress: false,
    contextMenuAnchorEl: null,
    currentFlowId: null
  }

  // That's how the backend generates the unique identifier for the pairing
  flowId (id, customerId) {
    return id + '_' + customerId
  }

  isDeletingPairing (id, customerId) {
    return this.state.deleteInProgress === this.flowId(id, customerId)
  }

  handleDelete = async (id, customerId, hubId) => {
    this.context.trackingService.trackEvent(
      'Flow Management',
      'Delete Flow',
      '1',
      1
    )
    this.setState({ deleteInProgress: this.flowId(id, customerId) })

    await this.props.deleteCpCustomerFlow(id, customerId, hubId)

    this.setState({ deleteInProgress: '' })
  }

  onContextClick = (flowId, e) => {
    this.setState({
      currentFlowId: flowId,
      contextMenuAnchorEl: e.currentTarget
    })

    e.preventDefault()
  }

  copyFlowIdToClipboard = e => {
    try {//Chrome, FF, Safari
      navigator.clipboard.writeText(this.state.currentFlowId)
    }
    catch (err) {//Edge
      const textField = document.createElement('textarea')
      textField.innerText = this.state.currentFlowId
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
    }

    this.closeContextMenu()

    e.stopPropagation()
  }

  closeContextMenu = () => {
    this.setState({
      currentFlowId: null,
      contextMenuAnchorEl: null
    })
    return
  }

  renderContextMenu () {
    return (
      <React.Fragment>
        <Menu
          keepMounted
          id="flow-context-menu"
          anchorEl={ this.state.contextMenuAnchorEl }
          open={ (Boolean(this.state.contextMenuAnchorEl)) }
          onClose={ this.closeContextMenu.bind(this) }
        >
          <MenuItem
            className={ this.props.classes.tableCellContextMenuContainer }
            onClick={ this.copyFlowIdToClipboard.bind(this) }>
            <FormattedMessage
              id="flowManager.clipboard"
              defaultMessage="Copy Flow ID To Clipboard"
            />
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }

  findCustomerData (customerId) {
    let customer = null
    this.props.sites.forEach(site => {
      if (!customer)
        customer = site.customers.find(c => c.customerId === customerId)
    })
    return customer
  }

  render () {
    const textId = 'cpSitesUser.noFlows'
    const defaultText = 'No Flows'
    const { classes } = this.props

    if (!this.props.flows) {
      return (
        <TableRow>
          <TableCell colSpan={ 1 } className={ classes.tableCellSpacer } />
          <TableCell colSpan="4" className={ classNames('tableCell') }>
            <span className={ 'noData' }>
              <FormattedMessage id={ textId } defaultMessage={ defaultText } />
            </span>
          </TableCell>
          <TableCell colSpan={ 1 } className={ classes.tableCellSpacer } />
        </TableRow>
      )
    }

    const validator = new PermissionsValidator(this.props.permissions)
    return (
      <React.Fragment>
        {this.props.flows.map(flow => (
          <React.Fragment key={ flow.id }>
            <TableRow className={ classNames(classes.hoverRow) }>
              <TableCell className={ classes.tableCellSpacer } />
              <TableCell
                key={ `name-${flow.id}-${flow.name}` }
                className={ classNames(
                  classes.tableCell,
                  classes.tableCellHideMobile,
                  classes.noLeftPadding,
                  classes.tableCellHover
                ) }
                onContextMenu={ this.onContextClick.bind(this, flow.id) }
                onClick={ () => this.props.onShowRightPane(flow) }
              >
                { flow.name }
                { this.renderContextMenu() }
              </TableCell>
              <TableCell
                key={ `creator-${flow.id}-${flow.hub}` }
                onClick={ () => this.props.onShowRightPane(flow) }
                className={ classNames(
                  classes.tableCell,
                  classes.tableCellHideMobile,
                  classes.tableCellHideTablet,
                  classes.noLeftPadding
                ) }
              >
                <UserIcon customer={ this.findCustomerData(flow.customerId) } tooltipsClass={ classes.tooltips } />
              </TableCell>
              <TableCell
                key={ `hub-${flow.id}-${flow.hub}` }
                onClick={ () => this.props.onShowRightPane(flow) }
                className={ classNames(
                  classes.tableCell,
                  classes.tableCellHideMobile,
                  classes.tableCellHideTablet,
                  classes.noLeftPadding
                ) }
              >
                { flow.hubName ? flow.hubName : <FormattedMessage id="cpFlows.unknownName" defaultMessage="Unknown" /> }
              </TableCell>
              <TableCell
                key={ `remote-clients-${flow.id}-${flow.clients.join('-')}` }
                onClick={ () => this.props.onShowRightPane(flow) }
                className={ classNames(
                  classes.tableCell,
                  classes.tableCellHideMobile,
                  classes.tableCellHideTablet,
                  classes.noLeftPadding
                ) }
              >
                { flow.clientsWithName && flow.clientsWithName.length > 0 ?
                  flow.clientsWithName.map(clientWithName => { return clientWithName.name }).join(', ') :
                  flow.clients.join(', ') }
              </TableCell>
              <TableCell
                key={ `workflows-${flow.id}-${flow.clients.join('-')}` }
                onClick={ () => this.props.onShowRightPane(flow) }
                className={ classNames(
                  classes.tableCell,
                  classes.tableCellHideMobile,
                  classes.tableCellHideTablet,
                  classes.noLeftPadding
                ) }
              >
                { flow.wfName || '' }
              </TableCell>
              <TableCell
                key={ `delete-${flow.id}-${flow.name}-${flow.hub}` }
                className={ classNames(
                  classes.tableCell,
                  classes.noLeftPadding,
                  classes.tableCellHideMobile
                ) }
              >
                { validator.hasUserPermissionForCp(flow.hub, PermissionTypes.ManageFlow) &&
                  !this.isDeletingPairing(flow.id, flow.customerId) && (
                    <span className={ classNames(classes.deleteIcon) }>
                      <DeleteIcon
                        onClick={ () => this.handleDelete(flow.id, flow.customerId, flow.hub) }
                      />
                    </span>
                  )
                }
                { validator.hasUserPermissionForCp(flow.hub, PermissionTypes.ManageFlow) &&
                  this.isDeletingPairing(flow.id, flow.customerId) && (
                    <span className={ classNames(classes.deleteProgressColor) }>
                      <CircularProgress color={ 'inherit' } size={ 20 } />
                    </span>
                  )
                }
              </TableCell>
              <TableCell className={ classes.tableCellSpacer } />
            </TableRow>
          </React.Fragment>
        )) }
      </React.Fragment>
    )
  }
}

function mapStateToProps (state) {
  return {
    permissions: state.permissionsInfo.customerInfo
  }
}

FlowsList.propTypes = {
  deleteCpCustomerFlow: PropTypes.func.isRequired,
  onShowRightPane: PropTypes.func.isRequired,
  toggleRightPane: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  sites: PropTypes.array,
  flows: PropTypes.array,
}

export default connect(mapStateToProps, { deleteCpCustomerFlow })(FlowsList)