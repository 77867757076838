import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M20.76,3.2h-4.93V1.97c0-1-0.81-1.81-1.81-1.81H9.74c-1,0-1.81,0.81-1.81,1.81V3.2H3v0.91h1.3l1.45,17.93
			c0,1,0.81,1.81,1.81,1.81c0,0,0.01,0,0.01,0h8.69c1,0,1.81-0.81,1.81-1.81l1.44-17.93h1.25V3.2z M8.83,1.97
			c0-0.5,0.41-0.91,0.91-0.91l0,0h4.28c0.5,0,0.9,0.4,0.9,0.9c0,0,0,0.01,0,0.01V3.2H8.83V1.97z M17.16,21.98L17.16,21.98
			c0,0.5-0.4,0.9-0.9,0.91H7.57c-0.5,0-0.9-0.4-0.9-0.9c0,0,0-0.01,0-0.01l0,0L5.2,4.11h13.4L17.16,21.98z"
    />
    <rect x="11.45" y="6.08" width="0.86" height="14.8" />
    <polygon points="15.83,6.08 14.93,6.08 14.27,20.88 15.16,20.88 		" />
    <polygon points="8.83,6.08 7.93,6.08 8.59,20.88 9.49,20.88 		" />
  </React.Fragment>,
  'DeleteIcon'
)
