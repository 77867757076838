import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk'
import { loadState, saveState } from './localStorage'

let finalCreateStore = null
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

if (process.env.REACT_APP_STAGE !== 'Prod')
  finalCreateStore = composeEnhancers(
    applyMiddleware(thunk),
    //applyMiddleware(createLogger())
  )(createStore)
else
  finalCreateStore = compose(applyMiddleware(thunk))(createStore)

export default function configureStore () {
  const persistedState = loadState()
  const store = finalCreateStore(rootReducer, persistedState)

  store.subscribe(() => {
    let state = store.getState()

    saveState({
      authentication: state.authentication,
      authItemsState: state.authItemsState,
      jobs: state.jobs,
      printers: state.printers,
      flowOrders: state.flowOrders,
      pairingInfo: state.pairingInfo,
      permissionsInfo: state.permissionsInfo,
      filters: state.filters
    })
  })

  return store
}