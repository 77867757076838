import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <rect x="7.1" y="2.3" width="1.1" height="18.6" />
    <rect x="15.8" y="2.3" width="1.1" height="18.6" />
  </React.Fragment>,
  'PauseIcon'
)
