/* eslint-disable no-loop-func */
import gql from 'graphql-tag'
import { client } from '../../shared/config/Clients'
import { listCustomerPrinters } from '../../../graphql/queries'
import { addAction } from '../../../graphql/mutations'
import {
  onCpAddPrinter,
  onCpDeletePrinter,
  onCpUpdatePrinter,
  onCpUpdatePrinterDetails
} from '../../../graphql/subscriptions'
import * as types from './types'

export const getCustomerPrinters = () => {
  return dispatch => {
    dispatch({
      type: types.GET_CUSTOMER_PRINTERS,
      loading: true
    })
    return client.query({
      query: gql(listCustomerPrinters),
      fetchPolicy: 'no-cache'
    })
      .then(res => dispatch({ type: types.GET_CUSTOMER_PRINTERS_SUCCESS, res }))
      .catch(err => dispatch({ type: types.GET_CUSTOMER_PRINTERS_FAILURE, err }))
  }
}

export const subscribeToPrinterDetailUpdates = (printers) => {
  return dispatch => {
    dispatch({
      type: types.SUBSCRIBE_TO_PRINTERDETAIL_UPDATE,
      loading: true
    })
    let updatePrinterDetailSubscriptions = []
    printers.forEach(printer => {
      updatePrinterDetailSubscriptions[ printer.printerId ] = client.subscribe({
        query: gql(onCpUpdatePrinterDetails),
        variables: { cpId: printer.cpId, printerId: printer.printerId }
      })
        .subscribe(
          result => {
            dispatch({
              type: types.SUBSCRIBE_TO_PRINTERDETAIL_UPDATE_SUCCESS,
              subscriptions: updatePrinterDetailSubscriptions,
              data: result.data.onCpUpdatePrinterDetails
            })
          },
          error => dispatch({ type: types.SUBSCRIBE_TO_PRINTERDETAIL_UPDATE_FAILURE, err: error })
        )
    })
    return
  }
}

export const subscribeToPrinterOverviewUpdates = (authorizedCps) => {
  return dispatch => {
    dispatch({
      type: types.SUBSCRIBE_TO_UPDATE_PRINTER,
      loading: true
    })

    let updatePrinterOverviewSubscriptions = []
    for (let cpId of authorizedCps) {
      if (updatePrinterOverviewSubscriptions[ cpId ] !== undefined) {
        // don't over-subscribe to duplicate cp IDs
        continue;
      }
      updatePrinterOverviewSubscriptions[ cpId ] = client.subscribe({
        query: gql(onCpUpdatePrinter),
        variables: { cpId: cpId }
      })
        .subscribe(
          result => {
            dispatch({
              type: types.SUBSCRIBE_TO_UPDATE_PRINTER_SUCCESS,
              subscriptions: updatePrinterOverviewSubscriptions,
              data: result.data.onCpUpdatePrinter
            })
          },
          error => dispatch({ type: types.SUBSCRIBE_TO_UPDATE_PRINTER_FAILURE, err: error })
        )
    }
    return
  }
}

export const subscribeToCpAddPrinter = (authorizedCps) => {
  return dispatch => {
    dispatch({
      type: types.SUBSCRIBE_TO_ADD_PRINTER,
      loading: true
    })
    let addPrinterSubscriptions = []
    for (let cpId of authorizedCps) {
      if (addPrinterSubscriptions[ cpId ] !== undefined) {
        // don't over-subscribe to duplicate cp IDs
        continue;
      }
      addPrinterSubscriptions[ cpId ] = client.subscribe({
        query: gql(onCpAddPrinter),
        variables: { cpId: cpId }
      })
        .subscribe(
          result => {
            dispatch({
              type: types.SUBSCRIBE_TO_ADD_PRINTER_SUCCESS,
              subscriptions: addPrinterSubscriptions,
              data: [ result.data.onCpAddPrinter ]
            })
          },
          error => dispatch({ type: types.SUBSCRIBE_TO_ADD_PRINTER_FAILURE, err: error })
        )
    }
    return
  }
}

export const subscribeToPrinterDelete = (authorizedCps) => {
  return dispatch => {
    dispatch({
      type: types.SUBSCRIBE_TO_DELETE_PRINTER,
      loading: true
    })
    let deletePrinterSubscriptions = []
    for (let cpId of authorizedCps) {
      deletePrinterSubscriptions[ cpId ] = client.subscribe({
        query: gql(onCpDeletePrinter),
        variables: { cpId: cpId }
      })
        .subscribe(
          result => {
            dispatch({
              type: types.SUBSCRIBE_TO_DELETE_PRINTER_SUCCESS,
              subscriptions: deletePrinterSubscriptions,
              data: result.data.onCpDeletePrinter
            })
          },
          error => dispatch({ type: types.SUBSCRIBE_TO_DELETE_PRINTER_FAILURE, err: error })
        )
    }
    return
  }
}

export const mutateAddAction = (actionInput) => {
  return dispatch => {
    dispatch({
      type: types.ADD_ACTION,
      loading: true
    })
    return client.mutate({
      mutation: gql(addAction),
      variables: { input: actionInput }
    })
      .then(res => dispatch({ type: types.ADD_ACTION_SUCCESS, res }))
      .catch(err => dispatch({ type: types.ADD_ACTION_FAILURE, err }))
  }
}

export const clearPrintersData = () => {
  return dispatch => {
    dispatch({
      type: types.CLEAR_PRINTERS_DATA
    })
  }
}