import React from 'react';
import styles from '../../../shared/Styles/styles';
import { withStyles } from '@material-ui/core/styles/index';
import * as constants from '../Types/constants';
import { TextField } from '@material-ui/core';
import { Field } from 'react-final-form';

interface Props {
  classes: any;
  selectedItem: string;
  values: any;
  name: string;
}

const inputLabelStyle = {
  shrink: false,
  style: {
    color: '#bbbbbb',
    fontStyle: 'italic',
    fontSize: '17px',
    height: '12px',
    margin: '0px',
    padding: '0px',
    lineHeight: '0.5em',
    transform: 'translate(8px, 12px) scale(1)',
  },
};

const inputPropsStyle = {
  style: {
    height: '12px',
    fontSize: '17px',
  },
};

const required = (value: any, allValues: any, meta: any) => {
  if (!meta.name == undefined) {
    return undefined;
  }

  const selectName = meta.name.split('.')[0];
  if (allValues[selectName].select === constants.REPLACE_WITH) {
    return value ? undefined : 'Required';
  }

  return undefined;
};

const placeholderLabel = (seletedItem: string, inputNameKey: string, values: any): string => {
  const selectName = inputNameKey.split('.')[0];
  return seletedItem === constants.REPLACE_WITH && values[selectName].replaceColor === undefined
    ? constants.TYPE_NAME
    : '';
};

const AddReplaceColor: React.FC<Props> = ({ classes, selectedItem, values, name }) => {
  return (
    <Field name={name} classes={classes} selectedItem={selectedItem} values={values} validate={required}>
      {(props) => (
        <div>
          <TextField
            name={props.input.id}
            id={`${props.input.id}_outlined-basic`}
            variant="outlined"
            size="small"
            value={props.input.value}
            label={placeholderLabel(props.selectedItem, props.input.name, props.values)}
            fullWidth={true}
            disabled={props.selectedItem !== constants.REPLACE_WITH}
            inputProps={inputPropsStyle}
            InputLabelProps={inputLabelStyle}
            onChange={props.input.onChange}
          />
        </div>
      )}
    </Field>
  );
};

export default withStyles(styles)(AddReplaceColor);
