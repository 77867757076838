
import React from 'react';
import styles from '../../../shared/Styles/styles'
import { withStyles } from '@material-ui/core/styles/index'
import { ChannelValueRow } from './UI/ChannelValueRow';
import { composeValidators, mustBeNumber, maxValue, minValue, required } from '../Types/types';
interface Props {
  classes: any,
  name: string
}

// L* axis (lightness) ranges from 0 to 100
// a* and b* (color attributes) axis range from -128 to +127

// 0 to 127
// ^([0-9]|[1-9][0-9]|1[0-2][0-7])$

// -127 to 127
//^(-?([0-9]|[1-9][0-9]|1[0-2][0-7]))$


const AddLab: React.FC<Props> = (props: Props) => {

  const isNumberValidforAandB = (value: string): string | undefined => {
    const regex = /(^-?\d{0,3}\.\d{0,2}$)?(^-?\d{0,3}$)?(^-?\d{0,3}\.$)?/gm;

    const valueMatch = value.match(regex) || [''];
    if (valueMatch[0] === value) {
      return undefined;
    }

    return 'Is not valid';
  };

  return (
    <ChannelValueRow channelRowItems={[
      {
        placeholder: 'L*',
        name: `${props.name}.l`,
        validator: composeValidators(required, mustBeNumber, maxValue(100), minValue(0))
      },
      {
        placeholder: 'a*',
        name: `${props.name}.a`,
        validator: composeValidators(required, isNumberValidforAandB, maxValue(127), minValue(-128))
      },
      {
        placeholder: 'b*',
        name: `${props.name}.b`,
        validator: composeValidators(required, isNumberValidforAandB, maxValue(127), minValue(-128))
      },
    ]}/>
  );
};


export default withStyles(styles)(AddLab)