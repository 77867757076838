import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import DefaultDateTypes from '../../duck/helpers/DefaultDateTypes'
import DefaultDatesDropdown from './DefaultDatesDropdown'
import withIntl from '../../../shared/Localization'
import DateRangeFilter from './DateRangeFilter'
import { changeStartDate, changeEndDate, getDateRange } from '../../duck/helpers/dateTime'
import ArrowDownIcon from '../../../../assets/icons/ArrowDownIcon'
import ArrowUpIcon from '../../../../assets/icons/ArrowUpIcon'

class DateRangeDisplay extends React.Component {
  dateInputRef = React.createRef()
  state = {
    showPicker: false,
    showMenu: false,
    menuAnchorEl: null
  }

  getDateStringValue = date => {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
  }

  createDateRangeDisplayValue = e => {
    const startDate = e.startDate ? this.getDateStringValue(e.startDate) : ''
    const endDate = e.endDate ? this.getDateStringValue(e.endDate) : ''
    const dateRangeText = `${startDate} - ${endDate}`

    this.props.onDateRangeChange({
      displayText: dateRangeText,
      startDate: changeStartDate(e.startDate),
      endDate: changeEndDate(e.endDate)
    })

    this.setState({
      showPicker: false,
      menuAnchorEl: null,
      showMenu: false
    })
  }

  menuItemCLick = e => {
    if (e.target.textContent === DefaultDateTypes.Custom) {
      this.setState({ showPicker: !this.state.showPicker, showMenu: !this.state.showMenu })
    }
    else {
      const dateRange = getDateRange(e.target.textContent)
      this.props.onDateRangeChange({
        displayText: e.target.textContent,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate
      })
      this.setState({ showMenu: !this.state.showMenu })
    }
  }

  openMenu = e => {
    this.setState({ showMenu: !this.state.showMenu, menuAnchorEl: this.dateInputRef.current })
    e.preventDefault()
  }

  closeDatesMenu = () => {
    this.setState({ showMenu: !this.state.showMenu, menuAnchorEl: null })
  }

  render () {
    const { classes, useRight, locale } = this.props
    const inputContainerClass = useRight ? classes.dateRangeInputWrapperRight : classes.dateRangeInputWrapperLeft
    const dateRangeContainerClass = useRight ? classes.dateRangeContainerRight : classes.dateRangeContainerLeft
    const inputClass = useRight ? classes.dateRangeInputRight : classes.dateRangeInput

    return (
      <React.Fragment>
        <div className={ inputContainerClass }>
          <OutlinedInput
            id="cpFlowOrders.dateRange"
            ref={ this.dateInputRef }
            type="text"
            variant="outlined"
            value={ this.props.dateRange.displayText }
            className={ inputClass }
            readOnly={ true }
            endAdornment={
              <InputAdornment position="end">
                <div className={ classes.countersContainer }>
                  { !this.state.showMenu && !this.state.showPicker &&
                    <ArrowDownIcon
                      id="cpFlowOrders.dateRange-down-button"
                      className={ classes.dateRangeInputArrow }
                      onClick={ e => this.openMenu(e) }
                    />
                  }
                  { (this.state.showPicker || this.state.showMenu) &&
                    <ArrowUpIcon
                      id="cpFlowOrders.dateRange-up-button"
                      className={ classes.dateRangeInputArrow }
                      onClick={ this.closeDatesMenu }
                    />
                  }
                </div>
              </InputAdornment>
            }
          />
        </div>

        {this.state.showMenu &&
          <DefaultDatesDropdown
            classes={ classes }
            menuAnchorEl={ this.state.menuAnchorEl }
            onMenuItemClick={ e => this.menuItemCLick(e) }
            onCloseMenu={ this.closeDatesMenu }
            useRight={ useRight }
            currentValue={ this.props.dateRange.displayText }
          />
        }

        {this.state.showPicker &&
          <DateRangeFilter
            locale={ locale }
            rootClass={ dateRangeContainerClass }
            onChangeDateRange={ e => this.createDateRangeDisplayValue(e) }
          />
        }
      </React.Fragment>
    )
  }
}

DateRangeDisplay.defaultProps = {
  useRight: false
}

DateRangeDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  useRight: PropTypes.bool
}

export default withIntl(DateRangeDisplay)