import gql from 'graphql-tag'
import { client } from '../../shared/config/Clients'
import * as types from './types'
import { getOrderStatistics } from '../../../graphql/queries'

export const getFlowOrdersStatistics = (flowId, fromDate, toDate) => {
  return dispatch => {
    dispatch({
      type: types.GET_FLOW_ORDER_STATISTICS,
      loading: true
    })
    return client.query({
      query: gql(getOrderStatistics),
      variables: {
        input: {
          flowId: flowId,
          fromDate: fromDate,
          toDate: toDate
        }
      },
      fetchPolicy: 'no-cache'
    })
      .then(res => dispatch({ type: types.GET_FLOW_ORDER_STATISTICS_SUCCESS, res }))
      .catch(err => dispatch({ type: types.GET_FLOW_ORDER_STATISTICS_FAILURE, err }))
  }
}