import React from 'react'
import GMGColorCardLogoSVG from '../../../assets/gmg_ColorCard_logo.svg'

const GMGColorCardLogo = props => (
  <img
    src={ GMGColorCardLogoSVG }
    alt="GMG ColorCard"
    title="GMG ColorCard"
    { ...props }
  />
)

export default GMGColorCardLogo
