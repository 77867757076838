import gql from 'graphql-tag'
import { client } from '../../config/Clients'
import * as types from '../types/pairingInfoTypes'
import {
  getCustomerPairInfo,
  listFlows
} from '../../../../graphql/queries'
import {
  updateCustomerCpSite,
  deleteCpCustomer,
  updateCustomerPair,
  deletePendingCpCustomer,
  invokeEmailPairing,
  addFlow,
  deleteFlow
} from '../../../../graphql/mutations'
import {
  onCustomerUpdateCpSite,
  onUpdateLinkedCpInfo,
  onUpdateFlow
} from '../../../../graphql/subscriptions'

export const getFlows = () => {
  return dispatch => {
    dispatch({
      type: types.GET_FLOWS
    })
    return client.query({
      query: gql(listFlows),
      fetchPolicy: 'no-cache'
    })
      .then(res => dispatch({ type: types.GET_FLOWS_SUCCESS, res }))
      .catch(err => dispatch({ type: types.GET_FLOWS_FAILURE, err }))
  }
}

export const getCustomerPairInfoLists = () => {
  return dispatch => {
    dispatch({
      type: types.GET_CUSTOMER_PAIR_INFO,
      loading: true
    })
    return client.query({
      query: gql(getCustomerPairInfo),
      fetchPolicy: 'no-cache'
    })
      .then(res => dispatch({ type: types.GET_CUSTOMER_PAIR_INFO_SUCCESS, res }))
      .catch(err => dispatch({ type: types.GET_CUSTOMER_PAIR_INFO_FAILURE, err }))
  }
}

export const changeAlias = (cpId, customerId, dongleId, aliasName) => {
  return dispatch => {
    dispatch({
      type: types.CHANGE_ALIAS
    })
    return client.mutate({
      mutation: gql(updateCustomerCpSite),
      variables: {
        input: {
          cpId: cpId,
          customerId: customerId,
          alias: aliasName,
          dongleId: dongleId
        }
      }
    })
      .then(res => dispatch({ type: types.CHANGE_ALIAS_SUCCESS, res }))
      .catch(err => dispatch({ type: types.CHANGE_ALIAS_FAILURE, err }))
  }
}

export const changeColorHub = (cpId, customerId, dongleId, isHub) => {
  return dispatch => {
    dispatch({
      type: types.CHANGE_COLOR_HUB
    })
    return client.mutate({
      mutation: gql(updateCustomerCpSite),
      variables: {
        input: {
          cpId: cpId,
          customerId: customerId,
          isHub: isHub,
          dongleId: dongleId
        }
      }
    })
      .then(res => dispatch({ type: types.CHANGE_COLOR_HUB_SUCCESS, res }))
      .catch(err => dispatch({ type: types.CHANGE_COLOR_HUB_FAILURE, err }))
  }
}

export const handleUserDelete = (cpId, customerId, isPending) => {
  return isPending ?
    handlePendingCpCustomerDelete(cpId, customerId) :
    handleCpCustomerDelete(cpId, customerId)
}

export const handleRoleChange = (cpId, customerId, newRole) => {
  return dispatch => {
    dispatch({
      type: types.UPDATE_CP_CUSTOMER_PAIR
    })
    return client.mutate({
      mutation: gql(updateCustomerPair),
      variables: {
        input: {
          cpId: cpId,
          customerId: customerId,
          role: newRole
        }
      }
    })
      .then(res => dispatch({ type: types.UPDATE_CP_CUSTOMER_PAIR_SUCCESS, res }))
      .catch(err => dispatch({ type: types.UPDATE_CP_CUSTOMER_PAIR_FAILURE, err }))
  }
}

export const addCpCustomerFlow = (flow) => {
  return dispatch => {
    dispatch({
      type: types.ADD_FLOW
    })
    return client.mutate({
      mutation: gql(addFlow),
      variables: {
        input: {
          customerId: flow.customerId,
          name: flow.name,
          hubId: flow.hubId,
          clients: flow.clients,
          targetHfwfId: flow.targetHfwfId,
          targetWfName: flow.targetWfName
        }
      }
    })
      .then(res => dispatch({ type: types.ADD_FLOW_SUCCESS, res }))
      .catch(err => dispatch({ type: types.ADD_FLOW_FAILURE, err }))
  }
}

export const deleteCpCustomerFlow = (id, customerId, hubId) => {
  return dispatch => {
    dispatch({
      type: types.DELETE_FLOW
    })
    return client.mutate({
      mutation: gql(deleteFlow),
      variables: {
        input: {
          id: id,
          customerId: customerId,
          hubId: hubId
        }
      }
    })
      .then(res => dispatch({ type: types.DELETE_FLOW_SUCCESS, res }))
      .catch(err => dispatch({ type: types.DELETE_CP_CUSTOMER_FAILURE, err }))
  }
}

export const inviteUser = (cpId, role, email) => {
  return dispatch => {
    dispatch({
      type: types.INVITE_USER
    })
    return client.mutate({
      mutation: gql(invokeEmailPairing),
      variables: {
        input: {
          cpId: cpId,
          role: role,
          email: email
        }
      }
    })
      .then(res => dispatch({ type: types.INVITE_USER_SUCCESS, res }))
      .catch(err => dispatch({ type: types.INVITE_USER_FAILURE, err }))
  }
}

const handleCpCustomerDelete = (cpId, customerId) => {
  return dispatch => {
    dispatch({
      type: types.DELETE_CP_CUSTOMER
    })
    return client.mutate({
      mutation: gql(deleteCpCustomer),
      variables: {
        input: {
          cpId: cpId,
          customerId: customerId
        }
      }
    })
      .then(res => dispatch({ type: types.DELETE_CP_CUSTOMER_SUCCESS, res }))
      .catch(err => dispatch({ type: types.DELETE_CP_CUSTOMER_FAILURE, err }))
  }
}

const handlePendingCpCustomerDelete = (cpId, customerId) => {
  return dispatch => {
    dispatch({
      type: types.DELETE_PENDING_CP_CUSTOMER
    })
    return client.mutate({
      mutation: gql(deletePendingCpCustomer),
      variables: {
        input: {
          cpId: cpId,
          customerId: customerId
        }
      }
    })
      .then(res => dispatch({ type: types.DELETE_PENDING_CP_CUSTOMER_SUCCESS, res }))
      .catch(err => dispatch({ type: types.DELETE_PENDING_CP_CUSTOMER_FAILURE, err }))
  }
}



export const subscribeToSitesUpdates = (sites) => {
  return dispatch => {
    dispatch({
      type: types.SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE
    })
    let updateSitesSubscriptions = []
    for (let site of sites) {
      updateSitesSubscriptions[ site.dongleId ] = client.subscribe({
        query: gql(onUpdateLinkedCpInfo),
        variables: { dongleId: site.dongleId }
      })
        .subscribe(
          result => {
            dispatch({
              type: types.SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE_SUCCESS,
              subscriptions: updateSitesSubscriptions,
              data: [ result.data.onUpdateLinkedCpInfo ]
            })
          },
          error => dispatch({ type: types.SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE_FAILURE, err: error })
        )
    }
    return
  }
}

export const subscribeToAliasUpdates = (sites) => {
  return dispatch => {
    dispatch({
      type: types.SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE
    })
    let aliasSubscriptions = []
    for (let site of sites) {
      aliasSubscriptions[ site.dongleId ] = client.subscribe({
        query: gql(onCustomerUpdateCpSite),
        variables: { dongleId: site.dongleId }
      })
        .subscribe(
          result => {
            dispatch({
              type: types.SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE_SUCCESS,
              subscriptions: aliasSubscriptions,
              data: [ result.data.onCustomerUpdateCpSite ]
            })
          },
          error => dispatch({ type: types.SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE_FAILURE, err: error })
        )
    }
    return
  }
}

export const subscribeToFlowsUpdates = (flows) => {
  return dispatch => {
    dispatch({
      type: types.SUBSCRIBE_TO_FLOWS_UPDATE
    })
    let updateFlowsSubscriptions = []
    for (let flow of flows) {
      updateFlowsSubscriptions[ flow.id ] = client.subscribe({
        query: gql(onUpdateFlow),
        variables: { id: flow.id }
      })
        .subscribe(
          result => {
            dispatch({
              type: types.SUBSCRIBE_TO_FLOWS_UPDATE_SUCCESS,
              subscriptions: updateFlowsSubscriptions,
              data: [ result.data.onUpdateFlow ]
            })
          },
          error => dispatch({ type: types.SUBSCRIBE_TO_FLOWS_UPDATE_FAILURE, err: error })
        )
    }
    return
  }
}

export const clearSharedData = () => {
  return dispatch => {
    dispatch({
      type: types.CLEAR_SHARED_DATA
    })
  }
}