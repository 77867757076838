module.exports = {
  // eslint-disable
  // this is an auto generated file. This will be overwritten

  deleteCpCustomer : `mutation DeleteCpCustomer($input: DeleteCpCustomerInput!) {
    deleteCpCustomer(input: $input) {
      CustomerCpPair
      cpId
      customerId
      role
    }
  }
  `,
  updateCustomerPair : `mutation UpdateCustomerPair($input: UpdateCustomerPairInput!) {
    updateCustomerPair(input: $input) {
      CustomerCpPair
      cpId
      customerId
      role
    }
  }
  `,
  deletePendingCpCustomer : `mutation DeletePendingCpCustomer($input: DeleteCpCustomerInput!) {
    deletePendingCpCustomer(input: $input) {
      CustomerCpPair
      cpId
      customerId
      role
    }
  }
  `,
  invokeEmailPairing : `mutation InvokeEmailPairing($input: InvokeEmailPairingInput!) {
    invokeEmailPairing(input: $input) {
      CustomerCpPair
      cpId
      customerId
      role
    }
  }
  `,
  addAction : `mutation AddAction($input: AddActionInput!) {
    addAction(input: $input) {
      id
      cpId
      created
      action
      actionParams {
        name
        value
      }
    }
  }
  `,
  customerCreateOrder : `mutation CustomerCreateOrder($input: CustomerCreateOrderInput!) {
    customerCreateOrder(input: $input) {
      succeeded
      message
      orderId
      encryptionData
      encryptionCertId
      files {
        fileMetaData {
          bucket
          key
          FileName
          FileType
          FileSize
          MD5Checksum
        }
        multipart {
          accessKeyId
          secretAccessKey
          sessionToken
        }
      }
    }
  }
  `,
  updateCustomerCpSite : `mutation UpdateCustomerCpSite($input: UpdateCustomerCpSiteInput!) {
    updateCustomerCpSite(input: $input) {
      cpId
      dongleId
      version
      site
      licensed
      lastSeen
      licenseName
      alias
      isHub
      role
      flowLicensed
      customers {
        customerId
        email
        lastlogin
        role
        isPending
        color255
      }
      isOnline
    }
  }
  `,
  addFlow : `mutation AddFlow($input: FlowInput) {
    addFlow(input: $input) {
      hubId
      id
      customerId
      name
      created
      clients
      clientId
      targetHfwfId
      targetWfName
    }
  }
  `,
  deleteFlow : `mutation DeleteFlow($input: DeleteFlowInput) {
    deleteFlow(input: $input) {
      hubId
      id
      customerId
      name
      created
      clients
      clientId
      targetHfwfId
      targetWfName
    }
  }
  `,
  updateFlow : `mutation UpdateFlow($input: UpdateFlowInput) {
    updateFlow(input: $input) {
      hubId
      id
      customerId
      name
      created
      clients
      clientId
      targetHfwfId
      targetWfName
    }
  }
  `,
  addDomain : `mutation AddDomain($input: DomainInput) {
    addDomain(input: $input) {
      CustomerDomainPair
      customerId
      created
      domain
      email
      overallContingent {
        total
        used
        balance
        expirationDate
        isContingentAvailable
      }
      contingents {
        total
        used
        balance
        expirationDate
        isContingentAvailable
      }
    }
  }
  `,
  deleteDomain : `mutation DeleteDomain($input: DomainInput) {
    deleteDomain(input: $input) {
      CustomerDomainPair
      customerId
      created
      domain
      email
      overallContingent {
        total
        used
        balance
        expirationDate
        isContingentAvailable
      }
      contingents {
        total
        used
        balance
        expirationDate
        isContingentAvailable
      }
    }
  }
  `,
  updateDomain : `mutation UpdateDomain($input: DomainInput) {
    updateDomain(input: $input) {
      CustomerDomainPair
      customerId
      created
      domain
      email
      overallContingent {
        total
        used
        balance
        expirationDate
        isContingentAvailable
      }
      contingents {
        total
        used
        balance
        expirationDate
        isContingentAvailable
      }
    }
  }
  `,
  addSubstrate : `mutation AddSubstrate($input: AddSubstrateInput) {
    addSubstrate(input: $input) {
      id
      created
      domain
      name
      file
      data
      proofMedia
    }
  }
  `,
  deleteSubstrate : `mutation DeleteSubstrate($input: DeleteSubstrateInput) {
    deleteSubstrate(input: $input) {
      id
      created
      domain
      name
      file
      data
      proofMedia
    }
  }
  `,
  addTemplate : `mutation AddTemplate($input: AddTemplateInput) {
    addTemplate(input: $input) {
      id
      domain
      language
      name
      bucket
      created
      fields
    }
  }
  `,
  deleteTemplate : `mutation DeleteTemplate($input: TemplateInput) {
    deleteTemplate(input: $input) {
      id
      domain
      language
      name
      bucket
      created
      fields
    }
  }
  `,
  startCalculateProfileV2 : `mutation StartCalculateProfileV2($input: StartCalculateProfileInputV2) {
    startCalculateProfileV2(input: $input) {
      succeeded
    }
  }
  `,
  addHfWf : `mutation AddHfWf($input: AddHfWfInput!) {
    addHfWf(input: $input) {
      cpId
      id
      WorkflowName
      HotfolderName
      StandardName
      HotfolderId
      WorkflowId
    }
  }
  `,
  deleteHfWf : `mutation DeleteHfWf($input: DeleteHfWfInput!) {
    deleteHfWf(input: $input) {
      cpId
      id
      WorkflowName
      HotfolderName
      StandardName
      HotfolderId
      WorkflowId
    }
  }
  `,
  updateHfWf : `mutation UpdateHfWf($input: UpdateHfWfInput!) {
    updateHfWf(input: $input) {
      cpId
      id
      WorkflowName
      HotfolderName
      StandardName
      HotfolderId
      WorkflowId
    }
  }
  `,
  addCpJobDetail : `mutation AddCpJobDetail($input: AddCpJobDetailInput!) {
    addCpJobDetail(input: $input) {
      cpId
      id
      jobId
      order
      measurementResults {
        Timestamp
        Application
        MeasurementDevice
        DeviceType
        Angle
        Filter
        Light
        Colors {
          c
          m
          y
          k
          L
          a
          b
          Spectrum {
            Values
            StartNm
            IncrementNm
          }
        }
      }
      VerficationResults {
        StripName
        Rules {
          RuleName
          AnalysisType
          AnalysisValue
          AnalysisResult
        }
        DeviceType
        DeviceFilter
      }
      Name
      Workflow
      ProofStandard
      Dimension
      width
      height
      FileFormat
      PageNo
      Colors {
        ColorName
        R
        G
        B
      }
    }
  }
  `,
  deleteCpJobDetail : `mutation DeleteCpJobDetail($input: DeleteCpJobDetailInput!) {
    deleteCpJobDetail(input: $input) {
      cpId
      id
      jobId
      order
      measurementResults {
        Timestamp
        Application
        MeasurementDevice
        DeviceType
        Angle
        Filter
        Light
        Colors {
          c
          m
          y
          k
          L
          a
          b
          Spectrum {
            Values
            StartNm
            IncrementNm
          }
        }
      }
      VerficationResults {
        StripName
        Rules {
          RuleName
          AnalysisType
          AnalysisValue
          AnalysisResult
        }
        DeviceType
        DeviceFilter
      }
      Name
      Workflow
      ProofStandard
      Dimension
      width
      height
      FileFormat
      PageNo
      Colors {
        ColorName
        R
        G
        B
      }
    }
  }
  `,
  updateCpJobDetail : `mutation UpdateCpJobDetail($input: UpdateCpJobDetailInput!) {
    updateCpJobDetail(input: $input) {
      cpId
      id
      jobId
      order
      measurementResults {
        Timestamp
        Application
        MeasurementDevice
        DeviceType
        Angle
        Filter
        Light
        Colors {
          c
          m
          y
          k
          L
          a
          b
          Spectrum {
            Values
            StartNm
            IncrementNm
          }
        }
      }
      VerficationResults {
        StripName
        Rules {
          RuleName
          AnalysisType
          AnalysisValue
          AnalysisResult
        }
        DeviceType
        DeviceFilter
      }
      Name
      Workflow
      ProofStandard
      Dimension
      width
      height
      FileFormat
      PageNo
      Colors {
        ColorName
        R
        G
        B
      }
    }
  }
  `,
  addCpJob : `mutation AddCpJob($input: AddCpJobInput!) {
    addCpJob(input: $input) {
      cpId
      id
      name
      state
      userAction
      userActionMessage
      site
      created
      workflow
      printer
      printerType
      images
      copies
      widthInMM
      heightInMM
      mediaName
    }
  }
  `,
  deleteCpJob : `mutation DeleteCpJob($input: DeleteCpJobInput!) {
    deleteCpJob(input: $input) {
      cpId
      id
      name
      state
      userAction
      userActionMessage
      site
      created
      workflow
      printer
      printerType
      images
      copies
      widthInMM
      heightInMM
      mediaName
    }
  }
  `,
  updateCpJob : `mutation UpdateCpJob($input: UpdateCpJobInput!) {
    updateCpJob(input: $input) {
      cpId
      id
      name
      state
      userAction
      userActionMessage
      site
      created
      workflow
      printer
      printerType
      images
      copies
      widthInMM
      heightInMM
      mediaName
    }
  }
  `,
  addCpPrinter : `mutation AddCpPrinter($input: AddCpPrinterInput!) {
    addCpPrinter(input: $input) {
      cpId
      printerId
      name
      status
      site
      media
      mediaId
      isCalibrated
      lastCalibrationDate
      mediaSize
      mediaSizeId
      errorMessage
      printerType
      caliSets {
        Name
        Calibrated
        CaliSetDetail
      }
      inks {
        InkName
        InkPercentage
      }
      inkSet
      medias {
        Name
        id
        Sizes {
          Name
          id
        }
      }
    }
  }
  `,
  deleteCpPrinter : `mutation DeleteCpPrinter($input: DeleteCpPrinterInput!) {
    deleteCpPrinter(input: $input) {
      cpId
      printerId
      name
      status
      site
      media
      mediaId
      isCalibrated
      lastCalibrationDate
      mediaSize
      mediaSizeId
      errorMessage
      printerType
      caliSets {
        Name
        Calibrated
        CaliSetDetail
      }
      inks {
        InkName
        InkPercentage
      }
      inkSet
      medias {
        Name
        id
        Sizes {
          Name
          id
        }
      }
    }
  }
  `,
  updateCpPrinter : `mutation UpdateCpPrinter($input: UpdateCpPrinterInput!) {
    updateCpPrinter(input: $input) {
      cpId
      printerId
      name
      status
      site
      media
      mediaId
      isCalibrated
      lastCalibrationDate
    }
  }
  `,
  updateCpPrinterDetail : `mutation UpdateCpPrinterDetail($input: UpdatePrinterDetailsInput!) {
    updateCpPrinterDetail(input: $input) {
      cpId
      printerId
      errorMessage
      printerType
      caliSets {
        Name
        Calibrated
        CaliSetDetail
      }
      inks {
        InkName
        InkPercentage
      }
      mediaSize
      mediaSizeId
      inkSet
      medias {
        Name
        id
        Sizes {
          Name
          id
        }
      }
    }
  }
  `,
  deleteCpAction : `mutation DeleteCpAction($input: DeleteActionInput!) {
    deleteCpAction(input: $input) {
      id
      cpId
      created
      action
      actionParams {
        name
        value
      }
    }
  }
  `,
  updateLinkedCpInfo : `mutation UpdateLinkedCpInfo($input: UpdateLinkedCpInfoInput!) {
    updateLinkedCpInfo(input: $input) {
      dongleId
      cpId
      site
      lastSeen
      licensed
      licenseName
      isOnline
    }
  }
  `,
  cpCreateOrder : `mutation CpCreateOrder($input: CpCreateOrderInput!) {
    cpCreateOrder(input: $input) {
      succeeded
      message
      orderId
      encryptionData
      encryptionCertId
      files {
        fileMetaData {
          bucket
          key
          FileName
          FileType
          FileSize
          MD5Checksum
        }
        multipart {
          accessKeyId
          secretAccessKey
          sessionToken
        }
      }
    }
  }
  `,
  updateDongleInfo : `mutation UpdateDongleInfo($input: UpdateDongleInfoInput!) {
    updateDongleInfo(input: $input) {
      dongleId
      licensed
      cpId
      licenseName
    }
  }
  `,
  deleteDongleInfo : `mutation DeleteDongleInfo($input: DeleteDongleInfoInput!) {
    deleteDongleInfo(input: $input) {
      dongleId
      licensed
      cpId
      licenseName
    }
  }
  `,
  finishCalculateProfileV2 : `mutation FinishCalculateProfileV2($input: FinishCalculateProfileInputV2) {
    finishCalculateProfileV2(input: $input) {
      clientId
      jobId
      succeeded
      error
      thirdpartyId
      thirdpartyPayload
    }
  }
  `,
  addActionIntern : `mutation AddActionIntern($input: AddActionInput!) {
    addActionIntern(input: $input) {
      id
      cpId
      created
      action
      actionParams {
        name
        value
      }
    }
  }
  `,
  setIsOnlineIntern : `mutation SetIsOnlineIntern($input: SetIsOnlineInternInput) {
    setIsOnlineIntern(input: $input) {
      dongleId
      cpId
      site
      lastSeen
      licensed
      licenseName
      isOnline
    }
  }
  `,
  updateOrderIntern : `mutation UpdateOrderIntern($input: UpdateOrderIntern!) {
    updateOrderIntern(input: $input) {
      id
      targetId
      sourceId
      flowId
      printerNames
      printerTypes
      standardNames
      imageNames
      validationResults
      totalFileSize
      status
      created
      targetDongleIdDoc
      sourceDongleIdDoc
      flowNameDoc
      type
      customJobId
      substrateId
      thirdPartyID
      thirdPartyPayload
    }
  }
  `,
}
