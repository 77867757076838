import React from 'react'
import PropTypes from 'prop-types'

const NameWithAlias = ({ name, alias, classes }) => {
  if (alias)
    return (
      <span key={ Math.random() }>
        <React.Fragment>{ alias }</React.Fragment>
        <div className={ classes.subline } id={ alias }>
          { name }
        </div>
      </span>
    )
  return (
    <span>{ name }</span>
  )
}

NameWithAlias.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  alias: PropTypes.string
}

export default NameWithAlias