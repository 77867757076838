import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import authentication from '../Authentication/duck/reducers/auth'
import authItemsState from '../Authentication/duck/reducers/authItemsState'
import jobs from '../Jobs/duck/jobsReducers'
import printers from '../Printers/duck/printersReducers'
import flowOrders from '../FlowOrders/duck/flowOrdersReducers'
import settings from '../Settings/duck/settingsReducers'
import pairingInfo from '../shared/duck/reducers/pairingInfoReducers'
import permissionsInfo from '../shared/duck/reducers/permissionsReducers'
import filters from '../shared/duck/reducers/filtersReducers'

const rootReducer = combineReducers({
  authentication,
  authItemsState,
  jobs,
  printers,
  flowOrders,
  settings,
  pairingInfo,
  permissionsInfo,
  filters,
  routing: routerReducer
})

export default rootReducer