export default class PermissionTypes {
    //General
    static ViewColorProofGo = 'ViewColorProofGo'
    static ExecuteActions = 'ExecuteActions'
    static ManageColorProofGo = 'ManageColorProofGo'
    // Only applied in backend
    static ReducePairingRole = 'ReducePairingRole'
  
    //Flow
    static ViewFlow = 'ViewFlow'
    static ManageFlow = 'ManageFlow'
    static ShowFlowTab = 'ShowFlowTab'
    static ShowCpHub = 'ShowCpHub'
    static ShowFlowItemForHub = 'ShowFlowItemForHub'
    static ShowFlowItemForClients = 'ShowFlowItemForClients' //Not implemented
    static ShowFlowOrderTab = 'ShowFlowOrderTab'
    static ShowFlowOrderItemForHub = 'ShowFlowOrderItemForHub'
    static ShowFlowOrderItemForClient = 'ShowFlowOrderItemForClient'//Not implemented
    static ShowFlowStatistics = 'ShowFlowStatistics'
    static ChangeCpHub = 'ChangeCpHub'
    static AddFlowForHub = 'AddFlowForHub'
    static RemoveFlowItemForHub = 'RemoveFlowItemForHub'
    static RemoveFlowItemPairedClient = 'RemoveFlowItemPairedClient'
}