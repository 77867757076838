import React from 'react'
import { FormattedMessage } from 'react-intl'
import NameWithAlias from '../../shared/Components/ListItems/NameWithAlias'
import ItemsCellList from '../../shared/Components/ListItems/ItemsCellList'
import CpTimeAgo from '../../shared/Components/Date/CpTimeAgo'
import Popover from '../../shared/Components/Messaging/Popover'
import { FlowOrdersCategories } from '../duck/helper/Categories'
import StatusBubble from '../../shared/Components/ListItems/StatusBubble'

export const listRows = ( classes, sites ) => {
  const categories = new FlowOrdersCategories()
  return [
    {
      key: 'spacerLeft',
      hideMobile: false,
      hideTablet: false,
      hideRightPane: false,
      numeric: false,
      label: '',
      headClassName: 'tableCellSpacer'
    },
    {
      key: 'flowName',
      hideMobile: false,
      hideTablet: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      numeric: false,
      label: <FormattedMessage id="cpFlowOrders.flowName" defaultMessage="Flow Name" />,
      headClassName: 'tableHeadCellMinWidth',
      customTemplate: ( flowOrder, flowName ) => <span>{ flowName }</span>
    },
    {
      key: 'targetName',
      hideMobile: false,
      hideTablet: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      numeric: false,
      label: <FormattedMessage id="cpFlowOrders.flowOrderTargetName" defaultMessage="Target" />,
      headClassName: 'tableHeadCellMinWidth',
      customTemplate: flowOrder => {
        const site = sites.find( site => site.cpId === flowOrder.targetId )
        if ( site )
          return <NameWithAlias name={ site.site } alias={ site.alias } classes={ classes } />
      }
    },
    {
      key: 'sourceName',
      hideMobile: false,
      hideTablet: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      numeric: false,
      label: <FormattedMessage id="cpFlowOrders.flowOrderSourceName" defaultMessage="Source" />,
      headClassName: 'tableHeadCellMinWidth',
      customTemplate: flowOrder => {
        const site = sites.find( site => site.cpId === flowOrder.sourceId )
        if ( site )
          return <NameWithAlias name={ site.site } alias={ site.alias } classes={ classes } />
      }
    },
    {
      key: 'printerNames',
      hideMobile: false,
      hideTablet: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      numeric: false,
      label: <FormattedMessage id="cpFlowOrders.printerName" defaultMessage="Printer Name" />,
      headClassName: 'tableHeadCellMinWidth',
      customTemplate: flowOrder => (
        <ItemsCellList items={ flowOrder.printerNames || [] } alias="printers" />
      )
    },
    {
      key: 'standardNames',
      hideMobile: true,
      hideTablet: true,
      hideRightPane: true,
      hideRightPaneTabLandscape: true,
      numeric: false,
      label: <FormattedMessage id="cpFlowOrders.standardNames" defaultMessage="Standard" />,
      headClassName: 'tableHeadCellMinWidth',
      customTemplate: flowOrder => (
        <ItemsCellList items={ flowOrder.standardNames || [] } alias="standards" />
      )
    },
    {
      key: 'imageNames',
      hideMobile: true,
      hideTablet: true,
      hideRightPane: true,
      hideRightPaneTabLandscape: true,
      numeric: false,
      label: <FormattedMessage id="cpFlowOrders.images" defaultMessage="Images" />,
      headClassName: 'tableHeadCellMinWidth',
      customTemplate: flowOrder => (
        <ItemsCellList items={ flowOrder.imageNames || [] } alias="images" />
      )
    },
    {
      key: 'created',
      hideMobile: false,
      hideTablet: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      numeric: false,
      label: <FormattedMessage id="cpFlowOrders.createdOn" defaultMessage="Sent" />,
      headClassName: 'tableHeadCellMinWidth',
      customTemplate: flowOrder =>
        <Popover
          content={ new Date( flowOrder.created ).toLocaleString() }
          position="top"
        >
          <span>
            <CpTimeAgo date={ flowOrder.created } />
          </span>
        </Popover>
    },
    // {
    //   key: 'validationResults',
    //   hideMobile: false,
    //   hideTablet: false,
    //   hideRightPane: false,
    //   hideRightPaneTabLandscape: false,
    //   numeric: false,
    //   label: <FormattedMessage id="cpFlowOrders.validationResults" defaultMessage="Validation" />,
    //   headClassName: 'tableHeadCellMinWidth',
    //   customTemplate: flowOrder => (
    //     <ItemsCellList items={flowOrder.validationResults || []} alias="results" withSatusImage={true} />
    //   )
    // },
    {
      key: 'status',
      hideMobile: false,
      hideTablet: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      numeric: false,
      label: <FormattedMessage id="cpFlowOrders.status" defaultMessage="Status" />,
      headClassName: 'tableBubbleHeadCellMinWidth',
      customTemplate: flowOrder => <StatusBubble status={ flowOrder.status } categories={ categories } classes={ classes } />
    },
    {
      key: 'spacerRight',
      hideMobile: true,
      hideTablet: true,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      numeric: false,
      label: '',
      headClassName: 'tableCellSpacerMobile'
    }
  ]
}