import DefaultDateTypes from './DefaultDateTypes'

//YYYY-MM-DDThh:mm:ss.sssZ
export const getStartDate = (year, month, date) => {
    const objectDate = new Date(year, month, date, 1, 0, 0)

    return objectDate.toISOString()
}

export const getEndDate = (year, month, date) => {
    const objectDate = new Date(year, month, date, 0, 59, 59)

    return objectDate.toISOString()
}

export const changeStartDate = date => {
    return getStartDate(date.getFullYear(), date.getMonth(), date.getDate())
}

export const changeEndDate = date => {
    return getEndDate(date.getFullYear(), date.getMonth(), date.getDate())
}

export const getDateRange = requestedRangeType => {
    const today = new Date()
    switch (requestedRangeType) {
        case DefaultDateTypes.Today:
            return createDateRange(today, 1)
        case DefaultDateTypes.Yesterday:
            const yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1)
            return createDateRange(yesterday, 1)
        case DefaultDateTypes.LastWeek:
            const lastWeek = new Date(today)
            lastWeek.setDate(lastWeek.getDate() - 7)
            return createDateRange(lastWeek, 7)
        case DefaultDateTypes.LastMonth:
            const lastMonth = new Date(today)
            lastMonth.setMonth(lastMonth.getMonth() - 1)
            return {
                startDate: getStartDate(lastMonth.getFullYear(), lastMonth.getMonth(), lastMonth.getDate()),
                endDate: getEndDate(today.getFullYear(), today.getMonth(), today.getDate() + 1)
            }
        default:
            return createDateRange(today)
    }
}

export const defaultDateRangeValues = {
    displayText: DefaultDateTypes.Today,
    startDate: new Date(),
    endDate: new Date()
}

const createDateRange = (date, delta) => {
    return {
        startDate: getStartDate(date.getFullYear(), date.getMonth(), date.getDate()),
        endDate: getEndDate(date.getFullYear(), date.getMonth(), date.getDate() + delta)
    }
}