import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import styles from '../../Styles/styles'

export const CategoryListItem = ({
  id,
  name,
  label,
  selectedCategory,
  onCategoryClick,
  categoryCounter,
  classes,
  children,
  sidebarOpen
}) => (
  <ListItem
    id={ id }
    disableRipple
    button
    selected={ selectedCategory === name }
    onClick={ event => onCategoryClick(event, name) }
    className={
      selectedCategory === name ? classes.selected : classes.deselected
    }
    // That's how to use the Material UI api to override styles
    classes={ {
      selected: classes.listItemSelected
      // default: classes.listItemDeselected
    } }
  >
    <ListItemIcon title={ label }>{ children }</ListItemIcon>
    <ListItemText
      primary={ label }
      className={ classNames(!sidebarOpen && classes.hide) }
    />
    <ListItemSecondaryAction>
      <Typography
        variant="body2"
        className={
          selectedCategory === name ? classes.selected : classes.deselected
        }
      >
        <span className={ classes.counterNumber }>{ categoryCounter[ name ] }</span>
      </Typography>
    </ListItemSecondaryAction>
  </ListItem>
)

const CategoryList = props => {
  return (
    <List>
      {props.categories.map((value, index) => {
        return (
          <CategoryListItem
            { ...props }
            name={ value.name }
            id={ value.id }
            label={
              <FormattedMessage id={ value.id } defaultMessage={ value.label } />
            }
            key={ index }
          >
            {value.icon }
          </CategoryListItem>
        )
      }) }
    </List>
  )
}

export default withStyles(styles)(CategoryList)
