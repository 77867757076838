import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M22.2,4.7h-1.8V1.9H3.7v2.8H1.8C0.8,4.7,0,5.5,0,6.5v9.2c0,1,0.8,1.8,1.8,1.8h2.8v6.5h14.8v-6.5h2.8
			c1,0,1.8-0.8,1.8-1.8V6.5C24,5.5,23.2,4.7,22.2,4.7z M4.6,2.8h14.8v1.8H4.6V2.8z M18.5,23.2H5.5V12.1h12.9V23.2z M23.1,15.8
			c0,0.5-0.4,0.9-0.9,0.9h-2.8v-5.5H4.6v5.5H1.8c-0.5,0-0.9-0.4-0.9-0.9V6.5c0-0.5,0.4-0.9,0.9-0.9h20.3c0.5,0,0.9,0.3,0.9,0.8
			c0,0,0,0.1,0,0.1V15.8z"
    />
    <rect x="7.4" y="19.5" width="9.2" height="0.9" />
    <rect x="7.4" y="15.8" width="9.2" height="0.9" />
    <rect x="7.4" y="17.6" width="9.2" height="0.9" />
    <rect x="10.2" y="13.9" width="6.5" height="0.9" />
  </React.Fragment>,
  'PrintIcon'
)
