import React from 'react'
import { FormattedMessage } from 'react-intl'
import LoadingButton from '../../../../shared/Components/Buttons/LoadingButton'

export const UserRows = (classes, showInviteBox) => {
  return [
    {
      key: 'spacerLeft',
      hideMobile: false,
      hideTablet: false,
      hideRightPane: false,
      numeric: false,
      label: '',
      headClassName: 'tableCellSpacerMobile'
    },
    {
      key: 'user',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      label: <FormattedMessage id="cpSitesUser.user" defaultMessage="USER" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'role',
      hideMobile: true,
      hideTablet: true,
      numeric: false,
      label: <FormattedMessage id="cpSitesUser.role" defaultMessage="ROLE" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'lastLogin',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      label: <FormattedMessage id="cpSitesUser.lastLogin" defaultMessage="LAST LOGIN" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'siteAccess',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      label: <FormattedMessage id="cpSitesUser.siteAccess" defaultMessage="SITE ACCESS" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'add-user',
      hideMobile: true,
      hideTablet: true,
      numeric: false,
      label: (
        <LoadingButton
          id="usersList.showInvieUserModal"
          className={ classes.inviteButton }
          style={ { fontSize: 14 } }
          type="submit"
          color="primary"
          variant="outlined"
          disableRipple
          onClick={ () => showInviteBox() }
        >
          <FormattedMessage
            id="cpSitesUser.inviteUser"
            defaultMessage="INVITE USER"
          />
        </LoadingButton>
      ),
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'spacerRight',
      hideMobile: true,
      hideTablet: true,
      hideRightPane: false,
      numeric: false,
      label: '',
      headClassName: 'tableHeadCellMinWidth'
    }
  ]
}