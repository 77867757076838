import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import ReactHtmlParser from 'react-html-parser'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import { withStyles } from '@material-ui/core/styles/index'
import Paper from '@material-ui/core/Paper/index'
import Drawer from '@material-ui/core/Drawer/index'
import Typography from '@material-ui/core/Typography/index'
import Tabs from '@material-ui/core/Tabs/index'
import Tab from '@material-ui/core/Tab/index'
import Table from '@material-ui/core/Table/index'
import TableBody from '@material-ui/core/TableBody/index'
import TableCell from '@material-ui/core/TableCell/index'
import TableHead from '@material-ui/core/TableHead/index'
import TableRow from '@material-ui/core/TableRow/index'
import Card from '@material-ui/core/Card/index'
import CardActionArea from '@material-ui/core/CardActionArea/index'
import CardContent from '@material-ui/core/CardContent/index'
import CardMedia from '@material-ui/core/CardMedia/index'
import Modal from '@material-ui/core/Modal/index'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Utils from '../shared/duck/helpers/Utils'
import InProgressIcon from '../../assets/icons/InProgressIcon'
import PauseIcon from '../../assets/icons/PauseIcon'
import DangerIcon from '../../assets/icons/DangerIcon'
import PrintIcon from '../../assets/icons/PrintIcon'
import PlayIcon from '../../assets/icons/PlayIcon'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import EditIcon from '../../assets/icons/EditIcon'
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon'
import CheckmarkCircleIcon from '../../assets/icons/CheckmarkCircleIcon'
import CrossCircleIcon from '../../assets/icons/CrossCircleIcon'
import CloseIcon from '../../assets/icons/CloseIcon'
import ArrowUpIcon from '../../assets/icons/ArrowUpIcon'
import ArrowDownIcon from '../../assets/icons/ArrowDownIcon'
import LeftIcon from '../../assets/icons/LeftIcon'
import styles from '../shared/Styles/styles'
import LeftSidePane from '../shared/Components/SideBar/LeftSidePane'
import Notification from '../shared/Components/Messaging/Notification'
import Popover from '../shared/Components/Messaging/Popover'
import LoadingButton from '../shared/Components/Buttons/LoadingButton'
import ServiceContext from '../shared/Services/ServiceContext'
import InputComponent from './Components/FileUpload/InputComponent'
import ProgressBar from './Components/FileUpload/ProgressBar'
import { listRows } from './Components/ListRows'
import { JobsCategories } from './duck/helpers/JobsCategories'
import {
  getCustomerHfWfs,
  getCustomerCpJobDetailsByJob,
  getCustomerJobsIds,
  mutateAddAction,
  mutateCustomerCreateOrder,
  downloadJobDetailsImages,
  downloadSingleJobDetailsImage,
  getImageDetails,
  getCpCertificate
} from './duck/jobsActions'
import PermissionTypes from '../shared/duck/types/permissionTypes'
import PermissionsValidator from '../shared/duck/helpers/permissionsValidator'
import { NotificationTypes } from '../shared/duck/types/notificationTypes'

import { encryptData, createEnvelope, createPublicKey } from './duck/crypto/envelopeUtils'
import DialogSelect from './ChannelMapping/DlgColorChannelMapping'
import { getJobStatus } from './Status/JobStatusBuilder'

const AWS = require('aws-sdk')

let verificationLength = 28
const UPLOAD_FILE_TYPES = ".jpg, .pdf, .tif, .tiff, .ps, .xml, .proof"

class CpJobs extends React.Component {
  static contextType = ServiceContext

  constructor() {
    super()
    this.jobsCategories = new JobsCategories();
  }

  state = {
    sidebarOpen: true,
    selectedFilter: 'inQueue',
    tabValue: 0,
    currentJob: { status: '', id: '' },
    isOpen: false,
    image: '',
    jobDetail: [],
    isOpenRightPane: false,
    modalOpen: false,
    selectedImageIndex: 0,
    isResizing: false,
    lastDownX: 0,
    newWidth: { width: 234 },
    jobProps: '',
    isFileUploadModal: false,
    numberOfWorkFlows: [],
    newWorkFlowModal: false,
    workFlowName: '',
    currentColorProofSystem: '',
    currentWorkFlow: '',
    colorProofSystems: [
      { Label: 'CP 1', Value: 'cp_1' },
      { Label: 'CP 2', Value: 'cp_2' },
      {
        Label: 'CP 2',
        Value: 'cp_2'
      }
    ],
    filteredJobs: null,
    numberOfFileCopies: 1,
    filesToUpload: [],
    siteWorkflows: [],
    filesUploadProgress: [],
    selectedFile: null,
    selectedFileString: null,
    singleFileUploadStarted: false,
    notification: null
  }

  static getDerivedStateFromProps (nextProps, state) {
    if (nextProps.jobs) {
      const job = state.currentJob;
      const nextPropsJob = nextProps.jobs.find(next => next.id === job.id);
      if (nextPropsJob === undefined || nextPropsJob.status !== job.status) {
        state.isOpenRightPane = false;
      }
    }
    return null
  }

  componentDidUpdate (prevProps) {
    if (prevProps.jobs.length !== this.props.jobs.length)//job added/deleted
      this.handleSearchTerms()
    if (this.props.jobDetailsUpdated && prevProps.jobDetailsUpdated !== this.props.jobDetailsUpdated)//job modified
      this.handleSearchTerms()
    if (prevProps.filterChips !== this.props.filterChips)//filterchips modified
      this.handleSearchTerms()
    if (prevProps.liveSearchTerm && this.props.liveSearchTerm !== prevProps.liveSearchTerm)
      this.handleLiveSearch()
  }

  componentDidMount () {
    this.props.getCustomerHfWfs()
    
    document.addEventListener('mousemove', this.handleMousemove.bind(this))
    document.addEventListener('mouseup', this.handleMouseup.bind(this))

    let jobId = this.props.match.params.id;
    if (jobId) {

      let job = this.props.jobs.find(job => job.id === jobId)
      if (job) {
        this.changeRightPane(job)
      }
      else {
        this.setState({ isOpenRightPane: false, jobProps: this.props.jobs })
      }
    }
    else {
      this.setState({ isOpenRightPane: false, jobProps: this.props.jobs })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousemove', this.handleMousemove.bind(this))
    document.removeEventListener('mouseup', this.handleMouseup.bind(this))
  }

  handleSearchTerms () {
    const newFilteredJobs = this.applySearchTerms()
    this.setState({ filteredJobs: newFilteredJobs })
  }

  handleLiveSearch () {
    if (!this.props)
      return

    const jobs = this.applySearchTerms()
    const filteredJobs = jobs.filter(item => this.isJobMeetSearchTerm(item, this.props.liveSearchTerm))

    this.setState({ filteredJobs: filteredJobs })
  }

  applySearchTerms () {
    const page = this.context.navigationService.getLocation()
    const searchTerms = this.props.filterChips[ page ] ? this.props.filterChips[ page ].filter(t => t.isActive) : []

    return this.props.jobs.filter(item => searchTerms.every(searchTerm => this.isJobMeetSearchTerm(item, searchTerm.value)))
  }

  isJobMeetSearchTerm (job, searchTerm) {
    if (!searchTerm)
      return true

    return this.findJobAlias(job, searchTerm) ||
      job.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.site.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.printer.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.workflow.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.images.toLowerCase().includes(searchTerm.toLowerCase())
  }

  findJobAlias (job, searchTerm) {
    const site = this.props.sites.find(site => site.site === job.site && site.cpId === job.cpId)

    if (!site)
      return

    return site.alias && site.alias.toLowerCase().includes(searchTerm.toLowerCase())
  }

  handleMousedown = e => {
    this.setState({ isResizing: true, lastDownX: e.clientX })
  }

  handleMousemove = e => {
    // we don't want to do anything if we aren't resizing.
    if (!this.state.isResizing) {
      return
    }

    let offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft)
    let minWidth = 234
    let maxWidth = 600

    if (offsetRight > minWidth && offsetRight < maxWidth) {
      this.setState({ newWidth: { width: offsetRight } })
    }
    verificationLength = offsetRight / 10
  }

  handleMouseup = () => {
    this.setState({ isResizing: false })
  }

  handleListItemClick = filterName => {
    this.setState({ isOpen: false, isOpenRightPane: false, selectedFilter: filterName })
    this.handleSearchTerms()
  }

  toggleDrawer = () => {
    const { sidebarOpen } = this.state
    this.setState({ sidebarOpen: !sidebarOpen })
  }

  isJobStateInSelectedCategory = (state, selectedCategory) => {
    const category = this.jobsCategories.getCategoryStates(selectedCategory)
    return category.includes(state);
  }

  isJobStateInSelectedCategories = (state, selectedCategories) => {
    return selectedCategories.some(category => this.isJobStateInSelectedCategory(state, category));
  }

  isJobStateNestingAndQueueCategory = (jobState) => {
    if(jobState !== 'Nesting') {
      return false;
    }

    return  this.isJobStateInSelectedCategory(jobState, 'inQueue');
  }

  getNumberOfJobs = selectedFilter => {
    const { jobs } = this.props
    return (jobs || []).filter(job => this.isJobStateInSelectedCategory(job.state, selectedFilter)).length
  }

  filterJobCounter = () => ({
    inQueue: this.getNumberOfJobs('inQueue'),
    onHold: this.getNumberOfJobs('onHold'),
    inError: this.getNumberOfJobs('inError'),
    complete: this.getNumberOfJobs('complete')
  })

  handleDropZoneOpen () {
    let workFlows = this.props.hfwfs.map(value => {
      return {
        id: value.cpId,
        Name: value.WorkflowName,
        Value: value.id,
        StandardName: value.StandardName
      }
    })

    let sWf = workFlows.filter(f => f.id === this.props.sites[ 0 ].cpId)
    this.setState({
      isFileUploadModal: true,
      numberOfWorkFlows: workFlows,
      currentColorProofSystem: this.props ? this.props.sites[ 0 ] : '',
      siteWorkflows: sWf,
      currentWorkFlow: sWf.length === 0 ? '' : sWf[ 0 ]
    })
  }

  renderSidebar () {
    const { selectedFilter } = this.state
    const categories = [
      {
        name: 'inQueue',
        label: 'In Queue',
        id: 'categoryList.inQueue',
        icon: <InProgressIcon />
      },
      {
        name: 'onHold',
        label: 'On Hold',
        id: 'categoryList.onHold',
        icon: <PauseIcon />
      },
      {
        name: 'inError',
        label: 'In Error',
        id: 'categoryList.inError',
        icon: <DangerIcon />
      },
      {
        name: 'complete',
        label: 'Complete',
        id: 'categoryList.complete',
        icon: <PrintIcon />
      }
    ]

    return (
      <LeftSidePane
        onInputClick={ () => this.handleDropZoneOpen() }
        onCategoryClick={ this.handleListItemClick }
        classes={ this.props.classes }
        data={ {
          counter: this.filterJobCounter(),
          selectedFilter: selectedFilter,
          categories: categories,
          label: false,
          labelTitle: 'PROOF JOBS',
          labelID: 'cpJobs.proofJobs',
          fileUpload: this.props.sites && this.props.sites.length !== 0
        } }
      />
    )
  }

  async loadListJobDetailsFromStorage (job) {
    await this.props.downloadSingleJobDetailsImage({
      cpId: job.cpId,
      jobId: job.id,
      id: job.id
    })
    await this.props.getImageDetails(this.props.fileUrl)

    if (!this.props.imageDetails || this.props.imageDetails.length === 0)
      return

    let imageDetails = Utils.deepClone(this.props.imageDetails)
    const params = imageDetails.map(image => {
      return {
        cpId: job.cpId,
        jobId: job.id,
        id: image.ImageId
      }
    })
    await this.props.downloadJobDetailsImages(params)

    for (let i = 0; i < imageDetails.length; i++) {
      imageDetails[ i ].Url = this.props.jobDetailsImages[ i ]
    }

    this.saveJobDetailsImages(job, imageDetails)
    this.context.trackingService.trackEvent('Job Right Pane', 'Job Right Pane Open/Change', '1', 1)
  }

  changeRightPane = async (job) => {
    await this.props.getCustomerCpJobDetailsByJob(job.id)

    let jobDetails = Utils.deepClone(this.props.jobDetails)
    if (jobDetails.length === 0 || (jobDetails.length > 0 && jobDetails.find(item => !item.Name))) {
      await this.loadListJobDetailsFromStorage(job)
    }
    else {
      await this.props.downloadJobDetailsImages(jobDetails)

      jobDetails.forEach(item => item.Url = this.props.jobDetailsImages.find(url => url.includes(item.id)))
      this.saveJobDetailsImages(job, jobDetails)
    }
  }

  saveJobDetailsImages (job, imageDetails) {
    this.setState({
      currentJob: job,
      isOpenRightPane: true,
      selectedImageIndex: 0,
      jobDetail: { ImageDetails: imageDetails }
    })
  }

  rgbToHex (r, g, b) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
  }

  rightImage () {
    this.context.trackingService.trackEvent(
      'Job Right Pane Images',
      'Click to See Next Job',
      '1',
      1
    )
    const { jobDetail, selectedImageIndex } = this.state
    const imageCount = jobDetail.ImageDetails.length
    let nextImage = selectedImageIndex + 1
    if (nextImage === imageCount) {
      nextImage = 0
    }
    this.setState({ selectedImageIndex: nextImage })
  }

  leftImage () {
    this.context.trackingService.trackEvent(
      'Job Right Pane Images',
      'Click to See Previous Job',
      '1',
      1
    )
    const { jobDetail, selectedImageIndex } = this.state
    const imageCount = jobDetail.ImageDetails.length
    let preImage = selectedImageIndex - 1
    if (selectedImageIndex === 0) {
      preImage = imageCount - 1
    }
    this.setState({ selectedImageIndex: preImage })
  }

  verificationStripSpacer (value) {
    let number = parseFloat(value).toFixed(2)
    if (number.length === 4) {
      let formattedNumber = '0' + number
      return formattedNumber
    }
    return number
  }

  isSiteForJobOnline (job) {
    let isOnline = false
    this.props.sites.forEach((site) => {
      if (job.cpId === site.cpId && job.site === site.site) {
        isOnline = Utils.isSiteOnline(site)
      }
    })
    return isOnline
  }

  sendRemoteAction (actionType, job) {
    if (!this.context.featureFlagService.areJobActionsSupported(job, this.props.sites)) {
      this.context.notificationService.showNotification(this, NotificationTypes.Warning, this.props.intl.formatMessage({ id: 'cpJobs.jobActionsUnsupported' }))
      return
    }

    this.props.mutateAddAction({
      cpId: job.cpId,
      action: actionType,
      actionParams: [ {
        name: 'id',
        value: job.id
      } ]
    })
  }

  sendNotification(notificationType, messageId) {
    this.context.notificationService.showNotification(
      this,
      notificationType,
      this.props.intl.formatMessage({ id: `${messageId}` }),
    )
  }

  remotePrintJob (job) {
    this.context.trackingService.trackEvent('Job Right Pane Action', 'Print Job', '1', 1)

    // Notification for user about job
    this.context.notificationService.showNotification(
      this,
      NotificationTypes.Success,
      this.props.intl.formatMessage({ id: 'cpJobActions.moveToQueue' })
    )
    this.sendRemoteAction('PrintJob', job)
  }

  remoteHoldJob (job) {
    this.context.trackingService.trackEvent('Job Right Pane Action', 'Pause Job', '1', 1)
    // Notification for user about job
    this.context.notificationService.showNotification(
      this,
      NotificationTypes.Success,
      this.props.intl.formatMessage({ id: 'cpJobActions.moveToHold' }))
    this.sendRemoteAction('HoldJob', job)
  }

  remoteDeleteJob (job) {
    this.context.trackingService.trackEvent(
      'Job Right Pane Action',
      'Delete Job',
      '1',
      1
    )
    // Notification for user about job
    this.context.notificationService.showNotification(
      this,
      NotificationTypes.Success,
      this.props.intl.formatMessage({
        id: 'cpJobActions.deleteJob'
      })
    )
    this.sendRemoteAction('DeleteJob', job)
  }

  removeDelta (RuleName, AnalysisType) {
    let RuleNames = RuleName + ' ' + AnalysisType
    RuleNames = RuleNames.padEnd('80', '.')
    RuleNames = RuleNames.replace(/_dE/g, '')
    RuleNames = RuleNames.replace(/dE00/g, '&#916;E00')
    RuleNames = RuleNames.replace(/dCh/g, '&#916;Ch')
    RuleNames = RuleNames.replace(/dH/g, '&#916;H')
    RuleNames = RuleNames.replace(/DeltaE2000/g, '&#916;E00')
    return <span>{ ReactHtmlParser(RuleNames) }</span>
  }

  setNotification = notification => {
    this.setState({ notification })
  }

  isJobFoundInDocDb = (jobDetail, selectedImageIndex) => {
    return this.props.dbJobIds.indexOf(`${jobDetail.ImageDetails[ selectedImageIndex ].jobId}`) !== -1;
  }

  rightPaneData = (classes, currentJob, jobDetail, selectedImageIndex) => {
    const { modalOpen } = this.state
    const validator = new PermissionsValidator(this.props.permissions)
    const isJobFoundInDocDb = this.isJobFoundInDocDb(jobDetail, selectedImageIndex);
    const isSiteForJobOnline = this.isSiteForJobOnline(currentJob);

    if (verificationLength < 20) {
      verificationLength = 20
    }

    const popoverText = (isSiteForJobOnline, isJobFoundInDocDb) => {
      if(!isSiteForJobOnline) {
        return ( currentJob.site +
          ' ' +
          this.props.intl.formatMessage({ id: 'cpJobs.siteIsOffline' })
        )
      }
      else if (!isJobFoundInDocDb) {
        return this.props.intl.formatMessage({ id: 'cpJobs.jobIsNotAvailable' })
      }

      return "";
    }

    return (
      <React.Fragment>
        { currentJob && validator.hasUserPermissionForCp(currentJob.cpId, PermissionTypes.ExecuteActions) ? (
          <p className={ classNames(classes.jobActions, classes.noMarginTop) }>
            {
              <DialogSelect
                classes={ classes }
                jobsCategories= { this.jobsCategories }
                imageDetail= { jobDetail.ImageDetails[ selectedImageIndex ] }
                currentJobState={ currentJob.state }
                currentCpId={ currentJob.cpId }
                isEnabled= { isSiteForJobOnline && isJobFoundInDocDb && this.isJobStateInSelectedCategories(currentJob.state, ['onHold', 'inError']) }
                popOverText = { popoverText(isSiteForJobOnline, isJobFoundInDocDb) }
                sendNotification = { this.sendNotification.bind(this) }  />
            }
            { this.isSiteForJobOnline(currentJob) ? (
              <span
                  className={ classNames(classes.jobActionSpace, {
                    [ classes.normalClass ]: this.isJobStateInSelectedCategories(currentJob.state, [ 'inQueue' ]),                  
                    [ classes.disabled ]: this.isJobStateInSelectedCategories(currentJob.state, ['onHold', 'inError', 'complete']),
                  }) }
                onClick={ (event) => {
                  event.stopPropagation();
                  this.remoteHoldJob(currentJob)
                } }
              >
                <PauseIcon className={ classes.noPointerEvent } />
              </span>
            ) : (
              <Popover
                content={
                  currentJob.site +
                  ' ' +
                  this.props.intl.formatMessage({ id: 'cpJobs.siteIsOffline' })
                }
                distance="5"
                position="left"
              >
                <span
                  className={ classNames(
                    classes.jobActionSpace,
                    classes.disabled
                  ) }
                >
                  <PauseIcon className={ classes.noPointerEvent } />
                </span>
              </Popover>
            ) }

            { this.isSiteForJobOnline(currentJob) ? (
              <span
                className={ classNames(classes.jobActionSpace, {
                  // We need to enable the Play Icon if job is in Queue category and the job is in Nesting state
                  // to allow users to print the job without waiting for nesting timeout in CP.
                  [ classes.normalClass ]: this.isJobStateInSelectedCategories(currentJob.state, ['onHold', 'inError', 'complete']) ||
                                            this.isJobStateNestingAndQueueCategory(currentJob.state),
                  [ classes.disabled ]: this.isJobStateInSelectedCategories(currentJob.state, [ 'inQueue' ]) &&
                                           !this.isJobStateNestingAndQueueCategory(currentJob.state),
                }) }
                onClick={ (event) => {
                  event.stopPropagation();
                  this.remotePrintJob(currentJob)
                } }
              >
                <PlayIcon className={ classes.noPointerEvent } />
              </span>
            ) : (
              <Popover
                content={
                  currentJob.site +
                  ' ' +
                  this.props.intl.formatMessage({ id: 'cpJobs.siteIsOffline' })
                }
                position="left"
                distance="5"
              >
                <span
                  className={ classNames(
                    classes.jobActionSpace,
                    classes.disabled
                  ) }
                >
                  <PlayIcon />
                </span>
              </Popover>
            ) }

            { this.isSiteForJobOnline(currentJob) ? (
              <span
                className={ classNames(
                  classes.jobActionSpace,
                  classes.normalClass
                ) }
                onClick={ (event) => {
                  event.stopPropagation();
                  this.remoteDeleteJob(currentJob)
                } }
              >
                <DeleteIcon className={ classes.noPointerEvent } />
              </span>
            ) : (
              <Popover
                content={
                  currentJob.site +
                  ' ' +
                  this.props.intl.formatMessage({ id: 'cpJobs.siteIsOffline' })
                }
                position="left"
                distance="5"
              >
                <span
                  className={ classNames(
                    classes.jobActionSpace,
                    classes.disabled
                  ) }
                >
                  <span>
                    <DeleteIcon />
                  </span>
                </span>
              </Popover>
            ) }
          </p>
        ) : (
          <span className={ classes.marginTop } />
        ) }

        <span className={ classNames(classes.showMobile, classes.showTablet) }>
          <Typography className={ classes.imageCount }>
            <FormattedMessage id="rightPane.image" defaultMessage="Image" />
            &nbsp;{ selectedImageIndex + 1 } &nbsp;
            <FormattedMessage id="rightPane.of" defaultMessage="of" /> &nbsp;
            { jobDetail.ImageDetails.length }
          </Typography>
        </span>

        <Modal
          open={ modalOpen }
          onClose={ () => {
            this.setState({ modalOpen: false })
          } }
          className={ classes.zIndex }
        >
          <div className={ classes.modalStyle }>
            <div
              className={ classes.iconModalPane }
              onClick={ (event) => {
                event.stopPropagation();
                this.remoteHoldJob(currentJob)
                this.setState({ modalOpen: false })
              } }
            >
              <PauseIcon className={ classes.tabletIconPosition } />
              <Typography className={ classes.paneDrawerValues }>
                <FormattedMessage id="rightPane.stop" defaultMessage="STOP" />
              </Typography>
            </div>

            <div
              className={ classes.iconModalPane }
              onClick={ (event) => {
                event.stopPropagation();
                this.remotePrintJob(currentJob)
                this.setState({ modalOpen: false })
              } }
            >
              <PlayIcon className={ classes.tabletIconPosition } />
              <Typography className={ classes.paneDrawerValues }>
                <FormattedMessage id="rightPane.play" defaultMessage="PLAY" />
              </Typography>
            </div>

            <div
              className={ classes.iconModalPane }
              onClick={ (event) => {
                event.stopPropagation();
                this.remoteDeleteJob(currentJob)
                this.setState({ modalOpen: false })
              } }
            >
              <DeleteIcon className={ classes.tabletIconPosition } />
              <Typography className={ classNames(classes.paneDrawerValues) }>
                <FormattedMessage
                  id="rightPane.delete"
                  defaultMessage="DELETE"
                />
              </Typography>
            </div>
          </div>
        </Modal>
        {/* onClick={() => { this.setState({ isOpen: true }) }} */ }

        <span>
          <Typography className={ classes.imageCountTab }>
            <FormattedMessage id="rightPane.image" defaultMessage="Image" />
            &nbsp;{ selectedImageIndex + 1 } &nbsp;
            <FormattedMessage id="rightPane.of" defaultMessage="of" /> &nbsp;
            { jobDetail.ImageDetails.length }
          </Typography>
        </span>
        <div className={ classes.row }>
          { jobDetail.ImageDetails.length !== 1 ? (
            <div className={ classes.leftColumn }>
              <span
                className={ classes.hoverButtonLeft }
                onClick={ this.leftImage.bind(this) }
              >
                <LeftIcon className={ classNames(classes.leftIcon) } />
              </span>
            </div>
          ) : null }
          <div
            className={ classNames(classes.cardDiv, {
              [ classes.adjustAlign ]: jobDetail.ImageDetails.length === 1
            }) }
          >
            { jobDetail.ImageDetails.length !== 1 ? (
              <div className={ classes.mobileImageSlide }>
                <span className={ classes.imageMarginMobile }>
                  <LeftIcon
                    className={ classes.leftImageSlideMobile }
                    onClick={ this.leftImage.bind(this) }
                  />
                  <span>
                    <Typography className={ classes.imageCountMobile }>
                      <FormattedMessage
                        id="rightPane.image"
                        defaultMessage="Image"
                      />
                      &nbsp;{ selectedImageIndex + 1 } &nbsp;
                      <FormattedMessage
                        id="rightPane.of"
                        defaultMessage="of"
                      />{ ' ' }
                      &nbsp;{ jobDetail.ImageDetails.length }
                    </Typography>
                  </span>
                  <LeftIcon
                    className={ classes.rightImageSlideMobile }
                    onClick={ this.rightImage.bind(this) }
                  />
                </span>
              </div>
            ) : null }

            <Card
              className={ classNames(
                classes.card,
                {
                  [ classes.cardWidth ]: jobDetail.ImageDetails.length > 1
                },
                {
                  [ classes.adjustCard ]: jobDetail.ImageDetails.length === 1
                }
              ) }
            >
              <CardActionArea>
                <CardContent>
                  <CardMedia
                    style={ { height: this.state.newWidth.width / 2 } }
                    className={ classNames(classes.media, classes.showWeb) }
                    title=""
                    image={ jobDetail.ImageDetails[ selectedImageIndex ].Url || '' }
                  />
                  <CardMedia
                    className={ classNames(classes.media, classes.showMobile) }
                    title=""
                    image={ jobDetail.ImageDetails[ selectedImageIndex ].Url || '' }
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          { jobDetail.ImageDetails.length !== 1 ? (
            <div className={ classes.rightColumn }>
              <span
                className={ classes.hoverButtonRight }
                onClick={ this.rightImage.bind(this) }
              >
                <LeftIcon
                  className={ classNames(classes.leftIcon, classes.rightIcon) }
                />
              </span>
            </div>
          ) : null }
        </div>

        <Typography className={ classNames(classes.drawerTitles) }>
          <FormattedMessage
            id="rightPane.imageName"
            defaultMessage="IMAGE NAME"
          />
        </Typography>

        <Popover
          content={ jobDetail.ImageDetails[ selectedImageIndex ].ImageName || jobDetail.ImageDetails[ selectedImageIndex ].Name }
          position={ 'left' }
          distance="-17"
        >
          <span>
            <Typography
              className={ classNames(classes.drawerValues, classes.ellipsis) }
            >
              { jobDetail.ImageDetails[ selectedImageIndex ].ImageName || jobDetail.ImageDetails[ selectedImageIndex ].Name }
            </Typography>
          </span>
        </Popover>
        <Typography className={ classes.drawerTitles }>
          <FormattedMessage
            id="rightPane.proofStandard"
            defaultMessage="PROOF STANDARD"
          />
        </Typography>
        <Typography className={ classes.drawerValues }>
          { jobDetail.ImageDetails[ selectedImageIndex ].ProofStandard }
        </Typography>
        <Typography className={ classes.drawerTitles }>
          <FormattedMessage id="rightPane.workflow" defaultMessage="WORKFLOW" />
        </Typography>
        <Typography className={ classes.drawerValues }>
          { jobDetail.ImageDetails[ selectedImageIndex ].Workflow
            ? jobDetail.ImageDetails[ selectedImageIndex ].Workflow
            : '-' }
        </Typography>
        <Typography className={ classes.drawerTitles }>
          <FormattedMessage
            id="rightPane.dimensions"
            defaultMessage="DIMENSIONS"
          />
        </Typography>

        <Popover
          content={ jobDetail.ImageDetails[ selectedImageIndex ].Dimension }
          distance="-17"
          position="left-end"
        >
          <span>
            <Typography
              className={ classNames(classes.drawerValues, classes.ellipsis) }
            >
              { jobDetail.ImageDetails[ selectedImageIndex ].Dimension }
              {/* {jobDetail.ImageDetails[selectedImageIndex].Dimension} */ }
            </Typography>
          </span>
        </Popover>
        <Typography
          className={ classNames(classes.drawerTitles, classes.marginTop) }
        >
          <FormattedMessage id="rightPane.colors" defaultMessage="COLORS" />
        </Typography>
        <Typography className={ classes.colorPosition }>
          { jobDetail.ImageDetails[ selectedImageIndex ].Colors.map(
            (color, index) => {
              let counter = index + 1
              return (
                <span className={ classNames(classes.flexDirection ) } key={ index }>
                  <span className={ classNames( classes.colorSmallBoxesLeftSide )}
                    key={ index + Math.random() }
                    style={ {
                      borderTop:
                        '14px solid ' +
                        this.rgbToHex(color.R, color.G, color.B),
                    } }
                  />
                  <span className={ classNames( classes.colorSmallBoxesRightSide )}
                    key={ index }
                    style={ {
                      borderBottom:
                        '14px solid ' +
                        this.rgbToHex(color.R, color.G, color.B),
                    } }
                  />
                  { counter !== 0 && counter % 4 === 0 ? <br /> : null }
                </span>
              )
            }
          ) }
        </Typography>
        <Typography
          className={ classNames(classes.drawerTitles, classes.noMarginTop) }
        >
          <FormattedMessage
            id="rightPane.fileFormat"
            defaultMessage="FILE FORMAT"
          />
        </Typography>
        <Typography className={ classNames(classes.drawerValues) }>
          { jobDetail.ImageDetails[ selectedImageIndex ].FileFormat }
        </Typography>
        <div>
          <hr className={ classNames(classes.separator) } />
        </div>
        <Typography
          className={ classNames(classes.drawerTitles, classes.noDisplay) }
        >
          <FormattedMessage
            id="rightPane.statusInfo"
            defaultMessage="STATUS INFO"
          />
        </Typography>
        <Typography
          className={ classNames(classes.drawerValues, classes.noDisplay) }
        >{ getJobStatus({
              job: currentJob,
              isJobStateInSelectedCategory: this.isJobStateInSelectedCategory,
              translationService: this.context.translationService
            }) }
        </Typography>
        { jobDetail.ImageDetails[ selectedImageIndex ].VerficationResults && jobDetail.ImageDetails[ selectedImageIndex ].VerficationResults
          .length !== 0 ? (
          <React.Fragment>
            <Typography className={ classes.rightPaneDivider } />
            <span className={ classes.verificationStyle }>
              <Typography
                className={ classNames(
                  classes.drawerTitles,
                  classes.rightPaneTabLandscape
                ) }
              >
                <FormattedMessage
                  id="rightPane.verification"
                  defaultMessage="VERIFICATION"
                />
              </Typography>
              { jobDetail.ImageDetails[
                selectedImageIndex
              ].VerficationResults.map((verification, imageIndex) => {
                return (
                  <span
                    key={ imageIndex }
                    className={ classNames(classes.tableCell, {
                      [ classes.verificationHide ]: imageIndex === 1,
                      [ classes.verificationHide ]: imageIndex === 1
                    }) }
                  >
                    <Popover
                      content={ verification.StripName }
                      placement="left"
                      distance="-17"
                    >
                      <span>
                        <Typography
                          className={ classNames(
                            classes.drawerValues,
                            classes.marginBottom,
                            classes.marginTop,
                            classes.ellipsis
                          ) }
                        >
                          { verification.StripName }
                        </Typography>
                      </span>
                    </Popover>
                    { verification.Rules !== null
                      ? verification.Rules.map((rule, ruleIndex) => {
                        return (
                          <Typography
                            className={ classNames(
                              classes.drawerValues,
                              classes.smallerFont,
                              classes.verificationStripMargin
                            ) }
                            key={ ruleIndex }
                          >
                            <span className={ classes.verificationRules }>
                              { this.removeDelta(
                                rule.RuleName,
                                rule.AnalysisType
                              ) }
                            </span>
                            <span>
                              { this.verificationStripSpacer(
                                rule.AnalysisValue
                              ) }

                              { rule.AnalysisResult === 'Passed' ? (
                                <CheckmarkCircleIcon
                                  className={ classes.passed }
                                />
                              ) : (
                                <CrossCircleIcon className={ classes.failed } />
                              ) }
                            </span>
                          </Typography>
                        )
                      })
                      : '' }
                  </span>
                )
              }) }
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment />
        ) }

        <span
          className={ classNames(classes.editButton) }
          onClick={ () => {
            this.setState({ modalOpen: true })
          } }
        >
          <EditIcon className={ classes.pencilIcon } />
        </span>
      </React.Fragment>
    )
  }

  renderRightPane () {
    const { classes, jobs } = this.props
    const {
      selectedFilter,
      jobDetail,
      currentJob,
      selectedImageIndex
    } = this.state
    const filterStates = this.jobsCategories.getCategoryStates(selectedFilter)
    const filteredAndSortedJobs = (jobs || [])
      .filter(job => filterStates.includes(job.state))
      .sort((a, b) => (a.created < b.created ? 1 : -1))
    if (filteredAndSortedJobs.length === 0) {
      return null
    }
    return (
      <React.Fragment>
        <Drawer
          variant="permanent"
          align="center"
          classes={ {
            paper: classNames(
              classes.drawerPaperTablet,
              classes.rightPaneDrawer
            )
          } }
        >
          { this.rightPaneData(
            classes,
            currentJob,
            jobDetail,
            selectedImageIndex
          ) }
        </Drawer>
        <div className={ classes.showRightPaneMobile }>
          <div className={ classes.mobilePaneHeader }>
            <div className={ classNames(classes.row) }
              onClick={ () => {
                this.setState({ isOpenRightPane: false })
                this.context.trackingService.trackEvent(
                  'Job Right Pane',
                  'Job Right Pane Close',
                  '1',
                  1
                )
              } }
            >
              <div className={ classes.backButtonPane }>
                <ArrowLeftIcon />
                <Typography className={ classes.rightPaneTitle }>
                  { currentJob.name }
                </Typography>
              </div>
            </div>
          </div>
          <div className={ classes.rightPaneMobileDataContainer }>
            <div className={ classes.rightPaneMobileDataCol }>
              { this.rightPaneData(
                classes,
                currentJob,
                jobDetail,
                selectedImageIndex
              ) }
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  handleMobileTab = (event, value) => {
    let currentScreen
    value === 0
      ? (currentScreen = 'inQueue')
      : value === 1
        ? (currentScreen = 'onHold')
        : value === 2
          ? (currentScreen = 'inError')
          : (currentScreen = 'complete')
    this.setState({
      tabValue: value,
      selectedFilter: currentScreen,
      isOpenRightPane: false
    })
  }

  renderMobileHeader () {
    const { classes } = this.props
    let currentScreen = this.state.selectedFilter
    const jobNumbers = this.filterJobCounter()[ currentScreen ]
    return (
      <Typography
        variant="h6"
        align="left"
        color="textPrimary"
        className={ classNames(
          classes.navigationTitle,
          classes.navigationTitleMobile
        ) }
      >
        <FormattedMessage id="cpJobs.proofJobs" defaultMessage="PROOF JOBS" />
        <FormattedMessage
          id={ `cpJobs.${currentScreen}` }
          defaultMessage={ currentScreen.toUpperCase() }
        />
        ({ jobNumbers })
      </Typography>
    )
  }

  renderTabs () {
    const { classes } = this.props
    return (
      <Paper
        square
        className={ classNames(classes.tabPaper, {
          // [classes.noDisplay]: this.state.isOpenRightPane
        }) }
      >
        <Tabs
          value={ this.state.tabValue }
          onChange={ this.handleMobileTab }
          classes={ {
            indicator: classes.indicator
          } }
          textColor="primary"
          centered
          className={ classes.tabs }
        >
          <Tab
            id="inQueueTab"
            className={ classes.tabOptions }
            icon={ <InProgressIcon /> }
            label={
              <FormattedMessage id="cpJobs.inQueue" defaultMessage="In Queue" />
            }
          />
          <Tab
            id="onHoldTab"
            className={ classes.tabOptions }
            icon={ <PauseIcon /> }
            label={
              <FormattedMessage id="cpJobs.onHold" defaultMessage="On Hold" />
            }
          />
          <Tab
            id="inErrorTab"
            className={ classes.tabOptions }
            icon={ <DangerIcon /> }
            label={
              <FormattedMessage id="cpJobs.inError" defaultMessage="In Error" />
            }
          />
          <Tab
            id="completeTab"
            className={ classes.tabOptions }
            icon={ <PrintIcon /> }
            label={
              <FormattedMessage
                id="cpJobs.complete"
                defaultMessage="Complete"
              />
            }
          />
        </Tabs>
      </Paper>
    )
  }

  renderJob = (job, rows) => {
    const { classes } = this.props
    const { currentJob, isOpenRightPane } = this.state

    return (
      <TableRow
        key={ job.id }
        className={ classNames(classes.hoverRow, {
          [ classes.activeJob ]: job.id === currentJob.id && isOpenRightPane
        }) }
      >
        { rows.map(row => (
          <TableCell
            key={ `${job.id}-${row.key}` }
            className={ classNames(classes.tableCell, {
              [ classes.tableCellHideMobile ]: row.hideMobile,
              [ classes.tableCellHideTablet ]: row.hideTablet,
              [ classes.tableCellHideRightPaneLandscape ]: row.hideRightPaneTabLandscape && isOpenRightPane,
              [ classes.tableCellHideRightPane ]: row.hideRightPane && isOpenRightPane,
              [ classes.tableCellSpacer ]: row.key === 'spacerLeft' || row.key === 'spacerRight',
              [ classes.tableHeadCellState ]: row.key === 'state',
              [ classes.tableValueAdjustStatus ]: row.key === 'state'
            }) }
            onClick={ this.changeRightPane.bind(this, job) }
          >
            { row.customTemplate && row.customTemplate(job) }
            { !row.customTemplate && job[ row.key ] }
          </TableCell>
        )) }
      </TableRow>
    )
  }

  renderCpJobs () {
    if (!this.props.jobs)
      return

    const { classes, jobs, sites } = this.props
    const { selectedFilter, isOpenRightPane, notification } = this.state
    const filteredJobs = this.state.filteredJobs || jobs
    const filterStates = this.jobsCategories.getCategoryStates(selectedFilter)
    const filteredAndSortedJobs = (filteredJobs || [])
      .filter(job => filterStates.includes(job.state))
      .sort((a, b) => (a.created < b.created ? 1 : -1))
    const rows = listRows(classes, sites)

    return (
      <div className={ classes.root }>
        { this.renderSidebar() }
        <Paper square className={ classes.mainPaper }>
          { this.renderMobileHeader() }
          <Table classes={ { root: classes.tableHeadRoot } }
            className={ classNames(classes.table, {
              [ classes.tableTabLandscape ]: isOpenRightPane
            }) }
          >
            <TableHead >
              <TableRow className={ classNames(classes.tableHeadRow) }>
                { rows.map(row => (
                  <TableCell
                    className={ classNames(classes[ row.headClassName ], {
                      [ classes.tableCellHideMobile ]: row.hideMobile,
                      [ classes.tableCellHideTablet ]: row.hideTablet,
                      [ classes.tableCellHideRightPaneLandscape ]: row.hideRightPaneTabLandscape && isOpenRightPane,
                      [ classes.tableCellSpacer ]: row.key === 'spacerLeft' || row.key === 'spacerRight',
                      [ classes.tableCellHideRightPane ]: row.hideRightPane && isOpenRightPane,
                      [ classes.tableHeadAdjustStatus ]: row.key === 'state'
                    }) }
                    key={ row.key }
                    align={ row.numeric ? 'right' : 'left' }
                  >
                    { row.label }
                  </TableCell>
                )) }
              </TableRow>
            </TableHead>
            <TableBody>
              { filteredAndSortedJobs.length ? (
                filteredAndSortedJobs.map(job => this.renderJob(job, rows))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={ 1 }
                    className={ classes.tableCellSpacerMobile }
                  />
                  <TableCell
                    colSpan={ rows.length - 2 }
                    className={ classes.tableCell }
                  >
                    <span className={ classes.noJobs }>
                      <FormattedMessage
                        id="cpJobs.noJobs"
                        defaultMessage="No Jobs"
                      />
                    </span>
                  </TableCell>
                  <TableCell
                    colSpan={ 1 }
                    className={ classes.tableCellSpacerMobile }
                  />
                </TableRow>
              ) }
            </TableBody>
          </Table>
        </Paper>
        <Notification
          setNotification={ this.setNotification }
          notification={ notification }
          services={ this.context }
        />
        { isOpenRightPane ? this.renderRightPane() : null }
        { this.renderTabs() }
      </div>
    )
  }

  convertBytesToMb (bytes) {
    return (bytes / 1024) / 1024
  }

  validateUploadFilesSize (files) {
    if (files.length > 10) {
      this.context.notificationService.showNotification(this, NotificationTypes.Error, this.context.translationService.tr("cpJobs.filesCountError"), '3col', true)
      this.clearUploadedFiles(files)
      return false
    }

    let totalBytes = 0
    files.forEach(f => totalBytes += f.meta.size)

    let totalMb = this.convertBytesToMb(totalBytes)
    if (totalMb > 500) {
      this.context.notificationService.showNotification(this, NotificationTypes.Error, this.context.translationService.tr("cpJobs.bulkSizeError"), '3col', true)
      this.clearUploadedFiles(files)
      return false
    }

    return true
  }

  validateUploadFiles (cpWfHfId, fileWithMeta) {
    //reject any file if the upload in process
    if (this.state.filesToUpload &&
      this.state.filesToUpload.length !== 0 &&
      this.state.filesToUpload.find(f => f.cpWfHfId === cpWfHfId)) {
      fileWithMeta.remove()
      return true
    }

    return false
  }

  async handleChangeStatus (workflow, fileWithMeta, status, fileWithMetaArr) {
    this.context.trackingService.trackEvent('Dropzone', 'Upload Files Through the Dropzone', '1', 1)

    this.setState({
      currentColorProofSystem: this.props.sites.find(s => s.cpId === workflow.id)
    })

    if (fileWithMeta.meta.status === "error_file_size") {
      this.context.notificationService.showNotification(this, NotificationTypes.Error,
        `"${fileWithMeta.meta.name}"` + this.context.translationService.tr("cpJobs.singleFileSizeError"), '3col', true)

      fileWithMeta.remove()
      return
    }

    //if any of the files != "done" - do nothing
    //otherwise - create customerCreateOrderInput and call customerCreateOrder
    if (status !== 'done' || fileWithMetaArr.find(f => f.meta.status !== 'done'))
      return

    if (!this.validateUploadFilesSize(fileWithMetaArr))
      return

    let keyData = null;
    if (this.context.featureFlagService.isFilesEncryptionSupported(this.state.currentColorProofSystem)) {
      await this.props.getCpCertificate(workflow.id)
      keyData = await createPublicKey()
    }

    const filesArray = this.findUniqueFiles(workflow.Value, fileWithMetaArr)
    if (!filesArray)
      return

    await this.prepareFilesToUpload(workflow, filesArray, keyData)
    await this.getCustomerOrderUploadInfo()

    if (this.props.uploadInfo.message) {
      this.context.notificationService.showNotification(this, NotificationTypes.Error, this.props.uploadInfo.message, '3col', true)
      this.clearUploadedFiles(fileWithMetaArr)

      return
    }

    await this.processFiles(workflow.Value, this.props.uploadInfo.files, filesArray, keyData)
  }

  clearUploadedFiles (fileWithMetaArr) {
    let copy = fileWithMetaArr.slice()
    copy.forEach(f => f.remove())
  }

  findUniqueFiles (wfHfId, fileWithMetaArr) {
    let uniqueArray = []
    let statefilesToUpload = this.state.filesToUpload

    fileWithMetaArr.forEach(e => {
      if (statefilesToUpload.length === 0)
        uniqueArray.push(e)

      statefilesToUpload.forEach(fileToUpload => {
        if (fileToUpload.cpWfHfId === wfHfId && fileToUpload.files.find(f =>
          f.FileName !== e.meta.name &&
          f.FileSize !== e.meta.size &&
          f.FileType !== e.meta.type))
          e.remove() //clear duplicate file
        else
          uniqueArray.push(e)
      })
    })

    return uniqueArray
  }

  async processFiles (wfHfId, filesInfo, fileWithMetaArr, keyData) {

    let promises = fileWithMetaArr.map(async fileWithMeta => {

      const isEncrypted = this.state.filesToUpload[ 0 ].encryptionCertId && keyData;

      let fileInfo = filesInfo.find(info => {
        const name = isEncrypted ? fileWithMeta.file.name + '.enc' : fileWithMeta.file.name
        return info.fileMetaData.FileName === name
      })

      const base64String = await this.createBase64String(fileWithMeta.file)

      let fileBuffer;
      if (isEncrypted) {
        fileBuffer = await encryptData(keyData, fileWithMeta.file)
      }
      else {
        fileBuffer = this.createFileBuffer(base64String);
      }

      let uploadProgress = this.state.filesUploadProgress.slice()
      uploadProgress.push({ Name: fileWithMeta.file.name, percent: 0 })

      this.setState({ filesUploadProgress: uploadProgress })

      let result = await this.uploadMultipartFile(fileInfo, fileBuffer)

      return result;
    })

    //wait untill all files are uploaded
    await Promise.all(promises).then(() => {
      //clear dropzone after 3 seconds
      setTimeout(() => {
        this.clearUploadedFiles(fileWithMetaArr)
        //clean up files for the certain dropzone only
        this.setState({ filesToUpload: this.state.filesToUpload.map(f => f.cpWfHfId === wfHfId) })
      }, 3000)
    })
  }

  async uploadMultipartFile (fileData, file) {
    const s3 = new AWS.S3({
      accessKeyId: fileData.multipart.accessKeyId,
      secretAccessKey: fileData.multipart.secretAccessKey,
      sessionToken: fileData.multipart.sessionToken,
      region: "eu-central-1"
    })

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: fileData.fileMetaData.bucket,
        Key: fileData.fileMetaData.key,
        Body: file
      },
      partSize: 1024 * 1024 * 5, // This is also the minimum number of the chunk size
      service: s3
    }).on('httpUploadProgress', evt => {
      let percent = evt.loaded / evt.total * 100
      let fileParts = evt.key.split('/')
      let fileName = fileParts[ fileParts.length - 1 ]
      let uploadProgress = this.state.filesUploadProgress.map(f => {
        if (f.Name + '.enc' === fileName || f.Name === fileName)
          f.percent = percent

        return f
      })

      this.setState({ filesUploadProgress: uploadProgress })
      console.log('Multipart Upload Progress:', evt.loaded, '/', evt.total, " percent: ", percent, '---', evt)
    })

    return await upload
      .promise()
      .catch(err => {
        this.context.notificationService.showNotification(this, NotificationTypes.Error, err.message, '3col', true)
        if (process.env.REACT_APP_STAGE !== 'Prod') {
          console.log('S3 Error: ', err)
        }
      })
  }

  async getCustomerOrderUploadInfo () {
    const promises = this.state.filesToUpload.map(async orderInput => await this.props.mutateCustomerCreateOrder(orderInput))
    await Promise.all(promises)
  }

  async prepareFilesToUpload (workflow, fileWithMetaArr, keyData) {
    let files = []
    fileWithMetaArr.map(value => {
      let fileName = this.props.certData && keyData ? value.meta.name + '.enc' : value.meta.name
      files.push({
        FileName: fileName,
        //.proof files doesn't have a file type attribute
        FileType: value.meta.type === '' ? 'text/xml' : value.meta.type,
        FileSize: value.meta.size
      })
    })

    let fileToUpload = this.state.filesToUpload.find(f => {
      return f.cpWfHfId === workflow.Value
    })

    if (fileToUpload) {//modify customerCreateOrderInput object
      fileToUpload.files = files
    }
    else {//add customerCreateOrderInput object
      const ftu = [ {
        cpWfHfId: workflow.Value,
        cpId: workflow.id,
        files: files
      } ]
      if (this.props.certData && keyData) {
        ftu[ 0 ].encryptionData = await createEnvelope(keyData, this.props.certData.id, this.props.certData.certificate)
        ftu[ 0 ].encryptionCertId = this.props.certData.id
      }

      this.setState({ filesToUpload: ftu })
    }
  }

  handleNewWorkFlow = async e => {
    const file = e.target.files[ 0 ]
    const base64String = await this.createBase64String(file)

    this.setState({
      filesToUpload: [ {
        cpWfHfId: this.state.currentWorkFlow.Value,
        copies: this.state.numberOfFileCopies,
        files: [ {
          FileName: file.name,
          FileType: file.type === '' ? 'text/xml' : file.type,
          FileSize: file.size
        } ]
      } ],
      selectedFile: file,
      selectedFileString: base64String
    })
  }

  handleColorProofSystem = e => {
    let wf = this.state.numberOfWorkFlows.filter(f => f.id === e.target.value.cpId)

    this.setState({
      currentColorProofSystem: e.target.value,
      siteWorkflows: wf,
      currentWorkFlow: wf.length === 0 ? '' : wf[ 0 ]
    })
  }

  handleWorkFlow = e => {
    this.setState({ currentWorkFlow: e.target.value })
  }

  getUpdatedWorkflow = async keyData => {
    let filesToUpload = this.state.filesToUpload.slice()

    filesToUpload[ 0 ].cpWfHfId = this.state.currentWorkFlow.Value
    filesToUpload[ 0 ].copies = this.state.numberOfFileCopies
    filesToUpload[ 0 ].cpId = this.state.currentColorProofSystem.cpId
    filesToUpload[ 0 ].files[ 0 ].FileName = filesToUpload[ 0 ].files[ 0 ].FileName;

    if (this.props.certData && keyData) {
      filesToUpload[ 0 ].encryptionData = await createEnvelope(keyData, this.props.certData.id, this.props.certData.certificate)
      filesToUpload[ 0 ].encryptionCertId = this.props.certData.id
      filesToUpload[ 0 ].files[ 0 ].FileName = filesToUpload[ 0 ].files[ 0 ].FileName + '.enc'
    }

    return filesToUpload
  }

  uploadSingleFile = async () => {
    this.context.trackingService.trackEvent('Dropzone', 'Upload File From "New File" Dialog', '1', 1)

    let keyData = null;
    if (this.context.featureFlagService.isFilesEncryptionSupported(this.state.currentColorProofSystem)) {
      await this.props.getCpCertificate(this.state.currentColorProofSystem.cpId)
      keyData = await createPublicKey()
    }

    let ftu = await this.getUpdatedWorkflow(keyData)

    this.setState({ singleFileUploadStarted: true, filesToUpload: ftu })
    await this.getCustomerOrderUploadInfo()

    if (!this.props.uploadInfo.files) {
      this.context.notificationService.showNotification(this, NotificationTypes.Error, this.props.uploadInfo.message, '3col', true)
      this.setState({ singleFileUploadStarted: false })
      return
    }

    let fileBuffer = this.createFileBuffer(this.state.selectedFileString)

    if (ftu[ 0 ].encryptionCertId && keyData) {
      fileBuffer = await encryptData(keyData, this.state.selectedFile)
    }

    const fileInfo = this.props.uploadInfo.files[ 0 ]

    await this.uploadMultipartFile(fileInfo, fileBuffer)
    this.context.notificationService.showNotification(this, NotificationTypes.Success,
      `${this.state.selectedFile.name} file has been successfully uploaded.`, '3col', true)

    this.closeUploadFIleBox()
  }

  async createBase64String (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        resolve(event.target.result)
      }

      reader.onerror = (err) => {
        reject(err)
      }

      reader.readAsDataURL(file)
    })
  }

  createFileBuffer (base64String) {
    let base64File = base64String.split(';base64,').pop()
    return Buffer.from(base64File, 'base64')
  }

  closeUploadFIleBox = () => {
    this.setState({
      newWorkFlowModal: false,
      singleFileUploadStarted: false,
      filesToUpload: [],
      selectedFile: "",
      selectedFileString: "",
      currentColorProofSystem: '',
      currentWorkFlow: '',
    })
  }

  closeDropZone = () => {
    this.setState({
      isFileUploadModal: false,
      filesToUpload: []
    })
  }


  openNewWorkFlows = () => {
    this.context.trackingService.trackEvent('Dropzone', 'Open New File Dialog', '1', 1)
    this.setState({ newWorkFlowModal: true, isFileUploadModal: false })
  }

  increaseNumberOfFileCopies = () => {
    this.setState({ numberOfFileCopies: this.state.numberOfFileCopies + 1 })
  }

  decreaseNumberOfFileCopies = () => {
    if (this.state.numberOfFileCopies === 1)
      return

    this.setState({ numberOfFileCopies: this.state.numberOfFileCopies - 1 })
  }

  renderSelectBoxes () {
    const { classes } = this.props
    return (
      <Modal disableEnforceFocus
        open={ this.state.newWorkFlowModal }
        onClose={ () => this.setState({ newWorkFlowModal: false }) }
        className={ classes.zIndex }
      >
        <div className={ classes.newWorkFlowModal }>
          <div>
            <Typography className={ classNames(classes.newWorkFlowModalTitle) }>
              <FormattedMessage id="cpJobs.newFile" defaultMessage="NEW FILE" />
            </Typography>
          </div>
          <div>
            <Typography className={ classNames(classes.newWorkFlowSelectBoxTitle) }>
              <FormattedMessage
                id="cpJobs.selectFile"
                defaultMessage="Select File"
              />
            </Typography>
            <div className={ classes.uploadWorkFlowContainer }>
              <TextField
                value={ this.state.selectedFile ? this.state.selectedFile.name : '' } // Initial value when rendered
                className={ classes.workFlowFileInputLabel }
                //variant="outlined"
                disabled={ true }
              />
              <div className={ classes.uploadNewWorkFlow }>
                <label
                  htmlFor="upload-workflow"
                  id="upload-workflow-button-label">
                  <MoreHorizIcon id="upload-workflow-button-icon" style={ { marginTop: 4, cursor: 'pointer' } } />
                </label>
                <input type="file"
                  className={ classes.inputFileHidden }
                  id="upload-workflow"
                  accept={ UPLOAD_FILE_TYPES }
                  onChange={ this.handleNewWorkFlow.bind(this) }
                />
              </div>
            </div>

            <Typography className={ classNames(classes.newWorkFlowSelectBoxTitle) }>
              <FormattedMessage
                id="cpJobs.colorProofSystem"
                defaultMessage="ColorProof System"
              />
            </Typography>
            <div className={ classes.uploadWorkFlowContainer }>
              <Select
                id="upload-workflow-systems"
                disableUnderline={ true }
                value={ this.state.currentColorProofSystem }
                onChange={ this.handleColorProofSystem.bind(this) }
                displayEmpty
                name="colorProofSystems"
                IconComponent={ LeftIcon }
                classes={ {
                  root: classes.cpSelectBox + ' ' + classes.printerModalFormControl,
                  selectMenu: classes.cpSelectBoxContent
                } }
                MenuProps={ {
                  classes: {
                    paper: classes.cpSelectMenuPaper + ' ' + classes.dropdownStyle
                  },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  MenuListProps: {
                    className: classNames(classes.cpSelectList)
                  }
                } }>
                { this.props.sites.map((value, index) => {
                  return (
                    <MenuItem
                      id={ value.site }
                      className={ classes.cpSelectMenuItem }
                      key={ value.lastSeen || index }
                      value={ value }
                      classes={ { selected: classes.cpSelectSelected } }>
                      <span className={ classes.noCheckMark } />
                      { value.alias ? value.alias : value.site }
                    </MenuItem>
                  )
                }) }
              </Select>
            </div>

            <Typography
              className={ classNames(classes.newWorkFlowSelectBoxTitle) }>
              <FormattedMessage
                id="cpJobs.workflow"
                defaultMessage="Workflow" />
            </Typography>
            <div className={ classes.uploadWorkFlowContainer }>
              <Select
                id="upload-workflow-workflows"
                onChange={ this.handleWorkFlow.bind(this) }
                name="workFlow"
                disableUnderline={ true }
                value={ this.state.currentWorkFlow }
                displayEmpty
                IconComponent={ LeftIcon }
                disabled={ this.state.currentWorkFlow === '' }
                classes={ {
                  root: classes.cpSelectBox + ' ' + classes.printerModalFormControl,
                  selectMenu: classes.cpSelectBoxContent
                } }
                MenuProps={ {
                  classes: { paper: classes.cpSelectMenuPaper + ' ' + classes.dropdownStyle },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  MenuListProps: {
                    className: classNames(classes.cpSelectList)
                  }
                } }
              >
                { this.state.siteWorkflows.map((value, index) => {
                  return (
                    <MenuItem
                      id={ value.Name }
                      className={ classes.cpSelectMenuItem }
                      key={ value.id + index }
                      value={ value }
                      classes={ { selected: classes.cpSelectSelected } }
                    >
                      { value.Name }
                    </MenuItem>
                  )
                }) }
              </Select>
            </div>
            <Typography className={ classes.newWorkFlowSelectBoxTitle }>
              <FormattedMessage
                id="cpJobs.numberOfCopies"
                defaultMessage="Copies"
              />
            </Typography>
            <div className={ classes.uploadWorkFlowContainer }>
              <OutlinedInput
                id="cpJobs.numberOfCopies-input"
                type="number"
                variant="outlined"
                value={ this.state.numberOfFileCopies }
                style={ { marginTop: 5 } }
                endAdornment={
                  <InputAdornment position="end">
                    <div className={ classes.countersContainer }>
                      <ArrowUpIcon
                        id="cpJobs.numberOfCopies-input-button-up"
                        className={ classes.counterUpButton }
                        onClick={ this.increaseNumberOfFileCopies.bind(this) } />
                      <ArrowDownIcon
                        id="cpJobs.numberOfCopies-input-button-down"
                        className={ classes.counterDownButton }
                        onClick={ this.decreaseNumberOfFileCopies.bind(this) } />
                    </div>
                  </InputAdornment>
                }
                className={ classes.cpNumberInput }
              />
            </div>
            <div className={ classes.inviteUserButtons }>
              <span className={ classes.printerActions }>
                <Button
                  id="newFileCancel"
                  color="secondary"
                  disabled={ this.state.singleFileUploadStarted }
                  className={ classNames(classes.inviteModalCancelButton) }
                  fontFamily="Segoe UI"
                  onClick={ () => this.closeUploadFIleBox() }
                >
                  <FormattedMessage
                    id="cpJobs.cancel"
                    defaultMessage="CANCEL"
                  />
                </Button>
                <LoadingButton
                  id="newFileUpload"
                  className={ classes.inviteButton }
                  fullWidth
                  loading={ this.state.singleFileUploadStarted }
                  disabled={ !this.state.selectedFile || this.state.currentWorkFlow === '' }
                  type="submit"
                  color="primary"
                  variant="outlined"
                  disableRipple
                  onClick={ this.uploadSingleFile.bind(this) }
                >
                  <FormattedMessage
                    id="cpJobs.upload"
                    defaultMessage="UPLOAD"
                  />
                </LoadingButton>
              </span>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  render () {
    const { classes } = this.props
    const workflows = this.state.numberOfWorkFlows
    return (
      <React.Fragment>
        { this.renderCpJobs() }
        { this.state.isFileUploadModal ? (
          <div className={ classes.fileUploadModal }>
            <div className={ classes.crossModal }>
              <span
                className={ classes.fileUploadCross }
                onClick={ () => this.closeDropZone() }>
                <CloseIcon />
              </span>
            </div>
            <div className={ classes.labelModal }>
              <span className={ classes.fileUploadLabel }>
                <FormattedMessage id="cpJobs.dragFiles" defaultMessage="DRAG YOUR FILES ON THE DROP ZONES" />
              </span>
            </div>
            <div className={ classes.labelModal }>
              <span
                className={ classes.browseUploadLabel }
                onClick={ this.openNewWorkFlows }>
                <FormattedMessage id="cpJobs.browseFiles" defaultMessage="or click to browse" />
              </span>
            </div>
            <div className={ classes.labelModal }>
              <div
                className={ classNames({ [ classes.modalContentGrid ]: true }) }>
                { workflows.map((value, index) => {
                  if (index > 8) {
                    return <React.Fragment key={ index }> </React.Fragment>
                  }
                  return (
                    <div className={ classes.fileUploadContainer } key={ index }>
                      <Dropzone
                        onChangeStatus={ this.handleChangeStatus.bind(this, value) }
                        validate={ this.validateUploadFiles.bind(this, value.Value) }
                        maxSizeBytes={ (1024 * 1024) * 500 }//not more than 500mb
                        accept={ UPLOAD_FILE_TYPES }//"image/*, application/pdf"
                        InputComponent={ () => (
                          <InputComponent
                            value={ value }
                            classes={ classes }
                            sites={ this.props.sites }
                          />
                        ) }
                        PreviewComponent={ props => (
                          <ProgressBar
                            files={ props.files }
                            fileWithMeta={ props.fileWithMeta }
                            filesUploadProgress={ this.state.filesUploadProgress }
                            classes={ classes }
                          />
                        ) }
                        classNames={ {
                          inputLabel: classes.inputLabelDropZone,
                          dropzone: classNames(classes.dropZoneCustomize),
                          inputLabelWithFiles: classes.inputLabelWithFiles,
                          preview: classes.previewFiles,
                          dropzoneActive: classes.dropZoneActiveSize
                        } }
                      />
                    </div>
                  )
                }) }
              </div>
            </div>
          </div>
        ) : null }

        { this.state.newWorkFlowModal && this.renderSelectBoxes() }
      </React.Fragment>
    )
  }
}

function mapStateToProps (state) {
  return {
    jobs: state.jobs.cpJobs,
    dbJobIds: state.jobs.cloudJobIds,
    hfwfs: state.jobs.hfwfs,
    sites: state.pairingInfo.sites,
    jobDetails: state.jobs.jobDetails,
    uploadInfo: state.jobs.uploadInfo,
    jobDetailsImages: state.jobs.jobDetailsImages,
    fileUrl: state.jobs.fileUrl,
    imageDetails: state.jobs.imageDetails,
    permissions: state.permissionsInfo.customerInfo,
    liveSearchTerm: state.filters.liveSearchTerm,
    currentSearchTerm: state.filters.currentSearchTerm,
    filterChips: state.filters.chips,
    certData: state.jobs.certificateData
  }
}

CpJobs.propTypes = {
  getCustomerHfWfs: PropTypes.func.isRequired,
  getCustomerCpJobDetailsByJob: PropTypes.func.isRequired,
  getCustomerJobsIds: PropTypes.func.isRequired,
  mutateAddAction: PropTypes.func.isRequired,
  mutateCustomerCreateOrder: PropTypes.func.isRequired,
  downloadJobDetailsImages: PropTypes.func.isRequired,
  downloadSingleJobDetailsImage: PropTypes.func.isRequired,
  getImageDetails: PropTypes.func.isRequired,
  getCpCertificate: PropTypes.func.isRequired,
  jobs: PropTypes.array,
  classes: PropTypes.object.isRequired,
  liveSearchTerm: PropTypes.string,
  currentSearchTerm: PropTypes.object,
  filterChips: PropTypes.object,
  certData: PropTypes.object
}

export default withStyles(styles)(
  injectIntl(
    withRouter(
      connect(mapStateToProps,
        {
          getCustomerHfWfs,
          getCustomerCpJobDetailsByJob,
          getCustomerJobsIds,
          mutateAddAction,
          mutateCustomerCreateOrder,
          downloadJobDetailsImages,
          downloadSingleJobDetailsImage,
          getImageDetails,
          getCpCertificate
        }
      )(CpJobs)
    )
  )
)