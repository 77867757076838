import React from 'react'
import { FormattedMessage } from 'react-intl'
import LoadingButton from '../../../../shared/Components/Buttons/LoadingButton'

export const FlowRows = (classes, showFlowModal) => {
  return [
    {
      key: 'spacerLeft',
      hideMobile: false,
      hideTablet: false,
      hideRightPane: false,
      numeric: false,
      label: '',
      headClassName: 'tableCellSpacer'
    },
    {
      key: 'name',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      label: <FormattedMessage id="cpSitesUser.flowname" defaultMessage="NAME" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'creator',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      label: <FormattedMessage id="cpSitesUser.creator" defaultMessage="CREATOR" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'hub',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      label: <FormattedMessage id="cpSitesUser.flowhub" defaultMessage="HUB" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'clients',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      label: <FormattedMessage id="cpSitesUser.flowclients" defaultMessage="REMOTE CLIENT" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'workflows',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      label: <FormattedMessage id="flowManager.workflow" defaultMessage="WORKFLOW" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'add-workflow',
      hideMobile: true,
      hideTablet: true,
      numeric: false,
      hideRightPane: true,
      hideRightPaneTabLandscape: true,
      label: (
        <LoadingButton
          id="flowsList.showAddFlowModal"
          className={ classes.inviteButton }
          style={ { fontSize: 14 } }
          type="submit"
          color="primary"
          variant="outlined"
          disableRipple
          onClick={ () => showFlowModal() }
        >
          <FormattedMessage
            id="flowManager.addFlow"
            defaultMessage="ADD FLOW"
          />
        </LoadingButton>
      ),
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'spacerRight',
      hideMobile: true,
      hideTablet: true,
      hideRightPane: false,
      hideRightPaneTabLandscape: false,
      numeric: false,
      label: '',
      headClassName: 'tableCellSpacerMobile'
    }
  ]
}