import Utils from '../helpers/Utils'

export const handleSetSearchTerm = (page, term, chips) => {
    if(term.value.trim() === "")
        return chips

    const duplicate = findChip(page, term, chips)
    if (duplicate)
        return chips

    let newChips = Utils.deepClone(chips)
    if (!newChips[page])
        newChips[page] = []

    newChips[page].push(term)

    return newChips
}

export const handleRemoveFilterChip = (page, term, chips) =>{
    const index = chips[page].indexOf(term)
    if (index === -1) 
        return chips

    let newChips = Utils.deepClone(chips)
    newChips[page].splice(index, 1)

    return newChips
}

export const handleChangeFilterChip = (page, term, chips) => {
    let existingTerm = findChip(page, term, chips)
    if(!existingTerm) 
        return chips

    existingTerm.isActive = !existingTerm.isActive

    return {...chips}
}

const findChip = (page, term, chips) => {
    if (!chips[page])
        return null

    return chips[page].find(chip => chip.value.toLowerCase() === term.value.toLowerCase())
}