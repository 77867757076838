import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <polygon points="23.4,11.4 21.3,13.4 19.3,11.4 18.7,11.9 21.3,14.5 23.9,12" />
    <path
      d="M21.9,11.4C21.9,11.4,21.9,11.3,21.9,11.4L21.9,11.4c-0.1-5.6-4.6-10-10.1-9.9c-3.5,0.1-6.8,2-8.5,5l0.6,0.4
			c2.4-4.5,8-6.1,12.4-3.7c3.3,1.8,5.2,5.4,4.7,9.2l0.8,0.1C21.9,12.1,21.9,11.8,21.9,11.4L21.9,11.4L21.9,11.4z"
    />
    <path
      d="M20,15.9c-2.5,4.4-8.1,6-12.5,3.5c-3.2-1.8-5-5.3-4.6-9L2,10.4c0,0.3-0.1,0.7-0.1,1l0,0c0,0,0,0,0,0.1
			c0,0,0,0.1,0,0.1l0,0c0.1,5.5,4.7,9.9,10.2,9.8c3.5-0.1,6.8-2,8.5-5.1L20,15.9z"
    />
    <polygon points="2.6,8.3 0,10.9 0.6,11.4 2.6,9.4 4.6,11.4 5.2,10.9" />
  </React.Fragment>,
  'InProgressIcon'
)
