export const categories = {
    inError: ['Error'],
    onHold: [
      'NestingCanceled',
      'Canceled',
      'Held',
      'HeldWaitngForConfig',
      'WaitForUserAction'
    ],
    inQueue: [
      'WaitForFinish',
      'Waiting',
      'Ready',
      'Initialising',
      'Nesting',
      'NestingPrinted',
      'Drying',
      'Measuring',
      'PrepareMeasuring',
      'InRip',
      'Init',
      'Preprocessing',
      'Processing'
    ],
    complete: [
      'InHistory',
      'Finished',
      'InHistoryImagesDeleted',
      'NestingDeleted'
    ]
  }