/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Date.prototype.addHours = function(h) {
  this.setHours(this.getHours() + h)
  return this
}

Date.prototype.addMinutes = function(m) {
  this.setMinutes(this.getMinutes() + m)
  return this
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (!serializedState) 
      return undefined

    let state = JSON.parse(serializedState)
    let expiryDateTime = new Date(state.expiryDateTime)

    if (expiryDateTime && expiryDateTime < new Date(Date.now()))
      localStorage.removeItem('state')

    return state.value
  } catch (e) {
    console.log("Error while trying to load localStrage: ", e)
  }
}

export const saveState = state => {
  try {
    //default expiry date
    let object = {
      value: state,
      expiryDateTime: new Date(Date.now()).addHours(12)
    }
    const serializedState = JSON.stringify(object)

    localStorage.setItem('state', serializedState)
  } catch (e) {
    console.log("Error while trying to save to the localStrage: ", e)
  }
}

export const saveAuthToken = (token) => {
  try {
    localStorage.setItem('auth:token', token)    
  } catch (e) {
    console.log("Error while trying to save to the localStrage: ", e)
  }
}

export const loadAuthToken = () => {
  try {
    const authToken = localStorage.getItem('auth:token')
    return authToken
  } catch (e) {
    console.log("Error while trying to read from the localStrage: ", e)
  }
}

export const clearAuthToken = () => {
  try {
    localStorage.removeItem('auth:token')
  } catch (e) {
    console.log("Error while trying to remove from the localStrage: ", e)
  }
}