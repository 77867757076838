import React from 'react'
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ChannelValueTextField } from './ChannelValueTextField';
import { ChannelValueRowItem } from '../../Types/types';

interface Props {
  channelRowItems: ChannelValueRowItem[];
};

const renderField = (item: ChannelValueRowItem, index: number) => {
    return (
      <Grid item xs key={index}>
        <ChannelValueTextField name={item.name} placeholder={item.placeholder} validator={item.validator} />
      </Grid>
    )
  }

export const ChannelValueRow: React.FC<Props> = (props: Props) => {
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid container item spacing={1}>
          {props.channelRowItems.map((item, index) => {
              return renderField(item, index);
          })}
        </Grid>
      </Grid>
    </Box>
  )
}
