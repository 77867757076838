export const GET_CUSTOMER_PRINTERS = 'GET_CUSTOMER_PRINTERS'
export const GET_CUSTOMER_PRINTERS_SUCCESS = 'GET_CUSTOMER_PRINTERS_SUCCESS'
export const GET_CUSTOMER_PRINTERS_FAILURE = 'GET_CUSTOMER_PRINTERS_FAILURE'

export const ADD_ACTION = 'ADD_ACTION'
export const ADD_ACTION_SUCCESS = 'ADD_ACTION_SUCCESS'
export const ADD_ACTION_FAILURE = 'ADD_ACTION_FAILURE'

export const SUBSCRIBE_TO_ADD_PRINTER = 'SUBSCRIBE_TO_ADD_PRINTER'
export const SUBSCRIBE_TO_ADD_PRINTER_SUCCESS = 'SUBSCRIBE_TO_ADD_PRINTER_SUCCESS'
export const SUBSCRIBE_TO_ADD_PRINTER_FAILURE = 'SUBSCRIBE_TO_ADD_PRINTER_FAILURE'

export const SUBSCRIBE_TO_UPDATE_PRINTER = 'SUBSCRIBE_TO_UPDATE_PRINTER'
export const SUBSCRIBE_TO_UPDATE_PRINTER_SUCCESS = 'SUBSCRIBE_TO_UPDATE_PRINTER_SUCCESS'
export const SUBSCRIBE_TO_UPDATE_PRINTER_FAILURE = 'SUBSCRIBE_TO_UPDATE_PRINTER_FAILURE'

export const SUBSCRIBE_TO_PRINTERDETAIL_UPDATE = 'SUBSCRIBE_TO_PRINTERDETAIL_UPDATE'
export const SUBSCRIBE_TO_PRINTERDETAIL_UPDATE_SUCCESS = 'SUBSCRIBE_TO_PRINTERDETAIL_UPDATE_SUCCESS'
export const SUBSCRIBE_TO_PRINTERDETAIL_UPDATE_FAILURE = 'SUBSCRIBE_TO_PRINTERDETAIL_UPDATE_FAILURE'

export const SUBSCRIBE_TO_DELETE_PRINTER = 'SUBSCRIBE_TO_DELETE_PRINTER'
export const SUBSCRIBE_TO_DELETE_PRINTER_SUCCESS = 'SUBSCRIBE_TO_DELETE_PRINTER_SUCCESS'
export const SUBSCRIBE_TO_DELETE_PRINTER_FAILURE = 'SUBSCRIBE_TO_DELETE_PRINTER_FAILURE'

export const CLEAR_PRINTERS_DATA = 'CLEAR_PRINTERS_DATA'