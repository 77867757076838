import GMG_Prod_Config from '../../../graphql/GMG_Prod_CpGoDocDb_Config.json'
import GMG_Test_Config from '../../../graphql/GMG_Test_CpGoDocDb_Config.json'
import GMG_Dev_Config from '../../../graphql/GMG_Dev_CpGoDocDb_Config.json'

const local = {
  url: 'https://2gdyrhed6bdubgfbo2yfi7e534.appsync-api.eu-central-1.amazonaws.com/graphql',
  region: 'eu-central-1'
}

let config;
switch (process.env.REACT_APP_STAGE) {
  case 'Prod': config = GMG_Prod_Config[ 'appSync' ];
  break;
  case 'Test': config = GMG_Test_Config[ 'appSync' ];
  break;
  case 'Dev': config = GMG_Dev_Config[ 'appSync' ];
  break;
  default: config = local;
}
export default config
