

import classNames from 'classnames';
import React from 'react';
import EditIcon from '../../../../assets/icons/EditIcon';
import Popover from '../../../shared/Components/Messaging/Popover';
import { JobsCategories } from '../../duck/helpers/JobsCategories';

interface Props {
    classes: any;
    jobsCategories: JobsCategories;
    currentJobState: string;
    isEnabled: boolean,
    popOverText: string,
    handleClickOpen: React.MouseEventHandler<HTMLSpanElement> | undefined
  }

export const EditChannelIcon: React.FC<Props> = ( { classes, jobsCategories, currentJobState, isEnabled, popOverText, handleClickOpen } ) => {

    const noPopoverText = ()  => {
        return (
            <span
                className={ classNames(
                    classes.jobActionSpace,
                    classes.disabled
                ) }
            >
                <EditIcon className={ classes.noPointerEvent } />
            </span>
        )
    }
    
    const popOverWithText = () => {
        return ( 
            <Popover
                content={ popOverText }
                distance="5"
                position="left"
                >
                    { noPopoverText() }
                </Popover> 
        )
    }

    return ( 
        <React.Fragment>
            {
                isEnabled
                ?   (<span
                        className={ classNames(classes.jobActionSpace, {
                        [ classes.activeClass ]: jobsCategories.categories[ 'inQueue' ].includes(
                            currentJobState
                        ),
                        [ classes.normalClass ]: !jobsCategories.categories[ 'inQueue' ].includes(
                            currentJobState
                        )
                        }) }
                        onClick={handleClickOpen}
                    >
                        <EditIcon className={ classes.noPointerEvent } />
                    </span>)
                :   (
                    popOverText !== ''
                    ? popOverWithText()
                    : noPopoverText()
                )
            }
        </React.Fragment>
    )
}