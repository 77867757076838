import React from 'react'
import Tippy from '@tippy.js/react'
import { withStyles } from '@material-ui/core'
import styles from '../../Styles/styles'

class Popover extends React.Component {
  render () {
    const { classes, position, distance, content } = this.props

    return (
      <Tippy
        className={ classes.tooltips }
        content={ content }
        placement={ position ? position : 'left' }
        distance={ distance ? distance : '10' }
        arrow={ true }
        arrowType="sharp"
        animation="fade"
        popperOptions={ {
          placement: position ? position : 'left',
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        } }
      >
        {this.props.children }
      </Tippy>
    )
  }
}

export default withStyles(styles)(Popover)
