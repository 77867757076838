import React from 'react'
import { FormattedMessage } from 'react-intl'
import CategoriesBase from '../../../shared/duck/helpers/CategoriesBase'
import {categories} from './PrinterState'

export class PrintersCategories extends CategoriesBase {    
    constructor(){
        super(categories)
    }

    translatedStatus = {
        Ready: <FormattedMessage id="cpPrintersStatus.ready" defaultMessage="Ready" />,
        Held: <FormattedMessage id="cpPrintersStatus.held" defaultMessage="Held" />,
        Error: <FormattedMessage id="cpPrintersStatus.error" defaultMessage="Held" />,
        Busy: <FormattedMessage id="cpPrintersStatus.busy" defaultMessage="Busy" />,
        Multi: <FormattedMessage id="cpPrintersStatus.multi" defaultMessage="Multi" />,
        MultiError: <FormattedMessage id="cpPrintersStatus.multiError" defaultMessage="Multi Error" />,
        ForDelete: <FormattedMessage id="cpPrintersStatus.forDelete" defaultMessage="For Delete" />,
        Calibrating: <FormattedMessage id="cpPrintersStatus.calibrating" defaultMessage="Calibrating" />
      }
}