import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      fill="none"
      d="M12,1.8C6.4,1.8,1.8,6.4,1.8,12S6.4,22.2,12,22.2S22.2,17.7,22.2,12S17.6,1.8,12,1.8z"
    />
    <path
      d="M12,0.2C5.5,0.2,0.2,5.5,0.2,12S5.5,23.9,12,23.9S23.8,18.6,23.8,12S18.5,0.2,12,0.2z M12,22.2
	C6.4,22.2,1.8,17.7,1.8,12S6.4,1.8,12,1.8S22.2,6.4,22.2,12S17.6,22.2,12,22.2z"
    />
    <g>
      <polygon points="17.1,8.1 15.9,6.8 6.8,15.9 8.1,17.2 	" />
      <polygon points="17.2,15.9 8.1,6.8 6.8,8.1 15.9,17.2 	" />
    </g>
  </React.Fragment>,
  'CrossCircleIcon'
)
