import * as types from '../types/filtersTypes'

export const setLiveSearchTerm = value => {
    return dispatch => {        
        if (!value)
            dispatch({ type: types.SET_LIVE_SEARCH_TERM_FAILURE })

        dispatch({ type: types.SET_LIVE_SEARCH_TERM, term: value })
    }
}

export const setFilterChip = (page, term) => {
    return dispatch => {

        if (!page || !term)
            dispatch({ type: types.SET_FILTER_CHIP_FAILURE })

        dispatch({ type: types.SET_FILTER_CHIP, page, term })
    }
}

export const removeFilterChip = (page, term) => {
    return dispatch => {
        if (!page || !term)
            dispatch({ type: types.REMOVE_FILTER_CHIP_FAILURE })

        dispatch({ type: types.REMOVE_FILTER_CHIP, page, term })
    }
}

export const changeFilterChip = (page, term) => {
    return dispatch => {
        if (!page || !term)
            dispatch({ type: types.CHANGE_FILTER_CHIP_STATE_FAILURE })

        dispatch({ type: types.CHANGE_FILTER_CHIP_STATE, page, term })
    }
}

export const setDateRangeFlowsStatisticsValues = valuesObject => {
    return dispatch => {
        if (!valuesObject || !valuesObject.startDate || !valuesObject.endDate)
            dispatch({ type: types.SET_DATERANGE_FLOWS_STATISTICS_VALUES_FAILURE })

        dispatch({ type: types.SET_DATERANGE_FLOWS_STATISTICS_VALUES, valuesObject })
    }
}

export const setDateRangeFlowOrdersValues = valuesObject => {
    return dispatch => {
        if (!valuesObject || !valuesObject.startDate || !valuesObject.endDate)
            dispatch({ type: types.SET_DATERANGE_ORDERS_LIST_VALUES_FAILURE })

        dispatch({ type: types.SET_DATERANGE_ORDERS_LIST_VALUES, valuesObject })
    }
}