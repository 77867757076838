import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <polygon points="9.71,19.01 8.64,20.01 0.1,12 8.64,3.99 9.71,4.99 2.17,12 	" />
    <rect x="2.11" y="11.29" width="21.79" height="1.41" />
  </React.Fragment>,
  'ArrowLeftIcon'
)
