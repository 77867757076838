import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      fill="#95989A"
      d="M20.4,1.2c1.32,0,2.4,1.08,2.4,2.4v16.8c0,1.32-1.08,2.4-2.4,2.4H3.6c-1.32,0-2.4-1.08-2.4-2.4V3.6
        c0-1.32,1.08-2.4,2.4-2.4H20.4 M20.4,0H3.6C1.56,0,0,1.56,0,3.6v16.8C0,22.44,1.56,24,3.6,24h16.8c2.04,0,3.6-1.56,3.6-3.6V3.6
        C24,1.56,22.44,0,20.4,0L20.4,0z"
    />
  </React.Fragment>,
  'CheckboxIcon'
)
