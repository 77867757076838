import { ValueMapType as ChannelMapType, Channel, Cmyk, Lab } from '../../ChannelMapping/Types/types';
import * as constants from '../../ChannelMapping/Types/constants';

export const generateInitialValuesForChannelMapping = (channels: Channel[]) => {
  const initValues: ChannelMapType = {};

  channels?.map((channel) => {
    const channelId = getColorChannelId(channel);
    initValues[channelId] = {
      name: channel.name,
      select: channel.selectedColor,
      cmyk: channel.cmyk ? { ...channel.cmyk } : { c: '', m: '', y: '', k: '' },
      lab: channel.lab ? { ...channel.lab } : { l: '', a: '', b: '' },
      replaceColor: channel.replaceColorName,
    };
  });

  //console.log(initValues)

  return initValues;
};

export const generateChannels = (channels: Channel[]): Channel[] => {
  const channelResult = channels?.map((channel) => {
    const channelId = getColorChannelId(channel);
    return {
      ...channel,
      id: channelId,
    };
  });

  return channelResult;
};

export const createChannelForSaving = (userInfo: ChannelMapType, channels: Channel[]): Channel[] => {
  let channelMappingResults: Channel[] = [];

  for (var key in userInfo) {
    if (userInfo.hasOwnProperty(key)) {
      const userChannelInput = userInfo[key];
      const serverChannel = channels.find((c) => c.name === userChannelInput.name);

      let selectedColor: string = '';
      let replaceColor: string | undefined = undefined;
      let lab: Lab | undefined = undefined;
      let enabled: boolean = true;
      let cmyk: Cmyk | undefined = undefined;

      if (userChannelInput.select === constants.DEFINE_IN_CMYK) {
        selectedColor = userChannelInput.select;
        replaceColor = undefined;
        lab = undefined;
        enabled = true;
        cmyk = userChannelInput.cmyk;
      } else if (userChannelInput.select === constants.DEFINE_IN_LAB) {
        selectedColor = userChannelInput.select;
        replaceColor = undefined;
        lab = userChannelInput.lab;
        enabled = true;
        cmyk = undefined;
      } else if (userChannelInput.select === constants.REPLACE_WITH) {
        selectedColor = userChannelInput.select;
        replaceColor = userChannelInput.replaceColor;
        lab = undefined;
        enabled = true;
        cmyk = undefined;
      } else if (userChannelInput.select === constants.DO_NOT_PRINT) {
        selectedColor = userChannelInput.select;
        replaceColor = undefined;
        lab = undefined;
        enabled = false;
        cmyk = undefined;
      } else {
        selectedColor = userChannelInput.select;
        replaceColor = undefined;
        lab = undefined;
        enabled = true;
        cmyk = undefined;
      }

      if (serverChannel) {
        // we don't need to be sending "Select" or "SEPARATOR" back to the server as available colors;
        // can't click apply with "Select" still shown, and "SEPARATOR" is not selectable either.
        const dontSend = [constants.SELECT, constants.SEPARATOR];
        const availableColors = serverChannel.availableColors.filter(color => !dontSend.includes(color));

        channelMappingResults.push({
          id: serverChannel.printOrder,
          name: serverChannel.name,
          printOrder: serverChannel.printOrder,
          availableColors: availableColors,
          selectedColor: selectedColor,
          cmyk: cmyk,
          lab: lab,
          replaceColorName: replaceColor,
          enabled: enabled,
        });
      }
    }
  }

  console.log(channels);
  console.log(channelMappingResults);
  return channelMappingResults;
};

const getColorChannelId = (channel: Channel) => {
  const channelName = channel.name.split('.')[0];
  const channelId = `${channelName}_${channel.id}`;

  return channelId;
};
