import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import ClearIcon from '@material-ui/icons/Clear'
import Divider from '@material-ui/core/Divider'
import CategoryList from './CategoryList'
import withIntl from '../../Localization'
import LeftIcon from '../../../../assets/icons/LeftIcon'
import PlusIcon from '../../../../assets/icons/PlusIcon'
import ServiceContext from '../../Services/ServiceContext'
import DateRangeFilter from '../Date/DateRangeDisplay'
import { removeFilterChip, changeFilterChip } from '../../duck/actions/filtersActions'
import { defaultDateRangeValues } from '../../duck/helpers/dateTime'
import PermissionsValidator from '../../duck/helpers/permissionsValidator'

class LeftSidePane extends React.Component {
  static contextType = ServiceContext
  state = {
    searchTerms: []
  }

  toggleDrawer = () => {
    this.context.leftSideBar.changeLeftSideBarState()
    this.context.trackingService.trackEvent(
      'Toggle Left Pane',
      'Open or Close Left Pane',
      '1',
      1
    )
    this.setState({})
  }

  componentDidMount () {
    this.restoreChips()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filterChips !== this.props.filterChips)
      this.restoreChips()
  }

  handleChips = e => {
    if (e.keyCode !== 13)
      return

    if (this.state.searchTerms.length === 8)//not more than 8 filter chips
      return

    this.setState({ searchTerms: this.props.filterChips[ this.context.navigationService.getLocation() ] })
  }

  handleListItemClick = (event, filterName) => {
    this.props.onCategoryClick(filterName)
  }

  handleFileInputClick = () => {
    this.context.trackingService.trackEvent('New File', 'Open Dropzone', '1', 1)
    this.props.onInputClick(true)
  }

  handleChipDelete = async value => {
    await this.props.removeFilterChip(this.context.navigationService.getLocation(), value)
    this.setState({ searchTerms: this.props.filterChips[ this.context.navigationService.getLocation() ] })
  }

  handleChipClick = value => {
    this.props.changeFilterChip(this.context.navigationService.getLocation(), value)
    this.setState({ searchTerms: this.props.filterChips[ this.context.navigationService.getLocation() ] })
  }

  restoreChips () {
    let restoredChips = this.props.filterChips[ this.context.navigationService.getLocation() ]
    if (!restoredChips || restoredChips.length === 0)
      return

    this.setState({ searchTerms: restoredChips })
  }

  render () {
    let sidebarOpen = this.context.leftSideBar.getLeftSideBarState()
    const { classes } = this.props
    const { data } = this.props
    const validator = new PermissionsValidator(this.props.permissions)

    if (!data.label) {
      sidebarOpen = true
    }
    return (
      <Drawer
        variant="permanent"
        classes={ {
          paper: classNames(
            classes.drawerPaper,
            !sidebarOpen && classes.drawerPaperClose
          )
        } }
        open={ sidebarOpen }
      >
        {data.label &&
          <React.Fragment>
            <div className={ classes.menuButtonContainer }>
              <IconButton
                disableRipple
                color="inherit"
                aria-label="Open drawer"
                onClick={ this.toggleDrawer }
                title="Menu"
                className={ classes.menuButton }
              >
                <LeftIcon
                  className={ classNames(
                    classes.leftIconPrinter,
                    !sidebarOpen && classes.rightIcon
                  ) }
                />
              </IconButton>
            </div>
            <Typography
              variant="h6"
              align="center"
              color="textPrimary"
              className={ classNames(
                classes.navigationTitle,
                !sidebarOpen && classes.hide
              ) }
            >
              <FormattedMessage
                id={ data.labelID }
                defaultMessage={ data.labelTitle }
              />
            </Typography>
          </React.Fragment>
        }

        {data.fileUpload && validator.hasUserLicenseAndExecutePermissions() &&
          <div className={ classes.svgWrapper } id="newFileUpload" onClick={ this.handleFileInputClick }>
            <svg height="40" width="260" xmlns="http://www.w3.org/2000/svg">
              <rect className={ classes.shape } height="40" width="260" />
            </svg>
            <div className={ classes.inputLabelText }>
              <PlusIcon className={ classes.plusIcon } />
              <FormattedMessage id="cpJobs.newFile" defaultMessage="NEW FILE" />
            </div>
          </div>
        }

        {data.dateRangeFilter &&
          <DateRangeFilter
            classes={ this.props.classes }
            dateRange={ this.props.dateRangeValues }
            onDateRangeChange={ this.props.onDateRangeChange }
          />
        }
        <CategoryList
          selectedCategory={ data.selectedFilter }
          onCategoryClick={ this.handleListItemClick }
          categoryCounter={ data.counter }
          sidebarOpen={ sidebarOpen }
          categories={ data.categories }
        />
        {this.state.searchTerms.length !== 0 &&
          <div>
            <Divider variant="middle" />
            <div className={ classes.chipsRoot }>
              { this.state.searchTerms.map((term, index) => {
                return (
                  <Chip key={ index }
                    id={ term.value }
                    size="medium" variant="outlined"
                    style={ { backgroundColor: term.isActive ? "#FAFAFA" : "#EDEDED" } }
                    deleteIcon={ <ClearIcon fontSize="small" className={ classes.chipLabel } /> }
                    label={ term.value }
                    onClick={ this.handleChipClick.bind(this, term) }
                    onDelete={ this.handleChipDelete.bind(this, term) }
                    className={ classes.chip }
                  />
                )
              })
              }
            </div>
          </div>
        }
      </Drawer>
    )
  }
}

function mapStateToProps (state) {
  return {
    filterChips: state.filters.chips,
    dateRangeValues: state.filters.dateRangeOrdersListValues,
    permissions: state.permissionsInfo.customerInfo
  }
}

LeftSidePane.defaultProps = {
  dateRangeValues: defaultDateRangeValues
}

LeftSidePane.propTypes = {
  classes: PropTypes.object.isRequired,
  removeFilterChip: PropTypes.func.isRequired,
  changeFilterChip: PropTypes.func.isRequired,
  currentCpId: PropTypes.string,
  filterChips: PropTypes.object,
  permissions: PropTypes.array,
  dateRangeValues: PropTypes.object.isRequired
}

export default withIntl(
  connect(
    mapStateToProps,
    {
      removeFilterChip,
      changeFilterChip
    }
  )(LeftSidePane)
)