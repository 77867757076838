import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M15.08,5.75l2.36,1.88L5.24,22.94H3.31l-0.43-1.89L15.08,5.75 M14.92,4.35L1.81,20.8l0.71,3.13h3.21
			L18.84,7.48L14.92,4.35L14.92,4.35z"
    />
    <path
      d="M18.83,1.06L18.83,1.06l2.36,1.88l-1.25,1.58l-2.36-1.88L18.83,1.06L18.83,1.06 M18.83,0.07
			c-0.3,0-0.59,0.14-0.78,0.38l-1.88,2.35l3.92,3.12l1.88-2.36c0.34-0.43,0.27-1.05-0.15-1.4c0,0,0,0-0.01,0l-2.37-1.87
			C19.27,0.14,19.05,0.07,18.83,0.07z"
    />
  </React.Fragment>,
  'EditIcon'
)
