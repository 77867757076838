import { Auth } from 'aws-amplify';
import config, { isHostedUiEnabled } from './Amplify';

const SESSION_LENGTH_SECONDS = 3600;  // one hour
const SESSION_LENGTH_MS = SESSION_LENGTH_SECONDS * 1000;

/****
 * When using SSO, the Cognito is in a different AWS account than the rest of the deployment,
 * we need to assume a role in the account with the resources in order to access them.
 * (such as Appsync, lambdas, S3, etc). This class takes care of all that and wraps it up in a single
 * call - currentCredentials()
 */
class GMGAuth {
  static #instance = undefined;

  static getInstance() {
    if (!GMGAuth.#instance) {
      GMGAuth.#instance = isHostedUiEnabled()
        ? new GMGHostedAuth() : new GMGAmplifyAuth();
    }

    return GMGAuth.#instance;
  }

  async getUserIfSignedIn() {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (e) {
      return undefined;
    }
  }
}

class GMGHostedAuth extends GMGAuth {
  static roleCredentials = undefined;

  async currentCredentials() {
    if (GMGHostedAuth.roleCredentials) {
      return GMGHostedAuth.roleCredentials;
    }

    setTimeout(() => {
      GMGHostedAuth.roleCredentials = undefined;
    }, SESSION_LENGTH_MS);

    GMGHostedAuth.roleCredentials = await this.#assumeRole();
    // update the AWS SDK credentials
    AWS.config.update(GMGHostedAuth.roleCredentials);

    return GMGHostedAuth.roleCredentials;
  }

  async signOut() {
    await Auth.signOut();
    this.#performSsoLogout();
  }

  #performSsoLogout() {
    document.location = `https://${config.Auth.oauth.domain}/logout?client_id=${config.Auth.userPoolWebClientId}&logout_uri=${config.Auth.oauth.redirectSignOut}`;
  }

  async #assumeRole() {
    const sts = new AWS.STS({
      credentials: await Auth.currentCredentials(),
      region: config.Auth.region,
    });

    try {
      const response = await sts
        .assumeRole({
          RoleArn: config.Auth.roleArn,
          RoleSessionName: `GMG_${process.env.REACT_APP_STAGE}_AuthenticatedCustomer`,
          DurationSeconds: SESSION_LENGTH_SECONDS,
        })
        .promise();

      return {
        accessKeyId: response.Credentials.AccessKeyId,
        sessionToken: response.Credentials.SessionToken,
        secretAccessKey: response.Credentials.SecretAccessKey,
        identityId: response.AssumedRoleUser.AssumedRoleId,
        authenticated: true,
      };
    } catch (error) {
      console.log('Error while assuming credentials...', error);
      throw error;
    }
  }
}

class GMGAmplifyAuth extends GMGAuth {
  async currentCredentials() {
    return await Auth.currentCredentials();
  }

  async signOut() {
    await Auth.signOut({ global: true })
  }
}

export { GMGAuth };
