import React from 'react'
import { FormattedMessage } from 'react-intl'
import NameWithAlias from '../../shared/Components/ListItems/NameWithAlias'
import Popover from '../../shared/Components/Messaging/Popover'
import CpTimeAgo from '../../shared/Components/Date/CpTimeAgo'

export const listRows = (classes, sites) => {
  return [
    {
      key: 'spacerLeft',
      hideMobile: true,
      hideTablet: true,
      hideRightPaneTabLandscape: true,
      numeric: false,
      label: '',
      headClassName: 'tableCellSpacerMobile'
    },
    {
      key: 'name',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      hideRightPaneTabLandscape: false,
      label: <FormattedMessage id="cpPrinters.printerTitle" defaultMessage="Printer" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'site',
      hideMobile: false,
      hideTablet: false,
      hideRightPaneTabLandscape: false,
      numeric: false,
      label: <FormattedMessage id="cpPrinters.siteTitle" defaultMessage="Site" />,
      headClassName: 'tableHeadCellMinWidth',
      customTemplate: printer => {
        const site = sites.find(site => site.site === printer.site && site.cpId === printer.cpId)
        if (site)
          return <NameWithAlias name={ printer.site } alias={ site.alias } classes={ classes } />
      }
    },
    {
      key: 'media',
      hideMobile: true,
      hideTablet: false,
      hideRightPaneTabLandscape: true,
      numeric: false,
      label: <FormattedMessage id="cpPrinters.proofMedia" defaultMessage="Proof Media" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'lastCalibrationDate',
      hideMobile: true,
      hideTablet: false,
      hideRightPaneTabLandscape: true,
      numeric: false,
      label: <FormattedMessage id="cpPrinters.calibrated" defaultMessage="Calibrated" />,
      headClassName: 'tableHeadCellMinWidth',
      customTemplate: printer => {
        const displayDate = new Date(printer.lastCalibrationDate).toLocaleString()

        if (!printer.isCalibrated || !printer.isCalibrated) {
          if (new Date(printer.lastCalibrationDate) > new Date('2000-01-01T23:59:00.0000000Z') && printer.lastCalibrationDate) {
            return (
              <Popover
                content={ displayDate }
                position="top"
              >
                <span>
                  <CpTimeAgo date={ printer.lastCalibrationDate } />
                </span>
              </Popover>
            )
          }
          else {
            return <FormattedMessage id="cpPrinters.never" defaultMessage="Never" />
          }
        } else {
          return (
            <Popover
              content={ displayDate }
              position="top"
            >
              <span>
                <CpTimeAgo date={ printer.lastCalibrationDate } />
              </span>
            </Popover>
          )
        }
      }
    },
    {
      key: 'status',
      hideMobile: false,
      hideTablet: false,
      hideRightPaneTabLandscape: false,
      numeric: false,
      label: <FormattedMessage id="cpPrinters.status" defaultMessage="Status" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'spacerRight',
      hideMobile: true,
      hideTablet: true,
      hideRightPaneTabLandscape: true,
      numeric: false,
      label: '',
      headClassName: 'tableCellSpacerMobile'
    }
  ]
}