import React from 'react'
import { FormattedMessage } from 'react-intl'

export const SiteRows = () => {
  return [
    {
      key: 'spacerLeft',
      hideMobile: false,
      hideTablet: false,
      hideRightPane: false,
      numeric: false,
      label: '',
      headClassName: 'tableCellSpacerMobile'
    },
    {
      key: 'site',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      label: <FormattedMessage id="cpSitesUser.site" defaultMessage="SITE" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'status',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      label: <FormattedMessage id="cpSitesUser.status" defaultMessage="STATUS" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'lastSeen',
      hideMobile: true,
      hideTablet: false,
      numeric: false,
      label: <FormattedMessage id="cpSitesUser.lastSeen" defaultMessage="LAST SEEN" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'licence',
      hideMobile: false,
      hideTablet: false,
      numeric: false,
      label: <FormattedMessage id="cpSitesUser.license" defaultMessage="LICENSE" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'colorHub',
      hideMobile: true,
      hideTablet: false,
      numeric: false,
      label: <FormattedMessage id="cpSitesUser.colorHub" defaultMessage="COLOR HUB" />,
      headClassName: 'tableHeadCellMinWidth'
    },
    {
      key: 'spacerRight',
      hideMobile: true,
      hideTablet: true,
      hideRightPane: false,
      numeric: false,
      label: '',
      headClassName: 'tableCellSpacerMobile'
    }
  ]
}