import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <rect x="2.7" y="4.82" width="18.6" height="1.1" />
    <rect x="2.7" y="11.45" width="18.6" height="1.1" />
    <rect x="2.7" y="18.08" width="18.6" height="1.1" />
  </React.Fragment>,
  'MenuIcon'
)
