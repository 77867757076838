import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M7.51,14.49c-1.38,0-2.49-1.12-2.49-2.49s1.12-2.49,2.49-2.49S10,10.62,10,12S8.89,14.49,7.51,14.49z
			 M7.51,10.5c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5S8.34,10.5,7.51,10.5z"
    />
    <path
      d="M13.5,6.51C12.12,6.51,11,5.4,11,4.02s1.12-2.49,2.49-2.49s2.49,1.12,2.49,2.49S14.87,6.51,13.5,6.51z
			 M13.5,2.52c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5S14.32,2.52,13.5,2.52z"
    />
    <rect x="0.03" y="11.5" width="3.99" height="1" />
    <rect x="11" y="11.5" width="12.97" height="1" />
    <rect x="16.99" y="3.52" width="6.98" height="1" />
    <rect x="0.03" y="3.52" width="9.98" height="1" />
    <path
      d="M17.49,22.48c-1.38,0-2.49-1.12-2.49-2.49c0-1.38,1.12-2.49,2.49-2.49s2.49,1.12,2.49,2.49
			C19.98,21.36,18.87,22.48,17.49,22.48z M17.49,18.49c-0.83,0-1.5,0.67-1.5,1.5c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5
			C18.98,19.16,18.31,18.49,17.49,18.49z"
    />
    <rect x="20.98" y="19.48" width="2.99" height="1" />
    <rect x="0.03" y="19.48" width="13.97" height="1" />
  </React.Fragment>,
  'ColorManagementIcon'
)
