import * as types from '../types/filtersTypes'
import {defaultDateRangeValues} from '../../duck/helpers/dateTime'
import {
    handleSetSearchTerm,
    handleRemoveFilterChip,
    handleChangeFilterChip
} from '../handlers/filterChipsHandler'

let initialState = {
    chips: {},
    liveSearchTerm: null,
    currentSearchTerm: null,
    dateRangeStatisticsValues: defaultDateRangeValues,
    dateRangeOrdersListValues: defaultDateRangeValues
}

export default function filters(state = initialState, action) {
    switch (action.type) {
        case types.SET_LIVE_SEARCH_TERM:
            return {
                ...state,
                liveSearchTerm: action.term.trim()
            }
        case types.SET_FILTER_CHIP:
            return {
                ...state,
                currentSearchTerm: action.term,
                liveSearchTerm: null,
                chips: handleSetSearchTerm(action.page, action.term, state.chips)
            }
        case types.REMOVE_FILTER_CHIP:
            return {
                ...state,
                chips: handleRemoveFilterChip(action.page, action.term, state.chips)
            }
        case types.CHANGE_FILTER_CHIP_STATE:
            return {
                ...state,
                chips: handleChangeFilterChip(action.page, action.term, state.chips)
            }
        case types.SET_DATERANGE_FLOWS_STATISTICS_VALUES:
            return {
                ...state,
                dateRangeStatisticsValues: action.valuesObject
            }
        case types.SET_DATERANGE_ORDERS_LIST_VALUES:
            return {
                ...state,
                dateRangeOrdersListValues: action.valuesObject
            }
        default:
            return state
    }
}