import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CpJobs from './../../CpJobs'
import { unsubscribeFrom } from './../../../shared/duck/helpers/subscriptions'
import {
  getCustomerCpJobs,
  getCustomerJobsIds,
  subscribeToCpJobAdd,
  subscribeToCpJobDelete,
  subscribeToCpJobUpdates,
  subscribeToCpJobsWithChannelMapping
} from './../jobsActions'

class CpJobsWithJobs extends React.Component {
  fetchJobs = this.fetchJobs.bind(this)

  async componentDidMount() {
    await this.fetchJobs()
    if (this.props.licensedCps.length !== 0) {
      await this.fetchJobsFromCloud(true)
    }
  }

  async componentDidUpdate(prevProps) {
    const haveCpLicensesChanged = prevProps.licensedCps.length !== this.props.licensedCps.length;
    const hasPairingUpdated = prevProps.auth.paired !== this.props.auth.paired && this.props.jobs.length === 0;

    if (haveCpLicensesChanged || hasPairingUpdated) {
      await this.fetchJobs();
      await this.fetchJobsFromCloud(true)
      return;
    }

    if(prevProps.jobs.length !== this.props.jobs.length) {
      this.subscribeToCpJobUpdates()    
    }
  }

  componentWillUnmount() {
    unsubscribeFrom(this.props.addCpJobSubscriptions)
    unsubscribeFrom(this.props.deleteJobSubscriptions)
    unsubscribeFrom(this.props.updateJobSubscriptions)
    unsubscribeFrom(this.props.colorMappingChannelJobSubscriptions)
  }

  async fetchJobs() {
    await this.props.getCustomerCpJobs()

    if(!this.props.jobs)
      return
    
    this.subscribeToCpJobAdd()
    this.subscribeToCpJobDelete()
    this.subscribeToCpJobUpdates()        
  }

  async fetchJobsFromCloud(withSubscription) {
    await this.props.getCustomerJobsIds(this.props.licensedCps)   

    if(withSubscription) {
      this.subscribeToCpJobWithColorChannelMapping()
    }
  }

  subscribeToCpJobAdd() {
    unsubscribeFrom(this.props.addCpJobSubscriptions)
    this.props.subscribeToCpJobAdd(this.props.licensedCps)
  }

  subscribeToCpJobDelete() {
    unsubscribeFrom(this.props.deleteJobSubscriptions)
    this.props.subscribeToCpJobDelete(this.props.licensedCps)    
  }

  subscribeToCpJobUpdates() {
    unsubscribeFrom(this.props.updateJobSubscriptions)
    this.props.subscribeToCpJobUpdates(this.props.licensedCps)
  }  

  subscribeToCpJobWithColorChannelMapping() {
    unsubscribeFrom(this.props.colorMappingChannelJobSubscriptions)
    this.props.subscribeToCpJobsWithChannelMapping(this.props.licensedCps)
  }  

  render() {
    return <CpJobs />
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authentication,
    jobs: state.jobs.cpJobs,
    addCpJobSubscriptions: state.jobs.addCpJobSubscriptions,
    deleteJobSubscriptions: state.jobs.deleteJobSubscriptions,
    updateJobSubscriptions: state.jobs.updateJobSubscriptions,
    colorMappingChannelJobSubscriptions: state.jobs.colorMappingChannelJobSubscriptions,
    licensedCps: state.permissionsInfo.licensedCps
  }
}

CpJobsWithJobs.propTypes = {
  getCustomerCpJobs: PropTypes.func.isRequired,
  getCustomerJobsIds: PropTypes.func.isRequired,
  subscribeToCpJobAdd: PropTypes.func.isRequired,
  subscribeToCpJobDelete: PropTypes.func.isRequired,
  subscribeToCpJobUpdates: PropTypes.func.isRequired,
  subscribeToCpJobsWithChannelMapping: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  getCustomerCpJobs,
  getCustomerJobsIds,
  subscribeToCpJobAdd,
  subscribeToCpJobDelete,
  subscribeToCpJobUpdates,
  subscribeToCpJobsWithChannelMapping,
})(CpJobsWithJobs)