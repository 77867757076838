export const updateCustomerInfo = (cpId, role, sitePermissionsCurrentUser) => {
  let updatedPermissions = sitePermissionsCurrentUser.slice()
  sitePermissionsCurrentUser.forEach(permission => {
    if (permission.cpId === cpId)
      permission.role = role
  })
  return updatedPermissions
}

export const findLicensedCps = sitePermissionsCurrentUser => {
  let licensedCps = []

  for (let sitePermission of sitePermissionsCurrentUser) {
    if (sitePermission.licensed)
      licensedCps.push(sitePermission.cpId)
  }

  return licensedCps
}