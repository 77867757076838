import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button/Button'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import styles from '../../Styles/styles'

const LoadingButton = ({ loading, children, classes, ...otherProps }) => (
  <div className={ classes.progressButtonWrapper }>
    <Button disabled={ loading } { ...otherProps }>
      { children }
    </Button>
    {loading && (
      <CircularProgress size={ 24 } className={ classes.buttonProgress } />
    ) }
  </div>
)

export default withStyles(styles)(LoadingButton)
