import { TranslationService } from "../../shared/Services/TranslationService";

interface IJobState {
  state: string;
  userAction: string;
  userActionMessage: string;
}

interface JobStatusProp {
  job: IJobState;
  isJobStateInSelectedCategory: (state: string, category: string) => boolean;
  translationService: TranslationService;
}

export const getJobStatus = (jobStatusProp: JobStatusProp) : string => {
  if(jobStatusProp.job === null) {
    return "Undefined job status";
  }

  if (!jobStatusProp.isJobStateInSelectedCategory(jobStatusProp.job.state, 'onHold')) {
    return jobStatusProp.job.state;
  }

  const message = jobStatusProp.job.userActionMessage;
  if (message !== '' &&
      message !== undefined && 
      message !== null) {
        return message;
      }

  switch (jobStatusProp.job.state) {
    case 'HeldWaitngForConfig': return jobStatusProp.translationService.tr('errors.job.WAITING_FOR_CONFIG');
    case 'Canceled': return jobStatusProp.job.state;
    case 'Held': return jobStatusProp.job.state;
    case 'WaitForUserAction': return jobStatusProp.translationService.tr('errors.job.GENERIC_ERROR');
    default: return jobStatusProp.translationService.tr('errors.job.GENERIC_ERROR');
  }
};