import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import CloseIcon from '../../../assets/icons/CloseIcon'
import PropTypes from 'prop-types'

const styles = theme => ({
  wrapper: {
    position: 'absolute',
    overflow: 'hidden',
    width: '100vw',
    height: '100vh',
    visibility: 'hidden',
    transition: 'visibility .5s, background .5s'
  },
  menu: {
    zIndex: 9999,
    position: 'absolute',
    top: 0,
    left: 0,
    minHeight: '40vh'
  },
  items: {
    margin: '1.6em 2em 3em 2em',
    display: 'inline-block',
    opacity: 0,
    transition: 'opacity .1s'
  },

  background: {
    zIndex: -1,
    position: 'absolute',
    top: '-80%',
    left: '-70%',
    height: 0,
    width: 0,
    borderRadius: '100%',
    background: theme.palette.primary.main,
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.2)',
    transition: 'width .5s ease-in, height .5s ease-in, top .5s linear',
    [ theme.breakpoints.down('sm') ]: {
      '@media (orientation: landscape)': {
        left: '-110%'
      },
      left: '-90%',
      top: '-85%'
    },
    [ theme.breakpoints.up('sm') ]: {
      '@media (orientation: portrait)': {
        left: '-145%',
        top: '-65%'
      },
      left: '-130%',
      top: '-85%'
    },
    [ theme.breakpoints.up('md') ]: {
      '@media (orientation: portrait)': {
        top: '-60%'
      }
    }
  },

  close: {
    position: 'absolute',
    opacity: 0,
    top: '100vh',
    left: '200%',
    color: 'white',
    textAlign: 'center',
    lineHeight: '6em',
    cursor: 'pointer',
    width: '5em',
    height: '5em',
    borderRadius: '100%',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.2)',
    background: theme.palette.primary.main,
    transition: 'opacity .5s, top .5s ease-in',
    outline: 'none',
    '& svg': {
      fontSize: '1.75em'
    },
    [ theme.breakpoints.down('sm') ]: {
      '@media (orientation: landscape)': {
        left: '160%'
      },
      '@media (orientation: portrait)': {
        left: '110%'
      }
    },
    [ theme.breakpoints.up('sm') ]: {
      '@media (orientation: portrait)': {
        left: '150%'
      }
    },
    [ theme.breakpoints.up('md') ]: {
      '@media (orientation: portrait)': {
        left: '140%'
      }
    }
  },

  menuOpen: {
    visibility: 'visible',
    background: 'rgba(250, 250, 250, 0.73)',

    '& $background': {
      width: '300%',
      [ theme.breakpoints.down('sm') ]: {
        '@media (orientation: landscape)': {
          height: '190%'
        },
        '@media (orientation: portrait)': {
          width: '330%'
        }
      },
      [ theme.breakpoints.up('sm') ]: {
        '@media (orientation: landscape)': {
          height: '180%'
        },
        height: '150%'
      },
      [ theme.breakpoints.up('md') ]: {
        '@media (orientation: landscape)': {
          height: '180%'
        },
        height: '120%'
      },
      height: '185%',
      transition: 'width .1s ease-out, height .5s ease-out, top .5s linear'
    },

    '& $close': {
      opacity: 1,
      top: '75%',
      [ theme.breakpoints.down('sm') ]: {
        '@media (orientation: landscape)': {
          top: 'calc(95% - 4em)'
        },
        '@media (orientation: portrait)': {
          top: '130%'
        }
      },
      [ theme.breakpoints.up('sm') ]: {
        '@media (orientation: landscape)': {
          top: 'calc(95% - 1em)'
        },
        '@media (orientation: portrait)': {
          top: '75%'
        }
      },
      [ theme.breakpoints.up('md') ]: {
        '@media (orientation: portrait)': {
          top: '60%'
        }
      },
      transition: 'opacity .5s, top .5s ease-out'
    },

    '& $items': {
      opacity: 1,
      transition: 'opacity .2s .3s'
    }
  }
})

class DropletMenu extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  componentDidMount () {
    document.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  handleOutsideClick = e => {
    if (this.ref.current) {
      const isOut = !this.ref.current.contains(e.target)

      if (isOut) {
        this.handleClose(e)
      }
    }
  }

  handleClose = e => {
    const { onClose } = this.props
    onClose(e)
  }

  render () {
    const { classes, className: classNameProp, children, open } = this.props
    return (
      <div
        className={ classNames(classes.wrapper, classNameProp, {
          [ classes.menuOpen ]: open
        }) }
      >
        <div ref={ this.ref } className={ classes.menu }>
          <div className={ classes.items }>{ children }</div>
          <div className={ classes.close } onClick={ this.handleClose }>
            <CloseIcon />
          </div>
          <div className={ classes.background } />
        </div>
      </div>
    )
  }
}

DropletMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.node
}

/*
document.addEventListener('resize', () => {
  const gcd = (a, b) => (b == 0) ? a : gcd (b, a%b)
  const w = screen.width
  const h = screen.height
  const r = gcd (w, h)
  console.log("Dimensions = ", w, " x ", h)
  console.log("Gcd        = ", r)
  console.log("Aspect     = ", w/r, ":", h/r)
})
*/
export default withStyles(styles)(DropletMenu)
