import React from 'react'
import GMGLogoSVG from '../../../assets/gmg_ColorProof_logo.svg'

const GMGLogo = props => (
  <img
    src={ GMGLogoSVG }
    alt="GMG ColorProof"
    title="GMG ColorProof"
    { ...props }
  />
)

export default GMGLogo
