import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as ReactDOM from 'react-dom'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tippy from '@tippy.js/react'
import DeleteIcon from '../../../../../assets/icons/DeleteIcon'
import LeftIcon from '../../../../../assets/icons/LeftIcon'
import CpTimeAgo from '../../../../shared/Components/Date/CpTimeAgo'
import { NotificationTypes } from '../../../../shared/duck/types/notificationTypes'
import ServiceContext from '../../../../shared/Services/ServiceContext'
import PermissionTypes from '../../../../shared/duck/types/permissionTypes'
import PermissionsValidator from '../../../../shared/duck/helpers/permissionsValidator'
import RoleTypes from '../../../../shared/duck/types/roleTypes'
import Utils from '../../../../shared/duck/helpers/Utils'
import NameWithAlias from '../../../../shared/Components/ListItems/NameWithAlias'
import { handleUserDelete, handleRoleChange } from '../../../../shared/duck/actions/pairingInfoActions'
import { updatePermission } from '../../../../shared/duck/actions/permissionsActions'

class UsersList extends React.Component {
  static contextType = ServiceContext

  state = {
    deleteInProgress: false,
    email: undefined,
  }
  handleRoleChange = this.handleRoleChange.bind(this)
  handleDelete = this.handleDelete.bind(this)

  getSnapshotBeforeUpdate (prevProps, prevState) {
    let scrolledContainer = this.getScrolledContainer()

    if (scrolledContainer) {
      return scrolledContainer.scrollTop
    }

    return 0
  }

  componentDidMount () {
    Utils.userEmail(this.props.auth.currentUser).then((email) => {
      this.setState({email: email});
    });
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let scrolledContainer = this.getScrolledContainer()
    if (scrolledContainer) {
      scrolledContainer.scrollTop = snapshot
    }

    if (prevProps.deleteUserInProgress !== this.props.deleteUserInProgress && !this.props.deleteUserInProgress)
      this.setState({ deleteInProgress: false })
  }

  // That's how the backend generates the unique identifier for the pairing
  cpUserPairingId (cpId, customerId) {
    return cpId + '_' + customerId
  }

  isDeletingPairing (user) {
    return this.state.deleteInProgress === this.cpUserPairingId(user.cpId, user.customerId)
  }

  async handleRoleChange (user, newRole) {
    let newUser = Utils.deepClone(user)
    newUser.role = newRole

    if (user.customerId === this.props.auth.currentUser.username)
      await this.props.updatePermission(newUser.cpId, newUser.role)

    await this.props.handleRoleChange(user.cpId, user.customerId, newRole)
    if (!this.props.graphQLErrors || this.props.graphQLErrors.length === 0)
      return

    if (this.props.graphQLErrors[ 0 ].errorType === 'Unauthorized') {
      this.context.notificationService.showNotification(
        this,
        NotificationTypes.Error,
        this.props.intl.formatMessage({
          id: 'cpSiteUser.noPermissionToSetRole'
        })
      )
    }
    else {
      this.context.notificationService.showNotification(
        this,
        NotificationTypes.Error,
        this.props.intl.formatMessage({ id: 'cpSiteUser.couldNotSetRole' }) + this.props.graphQLErrors[ 0 ].message
      )
    }
  }

  async handleDelete (user) {
    this.context.trackingService.trackEvent(
      'User Pairing',
      'Delete User Pairing',
      '1',
      1
    )

    this.setState({ deleteInProgress: this.cpUserPairingId(user.cpId, user.customerId) })

    const usersCount = this.props.users.filter(u => u.customerId === user.customerId).length
    const removeLastUser = user.customerId === this.props.auth.currentUser.username && usersCount === 1

    const email = await Utils.userEmail(user);
    this.setState({email});

    this.props.deleteUser({
      cpId: user.cpId,
      customerId: user.customerId,
      isPending: user.isPending,
      email,
      site: user.site,
      removeLastUser
    })
  }

  getScrolledContainer () {
    let domNode = ReactDOM.findDOMNode(this)
    if (!domNode) {
      return null
    }
    return domNode.parentNode.parentNode.parentNode
  }

  render () {
    const textId = 'cpSitesUser.noUsers'
    const defaultText = 'No Users'
    const { classes } = this.props

    if (!this.props.users) {
      return (
        <TableRow>
          <TableCell colSpan={ 1 } className={ classes.tableCellSpacer } />
          <TableCell colSpan="1" className={ classNames('tableCell') }>
            <span className={ 'noData' }>
              <FormattedMessage id={ textId } defaultMessage={ defaultText } />
            </span>
          </TableCell>
          <TableCell colSpan={ 1 } className={ classes.tableCellSpacer } />
        </TableRow>
      )
    }

    if (!this.state.email) {
      return (
        <TableRow>
          <TableCell colSpan={ 1 } className={ classes.tableCellSpacer } />
          <TableCell colSpan="1" className={ classNames('tableCell') }>
            <span className={ 'noData' }>
              <FormattedMessage id={ 'cpSitesUser.loading' } defaultMessage={ 'Loading...' } />
            </span>
          </TableCell>
          <TableCell colSpan={ 1 } className={ classes.tableCellSpacer } />
        </TableRow>
      )
    }

    const validator = new PermissionsValidator(this.props.permissions)
    return this.props.users
      .sort(Utils.sortObjectByKey('site', false))
      .map((user, index) => {
        const email = user.email;
        return (
          <TableRow key={ Math.random() }>
            <TableCell className={ classes.tableCellSpacerMobile } />
            <TableCell
              key={ `email-${email}-${Math.random()}` }
              className={ classNames(
                classes.tableCell,
                classes.ellipsis,
                classes.settingTableCellWidth,
                {
                  [ classes.disableDullColor ]: user.isPending
                }
              ) }
            >
              { email }
            </TableCell>
            <TableCell
              key={ `role-${email}-${Math.random()}` }
              className={ classNames(
                classes.tableCell,
                classes.tableCellHideMobile,
                classes.tableCellHideTablet,
                classes.noLeftPadding,
                {
                  [ classes.disableDullColor ]: user.isPending
                }
              ) }
            >
              { validator.hasUserPermissionForCp(user.cpId, PermissionTypes.ManageColorProofGo) &&
                email !== this.state.email && !user.isPending ? (
                <Select
                  disableUnderline={ true }
                  value={ user.role || '' }
                  onChange={ e => { this.handleRoleChange(user, e.target.value) } }
                  displayEmpty
                  name={ `user_role_select_userid_${user.customerId}_cp_${user.cpId}` }
                  IconComponent={ LeftIcon }
                  classes={ {
                    root: classNames(
                      classes.cpSelectBox + ' ' + classes.userAdminRoleSelectBox,
                      {
                        [ classes.disableDullColor ]: user.isPending
                      }
                    ),
                    selectMenu: classes.cpSelectBoxContent,
                    icon: classes.roleIcon
                  } }
                  MenuProps={ {
                    classes: {
                      paper: classes.cpSelectMenuPaper
                    },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    MenuListProps: {
                      className: classes.cpSelectList
                    }
                  } }
                >
                  {[ RoleTypes.Admin, RoleTypes.Operator, RoleTypes.Viewer ].map(
                    (value, index) => {
                      return (
                        <MenuItem
                          className={ classes.cpSelectMenuItem }
                          key={ index }
                          value={ value }
                          classes={ { selected: classes.cpSelectSelected } }
                          selected={ user.role === value }
                        >
                          {value }
                        </MenuItem>
                      )
                    }
                  ) }
                </Select>
              ) : (
                (() => {
                  let ret = this.props.intl.formatMessage({
                    id: `cpSiteUser.role.${user.role}`,
                    defaultMessage: user.role
                  })

                  if (!validator.isCpLicensed(user.cpId)) {
                    ret += `(${this.props.intl.formatMessage({ id: 'cpSiteUser.unlicensed', defaultMessage: "Not licensed" })})`
                  }
                  return ret
                })()
              ) }
            </TableCell>
            <TableCell
              key={ `lastlogin-${email}-${Math.random()}` }
              className={ classNames(classes.tableCell, classes.noLeftPadding, {
                [ classes.disableDullColor ]: user.isPending
              }) }
            >
              <Tippy
                content={
                  !user.lastlogin ? '-' : new Date(user.lastlogin).toLocaleString()
                }
                arrow={ true }
                arrowType="sharp"
                animation="fade"
                placement={ 'top' }
                distance="10"
                className={ classes.tooltips }
                popperOptions={ {
                  placement: 'top',
                  modifiers: {
                    preventOverflow: {
                      enabled: false
                    },
                    hide: {
                      enabled: false
                    }
                  }
                } }
              >
                <span>
                  { !user.lastlogin ? '-' : <CpTimeAgo date={ user.lastlogin } /> }
                </span>
              </Tippy>
            </TableCell>
            <TableCell
              key={ `site-${email}-${Math.random()}` }
              className={ classNames(classes.tableCell, classes.noLeftPadding, {
                [ classes.disableDullColor ]: user.isPending
              }) }
            >
              <Tippy
                content={ user.dongleId }
                arrow={ true }
                arrowType="sharp"
                animation="fade"
                placement={ 'top' }
                distance="10"
                className={ classes.tooltips }
                popperOptions={ {
                  placement: 'top',
                  modifiers: {
                    preventOverflow: {
                      enabled: false
                    },
                    hide: {
                      enabled: false
                    }
                  }
                } }
              >
                <span>
                  { this.props.sites.map(site => {
                    if (site.site === user.site && site.cpId === user.cpId)
                      return <NameWithAlias
                        key={ Math.random() }
                        name={ user.site }
                        alias={ site.alias }
                        classes={ this.props.classes } />
                  }) }
                </span>
              </Tippy>
            </TableCell>
            <TableCell
              key={ `delete-${email}-${Math.random()}` }
              className={ classNames(
                classes.tableCell,
                classes.noLeftPadding,
                classes.tableCellHideMobile,
                classes.tableCellHideTablet
              ) }
            >
              { validator.isUserLicensedAdmin() &&
                !this.isDeletingPairing(user) &&
                <span className={ classNames(classes.deleteIcon) }>
                  <DeleteIcon
                    id={ `delete-${email}` }
                    onClick={ () => this.handleDelete(user) }
                  />
                </span>
              }

              { validator.isUserLicensedAdmin() &&
                this.isDeletingPairing(user) &&
                <span className={ classNames(classes.deleteProgressColor) }>
                  <CircularProgress color={ 'inherit' } size={ 20 } />
                </span>
              }
            </TableCell>
            <TableCell className={ classes.tableCellSpacerMobile } />
          </TableRow>
        )
      })
  }
}

function mapStateToProps (state) {
  return {
    auth: state.authentication,
    graphQLErrors: state.pairingInfo.graphQLErrors,
    permissions: state.permissionsInfo.customerInfo
  }
}

UsersList.propTypes = {
  auth: PropTypes.object.isRequired,
  handleUserDelete: PropTypes.func.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  updatePermission: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  sites: PropTypes.array,
  users: PropTypes.array
}

export default connect(
  mapStateToProps,
  {
    handleUserDelete,
    handleRoleChange,
    updatePermission
  }
)(UsersList)