import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../../shared/Styles/styles'
import Tippy from '@tippy.js/react'

function JobWorkflow({ classes, workflow }) {
  if (!workflow || !workflow.length) return '-'

  return workflow.length > 1 ? (
    <Tippy
      content={workflow.join('\n')}
      arrow={true}
      arrowType="sharp"
      animation="fade"
      placement={'top'}
      distance="20"
      className={classes.tooltips}
      popperOptions={{
        placement: 'top',
        modifiers: {
          preventOverflow: {
            enabled: false
          },
          hide: {
            enabled: false
          }
        }
      }}
    >
      <span>{`${workflow.length} Workflow`}</span>
    </Tippy>
  ) : (
    workflow[0]
  )
}

export default withStyles(styles)(JobWorkflow)
