
import React from "react";
import * as constants from '../Types/constants'
import { Channel } from '../Types/types';
import styles from '../../../shared/Styles/styles'
import { withStyles } from '@material-ui/core/styles/index'
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LeftIcon from '../../../../assets/icons/LeftIcon'
import {
  MenuItem, 
  Select,
} from "@material-ui/core";
import AddCMYK from "./AddCMYK";
import AddLab from "./AddLab";
import AddReplaceColor from "./AddReplaceColor";
import { Field } from "react-final-form";

interface ChannelProp {
  classes: any;
  channel: Channel;
  values: any;
}

const AddChannelRow: React.FC<ChannelProp> = ({classes, channel, values}) => {
  const renderItem = (item: string, index: number) => {
    if (item === constants.SEPARATOR) {
      return <hr key={index} className={ classes.menuSeparator } />;
    }
    return (
      item !== constants.SELECT
        ? <MenuItem value={item} key={index} className={ classes.cpSelectMenuItem } classes={ { selected: classes.cpSelectSelected } }>{item}</MenuItem>
        : <MenuItem value={item} key={index} className={ classes.cpSelectMenuItem } classes={ { selected: classes.cpSelectSelected } }><em>{item}</em></MenuItem>
    );
  }

  const renderReplace = (selectedItem: string, channelName: string, values: any) => {
    switch (selectedItem) {
      case constants.DEFINE_IN_CMYK:
        return <AddCMYK classes={classes} name={`${channelName}.cmyk`}/>
        
      case constants.DEFINE_IN_LAB:
        return <AddLab classes={classes} name={`${channelName}.lab`}/>
    
      default:
        return <AddReplaceColor classes={classes} selectedItem={selectedItem} values={values} name={`${channelName}.replaceColor`} />
    }
  }

  const required = (value: any) => (value == constants.SELECT ? 'Required' : undefined)

  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Paper className={ classes.gridBoxLabel }>{channel.name}</Paper>
      </Grid>
      <Grid item xs={4}>
        <Field name={`${channel.id}.select`} classes={classes} item={channel} values={values} validate={required}>
          {({input, item, values}) => (
            <div>
              <Select
                name={input.name}
                value={input.value}
                IconComponent={ LeftIcon }
                onChange={input.onChange}
                disableUnderline={true}            
                classes={ {
                  root: classes.cpSelectBox,
                  selectMenu: classes.cpSelectBoxContent,
                  icon: classes.gridChannelMappingIcon,
                } }
                MenuProps={ {
                  classes: {
                    paper: classes.cpSelectMenuPaper
                  },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  MenuListProps: {
                    className: classes.cpSelectList
                  }
                } }
                inputProps={{
                  name: "max-width",
                  id: "max-width"
                }}
              >
                {channel.availableColors.map((color: string, index: number) => {
                    if (values[item.id].select !== constants.SELECT && color === constants.SELECT) {
                      return;
                    }
                    return renderItem(color, index);
                })}
              </Select>
            </div>
          )}
        </Field>
      </Grid>
      <Grid item xs={4}>
        { renderReplace(values[channel.id].select, channel.id, values) }
      </Grid>
    </React.Fragment>
  );
}

export default withStyles(styles)(AddChannelRow)