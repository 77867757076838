import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { client } from './app/shared/config/Clients'
import Container from './app/shared/Services/Container'
import ServiceContext from './app/shared/Services/ServiceContext'
import Routes from './app/store/Routes'
import withTheme from './app/shared/config/Theme'
import withIntl from './app/shared/Localization'
import { clearAuthToken } from './app/store/localStorage'

class App extends Component {
  // Initializing services here and passing them via the ServiceContext to all child components in render.
  services = {
    navigationService: Container.NavigationService(this.props.history),
    notificationService: Container.NotificationService(),
    trackingService: Container.TrackingService(),
    sessionService: Container.SessionService(),
    featureFlagService: Container.FeatureFlagService(),
    translationService: Container.TranslationService(),
    // TODO: Review this and potentially refactor it
    leftSideBar: Container.LeftSideBar()
  }

  signOut = () => {
    client.resetStore()
    clearAuthToken()
  }

  render () {
    // global body vertical overflow hidden to solve issue (52116). Best solution was to hide scroll for body as
    // we are using container (VBox) over body. Also changing body overflow to auto in notification.
    document.body.style.overflowY = 'hidden'

    return (
      <ServiceContext.Provider value={ this.services }>
        <Routes />
      </ServiceContext.Provider>
    )
  }
}
const styles = theme => ({})

export default withApollo(
  withStyles(styles)(
    withRouter(
      withTheme(
        withIntl(App)
      )
    )
  )
)