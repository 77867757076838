import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <rect
      x="-3"
      y="11.5"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.9706 12)"
      width="30"
      height="1.1"
    />
    <rect
      x="11.5"
      y="-3"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.9707 12.0001)"
      width="1.1"
      height="30"
    />
  </React.Fragment>,
  'CloseIcon'
)
