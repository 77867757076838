
import React from 'react';
import styles from '../../../shared/Styles/styles'
import { withStyles } from '@material-ui/core/styles/index'
import { ChannelValueRow } from './UI/ChannelValueRow';
import { composeValidators, mustBeNumber, maxValue, minValue, required } from '../Types/types';

interface Props {
  classes: any,
  name: string,
}

const AddCMYK: React.FC<Props> = (props: Props) => {

  return (
    <ChannelValueRow channelRowItems={[
      {
        placeholder: 'C',
        name: `${props.name}.c`,
        validator: composeValidators(required, mustBeNumber, maxValue(100), minValue(0))
      },
      {
        placeholder: 'M',
        name: `${props.name}.m`,
        validator: composeValidators(required, mustBeNumber, maxValue(100), minValue(0))
      },
      {
        placeholder: 'Y',
        name: `${props.name}.y`,
        validator: composeValidators(required, mustBeNumber, maxValue(100), minValue(0))
      },
      {
        placeholder: 'K',
        name: `${props.name}.k`,
        validator: composeValidators(required, mustBeNumber, maxValue(100), minValue(0))
      },
    ]}/>
  );
};


export default withStyles(styles)(AddCMYK)