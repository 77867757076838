import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography/index'
import FileIcon from '../../../../assets/icons/FileIcon'
import CheckmarkIcon from '../../../../assets/icons/CheckmarkIcon'

const ProgressBar = ({ files, fileWithMeta, filesUploadProgress, classes }) => {
  let bytesToSize = (bytes) => {
    const sizes = [ 'B', 'KB', 'MB', 'GB', 'TB' ]
    if (bytes === 0)
      return '0 Byte'

    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[ i ]
  }

  let getUploadPercent = (fileName) => {
    if (!filesUploadProgress || filesUploadProgress.length === 0)
      return 0

    let uploadProgress = filesUploadProgress.find(f => f.Name === fileName)

    if (!uploadProgress)
      return 0

    return uploadProgress.percent
  }

  const percent = getUploadPercent(fileWithMeta.meta.name)

  return (
    <span style={ { order: 2, zIndex: 1000000, pointerEvents: 'auto' } }>
      {files ? (
        <React.Fragment>
          <table style={ { marginLeft: 20 } }>
            <tbody>
              <tr>
                <td>
                  <FileIcon
                    viewBox="0 0 1000 1000"
                    className={ classes.fileIcon }
                  />
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td style={ { textAlign: 'justify', width: 220, display: 'block' } }>
                          <Typography className={ classNames(classes.fileNameLabel) } noWrap={ true }>
                            { fileWithMeta.meta.name }
                          </Typography>
                        </td>
                        <td>
                          <span
                            style={ { marginLeft: '-230%' } }
                            className={ classes.fileNameLabel }>
                            {
                              bytesToSize(fileWithMeta.meta.size)
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className={ classes.progressBar }>
                            { percent > 0 && (
                              <div
                                className={ classes.progressBarBackground }
                                style={ { width: percent + '%' } }
                              />
                            ) }
                          </div>
                        </td>
                        { percent === 100 &&
                          <td>
                            <CheckmarkIcon className={ classes.crossButton } />
                          </td>
                        }
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      ) : null }
    </span>
  )
}

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  fileWithMeta: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  filesUploadProgress: PropTypes.array.isRequired
}

export default ProgressBar