module.exports = {
  // eslint-disable
  // this is an auto generated file. This will be overwritten

  getCustomerPairInfo : `query GetCustomerPairInfo {
    getCustomerPairInfo {
      items {
        cpId
        dongleId
        version
        site
        licensed
        lastSeen
        licenseName
        alias
        isHub
        role
        flowLicensed
        customers {
          customerId
          email
          lastlogin
          role
          isPending
          color255
        }
        isOnline
      }
    }
  }
  `,
  getCustomerInfo : `query GetCustomerInfo {
    getCustomerInfo {
      items {
        cpId
        dongleId
        version
        site
        licensed
        lastSeen
        licenseName
        alias
        isHub
        role
        flowLicensed
        customers {
          customerId
          email
          lastlogin
          role
          isPending
          color255
        }
        isOnline
      }
    }
  }
  `,
  listCustomerCpJobs : `query ListCustomerCpJobs {
    listCustomerCpJobs {
      items {
        cpId
        id
        name
        state
        userAction
        userActionMessage
        site
        created
        workflow
        printer
        printerType
        images
        copies
        widthInMM
        heightInMM
        mediaName
      }
    }
  }
  `,
  listCustomerPrinters : `query ListCustomerPrinters {
    listCustomerPrinters {
      items {
        cpId
        printerId
        name
        status
        site
        media
        mediaId
        isCalibrated
        lastCalibrationDate
        mediaSize
        mediaSizeId
        errorMessage
        printerType
        caliSets {
          Name
          Calibrated
          CaliSetDetail
        }
        inks {
          InkName
          InkPercentage
        }
        inkSet
        medias {
          Name
          id
          Sizes {
            Name
            id
          }
        }
      }
    }
  }
  `,
  listCustomerHfWfs : `query ListCustomerHfWfs {
    listCustomerHfWfs {
      items {
        cpId
        id
        WorkflowName
        HotfolderName
        StandardName
        HotfolderId
        WorkflowId
      }
    }
  }
  `,
  listCustomerCpJobDetails : `query ListCustomerCpJobDetails {
    listCustomerCpJobDetails {
      items {
        cpId
        id
        jobId
        order
        measurementResults {
          Timestamp
          Application
          MeasurementDevice
          DeviceType
          Angle
          Filter
          Light
          Colors {
            c
            m
            y
            k
            L
            a
            b
            Spectrum {
              Values
              StartNm
              IncrementNm
            }
          }
        }
        VerficationResults {
          StripName
          Rules {
            RuleName
            AnalysisType
            AnalysisValue
            AnalysisResult
          }
          DeviceType
          DeviceFilter
        }
        Name
        Workflow
        ProofStandard
        Dimension
        width
        height
        FileFormat
        PageNo
        Colors {
          ColorName
          R
          G
          B
        }
      }
    }
  }
  `,
  listCustomerCpJobDetailsByJob : `query ListCustomerCpJobDetailsByJob(
    $input: ListCustomerCpJobDetailsByJobInput!
  ) {
    listCustomerCpJobDetailsByJob(input: $input) {
      items {
        cpId
        id
        jobId
        order
        measurementResults {
          Timestamp
          Application
          MeasurementDevice
          DeviceType
          Angle
          Filter
          Light
          Colors {
            c
            m
            y
            k
            L
            a
            b
            Spectrum {
              Values
              StartNm
              IncrementNm
            }
          }
        }
        VerficationResults {
          StripName
          Rules {
            RuleName
            AnalysisType
            AnalysisValue
            AnalysisResult
          }
          DeviceType
          DeviceFilter
        }
        Name
        Workflow
        ProofStandard
        Dimension
        width
        height
        FileFormat
        PageNo
        Colors {
          ColorName
          R
          G
          B
        }
      }
    }
  }
  `,
  listFlows : `query ListFlows {
    listFlows {
      items {
        hubId
        id
        customerId
        name
        created
        clients
        clientId
        targetHfwfId
        targetWfName
      }
    }
  }
  `,
  getOrderStatistics : `query GetOrderStatistics($input: OrderStatisticsInput) {
    getOrderStatistics(input: $input) {
      flowId
      fromDate
      toDate
      number
      mediaUsage
      data
    }
  }
  `,
  customerListOrder : `query CustomerListOrder($input: CustomerListOrderInput) {
    customerListOrder(input: $input) {
      items {
        id
        targetId
        sourceId
        flowId
        printerNames
        printerTypes
        standardNames
        imageNames
        validationResults
        totalFileSize
        status
        created
        targetDongleIdDoc
        sourceDongleIdDoc
        flowNameDoc
        type
        customJobId
        substrateId
        thirdPartyID
        thirdPartyPayload
      }
    }
  }
  `,
  getCustomerDomains : `query GetCustomerDomains {
    getCustomerDomains {
      items {
        CustomerDomainPair
        customerId
        created
        domain
        email
        overallContingent {
          total
          used
          balance
          expirationDate
          isContingentAvailable
        }
        contingents {
          total
          used
          balance
          expirationDate
          isContingentAvailable
        }
      }
    }
  }
  `,
  listSubstrates : `query ListSubstrates {
    listSubstrates {
      items {
        id
        created
        domain
        name
        file
        data
        proofMedia
      }
    }
  }
  `,
  listTemplates : `query ListTemplates {
    listTemplates {
      items {
        id
        domain
        language
        name
        bucket
        created
        fields
      }
    }
  }
  `,
  getTemplateSignedUrl : `query GetTemplateSignedUrl($input: GetTemplateSignedUrlInput!) {
    getTemplateSignedUrl(input: $input)
  }
  `,
  listTemplatesIntern : `query ListTemplatesIntern($input: ListTemplatesInternInput!) {
    listTemplatesIntern(input: $input) {
      items {
        id
        domain
        language
        name
        bucket
        created
        fields
      }
    }
  }
  `,
  listSubstratesIntern : `query ListSubstratesIntern($input: ListSubstratesInternInput!) {
    listSubstratesIntern(input: $input) {
      items {
        id
        created
        domain
        name
        file
        data
        proofMedia
      }
    }
  }
  `,
  listDomains : `query ListDomains {
    listDomains {
      items {
        CustomerDomainPair
        customerId
        created
        domain
        email
        overallContingent {
          total
          used
          balance
          expirationDate
          isContingentAvailable
        }
        contingents {
          total
          used
          balance
          expirationDate
          isContingentAvailable
        }
      }
    }
  }
  `,
  listHfWfs : `query ListHfWfs {
    listHfWfs {
      items {
        cpId
        id
        WorkflowName
        HotfolderName
        StandardName
        HotfolderId
        WorkflowId
      }
    }
  }
  `,
  listCpJobDetails : `query ListCpJobDetails {
    listCpJobDetails {
      items {
        cpId
        id
        jobId
        order
        measurementResults {
          Timestamp
          Application
          MeasurementDevice
          DeviceType
          Angle
          Filter
          Light
          Colors {
            c
            m
            y
            k
            L
            a
            b
            Spectrum {
              Values
              StartNm
              IncrementNm
            }
          }
        }
        VerficationResults {
          StripName
          Rules {
            RuleName
            AnalysisType
            AnalysisValue
            AnalysisResult
          }
          DeviceType
          DeviceFilter
        }
        Name
        Workflow
        ProofStandard
        Dimension
        width
        height
        FileFormat
        PageNo
        Colors {
          ColorName
          R
          G
          B
        }
      }
    }
  }
  `,
  listCpJobs : `query ListCpJobs {
    listCpJobs {
      items {
        cpId
        id
        name
        state
        userAction
        userActionMessage
        site
        created
        workflow
        printer
        printerType
        images
        copies
        widthInMM
        heightInMM
        mediaName
      }
    }
  }
  `,
  listCpPrinters : `query ListCpPrinters {
    listCpPrinters {
      items {
        cpId
        printerId
        name
        status
        site
        media
        mediaId
        isCalibrated
        lastCalibrationDate
        mediaSize
        mediaSizeId
        errorMessage
        printerType
        caliSets {
          Name
          Calibrated
          CaliSetDetail
        }
        inks {
          InkName
          InkPercentage
        }
        inkSet
        medias {
          Name
          id
          Sizes {
            Name
            id
          }
        }
      }
    }
  }
  `,
  listCpActions : `query ListCpActions {
    listCpActions {
      items {
        id
        cpId
        created
        action
        actionParams {
          name
          value
        }
      }
    }
  }
  `,
  listCpFlows : `query ListCpFlows {
    listCpFlows {
      items {
        hubId
        id
        customerId
        name
        created
        clients
        clientId
        targetHfwfId
        targetWfName
      }
    }
  }
  `,
  listOrders : `query ListOrders {
    listOrders {
      items {
        id
        cpId
        hotfolder
        jobticket
        copies
        bucket
        encryptionCertId
        encryptionData
        files {
          key
          FileName
          FileType
          FileSize
          MD5Checksum
        }
        thirdPartyID
        thirdPartyPayload
        flowId
        workflowId
        hotfolderId
        orderToken
        type
        customJobId
        substrateId
      }
    }
  }
  `,
  getOrder : `query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      cpId
      hotfolder
      jobticket
      copies
      bucket
      encryptionCertId
      encryptionData
      files {
        key
        FileName
        FileType
        FileSize
        MD5Checksum
      }
      thirdPartyID
      thirdPartyPayload
      flowId
      workflowId
      hotfolderId
      orderToken
      type
      customJobId
      substrateId
    }
  }
  `,
  verifyOrder : `query VerifyOrder($input: VerifyOrderInput!) {
    verifyOrder(input: $input) {
      type
      result
      message
    }
  }
  `,
  getCertificate : `query GetCertificate($input: GetCertificateInput) {
    getCertificate(input: $input) {
      cpId
      id
      certificate
    }
  }
  `,
}
