import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippy.js/react'

const UserIcon = ({customer, tooltipsClass}) => {
    const defaultText = 'N/A'
    const getCustomerColor = () => {
        let color255 = customer ? customer.color255 : 0
        // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
        // Adam Cole, 2011-Sept-14
        // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
        let r, g, b
        const h = color255 / 255
        const i = ~~(h * 6)
        const f = h * 6 - i
        const q = 1 - f
        switch (i % 6) {
            case 0: r = 1; g = f; b = 0; break;
            case 1: r = q; g = 1; b = 0; break;
            case 2: r = 0; g = 1; b = f; break;
            case 3: r = 0; g = q; b = 1; break;
            case 4: r = f; g = 0; b = 1; break;
            case 5: r = 1; g = 0; b = q; break;
            default: r = 1; g = f; b = 0; break;
        }
        const color = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2)

        return color
    }

    const iconText = () => {
        if(!customer)
            return defaultText

        let parts = customer.email.split("@")[0].split(".");
        if (parts.length === 1)
            parts = parts[0].split("_");

        let label = parts[0].charAt(0);
        if (parts.length === 1) 
            label += parts[0].charAt(1);
        else if (parts.length === 2) 
            label += parts[1].charAt(0);

        return label.toUpperCase();
    }
    
    return(
        <Tippy
            content={customer ? customer.email : defaultText}
            arrow={true}
            arrowType="sharp"
            animation="fade"
            placement={'top'}
            distance="10"
            className={tooltipsClass}
            popperOptions={{
                placement: 'top',
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    },
                    hide: {
                        enabled: false
                    }
                }
            }}>                
            <div style={{width: '50px', height: '50px', borderRadius: '50%', backgroundColor: getCustomerColor(), display: "inline-block", textAlign: 'center', padding: '15px 0', fontWeight: '800', fontSize: 15}}>                
                {iconText()}                
            </div>
        </Tippy>
    )
}

UserIcon.propTypes = {
    customer: PropTypes.object.isRequired,
    tooltipsClass: PropTypes.string.isRequired
}

export default UserIcon